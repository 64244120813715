var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('HeaderTop'),_c('div',{staticClass:"w"},[_c('div',{staticClass:"tips"},[_c('span',[_vm._v(_vm._s(_vm.$t("goodslist.We_have_searched_for"))+"\" "),_c('span',{staticStyle:{"color":"#333"}},[_vm._v(_vm._s(this.$route.query.searchValue))]),_vm._v(" \""+_vm._s(_vm.$t("goodslist.related_content_for_you_come_and_buy_it"))+"~")]),_c('span',{staticClass:"agree"},[_vm._v(_vm._s(_vm.$t("goodslist.Agreed"))+" "),_c('a',{staticClass:"search",attrs:{"href":"/question?requestId=20"}},[_vm._v(_vm._s(_vm.$t("goodslist.Search_Service_Terms")))])])]),_c('el-tabs',{staticClass:"tabContainer",on:{"tab-click":_vm.switchTab},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"Agent Shopping from - 1688","name":"1688"}},[_c('div',{staticClass:"goods"},[_c('ul',_vm._l((_vm.goodsList3),function(item){return _c('li',{key:item.id},[_c('a',{attrs:{"href":""}},[_c('router-link',{attrs:{"to":{
                    name: 'GoodsDetail',
                    query: { itemId: item.itemId, type: _vm.activeName },
                  },"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"title":item.title}}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":item.title,"placement":"bottom"}},[_c('span',{staticClass:"title"},[_c('a',[_vm._v(_vm._s(item.title))])])]),_c('span',{staticClass:"d_bottom"},[_c('b',{staticClass:"d_price"},[_vm._v(_vm._s(_vm.currency + " " + _vm.moneyRates(item.price)))]),_c('b',{staticClass:"sold"},[_vm._v(_vm._s(_vm.$t("goodslist.Sold") + "：" + item.sold))])])],1)],1)])}),0)])]),(_vm.tab != 'imageSearch')?_c('el-tab-pane',{attrs:{"label":"Agent Shopping from - Taobao","name":"taobao"}},[_c('div',{staticClass:"goods"},[_c('ul',_vm._l((_vm.goodsList2),function(item){return _c('li',{key:item.id},[_c('a',{attrs:{"href":""}},[_c('router-link',{attrs:{"to":{
                    name: 'GoodsDetail',
                    query: { itemId: item.itemId, type: _vm.activeName },
                  },"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"title":item.title}}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":item.title,"placement":"bottom"}},[_c('span',{staticClass:"title"},[_c('a',[_vm._v(_vm._s(item.title))])])]),_c('span',{staticClass:"d_bottom"},[_c('b',{staticClass:"d_price"},[_vm._v(_vm._s(_vm.currency + " " + _vm.moneyRates(item.price)))]),_c('b',{staticClass:"sold"},[_vm._v(_vm._s(_vm.$t("goodslist.Sold") + "：" + item.sold))])])],1)],1)])}),0)])]):_vm._e(),(_vm.tab != 'imageSearch')?_c('el-tab-pane',{attrs:{"label":"Agent Shopping from - Micro","name":"micro"}},[_c('div',{staticClass:"goods"},[_c('ul',_vm._l((_vm.goodsList1),function(item){return _c('li',{key:item.id},[_c('a',{attrs:{"href":""}},[_c('router-link',{attrs:{"to":{
                    name: 'GoodsDetail',
                    query: { itemId: item.itemId, type: _vm.activeName },
                  },"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image),expression:"item.image"}],attrs:{"title":item.title}}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":item.title,"placement":"bottom"}},[_c('span',{staticClass:"title"},[_c('a',[_vm._v(_vm._s(item.title))])])]),_c('span',{staticClass:"d_bottom"},[_c('b',{staticClass:"d_price"},[_vm._v(_vm._s(_vm.currency + " " + _vm.moneyRates(item.price)))]),_c('b',{staticClass:"sold"},[_vm._v(_vm._s(_vm.$t("goodslist.Sold") + "：" + item.sold))])])],1)],1)])}),0)])]):_vm._e(),(_vm.isLoading)?_c('van-loading',{staticStyle:{"text-align":"center","height":"80px","line-height":"80px"},attrs:{"size":"24px","type":"spinner"}},[_vm._v(" "+_vm._s(_vm.$t("goodslist.loading"))+" ")]):_vm._e()],1)],1),_c('Bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }