import {
    goodsSearch
} from "@/Api/index";

export const common = {
    data() {
        return {}
    },
    methods: {
        // 只跳转到GoodsList页面 不请求商品列表接口
        getResult() {
            if (this.input === "") {
                this.$message.info("search Value can't empty!");
                // console.log("关键词不能为空！！");
                return;
            }else if(this.input.indexOf("http")>=0){
                var type = ""
                var itemId=""
                if(this.input.indexOf("weidian")>=0){
                    type = "micro"
                    var param =   this.getParamsFromUrl(this.input)
                    itemId=param.itemID
                    location.href="/goodsDetail?itemId="+itemId+"&type="+type
                    // this.$router.push({
                    //     path:"/goodsDetail",
                    //     query:{
                    //         itemId:itemId,
                    //         type:type
                    //     }
                    // })
                }
                if(this.input.indexOf("tmall")>=0 || this.input.indexOf("taobao")>=0){
                    type="taobao"
                    var param =   this.getParamsFromUrl(this.input)
                    itemId=param.id
                    location.href="/goodsDetail?itemId="+itemId+"&type="+type
                    // this.$router.push({
                    //     path:"goodsDetail",
                    //     query:{
                    //         itemId:itemId,
                    //         type:type
                    //     }
                    // })
                }
                if(this.input.indexOf("1688")>=0){
                    type="1688"
                    var param =   this.input.match(/\d+/g)
                    itemId=param[1]
                    location.href="/goodsDetail?itemId="+itemId+"&type="+type
                    // this.$router.push({
                    //     path:"/goodsDetail",
                    //     query:{
                    //         itemId:itemId,
                    //         type:type
                    //     }
                    // })
                }
            } else {
                this.$router.push({
                    path: '/goodsList',
                    query: {
                        inputValue: this.input,
                        searchValue:this.input
                    }
                })
                // console.log('输入的值：' + this.input);
                // this.getGoods()
            }
        },
        getParamsFromUrl(url) {
            const queryString = url.split('?')[1];
            if (!queryString) return {};
            
            const params = {};
            const paramPairs = queryString.split('&');
            
            for (let pair of paramPairs) {
              const [key, value] = pair.split('=');
              params[key] = decodeURIComponent(value);
            }
            
            return params;
          },
        // 不跳转 只请求商品列表接口
        getGoods(keyword, type = "micro", pageNum = 1) {
            this.isLoading = true;
            if (this.isover == true) {
                return;
            }
            // @1 假设到底  不重复请求
            this.isover = true
            //判断关键词搜索是否发生变化
            if (keyword !== localStorage.getItem("searchValue" + type)) {
                //数组初始化
                this.goodsList1 = [];
                this.goodsList2 = [];
                this.goodsList3 = [];
            }

            // 请求查询接口 展示列表
            goodsSearch("/index/keyWordSearch?keyword=" + keyword + "&type=" + type + "&pageNum=" + pageNum).then(
                (res) => {
                    var i = 0;
                    if (res.data.length == 0) { // 如果这次请求为空数组说明到底了 ，不在往后走请求
                        this.isover = true
                    }
                    if (type === "micro") {
                        for (i = 0; i < res.data.length; i++) {
                            this.goodsList1.push(res.data[i])
                        }
                    } else if (type === "taobao") {
                        for (i = 0; i < res.data.length; i++) {
                            this.goodsList2.push(res.data[i])
                        }
                    } else if (type === "1688") {
                        for (i = 0; i < res.data.length; i++) {
                            this.goodsList3.push(res.data[i])
                        }
                    }
                    // @2 有数据了 再改回false （前后呼应 完成闭环）
                    this.isover = false
                    this.isLoading = false;
                },
                (err) => {
                    console.log(err);
                }
            );
        },
    },

}