<template>
  
  <div>
    <header-top></header-top>
    <div class="shop w">
      <div class="s_head">
      <img :src="this.$route.query.logo" alt="" />
      <span>{{ this.$route.query.name }}</span>
    </div>
    <div class="goods">
      <ul >
        <li v-for="item in shopList" :key="item.id" @click="toGoodsDetail(item.itemId)">
          <img :src="item.itemImg" alt="" />
          <span class="goods_title">
            <!-- <a href="" :title="item.title">{{ item.title.slice(0, 15) }}...</a> -->
            <a href="" :title="item.itemName">{{ item.itemName }}</a>
          </span>
          <span class="goods_price">{{ currency }} {{   item.price }}</span>
          <span class="goods_user"> saler {{ item.sold }}</span>
          <!-- <span>{{ getFormattedTimeDifference(item.last_time) }}前浏览</span> -->
        </li>
      </ul>
    </div>
    <van-loading
      v-if="isLoading"
      size="24px"
      type="spinner"
      style="text-align: center; height: 80px; line-height: 80px"
    >
    {{ $t('shopgoods.loading') }}
    </van-loading>
    </div>

  </div>
</template>

<script>
import { getShopGoods } from "@/Api/index";
import HeaderTop from "../mainPage/HeaderTop.vue";
export default {
  components: { HeaderTop },
  name: "ShopList",
  data() {
    return {
      shopList: [],
      isLoading: false,
      page:1,
      currency:''
    };
  },
  mounted() {},
  methods: {
    toGoodsDetail(itemId) {
      // console.log(itemId)
      window.open(
        "/goodsDetail?itemId=" + itemId + "&type=" + this.$route.query.type,
        "_blank"
      );
    },
       // 监听滚动事件的方法
       handleScroll() {
      // 计算：页面滚动的高度scrollTop、窗口可视区域高度clientHeight、页面总高度scrollHeight
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - scrollTop - clientHeight < 20 ) {
        // 当距离页面底部不足10px且非正在加载状态时，触发加载数据的方法
        if(this.isLoading==true){  //如果发起了请求 并且还没有响应 说明在加载中
          return
        }
  
          this.isLoading = true;
          this.page++
          this.getGoodsList()
      }
    },
    getGoodsList(){
      var data = {};
      data["type"] = this.$route.query.type;
      data["shopId"] = this.$route.query.shopId;
      data["page"] = this.page;

    this.isLoading = true;

    getShopGoods(data).then((res) => {
      if(this.page==1){
        this.shopList = res.data;
      }else{
        res.data.forEach(item => {
          this.shopList.push(item)
        });
      }

      this.isLoading = false;
    });
    }
  },
  created() {
    this.currency=this.getCurrency
    this.getGoodsList()
        // 监听滚动事件
        window.addEventListener("scroll", this.handleScroll);
   
  },
};
</script>

<style lang="less" scoped>
.shop {
  .s_head {
    // text-align: center;
    // width: 100%;
    min-width: fit-content;
    padding: 20px;
    border-radius: 4px;
    margin-top: 70px;
    margin: 70px 0 20px;
    background-color: #f8f8f8;
    img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      vertical-align: middle;
    }
    span {
      cursor: pointer;
      font-size: 18px;
      color: #333;
      font-weight: 500;
    }
  }

  /* 商品列表 */
  .goods {
    ul{
      display: flex;
    // justify-content: flex-start;
    // justify-content: space-around;
    justify-content: space-between;
    flex-wrap: wrap;
    }

    li {
      width: 220px;
      // margin-right: 20px;
      margin-bottom: 18px;
      border: 1px solid transparent;
      // border: 1px solid red;
      span {
        display: block;
        margin: 5px 0px 4px 4px;
        a {
          color: #333;
          font-size: 12px;
        }
        a:hover {
          text-decoration: underline;
          color: #ef0c25;
        }
      }
      img {
        // width: 220px;
        width: 100%;
        height: 200px;
      }
      // 文字溢出隐藏
      .goods_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .goods_price {
        color: #333;
        font-size: 16px;
        font-weight: 600;
      }
      .goods_user {
        color: #999;
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    // ul:nth-child(5n) {
    //   margin-right: 0;
    //   // background-color: pink;
    // }

    /* 过渡效果 transition：参与过渡的属性 过渡的持续时间*/
    li:hover {
      /* scale: 1.1; */
      transform: scale(1.1);
      transition: transform 0.5s;
      box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.1);
      border: 1px solid #e4e4e4;
    }
  }
  // 最后一行商品不贴边显示？ 待验证
  .goods:not(:last-child) {
    justify-content: flex-start;
  }
}
</style>
