<template>
  <div class="person_center_page">
    <div class="person_info_panel">
      <div class="my_info">
        <div class="avatar" style="cursor: pointer" @click="goUserCenter">
          <img class="userAvatar" :src="userInfo.avatar" />
        </div>
        <div class="username">{{ userInfo.username }}</div>
        <div class="uid" @click="invite" style="cursor: pointer">
          ID：{{ userInfo.usercode }}
        </div>
      </div>
      <div class="balance_info">
        <div class="label">{{ $t("center.My_Balance") }}</div>
        <div class="text">{{ currency }} {{ moneyRates(userInfo.money) }}</div>
        <div class="tools">
          <div class="topup" @click="open">{{ $t("center.Recharge") }}</div>
          <div class="withdraw" @click="withdraw">
            {{ $t("center.Withdraw") }}
          </div>
        </div>
      </div>
      <div class="other_info">
        <div class="tr" style="cursor: pointer" @click="toPoint">
          <div class="label">{{ $t("center.Points") }}</div>
          <div class="text">{{ userInfo.score }}</div>
        </div>
        <div class="tr" @click="toCoupon()" style="cursor: pointer">
          <div class="label">{{ $t("center.My_Coupons") }}</div>
          <div class="text">{{ userInfo.couponCount }}</div>
        </div>
        <div
          class="tr"
          style="cursor: pointer"
          :title="this.$t('center.service_fee_desc')"
        >
          <div class="label">{{ $t("center.Service_Fee") }}</div>
          <div class="text">3%</div>
        </div>
        <div
          class="tr"
          @click="$router.push({ path: '/person/promotion' })"
          style="cursor: pointer"
          :title="this.$t('promotion.Commission_rate_desc')"
        >
          <div class="label">{{ $t("center.Rebate_ratio") }}</div>
          <div class="text">3%</div>
        </div>
        <div class="tr invite" @click="invite">
          <el-button type="danger" round>{{ $t("center.invite") }}</el-button>
        </div>
      </div>
    </div>
    <div class="person_goods_panel">
      <el-row class="title_con">
        <el-col :span="7">{{ $t("center.Trade_name") }}</el-col>
        <el-col :span="7">{{ $t("center.Submit_time") }}</el-col>
        <el-col :span="5">{{ $t("center.Order_Status") }}</el-col>
        <el-col :span="5">{{ $t("center.operate") }}</el-col>
      </el-row>
      <div class="content">
        <el-row class="goods_row" v-for="item in tableData" :key="item.id">
          <el-col :span="7" class="goodsInfo">
            <!-- <span>1件</span> -->
            <img :src="item.order_img" alt="" />
            <div class="info">
              <a :href="item.goods_url" target="_blank">
                <p class="goodsInfo_title" :title="item.title">
                  {{ item.title.slice(0, 20) + "..." }}
                </p>
              </a>
              <p class="color_type" v-html="item.option"></p>
            </div>
          </el-col>
          <el-col class="tr" :span="7">{{ item.add_time_text }}</el-col>
          <el-col class="tr" :span="5">{{ item.stateValue }}</el-col>
          <el-col class="tr" :span="5">
            <div v-if="item.state == 0">
              <el-button type="success" @click="toPay(item)">{{
                $t("center.pay")
              }}</el-button>
            </div>
            <div v-if="[1].indexOf(item.state) >= 0">
              <el-button type="success" @click="orderDetailInfo(item)">{{
                $t("center.order_details")
              }}</el-button>
              <el-dialog
                :title="$t('center.order_details')"
                style="text-align: left"
                width="800px"
                :visible.sync="item.dialogTableVisibleOrderDetail"
              >
                <orderDetailInfo
                  :orderInfo="item"
                  v-if="item.dialogTableVisibleOrderDetail"
                ></orderDetailInfo>
              </el-dialog>
              <br />
            </div>
            <div v-if="item.state == 2">
              <el-button type="success" @click="okOrder(item)">{{
                $t("center.Confirm_Order")
              }}</el-button>
              <br />
            </div>
            <div v-if="item.state == 3">
              <el-button type="success" @click="orderTrackInfo(item)">{{
                $t("center.View_Logistics")
              }}</el-button>
              <br />
              <el-dialog
                :title="$t('center.Logistics_information')"
                style="text-align: left"
                :visible.sync="item.dialogTableVisibleOrderTrack"
              >
                <track-info :orderTrackInfos="orderTrackInfos"></track-info>
              </el-dialog>
            </div>
            <div v-if="item.state == 4">
              <el-button type="success" @click="toSubmit()">{{
                $t("center.Submit_package")
              }}</el-button>
              <br />
            </div>
            <div v-if="[1, 2, 3, 4].indexOf(item.state) >= 0">
              <el-button type="success" @click="toMessag(item)">{{
                $t("center.message")
              }}</el-button>
              <el-dialog
                title="message"
                style="text-align: left"
                width="960px"
                :visible.sync="item.dialogTableVisibleUserMessage"
              >
                <user-message
                  v-if="item.dialogTableVisibleUserMessage"
                  :currentItemMessage="item"
                ></user-message>
              </el-dialog>
              <br />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 充值对话框 -->
    <el-dialog
      :title='this.$t("center.Tips")'
      :visible.sync="rechargeVisable"
      width="480px"
      :before-close="handleClose"
    >
      <span style="font-size: 16px;">{{ this.$t("center.Please_enter_amount") + "(CNY)" }}</span>
      <el-input v-model="rechargeMoney" type="number"></el-input>
      <span style="font-size: 14px; color: red;" > ≈ {{ moneyRates(rechargeMoney)  }} {{ this.currency }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeVisable = false">{{  this.$t("center.Cancel") }}</el-button>
        <el-button type="primary" @click="recharge()"
          >{{ this.$t("center.Sure") }}</el-button
        >
      </span>
    </el-dialog>
    

        <!-- 提现 -->
        <el-dialog
      :title='this.$t("center.Tips")'
      :visible.sync="withdrawVisable"
      width="480px"
    >
      <span style="font-size: 16px;">{{ this.$t("center.Please_enter_the_withdrawal_amount") }}</span>
      <el-input v-model="withdrawMoney" type="number"></el-input>
      <span style="font-size: 14px; color: red;" > ≈ {{ moneyRates(withdrawMoney)  }} {{ this.currency }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="withdrawVisable = false">{{  this.$t("center.Cancel") }}</el-button>
        <el-button type="primary" @click="withdrawAction"
          >{{ this.$t("center.Sure") }}</el-button
        >
      </span>
    </el-dialog> 
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import { getUserInfo, userWithDraw } from "@/Api/user";
import { myOrderUp } from "@/Api/order";
import { orderDetail, orderOk, orderTrack } from "@/Api/order";
import orderDetailInfo from "@/components/others/orderDetail.vue";
import UserMessage from "@/components/user/UserMessage.vue";
import TrackInfo from "@/components/others/trackInfo.vue";
export default {
  components: { orderDetailInfo, UserMessage, TrackInfo },
  name: "person-center",
  data() {
    return {
      tableData: [],
      userInfo: {},
      orderInfo: {},
      currentItemMessage: {},
      orderTrackInfos: {},
      currency: "CNY",
      rechargeMoney:0,
      rechargeVisable:false,
      tipRecharge:"",
      withdrawVisable:false,
      withdrawMoney:0
    };
  },
  methods: {
    handleClose(){
      
    },
    goUserCenter() {
      this.$router.push({
        path: "/person/account",
      });
    },
    invite() {
      const url = location.host + "/register?invite=" + this.userInfo.usercode;
      const textField = document.createElement("textarea");
      textField.value = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      document.body.removeChild(textField);
      Dialog.alert({
        title: this.$t("center.Invite_friends_to_register"),
        message: url,
        theme: "round-button",
        width: "420px",
        height: "182px",
        confirmButtonText: this.$t("center.copy_Link"),
      }).then((res) => {
        console.log(res);
        Toast({
          message: this.$t("center.Copy_successful"),
          duration: 1000,
        });
      });
    },
    // 转换金额
    moneyRates(money) {
      return this.moneyRate(money);
    },
    // withdraw  提现
    withdraw() {
      this.withdrawVisable=true
    },
    withdrawAction(){
      if ( this.withdrawMoney== 0 || this.withdrawMoney == null) {
            this.$message.error(this.$t("center.Amount_cannot_be"));
            return;
          } else if (this.withdrawMoney < 0) {
            this.$message.error(this.$t("center.Amount_must_be_greater_than"));
            return;
          }
          if (parseFloat(this.withdrawMoney) > this.userInfo.money) {
            this.$message.error(
              this.$t(
                "center.The_withdrawal_amount_cannot_be_greater_than_the_wallet_limit"
              )
            );
            return;
          }
      //     /**
      //      * 走提现接口
      //      */
          var data = {};
          data["money"] = this.withdrawMoney;
          this.withdrawVisable=false
          userWithDraw(data).then((res) => {
            if (res.code == 1) {
              this.toGetUserInfo();
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
    },
    //去提交包裹
    toSubmit() {
      this.$router.push({
        path: "/person/wareHouse",
      });
    },
    // toPoint
    toPoint() {
      this.$router.push({
        path: "/person/wallet",
      });
    },
    // 去优惠券
    toCoupon() {
      this.$router.push({
        path: "/person/coupon",
      });
    },
    okOrder(item) {
      var data = {};
      data["oid"] = item.id;
      orderOk(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.toGetUserInfo();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 发送信息
    toMessag(item) {
      item.dialogTableVisibleUserMessage = true;
    },
    // 去支付
    toPay(item) {
      window.open("/payorder?orderId=" + item.id, "_blank");
    },
    // 订单详情
    orderDetailInfo(item) {
      var data = {};
      data["orderId"] = item.id;
      orderDetail(data).then((res) => {
        this.orderInfo = res.data;
        item.dialogTableVisibleOrderDetail = true;
      });
    },
    // 打开充值
    open() {
        this.rechargeVisable=true
    },
    recharge(){
        if (this.rechargeMoney == 0 || this.rechargeMoney == null) {
            this.$message.error(this.$t("center.Amount_cannot_be"));
            return;
          } else if (this.rechargeMoney < 0) {
            this.$message.error(this.$t("center.Amount_must_be_greater_than"));
            return;
          }
          this.rechargeVisable=false
          this.$router.push({
            path: "/userRecharge",
            query: {
              money: this.rechargeMoney,
            },
          });
    },
    toGetUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
          return;
        }
        this.userInfo = res.data;
      });
    },
    toGetmyOrderUp() {
      myOrderUp().then((res) => {
        this.tableData = res.data;
      });
    },
    // 物流信息
    orderTrackInfo(item) {
      var data = {};
      data["oid"] = item.id;
      orderTrack(data).then((res) => {
        item.dialogTableVisibleOrderTrack = true;
        this.orderTrackInfos = res.data;
      });
    },
  },
  created() {
    this.toGetUserInfo();
    this.currency = this.getCurrency;
    this.toGetmyOrderUp();
  },
};
</script>

<style scoped lang="less">
.person_info_panel {
  height: 200px;
  padding-top: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#3a3a3a),
    to(#515151)
  );
  background: linear-gradient(180deg, #3a3a3a, #515151);
  border-radius: 4px 4px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.person_info_panel .my_info {
  color: #fff;
  font-size: 12px;
  margin: 8px 0 3px;
  width: 240px;
  box-sizing: border-box;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
}
.my_info .username {
  margin: 8px 0 3px 0;
}
.my_info .avatar {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  // border: 1px solid red;
}
.userAvatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.person_info_panel .label {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #999;
}
.person_info_panel .text {
  font-size: 30px;
  color: #fff;
  height: 40px;
  line-height: 40px;
}
.person_info_panel .balance_info {
  width: 180px;
  height: 150px;
  padding-top: 40px;
  // border: 1px red solid;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.person_info_panel .balance_info .tools {
  display: flex;
  color: #fff;
}
.balance_info .tools div {
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  padding: 2px 12px;
  border-radius: 4px;
  border: 1px solid #fff;
}
.balance_info .tools div:first-child {
  margin-right: 10px;
}
.person_info_panel .other_info {
  display: flex;
  flex: 1;
  height: 150px;
  padding-top: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px;
}
.other_info .tr {
  width: 33.33%;
  text-align: center;
}
.invite {
  display: flex;
  justify-items: center;
  button {
    float: left;
    width: 100px;
    height: 40px;
    padding: 10px;
    border: #ef0c25;
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    background: linear-gradient(to right, #fd5532, #ef0c25);
  }
  button:hover {
    background: linear-gradient(to right, #e34d2d, #d60b20);
  }
}

.person_goods_panel {
  .title_con {
    text-align: center;
    line-height: 36px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    overflow: hidden;
    color: #4f4f4f;
    padding: 10px 0;
    font-weight: 700;
    font-size: 14px;
    display: flex;
  }
  .content {
    min-height: 450px;
    overflow: auto;
    align-items: center;
    button.el-button.el-button--success {
      width: 180px;
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    button.el-button.el-button--success:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
    .goods_row {
      margin-bottom: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .goodsInfo {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
      }
      .info {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        .goodsInfo_title {
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 4px;
        }
        .color_type {
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #999;
          padding: 4px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          text-overflow: ellipsis;
        }
      }
    }
    .tr {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: normal;
      -ms-flex-align: normal;
      align-items: normal;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      button {
        width: 90px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .page_con {
    .page {
      float: right;
      .el-pager li.active {
        border-radius: 4px;
        color: #fff;
        font-weight: 400;
      }
    }
  }
}
</style>
