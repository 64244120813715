<template>
  <div class="container">
    <div class="header">
      <h4>{{ $t('wallet.Consumption_records') }}</h4>
      <div class="judge">
        <div>
          <span>{{ $t('wallet.Query_by_date_period') }} : </span>
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            :start-placeholder="$t('wallet.Start_date')"  
            :end-placeholder="$t('wallet.End_date')"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <span> {{ $t('wallet.Query_by_type') }} : </span>
          <el-select v-model="value" :placeholder="$t('wallet.Please_select')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <!-- <el-button type="danger" round @click="search()">查询</el-button> -->
          <button @click="search()" style="cursor: pointer;">{{ $t('wallet.Query') }}</button>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="create_time_text"
          :label="$t('wallet.Date')"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="action" :label="$t('wallet.Behavior')" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="content"
          label="note"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
             <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          :label="$t('wallet.Consumption_amount')"
          align="center"
        >
        <template slot-scope="scope">
            {{ moneyRates(scope.row.money) }} {{ currency }}
          </template>
        
      </el-table-column>
        <el-table-column
          prop="after_money"
          :label="$t('wallet.Balance')"
          align="center"
        >
        <template slot-scope="scope">
            {{ moneyRates(scope.row.after_money) }} {{ currency }}
          </template>
      </el-table-column>
      </el-table>
      <div class="pageNo">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecord, getRecordType } from "@/Api/record";
export default {
  name:"wallet-vue",
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text:this.$t('wallet.Last_week'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text:this.$t('wallet.Last_month'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text:this.$t('wallet.Last_three_months'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      options: [],
      value: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 10,
      currency:"CNY",//显示货币
    };
  },
  methods: {
    moneyRates(money){
      return this.moneyRate(money)
    },
    getRecordList(data = {}) {
      if (typeof data["page"] == "undefined") {
        data["page"] = this.currentPage;
      }
      getRecord(data).then((res) => {
        if (res.code == 1) {
          this.currentPage = res.data.current_page;
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // 改变页面大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.getRecordList();
    },
    // 改变页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getRecordList();
    },
    //消费类型
    getRecordTypeInfo() {
      getRecordType().then((res) => {
        this.options = res.data;
      });
    },
    search() {
      var starttime = Date.parse(this.value2[0]) / 1000;
      var stoptime = Date.parse(this.value2[1]) / 1000;
      var data = {};
      if (starttime > 0) {
        data["startTime"] = starttime;
      }
      if (stoptime > 0) {
        data["stopTime"] = stoptime;
      }
      if (this.value != "") {
        data["action"] = this.value;
      }
      this.getRecordList(data);
    },
  },
  created() {
    this.currency = this.getCurrency
    this.getRecordList();
    this.getRecordTypeInfo();
  },
};
</script>

<style scoped lang="less">
.header {
  h4 {
    padding: 10px 0;
  }
  .judge {
    display: flex;
    justify-content: space-between;
    button {
      width: 86px;
      height: 32px;
      line-height: 32px;
      color: #fff;
      border: 0;
      border-radius: 15px;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    button:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
  }
}
.table {
  margin-top: 20px;
}
.pageNo {
  text-align: right;
}
</style>
