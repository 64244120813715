import VueI18n from "vue-i18n";
import vue from "vue";
import zh from "@/i18n/zh"
import en from "@/i18n/en"
import  german from "@/i18n/german"
import  portuguese from "@/i18n/portuguese"

vue.use(VueI18n)
var currentIndex = localStorage.getItem("langIndex")
if(currentIndex==null){
    currentIndex="en"
}else if(currentIndex==="中文"){
    currentIndex="zh"
}else if(currentIndex.toLowerCase()=="english"){
    currentIndex="en"
}else if(currentIndex.toLocaleLowerCase()=="german"){
    currentIndex="german"
}else if(currentIndex.toLocaleLowerCase()=="portuguese"){
    currentIndex="portuguese"
}
const i18n = new VueI18n({
    locale:currentIndex,
    messages:{
        en,
        zh,
        german,
        portuguese
    }
})
export default i18n