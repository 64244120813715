<template>
  <!-- middle部分 -->
  <div class="middle">
    <div class="w">
      <!-- 标语 -->
      <span class="slogan">{{ $t("middle.Shop_China_with_Seacloudbuy") }}</span>
      <!-- 输入框 -->
      <div class="middleInput">
        <el-input
          :placeholder="$t('middle.Please_enter_keywords_or_product_links')"
          v-model="input"
          @keyup.enter.native="getResult()"
          class="input-with-select"
        >
          <el-button
            slot="suffix"
            style="
              width: 100%;
              height: 100%;
              background-color: transparent;
              border: 0;
            "
          >
            <van-uploader :after-read="uploadImage">
              <i class="el-input__icon el-icon-picture-outline"> </i>
            </van-uploader>
          </el-button>

          <el-select
            class="inputTop"
            v-model="select"
            slot="prepend"
            :placeholder="$t('middle.choose')"
          >
            <el-option :label="$t('middle.goods')" value="1"></el-option>
            <el-option :label="$t('middle.Shop')" value="2"></el-option>
          </el-select>
          <el-button slot="append" class="inputSearch" @click="getResult()">
            {{ $t("middle.Search") }}
          </el-button>
        </el-input>
      </div>
      <!-- 进度条 -->
      <van-steps :active="active">
        <van-step>{{ $t("middle.Buyer_purchase_order") }}</van-step>
        <van-step>{{ $t("middle.Merchants_receive_orders") }}</van-step>
        <van-step>{{ $t("middle.Verify_parcel") }}</van-step>
        <van-step>{{ $t("middle.Transaction_complete") }}</van-step>
      </van-steps>
    </div>
    <el-dialog
      :title="information.title"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span v-html="information.content"></span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">{{ $t('cart.Cancel') }}</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">{{
          $t("cart.Sure")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { common } from "@/Api/common";
import { photoUpload, compressImage, question } from "@/Api/index";

export default {
  data() {
    return {
      input: "",
      select: this.$t("middle.goods"),
      active: 4,
      information: "",
      dialogVisible: false,
    };
  },
  created() {
    this.loadDialog();
  },
  methods: {
    loadDialog() {
      var timeOk = localStorage.getItem("timeOk");
      var time = new Date().getTime() / 1000;
      if (timeOk != null && time - timeOk < 3600 * 2) {
        return;
      }
      question().then((res) => {
        if (res.code == 1 && res.data != "") {
          this.information = res.data;
          localStorage.setItem("timeOk", time);
          this.dialogVisible = true;
        }
      });
    },
    // 上传照片
    uploadImage(file) {
      compressImage(file.content, 400, 500, 0.4).then((compressedBase64) => {
        var data = {};
        let imgSearch = localStorage.getItem("imgSearch");
        if (imgSearch) {
          localStorage.removeItem("imgSearch");
        }
        localStorage.setItem("imgSearch", file.content);
        data["base64"] = compressedBase64.split(";base64,")[1];
        data["type"] = "1688"; //目前只有1688
        // console.log(data["base64"])

        this.$toast.loading({
          message: "loading",
          duration: 0,
        });
        photoUpload(data).then((res) => {
          if (res.code == 1) {
            var data = {};
            // 这个地方可以优化  拿到 res.data 之后 以跳转的方式到另一个页面 然后走photoSearch方法
            data["imageId"] = res.data;
            data["type"] = "1688"; //目前只有1688
            this.$toast.clear();
            location.href =
              "/goodsImageSearch?tab=imageSearch&imageId=" +
              data["imageId"] +
              "&type=1688";
          }
        });
      });
    },
  },
  mixins: [common], // 使用common中的getResult（）方法
};
</script>

<style lang="less" scoped>
/* middle部分 */
.middle {
  width: 100%;
  min-width: fit-content;
  // height: 440px;
  height: 700px;
  // background: url("/src/assets/home_bg.png");

  background: url("/src/assets/home_bg11.png") no-repeat;
  background-size: 100% 100%;
}
.slogan {
  padding-top: 85px;
  display: block;
  text-align: center;
  // color: #ef0c25;
  color: #fff;
  font-size: 50px;
  font-weight: 500;
  padding-bottom: 30px;
  font-family: PingFang-SC-Regular, PingFang-SC;
}
/* input 输入框 */
.inputTop {
  width: 156px;
}
.inputSearch {
  width: 156px;
  font-size: 28px;
  font-weight: 300;
}
/* .input-with-select{
padding-bottom: 30px;
background-color: #fff;
} */
.middleInput {
  padding: 0 80px 30px 80px;
}
</style>
