export default {
          middle:{
                    Shop_China_with_Seacloudbuy:"Shop China with Seacloudbuy",
                    Please_enter_keywords_or_product_links:"Please enter keywords or product links",
                    goods:"goods",
                    Choose:"Choose",
                    Shop:"shop",
                    Buyer_purchase_order:"Buyer purchase order",
                    Merchants_receive_orders:"Merchants receive orders",
                    Verify_parcel:"Verify parcel", 
                    Transaction_complete:"Transaction complete",
                    Search:"Search",

          },
          describe:{
                    One_stop_service:"One-stop service",
                    One_click_order:"One click order",
                    Ship_to_warehouse:"Ship to warehouse",
                    Quality_inspection:"Quality inspection ",
                    Global_Freight:"Global Freight",
          },
          goodsdetail:{
                    Starting_purchase_of:"Starting purchase of ",
                    items:"items!",
                    Service_Terms:"Service Terms",
                    About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes_restricted_items_logistics_route_restrictions_and_volume_and_weight_restrictions:"About customs logistics Due to differences in customs policies among countries some countries or regions are more sensitive to special commodities so there may be embargoes restricted items logistics route restrictions and volume and weight restrictions",
                    Find_similar:"Find similar",
                    Promote_and_share:"Promote and share",
                    Favorite_Product:"Favorite",
                    Sales_volume:"Sales volume",
                    Enter_the_store:"Enter the store",
                    Force_refresh :"Force refresh",
                    modification:"modification",
                    "After_payment_the_buyer_will_provide_purchasing_services_for_you_from_09:00-18:00_(UTC+8)":"After payment, the buyer will provide purchasing services for you from 09:00-18:00 (UTC+8)",
                    Source_of_this_product:"Source of this product",
                    SeaCloud_can_entrust_buyers_to_purchase_for_you:"SeaCloud can entrust buyers to purchase for you",
                    Merchant:"Merchant",
                    SeaCloud_Certified_warehouse_shipping_costs:"SeaCloud Certified warehouse shipping costs",
                    SeaCloud_certified_warehouse:" SeaCloud certified warehouse",
                    your_address:"your address",
                    Estimate_international_shipping_costs:"Estimate international shipping costs",
                    Please_select_properties:"Please select properties",
                    leave_a_message:"leave a message",
                    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them:"Make a note for the purchased goods. If there are no special purchasing requirements, you don’t need to write them.",
                    The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default:"The displayed products are all from third-party platforms, and SeaCloud only provides information search, purchasing, and shipping services. SeaCloud cannot authenticate the quality and authenticity of goods. If you use SeaCloud purchasing service, you accept the above risks by default.",
                    agreed:"agreed",
                    Buy_now:"Buy now",
                    Add_to_Cart:"Add to Cart",
                    embargo:"embargo",
                    Tobacco_alcohol_and_contraband_are_not_accepted:"Tobacco, alcohol and contraband are not accepted.",
                    Prohibited_items_include_but_are_not_limited_to_the_following_items_Swiss_Army_knives_a_full_set_of_kitchen_knives_kitchen_knives_paper_cutters_letter_openers_gardening_knives_scissors_saws_seam_ripper_seam_ripper_pure_fruit_and_vegetable_juices_inflatable_lighters_wine_putty_knife_cheese_Western_cheese_hairy_crab_bamboo_sword_wooden_knife_military_knife_card_universal_knife_card_skate_shoes_other_outdoor_knives_magnetic_components_Swiss_army_knife_end_mill_carving_knife_cigarette_label_cigarette_Card_vajra_bell_vajra_pestle_yue_knife_seeds_etc:"Prohibited items include, but are not limited to, the following items: Swiss Army knives, a full set of kitchen knives, kitchen knives, paper cutters/letter openers, gardening knives/scissors/saws, seam ripper/seam ripper, pure fruit and vegetable juices, inflatable lighters , wine, putty knife, cheese/Western cheese, hairy crab, bamboo sword/wooden knife, military knife card/universal knife card, skate shoes, other outdoor knives, magnetic components, Swiss army knife, end mill, carving knife, cigarette label/cigarette Card, vajra bell/vajra pestle/yue knife, seeds, etc./切纸/拆信刀,园艺/刀/剪/锯,拆线器/拆线刀,纯果蔬汁,充气打火机,葡萄酒,油灰刀,芝士/西式奶酪,大闸蟹,竹剑/木刀,军刀卡/万能刀卡,冰刀鞋,其他户外刀具,磁性元器件,瑞士军刀,立铣刀,雕刻刀,烟标/烟卡,金刚铃/金刚杵／钺刀，种子等。",
                    Purchase_limit:"Purchase limit",
                    Medicines_liquids_paste_goods_and_cosmetic_powders_are_restricted_export_goods_by_China_Customs_Please_ask_before_ordering:"Medicines, liquids, paste goods, and cosmetic powders are restricted export goods by China Customs. Please ask before ordering",
                    customer_service:"【customer service】",
                    Kind_tips:"Kind tips" ,
                    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them_down:"Make a note for the purchased goods. If there are no special purchasing requirements, you don’t need to write them down.",
                    About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes:"[About customs logistics] Due to differences in customs policies among countries, some countries or regions are more sensitive to special commodities, so there may be embargoes, restricted items, logistics route restrictions, and volume and weight restrictions.",
                    The_goods_you_purchase_will_be_sent_to_the_SeaCloud_warehouse_After_arriving_at_the_warehouse_we_will_inspect_the_goods_for_you_and_take_photos_of_the_goods:"The goods you purchase will be sent to the SeaCloud warehouse. After arriving at the warehouse, we will inspect the goods for you and take photos of the goods.",
                    You_can_view_and_manage_products_in_My_Warehouse:"  You can view and manage products in [My Warehouse] ",
                    If_you_are_not_satisfied_with_the_goods_arriving_at_the_SeaCloud_warehouse_you_can_apply_for_a_return_or_exchange_within_5_days_of_arrival_at_the_warehouse_The_purchaser_will_negotiate_with_the_merchant_on_your_behalf_to_handle_the_after_sales_service:"If you are not satisfied with the goods arriving at the SeaCloud warehouse, you can apply for a return or exchange within 5 days of arrival at the warehouse. The purchaser will negotiate with the merchant on your behalf to handle the after-sales service." ,
                    No_store_information_found:"No store information found",
                    Please_read_and_agree_before_proceeding:"Please read and agree before proceeding. " ,
                    Please_select_specification_properties:"Please select specification properties",
                    Share_invite_code:"Share invite code  ", 
                    copy_Link:" copy Link  ",
                    qty:"qty",
                    stock:"stock",
                    Description_text:"Description text",
                    After_sales_service:"After-sales service",
                    Purchasing_instructions:"Purchasing instructions",
                    product_details:"product details",
                    loading:"loading...",
                    Copy_successful:"Copy successful",
                    Tips:"Tips",
                    Sure:"Sure",
                    Cancel:"Cancel",
          },
          bottom:{   
                    Massive_merchandise:"Massive merchandise",
                    Commodity_purchase:"Commodity purchase",
                    Cost_savings:"Freight consolidation",
                    If_you_purchase_goods_from_different_merchants_we_will_help_you_consolidate_them_into_one_package_and_send_them_to_your_country:"If you purchase goods from different merchants, we will help you consolidate them into one package and send them to your country.",
                    service_quality:"Quality inspection",
                    Provide_efficient_mature_and_stable_service_support:"Provide efficient, mature and stable service support",
                    Quick_response:"Service quality",
                    Help_you_solve_your_difficulties_in_the_shortest_time:"Help you solve your difficulties in the shortest time",
                    customer_service:"customer service ",
                    Help_Center:"Help Center",
                    contact_us:"contact us",
                    Purchasing_demo:"Purchasing demo",
                    Services_and_fees:"Services and fees",
                    Return_and_exchange_service:"Return and exchange service",
                    payment_methods:"payment methods",
                    Recharge_method:"Recharge method",
                    Paypal_payment:"Paypal payment",
                    International_Credit_Card_Payment:"International Credit Card Payment",
                    Delivery_related:"Delivery related",
                    Logistics_shipping_fee:"Logistics shipping fee",
                    Centralized_packaging:"Centralized packaging",
                    Customs_and_taxes:"Customs and taxes",
                    Source_of_this_product:"We will help you buy products from all online and offline channels in China.",
                    Precautions_for_receiving_goods:"Precautions for receiving goods",
                    After_sales_service:"After-sales service",
                    Product_storage_period:"Product storage period",
                    Inspection_rules:"Inspection rules",
                    Insurance_and_indemnity:"Insurance and indemnity",
                    YuICP_No_2024000320:"YuICP No. 2024000320",
                    About_Seacloud:"About Seacloud",
                    Terms_of_Service_and_User_Management:"Terms of Service and User Management",
                    Privacy_Notice:"Privacy Notice",
                    Company_name_SEACLOUD_CN_TECHNOLOGY_CO_LIMITED_Company_registration_number_24052668:"Company name: SEACLOUD (CN) TECHNOLOGY CO., LIMITED Company registration number: 24052668",
                    Henan_Cross_Sea_Yungou_Technology_Co_Ltd:"Henan Cross Sea Yungou Technology Co., Ltd",

                   },
          index:{    
                    Front_page:"Front page",
                    Shopping_cart:"Shopping cart",
                    My_Order:"My Order",
                    My_warehouse:"My warehouse",
                    Preview_package:"Preview package",
                    My_package:"My package",
                    Transaction_Record:"Transaction Record",
                    My_Points:"My Points",
                    Coupons:"Coupons",
                    Account_setup:"Account setup",
                    Trading_log:"Transaction Record",
                    Promoter_Programm:"Promoter Programm"
           },
           account:{
                    personal_data:"Personal data",
                    my_privilege :"My privilege",
                    Level_authority:"Level authority",
                    International_Shipping_Discount:"International Shipping Discount",
                    Insurance_Discount:"Insurance Discount",
                    Value_added_service_discounts:"Value-added service discounts",
                    Free_Aftersale_Operation_Fee:"Free Aftersale Operation Fee",
                    Used_Available_Month:"Used/Available(Month)",   
                    my_level:"my level",
                    growth_value:"growth value",
                    Shipping_address:"Shipping address",
                    Add_shipping_address:"Add shipping address",
                    you_have_created:"you have created",
                    Work:"Work",             
                    Address:"Address",
                    Operate:"Operate",
                    Edit:"Edit",
                    Delete:"Delete",
                    Set_as_default:"Set as default",
                    Default_address:"Default address",
                    You_have_not_added_a_delivery_address_please_add_a_delivery_address:"You have not added a delivery address, please add a delivery address",
                    New_shipping_address:" New shipping address",
                    Consignee:"Consignee",
                    name:"name",
                    Region:"Region",
                    country:"Country",
                    State_Province:"State/Province",
                    City:"City",
                    Details_address:"Details address",
                    Zip_code:"Zip code",
                    Phone_number:"Phone number",
                    Label:"Label",
                    Home:"Home",
                    Default_URL:" Default URL",
                    Cancel:"Cancel",
                    Sure:"Sure",
                    Account_safety:"Account safety",
                    Credit_card_billing_address:" Credit card billing address",
                    name_cannot_be_blank:"name cannot be blank",
                    Country_cannot_be_null:"Country cannot be null",
                    State_Province_cannot_be_blank:"State/Province cannot be blank",
                    City_cannot_be_empty:"City cannot be empty",
                    Please_select_at_least_one_activity_type:"Please select at least one activity type",
                    Zip_code_cannot_be_empty:"Zip code cannot be empty",
                    Address_cannot_be_empty:"Address cannot be empty",
                    Phone_number_cannot_be_empty:"Phone number cannot be empty",
                    Added_address_successfully:"Added address successfully",
                    Delete_successful:"Delete successful",
                    Setting_successful:"Setting successful",
                    Public_Member:"Public Member",
                    Silver_Member:"Silver Member",
                    Gold_Member:"Gold Member",
                    Platinum_Member:"Platinum Member",
                    Diamond_Member:"Diamond Member",
                    Black_Gold_Member:"Black Gold Member",
                    Black_Gold_PLUS:"Black Gold PLUS",
           },
          headertop:{
                    Please_enter_keywords_or_product_links:"Please enter keywords or product links",
                    Please_select:"Please select",
                    goods:"goods",
                    Shop:"Shop",
                    More_features:"More features",
                    Freight_estimate:"Freight estimate",
                    Language_Currency:"Language/Currency",
                    language:"language",
                    Currency:"Currency",
                    Saving:"Save",
                    Log_in:"Log in",
                    register:"register",
                    Personal_Center:"Personal Center",
                    Shopping_cart:"Shopping cart",
                    My_Order:"My Order",
                    My_warehouse:"My warehouse",
                    My_package:"My package",
                    Trading_log:"Transaction Record",
                    Sign_out:"Sign out",
                    Categories:"Categories",
                    Commodity:"Commodity",
                    Ladies:"Ladies", 
                    bag:"bag",
                    Men:"Men", 
                    Jewelry:"Jewelry",
                    shoe:"shoe",
                    Sankeng_Clothing:"Sankeng Clothing", 
                    work_out:"work out",
                    trousers:"trousers", 
                    skirt_suit:"skirt suit", 
                    Jacket:"Jacket",
                    coat:"coat",
                    travel_backpack:"travel backpack", 
                    fashion_handbags:"fashion handbags", 
                    Travel_series:"Travel series", 
                    small_bag:"small bag", 
                    Bags_accessories:"Bags accessories", 
                    // coat:"coat",
                    // Jacket:"Jacket", 
                    // trousers:"trousers", 
                    // work_out:"work out", 
                    // shoe:"shoe",
                    jewelry:"jewelry",
                    belt:"belt",
                    Glasses:"Glasses", 
                    hat:"hat",
                    scarf:"scarf", 
                    Accessories:"Accessories", 
                    hair_accessories:"hair accessories",
               },
            goodslist:{
                    We_have_searched_for:"We have searched for",
                    related_content_for_you_come_and_buy_it:"related content for you come and buy it",
                    Agreed:"Agreed",
                    Search_Service_Terms:"《Search Service Terms》",
                    micro:"micro",
                    Taobao:"Taobao",
                    loading:"loading... ",
                    Sold:"Sold"
               },
          shopgoods:{
                    loading:"loading... ",
               },
               goodsrecommend:{
                    Everyone_is_looking:"Best-selling product",
               },
          center:{
                    My_Balance:"My Balance",
                    Recharge:"Recharge",
                   Withdraw:"Withdraw",
                   Points:"Points",
                   Service_Fee:"Service Fee",
                   Rebate_ratio:"Rebate ratio",
                   service_fee_desc:"Labor costs for purchasing third-party goods and following up on orders",
                   My_Coupons:"My Coupons",
                   My_growth_value:"My growth value",
                   Trade_name:"Trade name",
                   Submit_time:"Submit time",
                   Order_Status:"Order Status",
                   operate:"operate",
                   pay:"pay",
                   order_details:"Details",
                   Confirm_Order:"Confirm Order",
                   View_Logistics:"View Logistics",
                   Logistics_information:"Logistics information",
                   Submit_package:"Submit ",
                   message:"message",
                   Please_enter_the_withdrawal_amount:"Please enter the withdrawal amount",
                   Tips:"Tips",
                   Sure:"Sure",
                   Cancel:"Cancel",
                   Amount_cannot_be:"Amount cannot be 0！",
                   Amount_must_be_greater_than:"Amount must be greater than 0",
                   The_withdrawal_amount_cannot_be_greater_than_the_wallet_limit:"The withdrawal amount cannot be greater than the wallet limit",
                   Please_enter_amount:"Please enter amount",
                   The_amount_you_input_is:"The amount you input is:",
                   invite:"invite",
                   Copy_successful:"Copy successful",
                   Invite_friends_to_register:"Invite friends to register",
                   copy_Link:" copy Link  ",
               },
               cart:{
                    Commodity_information:"Commodity information",
                    Note:"Note",
                    Price:"Price",
                    qty:"qty",
                    Amount:"Amount",
                    Operate:"Operate",
                    Product_remarks:"Product remarks",
                    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it:"Leave a message for purchasing If you don't have special purchasing requirements you don't need to write it",
                    Sure:"Sure",
                    Cancel:"Cancel",
                    Delete:"Delete",
                    Delete_selected_items:"Delete selected items",
                    selected:"selected ",
                    Choose_all:"Choose all",
                    pieces_excluding_freight:" pieces (excluding freight)",
                    Estimated_freight:"Estimated freight",
                    pay:"pay",
                    Your_shopping_cart_is_empty_go_treat_yourself:"Your shopping cart is empty, go treat yourself",
                    Are_you_sure_to_delete_the_item:"Are you sure to delete the item?",
                    Tips:"Tips",
                    // Sure:"Sure",
                    // Cancel:"Cancel",
                    Are_you_sure_you_want_to_delete_the_items_selected_in_your_shopping_cart:"Are you sure you want to delete the items selected in your shopping cart?",
                    Delete_successful:"Delete successful!",
                    Please_select_an_item:"Please select an item!",
    
    },
    cartlnfo:{
                    Confirm_Order:"Confirm Order",
                    Merchant:"Merchant",
                    Select_shipping_country:"Select shipping country",
                    Seacloud_warehouse:"Seacloud warehouse",
                    Please_fill_in_the_country_to_be_shipped_to:"Please fill in the country to be shipped to",
                    Seacloud_buyers_will_determine_whether_your_product_can_be_sent_based_on_the_country_you_fill_in:"Seacloud buyers will determine whether your product can be sent based on the country you fill in.",
                    Confirm_product_info:"Confirm product info",
                    product_detail:"product detail",
                    Note:"Note",
                    Price:"Price",
                    qty:"qty",
                    additional_services:"additional services",
                    Subtotal:"Subtotal",
                    Note_information:"Note information",
                    Product_remarks:"Product remarks",
                    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it:"Leave a message for purchasing If you don't have special purchasing requirements you don't need to write it",
                    Sure:"Sure",
                    Cancel:"Cancel",
                    Additional_services:"Additional services",
                    Total_price_of_goods:"Total price of goods",
                    Shipping_fee_to_warehouse:"Shipping fee to warehouse",
                    Total_stores:"Total stores ",
                    Total_price_to_be_paid_international_shipping_charges_are_extra:"Total price to be paid (international shipping charges are extra)",
                    Prohibited_Goods_Statement:"《Prohibited Goods Statement》",
                    Terms_of_Service_and_User_Management:"《Terms of Service and User Management》",
                    Return_and_Exchange_Service:"《Return and Exchange Service》",
                    Disclaimer:"《Disclaimer》",
                    I_have_read_and_agree:"I have read and agree",
                    Submit_Order:"Submit Order",
                    Kind_tips_After_the_order_is_submitted_and_the_payment_is_successful_please_wait_patiently_for_the_goods_to_be_put_into_storage_The_goods_can_be_submitted_for_shipping_after_being_put_into_storage:"Kind tips: After the order is submitted and the payment is successful, please wait patiently for the goods to be put into storage. The goods can be submitted for shipping after being put into storage.",
                    Please_agree_to_the_terms:"Please agree to the terms",
                    Please_choose_a_country:"Please choose a country",
          
  },
  payorder:{
          Checkout_counter:"Checkout counter",
         Orders_submitted_successfully:"Orders submitted successfully!",
         Balance:"Balance",
         Your_balance_is_insufficient_please_top_up_before_making_payment:"Your balance is insufficient, please top up before making payment.",
         Recharge:"Recharge",
         Total_price:"Total price:",
         Payables:"Payables",
         Handling_fee:"Handling fee",
         Confirm_payment:"Confirm payment",
         Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks:" Currency conversion and handling fees will be incurred when paying in non-USD currencies. ",
         Alipay:"Alipay",
         Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks:"Chinese Yuan Debit and credit cards from local partner banks",
         Please_enter_amount:"Please enter amount",
         Tips:"Tips",
         Sure:"Sure",
         Cancel:"Cancel",
         Amount_cannot_be:"Amount cannot be",
         Amount_must_be_greater_than:"Amount must be greater than",
         The_amount_you_input_is:"The amount you input is:",
         Please_select_mode_of_payment:"Please select mode of payment",
},
orderlist:{
          All:"All",
          Pending_payment:"Pending payment",
          Shopping:"Shopping",
          MyCoupon:"My coupon",
          Paid:"Paid",
          Ordering:"Ordering",
          Return_Exchange:"Return/Exchange",
          Start_date:"Start date",
          End_date:"End date",
          Search:"Search",
          product_detail:"product detail",
          Price:"Price",
          qty:"qty",
          Order_Status:"Order Status",
          Value_added_services:"Value added services",
          Order_total:"Order total",
          Other_operations:"Other operations",
          Order_number:"Order number:",
          Creation_time:"Creation time:",
          Warehouse_Seacloud_Warehouse:"Warehouse:Seacloud Warehouse",
          Add_notes:"Add notes",
          Product_remarks:"Product remarks",
          Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it:"Leave a message for purchasing If you don't have special purchasing requirements you don't need to write it",
          Sure:"Sure",
          Cancel:"Cancel",
          Product_shipping_cost:"Product shipping cost:",
          Value_added_service_fee:",Value added service fee:",
          Pay_order:"Pay order",
          order_details:"Order details",
          message:"message",
          Cancel_order:"Cancel order",
          logistics:"logistics",
          Logistics_information:"Logistics information",
          Apply_for_after_sales_service:"Apply for after sales service",
          Schedule:"Schedule",
          Are_you_sure_you_want_to_continue_deleting:"Are you sure you want to cancel order?",
          Tips:"Tips",
          
},
deliverydetail:{
     First_weight:"First weight",
     First_weight_price:"First weight price",
     Additional_weight:"Additional weight",
     Additional_weight_price:"Additional weight price",
     Size_limit:"Size limit",
     Sensitive_items_that_can_be_mailed:"Sensitive items that can be mailed",
     Fuel_fee:"Fuel fee",
     Operation_fee:"Operation fee",
     Service_fee:"Service fee",
},
orderdetail:{
               Title:"Title:",
               Product_Notes:"Product Notes:",
               Order_Number:"Order Number:",
               Specifications:"Specifications:",
               Seller_Name:"Seller Name:",
               Price:"Price:CNY",
               qty:"qty:",
               Order_Time:"Order Time:",
               Payment_Time:"Payment Time:",
               Weight: "Weight",
                Measure_size:"Measure size",            
                Remove_packaging:"Remove packaging",               
                Take_photos:"Take photos",
                Priority_purchase:"Priority purchase",           
                Add_air_column_pack:"Add air column pack",       
                Add_corner_protection:"Add corner protection",             
                Shoe_support:"Shoe support",   },
                
servereturn:{
     Application_Type:"Application Type",
     Please_select_application_type:"Please select application type",
     Request_return:"Request return",
     Request_exchange:"Request exchange",
     Request_reason:"Request reason",
     Submit:"Submit",
     Cancel:"Cancel",
},
trackInfo:{
     Logistics_Tracking:"Logistics Tracking",
},
userrecharge:{
     Checkout_counter:"Checkout counter",
     Total_price:"Total price",
     Payables:"Payables",
     Handling_fee:"Handling fee",
     Confirm_payment:"Confirm payment",
     Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks:" Currency conversion and handling fees will be incurred when paying in non-USD currencies. ",
     Alipay:"Alipay",
     Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks:"Chinese Yuan Debit and credit cards from local partner banks",
     Please_select_mode_of_payment:"Please select mode of payment",
     Please_keep_the_actual_shipping_address_of_the_product_consistent_with_the_PayPal_delivery_address_otherwise_it_will_cause_customs_clearance_failure_and_affect_the_timeliness:"Please keep the actual shipping address of the product consistent with the PayPal delivery address, otherwise it will cause customs clearance failure and affect the timeliness.",
    Cooperation_with_the_People_s_Bank_of_China:"Cooperation with the People's Bank of China"
},
security:{
          Username:"Username:",
          Email:"Email:",
          Security_level:"Security level:",
          After_encryption_your_security_level_is:"After encryption, your security level is",
          Medium:"Medium",
          Last_login:"Last login:",
          Logout:"Logout",
          Change_login_password:"Change login password",
          A_highly_secure_password_can_make_your_account_more_secure_It_is_recommended_that_you_change_your_password_regularly_and_set_a_password_that_contains_numbers_and_letters_and_is_more_than_6_characters_long:"A highly secure password can make your account more secure. It is recommended that you change your password regularly and set a password that contains numbers and letters and is more than 6 characters long.",
          Change_password:" Change password",
          Security_privacy_issues:"Security/privacy issues",
          Poor_customer_service:"Poor customer service",
          Too_high_a_fee:"Too high a fee",
          Complicated_shopping_process:"Complicated shopping process",
          Other_reasons:"Other reasons",
          Please_enter_content:"Please enter content",
          Cancel:"Cancel",
          Confirm:"Confirm",
          Change_email:"Change email",
          For_the_security_of_your_account_you_need_to_first_pass_the_email_verification_code_login_password_security_verification:"For the security of your account, you need to first pass the  email Captcha + login password security verification",
          Email_verification_code:"Email Captcha",
          Click_to_get_the_verification_code:"Click to get the Captcha",
          Login_password:"Login password",
          Next_step:"Next step",
          New_password:"New password",
          Repeat_password:"Repeat password",
          Verification_code_has_been_sent:"Verification code has been sent",
          Payment_password:"Payment password",
          Submit:"Submit",
          Please_enter_your_password_again:"Please enter your password again",
          The_two_passwords_are_inconsistent:"The two passwords are inconsistent",
          Please_enter_the_verification_code:"Please enter the Captcha",
          Please_enter_your_login_password:"Please enter your login password",
          Please_enter_a_new_login_password:"Please enter a new login password",
          Please_enter_a_new_payment_password:"Please enter a new payment password",
          Please_enter_your_payment_password_again:"Please enter your payment password again",
          Please_enter_the_verification_code_of_your_email:"Please enter the Captcha of your 2813910143@qq.com email",
          Please_enter_your_password:"Please enter your password",
          Successfully_changed_your_login_password:"Successfully changed your login password",
          Form_verification_failed:"Form verification failed",
          Please_enter_your_new_password:"Please enter your new password",
          _6_to_15_characters_long:"6 to 15 characters long"
  },
  userInfo:{
     Edit:"Edit",
     Username:"Username：",
     Balance:"Balance：",
     Phone:"Email：",
     Gender:"Gender：",
     None:"None",
     Male:"Male",
     Female:"Female",
     Language:"Language：",
     Registration_time:"Registration time：",
     Growth_Value:"Growth Value",
     View_Growth_Value_Record:"View Growth Value Record",
     score:"score",
     Only_3000_growth_points_are_needed_to_reach_V1:"Only 3000 growth points are needed to reach V1",
     Distance:"Distance",
     Only_requires :"Only requires",
     Sure:"Sure",
         Cancel:"Cancel",
                Upload_avatar:"Upload avatar",
     
},
warehouse:{
     Order_ID_Product_Name:"Order ID/Product Name",
     Start_Date:"Start Date",
     End_Date:"End Date",
     Search:"Search",
     Image:"Image",
     Product_Details:"Product Details",
     Price:"Price",
     Shipping:"Shipping",
     Weight:"Weight",
     Order_Status:"Order Status",
     Days:"Days",
     Action:"Action",
     Details:"Details",
     Leave_us_a_message:"Leave us a message",
     Select_All:"Select All",
     Shipping_Country:"Shipping Country",
     Actual_Weight:"Actual Weight",
     Selected_Products:"Selected Products",
     Pieces:"Pieces",
     Preview_Packaging:"Preview Packaging",
     Submit_Shipping:"Submit Shipping",
     Order_Details:"Order Details",
     Not_an_Array:"Not an Array",
     Please_select_a_country:"Please select a country!",
     Please_select_an_order_to_submit:"Please select an order to submit!",
     Seacloud_warehouse_To:"Seacloud warehouse To",
     Available:"Available"
},
invoice:{
     Tip_Depending_on_the_customs_policy_of_the_destination_country_and_your_experience_filling_in_a_customs_invoice_can_reduce_or_avoid_customs_fees:"Tip:Depending on the customs policy of the destination country and your experience, filling in a customs invoice can reduce or avoid customs fees",
     Add:"Add",
     Import_invoice:"Import invoice",
     Warehouse_ID:"Warehouse ID",
     Product_name:"Product name",
     Please_enter_a_title:"Please enter a title",
     qty:"qty",
     Please_enter_the_qty:"Please enter the qty",
     Price:"Price(USD)",
     Please_enter_the_value:"Please enter the value",
     Operation:"Operation",
     Delete:"Delete",
     Shipping_address:"Shipping address /",
     Please_select_a_shipping_address:"Please select a shipping address",
     Logistics_route:"Logistics route /",
     Please_select_a_logistics_route:"Please select a logistics route",
     Waybill_value_added_service_:"Waybill value-added service /",
     Waybill_value_added_service:"Waybill value-added service",
     Coupon:"Coupon /",
     Whether_to_purchase_insurance:"Whether to purchase insurance / ",
     insure:"insure",
     The_insurance_fee_is_3_of_the_total_price_of_the_goods:"The insurance fee is 3% of the total price of the goods",
     Rehearsal_fee:"Rehearsal fee",
     You_can_pay_the_rehearsal_fee_in_advance_to_ensure_that_the_package_is_packed_in_time_thereby_avoiding_additional_payments_and_making_the_delivery_process_faster_and_more_efficient_allowing_you_to_receive_the_package_faster:"You can pay the rehearsal fee in advance to ensure that the package is packed in time thereby avoiding additional payments and making the delivery process faster and more efficient allowing you to receive the package faster",
     Submit_package:"Submit package",
     Estimated_total_cost:"Estimated total cost/ ", 
     Total:"Total",
     pay:"Pay",
     Please_fill_in_the_invoice:"Please fill in the invoice",
     Please_choose_whether_to_purchase_insurance:"Please choose whether to purchase insurance",
     Estimated_cost:",Estimated cost",
     First_weight:",First weight",
     Fee:"g,Fee",
     Additional_weight:",Additional weight",
     Please_return_to_the_previous_step_to_select_the_warehouse_order_information:"Please return to the previous step to select the warehouse order information!",
     Please_return_to_the_previous_step_to_select_the_country_information:"Please return to the previous step to select the country information!",
     Waybill_full:"Waybill full",
     discount:"discount:",
     Yuan:"Yuan",
     Waybill_discount:"Waybill discount",
     Discount:"Discount",
     rehearsal:"rehearsal",
     You_have_available_coupons:"You have available coupons",
     No_coupons_available_yet:"No coupons available yet",
     Create_address:"Create address",
},
paysend:{
     Checkout_counter:"Checkout counter",
         Orders_submitted_successfully:"Orders submitted successfully!",
         Balance:"Balance",
         Your_balance_is_insufficient_please_top_up_before_making_payment:"Your balance is insufficient, please top up before making payment.",
         Recharge:"Recharge",
         Total_price:"Total price:",
         Payables:"Payables",
         Handling_fee:"Handling fee",
         Confirm_payment:"Confirm payment",
         Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks:" Currency conversion and handling fees will be incurred when paying in non-USD currencies.",
         Alipay:"Alipay",
         Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks:"Chinese Yuan Debit and credit cards from local partner banks",
         Please_enter_amount:"Please enter amount",
         Tips:"Tips",
         Sure:"Sure",
         Cancel:"Cancel",
         Amount_cannot_be:"Amount cannot be",
         Amount_must_be_greater_than:"Amount must be greater than",
         The_amount_you_input_is:"The amount you input is:",
         Please_select_mode_of_payment:"Please select mode of payment",
},
estimation:{
     Freight_Estimate:"Freight Estimate",
     Cooperate_with_many_international_express_companies_to_provide_super_low_freight_discounts:"Cooperate with many international express companies to provide super low freight discounts",
     Seacloud_warehouse_to:"Seacloud warehouse to (country)",
     Please_enter_weight:"Please enter weight (g)",
     Please_select:"Please select",
     Length:"Length",
     Width:"Width",
     Height:"Height",
     International_freight_description:"All logistics services for non-Seacloudbuy self-operated routes are provided by third-party logistics providers, and they will adjust freight prices according to market conditions. If there are any price changes, Seacloudbuy will notify you in advance. In order to provide the ultimate service experience, if you encounter problems with logistics services, Seacloudbuy can negotiate with third-party logistics providers on your behalf.",
     Logistics_Service_Description:"Logistics Service Description",
     Transportation_Method:"Transportation Method",
     ico:"icon",
     Time:"Time(day)",
     Freight:"Freight",
     Features:"Features",
     Operation:"Operation",
     View:"View",
     Details:"Details",
},
userlogin:{
     Welcome_to_log_in:"Welcome to log in",
     Please_enter_your_email_address:"Please enter your email address",
     Please_enter_your_password:"Please enter your password",
     Please_enter_your_verification_code:"Please enter your Captcha",
     Login:"Login",
     Forgot_your_password:"Forgot your password?",
     Register:"Register",

},
userforget:{
     Login:"Login",
     Email:"Email",
     Account_safety:"Account safety",
     Submit:"Submit",
     Already_have_an_account:"Already have an account",
     Return_to_SeaCloud_homepage:"Return to SeaCloud homepage",
     Please_enter_the_email_address_associated_with_your_account:"Please enter the email address associated with your account",
     Captcha:"Captcha",
     Please_enter_the_correct_email_address:"Please enter the correct email address",
     The_verification_code_cannot_be_empty:"The captcha cannot be empty",
     New_Password:"New Password",
                Send: "Send",
                Please_enter_your_password:"Please enter your password",   
          Please_enter_the_verification_code:"Please enter the Captcha",


},
UserRegister:{
          Please_enter_your_password:"Please enter your password",   
          Please_enter_the_verification_code:"Please enter the Captcha",
          Welcome_to_register:"Welcome to register",
          Please_select_your_country:"Please select your country",
          Please_fill_in_a_real_and_available_email_address:"Please fill in a real and available email address",
          Please_confirm_your_password:"Please confirm your password",
          Please_enter_the_invitation_code:"Please enter the invitation code(Optional)",
          Terms_of_Service_and_User_Management:"《Terms of Service and User Management》",
          Privacy_Notice:"《Privacy Notice》",
          register:"Register",
          Already_have_an_account:"Already have an account",
          Log_in:"Log in",
          Please_read_the_terms_and_check_I_agree:"Please read the terms and check I agree",
          The_two_passwords_do_not_match:"The two passwords do not match!",
          Please_enter_your_email_address:"Please enter your email address!", 
          I_have_read_and_agree:"I have read and agree",
     
     
},
     
UserSendMessage:{
     Please_enter_your_question:"Please enter your question",
     Send:"Send",
},

parcel:{
     Pending_payment:"Pending payment",
     Weight:"Weight",
     Total_price:"Total price:",
     Operate:"Operate",
     order_details:"order details",
     View_Logistics:"View Logistics",
     Logistics_information:"Logistics information",
     All_packages:"All packages",
     Waiting_for_packaging:"Waiting for packaging",
     Pay_the_difference:"Pay the difference",
     Sent:"Sent",
     Confirm_receipt:"Confirm receipt",
     Canceled:"Canceled",
     After_sales_waybill:"After-sales waybill",
     Enter_shipping_method_or_consignee:"Enter shipping method or consignee",
     Package_status:"Package status",
     Shipping_method:"Shipping method",
     Pay_waybill:"Pay waybill",
     View_details:"View details",
     Cancel_waybill:"Cancel waybill",
     Consult_customer_service:"Consult customer service",
     Waybill_details:"Waybill details",
     Consignee:"Consignee",
     },
     preview:{
               Waiting_for_shipment:"Waiting for shipment",
               Cancel_the_preview_shipping_order:"Cancel the preview shipping order",
               Pending_payment:"Pending payment",
               Weight:"Weight",
               Total_price:"Total price:",
               Operate:"Operate",
               order_details:"order details",
               View_Logistics:"View Logistics",
               Logistics_information:"Logistics information",
               All_packages:"All packages",
               Waiting_for_packaging:"Waiting for packaging",
               Pay_the_difference:"Pay the difference",
               Sent:"Sent",
               Confirm_receipt:"Confirm receipt",
               Canceled:"Canceled",
               After_sales_waybill:"After-sales waybill",
               Enter_shipping_method_or_consignee:"Enter shipping method or consignee",
               Package_status:"Package status",
               Shipping_method:"Shipping method",
               Pay_waybill:"Pay waybill",
               View_details:"View details",
               Cancel_waybill:"Cancel waybill",
               Consult_customer_service:"Consult customer service",
               Waybill_details:"Waybill details",
               Consignee:"Consignee",
     },
     wallet:{
          Start_date:"Start date",
          End_date:"End date",
          Please_select:"Please select",
          Consumption_records:"Consumption records",
          Query_by_date_period:"Query by date period",
          Query_by_type:"Query by type",
          Query:"Query",
          Date:"Date",
          Behavior:"Behavior",
          Consumption_amount:"Consumption amount",
          Last_week:"Last week",
          Last_month:"Last month",
          Last_three_months:"Last three months",
          Balance:"Balance",
   },
   senddetail:{
     Waybill_number:"Waybill number",
     Shipping_name:"Shipping name",
     Destination_country:"Destination country",
     Telephone:"Phone",
     Chargeable_Weight:"Chargeable Weight  ",
     Volume:"Volume",
     Total_cost:"Total cost",
     insurance:"insurance",
     Discounted_price:"Discounted price",
     Waybill_status:"Waybill status",
     Note:"Note",
     Consignee: "Consignee",
     Shipping_address: "Shipping address",
     Submit_time: "Submit time",
     Shipping: "Shipping",
     Value_added_services: "Value added services",
   },
   points:{
     My_Points:"My Points",
     Date:"Date",
     Redeem_points_this_month:"Redeem points this month",
     My_VIP:"My VIP",
     Required_to_reach_next_level:"Required to reach next level: ",
     points:"points",
     Go_to_upgrade:"Go to upgrade",
     View_coupon:"View coupon",
     reason:"reason",
     Points_before_change:"Points before change",
     Points_after_change:"Points after change",
     change_value:"change value",
   },
   coupon:{
     Coupon_activation:"Coupon activation",
     Enter_your_coupon_activation_code:"Enter your coupon activation code",
     activation:"activation",
     all:"all",
     Used:"Used",
     expired:"expired",
     Order_full:"Order full",
     reduce:"reduce",
     Waybill:"Waybill",
     Discount:"Discount",
     Get_coupon_number:"Get coupon number",
     Validity:"Validity",
     Remaining:"Remaining",
     cards:"cards",
     Require:"Require",
     Points:"Points",
     discount:"discount",
     Full:"Full",
     Available:"Available",
     Coupon_type:"Coupon type",
     The_validity_period_after_activation_is:"The validity period after activation is",
     sky:"sky",
     Redeem_now:"Redeem now",
     Waybill_full:"Waybill full",
     minus:"minus",
     Coupon_No:"Coupon No",
     usage_time:"usage time",
     CNY_available:"CNY available",
     Obtain_method:"Obtain method",
     Use_now:"Use now",
     Order_Coupon:"Order Coupon",
                Waybill_Coupon:"Waybill Coupon",
                Activate_Coupon:"Activate Coupon",
     Coupons_can_be_used_as_service_fees_Reward_coupons_must_be_activated_before_use_Please_activate_within_the_validity_period:"Coupons can be used as service fees. Reward coupons must be activated before use. Please activate within the validity period.",
     Coupon_Number:"Coupon Number",
     Type:"Type",
     Name:"Name",
     Expiration_Date:"Expiration Date",
     Amount:"Amount",
     Method:"Method",
     Status:"Status",
     Please_enter_the_activation_code:"Please enter the activation code",
     Coupon_code_cannot_be_empty:"Coupon code cannot be empty!",
     Tips:"Tips",
     Cancel:"Cancel",
     Sure:"Sure",
   },
   suspendball:{
     Por_favor_insira_sua_pergunta:"Por favor insira sua pergunta",
     Click_to_enter_customer_service:"Click to enter customer service",
   },
   promotion:{
     My_promotional_commission:"My promotional commission",
     total_users:"total users",
     New_users_within_7_days:"New users within 7 days",
     active_user:"active user",
     Payment_settings:"Payment settings",
     My_alliance_balance:"My alliance balance",
     Amount_withdrawn:"Amount withdrawn",
     withdraw:"withdraw",
     my_rights:"my rights",
     Commission_rate:"Commission rate",
     Points_record:"Points record",
     Points:"Points",
     What_is_an_affiliate_program:"What is an affiliate program",
     What_is_an_affiliate_program_desc:"Seacloudbuy is a sharing and profit project initiated by all users. Whether you are an individual or a business, join Seacloudbuy as a promoter and invite new people to register on Seacloudbuy and successfully purchase and sign for packages. He/she will become your exclusive user, and every international logistics consumption he/she makes will bring you benefits. Cash back, no upper limit.",
     How_my_winnings_are_calculated:"How my winnings are calculated",
     How_my_winnings_are_calculated_desc:"Once you become a member, your account will immediately have a 3% rebate rate. The more international logistics shipments you invite registered users to, the greater your bonus will be and the rebate rate will also increase. When the bonus reaches a certain amount, you can apply for withdrawal or use it to deduct any service fees on the Seacloudbuy platform.",
     How_to_promote_for_free:"How to promote for free",
     How_to_promote_for_free_desc:"Copy your promotion link and send it to your friend. When she visits, she can become your exclusive user after registering.",
     Commission_rate_desc:"What is commission rate? After your promotional user successfully receives the package, Seacloudbuy will reward you with the total shipping cost of the package multiplied by the commission rate.",     
     Click_here_to_invite:"Click here to invite"
   }
   
   
  }
