import { render, staticRenderFns } from "./Estimation.vue?vue&type=template&id=6be70f64&scoped=true"
import script from "./Estimation.vue?vue&type=script&lang=js"
export * from "./Estimation.vue?vue&type=script&lang=js"
import style0 from "./Estimation.vue?vue&type=style&index=0&id=6be70f64&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be70f64",
  null
  
)

export default component.exports