<template>
  <div class="allyContainer">
    <div class="content-right">
      <div class="banner">
<!--        <div class="b_left">XXX累计收益18888.66 CNY</div>-->
<!--        <div class="b_right">-->
<!--          <button>推广规则</button>-->
<!--          <button>推广协议</button>-->
<!--        </div>-->
<!--        <div class="bannerCenter">-->
<!--          <h4>新用户提包额外发放</h4>-->
<!--          <h1>30%佣金</h1>-->
<!--          <button>活动时间：2024.03.20 - 2024.9.30</button>-->
<!--        </div>-->
      </div>
      <div class="myCommission">
        <div class="c_head">
          <div class="c_left">
            <h3>{{$t("promotion.My_promotional_commission") }}</h3>
            <span> {{$t("promotion.total_users") }} ：{{ promoteInfo.countInviter}}</span>
            <span>{{$t("promotion.New_users_within_7_days") }} ：{{ promoteInfo.newFriend}}</span>
            <span> {{$t("promotion.active_user") }} ：{{ promoteInfo.onlineFriend}}</span>
          </div>
          <!-- <div class="c_right">
            {{$t("promotion.Payment_settings") }} <i class="el-icon-arrow-right"></i>
          </div> -->
        </div>
        <div class="c_body">
          <div class="c_balance">
            <div class="my_left">
              <span>{{$t("promotion.My_alliance_balance") }} (CNY)</span>
              <span>
                <h1>{{ promoteInfo.unionMoney == null ?0:promoteInfo.unionMoney}}</h1>
                <i class="el-icon-arrow-right"></i>
              </span>
              <span>{{$t("promotion.Amount_withdrawn") }} (CNY)： {{ promoteInfo.unionWithdrawalMoney == null ?0:promoteInfo.unionWithdrawalMoney}}</span>
            </div>

            <button class="alable" v-if="promoteInfo.unionMoney>0"> {{$t("promotion.withdraw") }}</button>
            <button class="noalable" v-else > {{$t("promotion.withdraw") }} </button>
          </div>
<!--          <div class="c_moneyList">-->
<!--            <div class="myCard">-->
<!--              <span>可领取奖金 </span>-->
<!--              <span>-->
<!--                <h5>0.00</h5>-->
<!--                <i class="el-icon-arrow-right"></i>-->
<!--              </span>-->
<!--              <el-tooltip-->
<!--                class="item"-->
<!--                effect="dark"-->
<!--                content="再邀请20个活跃用户即可领取邀请奖励"-->
<!--                placement="bottom"-->
<!--              >-->
<!--                <el-button>领取</el-button>-->
<!--              </el-tooltip>-->
<!--            </div>-->
<!--            <div class="myCard">-->
<!--              <span>可领取奖金 </span>-->
<!--              <span>-->
<!--                <h5>0.00</h5>-->
<!--                <i class="el-icon-arrow-right"></i>-->
<!--              </span>-->
<!--              <button>领取</button>-->
<!--            </div>-->
<!--            <div class="myCard">-->
<!--              <span>可领取奖金 </span>-->
<!--              <span>-->
<!--                <h5>0.00</h5>-->
<!--                <i class="el-icon-arrow-right"></i>-->
<!--              </span>-->
<!--              <button>领取</button>-->
<!--            </div>-->
<!--          </div>-->
        </div>
<!--        <div class="flowChart">-->
<!--          <h4>联盟流程</h4>-->
<!--          <dl>-->
<!--            <dd>-->
<!--              <img src="../../assets/ban/step1.png" alt="" />-->
<!--              <p>分享邀请码给好友</p>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <img src="../../assets/ban/step2.png" alt="" />-->
<!--              <p>好友下单并收到包裹</p>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <img src="../../assets/ban/step3.png" alt="" />-->
<!--              <p>获得佣金</p>-->
<!--            </dd>-->
<!--          </dl>-->
<!--        </div>-->
<!--        <div class="promotionWay">-->
<!--          <div class="p_top">-->
<!--            <h4>推广方式</h4>-->
<!--            <p>-->
<!--              通过该二维码、链接或者邀请码点击进入SeaCloud注册的用户会自动绑定为您的推广用户。-->
<!--            </p>-->
<!--          </div>-->
<!--          <dl>-->
<!--            <dd>-->
<!--              <div>-->
<!--                <span>下载二维码</span> <i class="el-icon-download"></i>-->
<!--              </div>-->
<!--              <p>方法 ①</p>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <div>-->
<!--                <span>复制链接</span> <i class="el-icon-copy-document"></i>-->
<!--              </div>-->
<!--              <p>方法 ②</p>-->
<!--            </dd>-->
<!--            <dd>-->
<!--              <div>-->
<!--                <span>复制邀请码</span> <i class="el-icon-copy-document"></i>-->
<!--              </div>-->
<!--              <p>方法 ③</p>-->
<!--            </dd>-->
<!--          </dl>-->
<!--        </div>-->
        <div class="myAssest">
          <div class="m_title">
            <div class="m_left">
              <span> {{$t("promotion.my_rights") }} </span>
              <span>  {{$t("promotion.Commission_rate") }}  <van-icon name="question-o" :title='this.$t("promotion.Commission_rate_desc")'/></span>
            </div>
            <div class="m_right" @click="$router.push({path:'/person/points'})">
              <span>  {{$t("promotion.Points_record") }} </span>
            </div>
          </div>
          <ul>
            <li v-for="(item,index) in promoteInfo.rateInfo" :key="index">
              <div class="my_item">
                <div class="avatar">
                  <el-avatar style="margin: 0 auto" v-if="promoteInfo.myLevel== item.level"  size="small"  src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                </div>
                <button>{{item.promotion_name}}</button>
                <span>  {{$t("promotion.Points") }} </span>
                <span>{{ item.minScore}} - {{item.require_score}}</span>
                <br>
                <span>{{$t("promotion.Commission_rate") }}</span>
                <h1>{{item.rate}}%</h1>
                <div class="percent" v-if="promoteInfo.myLevel==item.level">
                  <!-- <el-progress :percentage="50" color="#ff595e"></el-progress> -->
                  <!--                <el-progress-->
                  <!--                    :percentage="50"-->
                  <!--                    :text-inside="true"-->
                  <!--                    text-color="#fff"-->
                  <!--                    stroke-width="8"-->
                  <!--                    color="#ff595e"-->
                  <!--                ></el-progress>-->
<!--                  <span class="p_left">积分</span>-->
<!--                  <span class="p_right">{{item.minScore}} /{{ item.require_score}}</span>-->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <h3>{{ $t("promotion.What_is_an_affiliate_program")}}?</h3>
      <p>
        {{ $t("promotion.What_is_an_affiliate_program_desc") }}
        <!-- Seacloudbuy is a sharing and profit project initiated by all users. Whether you are an individual or a business, join Seacloudbuy as a promoter and invite new people to register on Seacloudbuy and successfully purchase and sign for packages. He/she will become your exclusive user, and every international logistics consumption he/she makes will bring you benefits. Cash back, no upper limit. -->
       </p>
      <h3> {{ $t("promotion.How_my_winnings_are_calculated")}}?</h3>
      <p>
        {{ $t("promotion.How_my_winnings_are_calculated_desc")}}
      </p>
      <h3>{{ $t("promotion.How_to_promote_for_free")}}?</h3>
      <p>
        {{ $t("promotion.How_to_promote_for_free_desc")}}<a style="color: red;cursor: pointer;" @click="invite()" >{{ $t('promotion.Click_here_to_invite') }}</a>
     
      </p>
    </div>

  </div>
</template>

<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import {promotionPlan} from  "@/Api/user"
export default {
  name:"promotion-vue",
  data(){
    return{
      promoteInfo:{}
    }
  },
  methods:{
    invite() {
      var userinfo =  localStorage.getItem("userinfo")
      userinfo = JSON.parse(userinfo)
      const url = location.host + "/register?invite=" + userinfo.usercode
      const textField = document.createElement("textarea");
      textField.value = url;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      document.body.removeChild(textField);
      Dialog.alert({
        title: this.$t("center.Invite_friends_to_register"),
        message: url,
        theme: "round-button",
        width: "420px",
        height: "182px",
        confirmButtonText: this.$t("center.copy_Link"),
      }).then((res) => {
        console.log(res)
        Toast({
          message: this.$t("center.Copy_successful"),
          duration: 1000,
        });
      });
    },
    getPromotion(){
      promotionPlan().then((res)=>{
        this.promoteInfo=res.data
        console.log( this.promoteInfo)
      })
    }
  },
  created() {
    this.getPromotion()
  }
};
</script>

<style lang="less" scoped>
.allyContainer {
  width: 970px;
  .content-right {
    // width: 1030px;
    height: 570px;
    border-radius: 4px;
    //background-color: #005ae9;
    background-color: #ef0c25;;
    .banner {
      //height: 335px;
      //border-bottom: 1px solid #000;
      padding: 30px 15px 0;
      .b_left {
        float: left;
        text-align: center;
        width: 280px;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        border-radius: 20px;
        background-color: #00245d;
      }

      .b_right {
        float: right;
        button {
          border: 0;
          padding: 5px 10px;
          color: #fff;
          border-radius: 8px;
          cursor: pointer;
          background-color: #0148ba;
        }
      }
      .b_right button:nth-child(1) {
        margin-right: 10px;
      }
      .bannerCenter {
        color: #fff;
        margin-top: 50px;
        text-align: center;
        h4 {
          font-size: 24px;
          font-weight: normal;
        }
        h1 {
          font-size: 110px;
        }
        button {
          border: 0;
          margin-top: 8px;
          border-radius: 15px;
          padding: 4px 15px;
          background-color: #000;
        }
      }
    }
    .myCommission {
      // width: 910px;
      height: 228px;
      margin: 0 30px;
      color: #333;
      border-radius: 12px;
      background-color: #fff;
      .c_head {
        height: 74px;
        line-height: 74px;
        padding: 0 20px;
        border-radius: 12px 12px 0 0;
        background-color: #ffda65;
        .c_left {
          float: left;
          h3 {
            float: left;
            font-size: 28px;
            margin-right: 30px;
          }
          span {
            // line-height: 100px;
            font-weight: 600;
            margin-right: 30px;

            font-size: 12px;
          }
        }
        .c_right {
          float: right;
        }
      }
      .c_body {
        //height: 254px;
        margin-bottom: 20px;;
        padding: 20px;
        .c_balance {
          height: 95px;
          // border: 1px solid red;
          .my_left {
            float: left;
            span {
              display: block;
              margin-bottom: 5px;
            }
            h1 {
              font-size: 40px;
              display: inline;
              font-weight: 500;
              margin-right: 10px;
              cursor: pointer;
              vertical-align: middle;
            }
          }
          .my_left span:nth-child(3) {
            color: #999;
            font-size: 12px;
          }


        }
        //.c_moneyList {
        //  display: flex;
        //  justify-content: space-between;
        //  margin-top: 10px;
        //  .myCard {
        //    padding: 20px;
        //    width: 286px;
        //    height: 109px;
        //    border-radius: 4px;
        //    // border: 1px solid red;
        //    background-color: #f7f7f7;
        //    span {
        //      display: block;
        //      color: #5c5c5c;
        //    }
        //
        //    h5 {
        //      display: inline;
        //      height: 28px;
        //      line-height: 28px;
        //      margin-right: 10px;
        //      font-size: 20px;
        //      cursor: pointer;
        //      font-weight: 500;
        //    }
        //    button {
        //      width: 72px;
        //      height: 20px;
        //      padding: 0;
        //      line-height: 20px;
        //      margin-top: 4px;
        //      font-size: 12px;
        //      border: none;
        //      border-radius: 10px;
        //      color: #fff;
        //      background-color: #dddddd;
        //    }
        //    button:hover {
        //      cursor: not-allowed;
        //    }
        //  }
        //  .myCard span:nth-child(1) {
        //    height: 18px;
        //    line-height: 18px;
        //    font-size: 12px;
        //  }
        //}
      }
      .flowChart {
        height: 232px;
        margin: 20px 0;
        padding: 20px 40px;
        border-radius: 12px;
        background-color: #fff;
        h4 {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          margin-bottom: 14px;
        }
        dl {
          display: flex;
          justify-content: space-between;
          dd {
            width: 250px;
            height: 150px;
            img {
              width: 100%;
              height: 120px;
            }
            p {
              font-size: 12px;
              color: #333;
              text-align: center;
              margin-top: 6px;
            }
          }
        }
      }
      .promotionWay {
        height: 220px;
        border-radius: 12px;
        margin-top: 20px;
        background-color: #1a6beb;
        border: 1px solid #5f97f1;
        .p_top {
          h4 {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            line-height: 28px;
            text-align: center;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 17px;
            text-align: center;
          }
        }
        dl {
          display: flex;
          justify-content: space-around;
          margin-top: 30px;
          dd div {
            width: 250px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 400;
            cursor: pointer;
            background-color: #ffe300;
            i {
              font-weight: 700;
            }
          }
          div:hover {
            transform: translateY(-7px) scale(1.2); // 元素上移动7像素 保持底不变
            transition: all 0.3s;
          }
          p {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .myAssest {
    margin-top: 30px;
    padding: 10px;
    background-color: white;
    color: #333;
    border-radius: 10px;
    .m_title {
      height: 27px;
      line-height: 27px;
      margin-bottom: 10px;
      .m_left {
        float: left;
      }
      .m_left span:first-child {
        font-size: 18px;
        font-weight: 400;
      }
      .m_left span:last-child {
        font-size: 12px;
        margin-left: 10px;
        i {
          cursor: pointer;
        }
      }
      .m_right {
        float: right;
        span {
          font-size: 12px;
          font-weight: 400;
          color: #2e5aef;
          cursor: pointer;
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        width: 185.05px;
        // height: 200px;
        padding: 20px;
        text-align: center;
        border: 1px solid #f2f2f2;
        border-right: 0;
        img {
          display: block;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          margin-bottom: 6px;
          border-radius: 50%;
        }
        button {
          display: block;
          width: 109px;
          height: 20px;
          line-height: 20px;
          border: 0;
          margin: 0 auto;
          // margin-bottom: 4px;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          border-radius: 10px;
          background-color: #e0e0e0;
          text-align: center;
        }
        span {
          font-size: 12px;
          font-weight: 500;
        }
        h1 {
          font-size: 28px;
          font-weight: 400;
          // line-height: 40px;
          // margin-bottom: -8px;
        }
        .percent {
          span {
            font-size: 12px;
            font-weight: 400;
            color: #666;
            // margin-top: 2px;
          }
          .p_left {
            float: left;
          }
          .p_right {
            float: right;
          }
        }
        // 除第一个外的 其余小li
        .my_item {
          // margin-top: 35px;
          margin-top: 26px;
          span {
            display: block;
          }
          p {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500;
          }
          h1 {
            line-height: 40px;
          }
        }
      }
    }
    ul li:nth-child(1) {
      width: 230px;
    }
    ul li:nth-child(1) button {
      font-weight: 600;
      background-color: #ff595e;
    }
    ul li:last-child {
      border-right: 1px solid #f2f2f2;
    }
  }
}
.avatar{
  height: 40px;
}
.info{
  h3{
    font-size: 16px;
    padding: 10px 0;
    color: #333;;
  }
  p{
  line-height: 25px;
    font-size: 14px;
  }
}
.noalable {
  width: 104px;
  height: 36px;
  color: #fff;
  line-height: 36px;
  border: 0;
  margin: 25px;
  border-radius: 20px;
  background-color: #d5d5d5;
  cursor: pointer;
}
.alable {
  width: 104px;
  height: 36px;
  color: #fff;
  line-height: 36px;
  border: 0;
  margin: 25px;
  border-radius: 20px;
  background-color: #ef0c25;
  cursor: pointer;
}
</style>
