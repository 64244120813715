<template>
  <div>
    <header-top></header-top>
    <div class="login">
      <div class="content">
        <div class="backimg"></div>
        <div class="loginForm">
          <div class="form">
            <h2 class="title">{{ $t("UserRegister.Welcome_to_register") }}</h2>
            <div id="input">
              <el-select
                prefix-icon="el-icon-message"
                v-model="value"
                filterable
                :placeholder="$t('UserRegister.Please_select_your_country')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                prefix-icon="el-icon-message"
                type="email"
                class="input"
                v-model="email"
                :placeholder="
                  $t(
                    'UserRegister.Please_fill_in_a_real_and_available_email_address'
                  )
                "
              ></el-input>
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                id="password"
                class="input"
                v-model="password"
                :placeholder="$t('UserRegister.Please_enter_your_password')"
              >
                <i
                  slot="suffix"
                  @click="togglePasswordVisibility()"
                  class="el-input__icon el-icon-view"
                ></i>
              </el-input>
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                id="password2"
                class="input"
                v-model="password2"
                :placeholder="$t('UserRegister.Please_confirm_your_password')"
              >
                <i
                  slot="suffix"
                  @click="togglePasswordVisibility2()"
                  class="el-input__icon el-icon-view"
                ></i>
              </el-input>
              <el-input
                prefix-icon="el-icon-edit"
                type="text"
                class="input"
                v-model="invitecode"
                :placeholder="
                  $t('UserRegister.Please_enter_the_invitation_code')
                "
              ></el-input>
              <div style="display: flex; align-items: center">
                <el-input
                  class="input"
                  style="width: 50%; float: left; margin-left: 20px"
                  v-model="captcha"
                  :placeholder="
                    $t('UserRegister.Please_enter_the_verification_code')
                  "
                ></el-input>
                <img
                  class="captcha"
                  @click="getCaptcha()"
                  :src="code"
                  style="width: 34%; height: 40px"
                />
              </div>
            </div>
            <div
              style="
                width: 90%;
                color: #666;
                font-size: 12px;
                margin: 10px auto;
              "
            >
              <el-checkbox label="" v-model="checked"></el-checkbox>
              {{ $t("UserRegister.I_have_read_and_agree")
              }}<a
                style="color: #409eff"
                href="/question?requestId=2"
                target="_blank"
                >{{
                  $t("UserRegister.Terms_of_Service_and_User_Management")
                }}</a
              >
              &nbsp;&nbsp;&nbsp;&nbsp;<a
                style="color: #409eff"
                href="/question?requestId=7"
                target="_blank"
                >{{ $t("UserRegister.Privacy_Notice") }}</a
              >
            </div>
            <el-row style="text-align: center; margin-top: 20px;">
              <el-button
                style="width: 90%;background: linear-gradient(90deg, #fd5532, #ef0c25);border-color: ef0c25;"
                @click="toregister"
                type="success"
                >{{ $t("UserRegister.register") }}</el-button
              >
            </el-row>
            <div
              style="
                display: flex;
                width: 90%;
                font-size: 14px;
                margin: 15px auto;
                justify-content: flex-end;
              "
            >
              <router-link to="/login"
                ><span style="color: #999"
                  >{{ $t("UserRegister.Already_have_an_account") }}?&nbsp;</span
                ><a style="color: #ef0c25">{{
                  $t("UserRegister.Log_in")
                }}</a></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "../mainPage/HeaderTop.vue";
import { register, getCaptcha } from "@/Api/user";
import { getCountryList } from "@/Api/index";
import Bottom from "../mainPage/Bottom.vue";
export default {
  name: "user-register",
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      captcha: "",
      code: "",
      t: "",
      options: [],
      value: "",
      username: "",
      invitecode: "",
      checked: false,
    };
  },
  components: {
    HeaderTop,
    Bottom,
  },

  methods: {
    togglePasswordVisibility() {
      var passwordInput = document.getElementById("password");

      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    togglePasswordVisibility2() {
      var passwordInput = document.getElementById("password2");

      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    getCaptcha() {
      this.t = Date.now();
      this.code = getCaptcha(this.t);
    },
    toregister() {
      var data = {};
      if (this.checked == false) {
        this.$message.info(
          this.$t("UserRegister.Please_read_the_terms_and_check_I_agree")
        );
        return;
      }
      if (this.password != this.password2 || this.password2 === "") {
        this.$message.error(
          this.$t("UserRegister.The_two_passwords_do_not_match")
        );
        return;
      }
      if (this.isEmail(this.email) == false) {
        this.$message.error(
          this.$t("UserRegister.Please_enter_your_email_address")
        );
        return;
      }
      data["password"] = this.password;
      data["email"] = this.email;
      data["invitecode"] = this.invitecode;
      data["countrycode"] = this.value;
      data["code"] = this.captcha;
      data["t"] = this.t;
      register(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.$router.push({ path: "/login" });
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },

    setInvite() {
      // https://seacloudbuy.com/register?invite=2WWMoLGj4s
      var invite = this.$route.query.invite;
      if (invite != null) {
        this.invitecode = invite;
      }
    },
    getCountryListMethod() {
      getCountryList().then((res) => {
        if (res.code === 1) {
          this.options = res.data;
        }
      });
    },
    isEmail(email) {
      var reg = /^([a-zA-Z0-9_-])+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      return reg.test(email);
    },
  },
  created() {
    this.setInvite();
    this.t = Date.now();
    this.code = getCaptcha(this.t);
    this.getCountryListMethod();
  },
};
</script>

<style scoped>
.content {
  width: 1200px;
  height: 100%;
  background-image: url("/src/assets/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  justify-content: flex-end;
}

.login {
  width: 100%;
  height: 720px;
  background-color: black;
}

.loginForm {
  padding: 40px;
  /* margin-top: 80px; */
}

.form {
  width: 340px;
  /* height: 580px; */
  height: 620px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 10px;
}

.content {
  margin: 0 auto;
  display: flex;
}

.title {
  margin: 0 auto;
  width: 50%;
  padding: 20px;
  text-align: center;
}

.input {
  width: 90%;
  text-align: center;
  margin: 10px;
}
.el-select {
  width: 90%;
}
.el-input--prefix .el-input__inner,
input {
  background: #f8f8f8;
  border: none !important;
}

#input {
  text-align: center;
}
</style>
