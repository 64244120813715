<template>
  <div class="parcel_page">
    <div class="order-filter">
      <el-row>
        <el-col :span="17">
          <ul class="btn-list">
            <li
              :class="{ 'btn-item': true, active: btnActive === 0 }"
              @click="(btnActive = 0), switchTab('')"
            >
              <span>{{ $t('parcel.All_packages') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 1 }"
              @click="(btnActive = 1), switchTab('0')"
            >
              <span>{{ $t('parcel.Pending_payment') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 2 }"
              @click="(btnActive = 2), switchTab('2')"
            >
              <span>{{ $t('parcel.Waiting_for_packaging') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 3 }"
              @click="(btnActive = 3), switchTab('3')"
            >
              <span>{{ $t('parcel.Pay_the_difference') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 6 }"
              @click="(btnActive = 6), switchTab('6')"
            >
              <span>{{ $t('parcel.Sent') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 7 }"
              @click="(btnActive = 7), switchTab('7')"
            >
              <span>{{ $t('parcel.Confirm_receipt') }}</span>
            </li>

            <li
              :class="{ 'btn-item': true, active: btnActive === 8 }"
              @click="(btnActive = 8), switchTab('8')"
            >
              <span>{{ $t('parcel.Canceled') }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 9 }"
              @click="(btnActive = 9), switchTab('9')"
            >
              <span>{{ $t('parcel.After_sales_waybill') }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>

    <div class="order-search">
      <el-row>
        <el-col :span="12">
          <el-input
          :placeholder="$t('parcel.Enter_shipping_method_or_consignee')"
            v-model="searchValue"
            size="small"
            style="width: 300px"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col
          :span="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <span>{{ $t('parcel.Package_status') }} &nbsp;</span>
          <el-select
            v-model="value"
            :placeholder="$t('parcel.Package_status')"
            style="width: 140px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div class="parcel_list">
      <el-table
        :data="tableData"
        class="table"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.send_code }}
          </template>
        </el-table-column>

        <el-table-column
          prop="consignee"
          :label="$t('parcel.Consignee')"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.consignee }}
          </template>
        </el-table-column>

        <el-table-column
          prop="shipping"
          :label="$t('parcel.Shipping_method')"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.delivery_name }}
          </template>
        </el-table-column>

        <el-table-column prop="weight" :label="$t('parcel.Weight')" width="110" align="center">
          <template slot-scope="scope"> {{ scope.row.sum_weight }}g </template>
        </el-table-column>

        <el-table-column prop="total" :label="$t('parcel.Total_price')" width="110" align="center">
          <template slot-scope="scope">
            {{ moneyRates(scope.row.total_fee) }} {{ currency }}
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="$t('parcel.Package_status')"
          width="140"
          align="center"
        >
          <template slot-scope="scope">
            <el-link type="success">
              {{ scope.row.status }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column :label="$t('parcel.Operate')" width="70" align="center">
          <template slot-scope="scope">
            <el-dialog
            :title="$t('parcel.order_details')"
              width="800px"
              :visible.sync="dialogTableVisibleOrderDetail"
            >
              <!-- <order-detail v-if="dialogTableVisibleOrderDetail" :orderInfo="scope.row"></order-detail> -->
            </el-dialog>
            <el-dropdown trigger="click" slot="reference">
              <span class="el-dropdown-link">
                <el-badge class="item badge" type="warning">
                  <el-button class="more el-icon-more"></el-button>
                </el-badge>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.state == 0 || scope.row.state==3"
                  @click.native="toSendPay(scope.row.send_code)"
                  >{{ $t('parcel.Pay_waybill') }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="sendOrderDetail(scope.row.id)"
                  >{{ $t('parcel.View_details') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="[0,1, 2, 3, 4].indexOf(scope.row.state) >= 0"
                  @click.native="cancelSend(scope.row.id)"
                  >{{ $t('parcel.Cancel_waybill') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="[6, 7].indexOf(scope.row.state) >= 0"
                  @click.native="sendTrackInfo(scope.row.id)"
                  >{{ $t('parcel.View_Logistics') }}
                </el-dropdown-item>

                <el-dropdown-item @click.native="getmessage(scope.row)"
                  >{{ $t('parcel.Consult_customer_service') }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="[6].indexOf(scope.row.state) >= 0"
                  @click.native="okSend(scope.row.send_code)"
                  >{{ $t('parcel.Confirm_receipt') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="parcel_pagination" v-if="total > 10">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 通用消息对话框 -->
    <el-dialog
      title="message"
      style="text-align: left"
      width="960px"
      :visible.sync="messageVisable"
    >
      <user-send-message
        v-if="messageVisable"
        :currentItemMessage="currentMessage"
      ></user-send-message>
    </el-dialog>
    <!-- 物流信息 -->
    <el-dialog
    :title="$t('parcel.Logistics_information')"
      style="text-align: left"
      :visible.sync="dialogTableVisibleOrderTrack"
    >
      <track-info :orderTrackInfos="tarckMessage"></track-info>
    </el-dialog>
    <!-- 运单详情 -->
    <el-dialog
    :title="$t('parcel.Waybill_details')"
      style="text-align: left"
      :visible.sync="dialogTableVisibleSendOrderDetail"
    >
      <send-detail :sendOrderDetail="currentItemMessage"></send-detail>
    </el-dialog>
  </div>
</template>

<script>
import {
  sendorderListg,
  ConfirmOrder,
  sendorderDetail,
  cancelSend,
  sendTrack,
} from "@/Api/send";
import UserSendMessage from "@/components/user/UserSendMessage.vue";
import SendDetail from "@/components/others/sendDetail.vue";
import TrackInfo from "@/components/others/trackInfo.vue";
export default {
  name:"parcel-vue",
  components: { UserSendMessage, SendDetail, TrackInfo },
  data() {
    return {
      tableData: [],
      dialogTableVisibleOrderDetail: false,
      currentPage: 1,
      handleSizeChange: 10,
      total: 20,
      btnActive: 0,
      searchValue: "",
      options: [
        {
          label:this.$t('parcel.All_packages'),
          value: -1,
        },
        {
          label:this.$t('parcel.Pending_payment'),
          value: 0,
        },
        {
          label:this.$t('parcel.Waiting_for_packaging'),
          value: 2,
        },
        {
          label:this.$t('parcel.Pay_the_difference'),
          value: 3,
        },
        {
          label:this.$t('parcel.Sent'),
          value: 6,
        },
        {
          label:this.$t('parcel.Confirm_receipt'),
          value: 7,
        },
        {
          label:this.$t('parcel.Canceled'),
          value: 8,
        },
        {
          label:this.$t('parcel.After_sales_waybill'),
          value: 9,
        },
      ],
      value: "",
      messageVisable: false,
      dialogTableVisibleOrderTrack: false,
      tarckMessage: "", //当前信息
      dialogTableVisibleSendOrderDetail: false,
      currentItemMessage: "",
      currency:"CNY",//显示货币
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == 0) {
          newValue = 1;
        } else if (newValue == -1) {
          newValue = 0;
        }
        this.btnActive = newValue;
        this.switchTab(newValue);
      },
    },
  },
  methods: {

    moneyRates(money){
      return this.moneyRate(money)
    },
    // cancelSend 取消运单
    cancelSend(sid) {
      var data = {};
      data["sid"] = sid;
      cancelSend(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.value = 8;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查看运单详情
    sendOrderDetail(sid) {
      var data = {};
      data["sid"] = sid;
      sendorderDetail(data).then((res) => {
        this.dialogTableVisibleSendOrderDetail = true;
        this.currentItemMessage = res.data;
        // console.log(this.currentItemMessage);
      });
    },
    // sendTrack 查看物流
    sendTrackInfo(sid) {
      var data = {};
      data["sid"] = sid;
      sendTrack(data).then((res) => {
        this.tarckMessage = res.data;
        console.log(this.tarckMessage);
        this.dialogTableVisibleOrderTrack = true;
      });
    },
    // 打开消息框
    getmessage(item) {
      this.messageVisable = true;
      this.currentMessage = item;
    },
    // 切换页面
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSendList();
    },
    // 获取列表
    getSendList(state = "") {
      var data = {};
      data["pageSize"] = this.handleSizeChange;
      data["page"] = this.currentPage;
      if (state != "") {
        data["state"] = state;
      }
      if (this.searchValue != "") {
        data["searchValue"] = this.searchValue;
      }
      sendorderListg(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    // 切换状态
    switchTab(state) {
      this.getSendList(state);
    },
    search() {
      this.getSendList();
    },
    // 去支付
    toSendPay(code) {
      if (code == "") {
        return;
      }
      this.$router.push({
        path: "/paysend",
        query: {
          sendcode: code,
        },
      });
    },
    // 确认收货
    okSend(sendCode) {
      var data = {};
      data["sendcode"] = sendCode;
      ConfirmOrder(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.value = 7;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    this.currency = this.getCurrency
    this.getSendList();
  },
};
</script>

<style scoped lang="less">
.order-filter {
  padding: 20px 0;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    .btn-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      min-width: 110px;
      text-align: center;
      cursor: pointer;
      height: 40px;
      padding: 0 10px;
      margin-right: 10px;
      font-size: 14px;
      color: #666;
      border: 1px solid #fff;
      border-radius: 4px;
    }
    .active {
      color: #ef0c25;
      border: 2px solid #ef0c25;
      background-color: #f7e5e6;
    }
  }
  .time-sort {
    text-align: right;
    line-height: 40px;
  }
}
.order-search {
  margin: 10px 0 20px 0;
  .el-input-group--append .el-input__inner,
  .el-input-group__prepend {
    background-color: #f8f8f8 !important;
    border: none;
  }
}
.parcel_list {
  min-height: 450px;
  .table {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      background: #f7f7f7;
    }
    .info {
      .title {
        color: #666;
      }
    }
  }
  .goods_img {
    width: 55px;
  }

  .badge {
    .more {
      background: transparent;
      border: none;
      padding: 0;
      width: 25px;
      height: 50px;
      transform: rotate(90deg);
    }
  }
}
.parcel_pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
