import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index';
import i18n from "./i18n/i18n"

function getcurrencyCode() {
  var currency = localStorage.getItem("currIndex")
  if (currency == null || currency == "null" || currency=="") {
    currency = "USD"
  }
  return currency
}

Vue.prototype.getCurrency = getcurrencyCode()
Vue.prototype.moneyRate = function (money = 0) {
  if (money == 0) {
    return money
  }
  var rate = localStorage.getItem("rate")
  if (rate == null || rate == "null" || rate=="") {
    rate = 6.7
  }
  const rateMoney = parseFloat(money) / rate
  return rateMoney.toFixed(2)
}
Vue.config.productionTip = false

import {
  Step,
  Steps,
  Dialog,
  icon,
  Loading,
  Empty,
  Lazyload,
  Uploader,
  NoticeBar,
  Toast
} from 'vant';
Vue.use(Step);
Vue.use(Steps);
Vue.use(Dialog);
Vue.use(icon);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Lazyload);
Vue.use(Uploader);
Vue.use(NoticeBar);
Vue.use(Toast);

Vue.use(ElementUI);


new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')