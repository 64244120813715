<template>
  <div class="collection_page">
      <el-tabs v-model="activeName" @tab-click="handleClick" >
          <el-tab-pane label="店铺(1)" name="first">
              <div class="search_con">
                  <div>
                      <el-select v-model="value" placeholder="请选择平台">
                          <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                      </el-select>
                  </div>
                  <div>
                      <el-input
                          placeholder="请输入收藏店铺关键词"
                          prefix-icon="el-icon-search"
                          style="width:310px"
                          v-model="search">
                      </el-input>
                  </div>
              </div>
              <div class="shop">
                  <ul  class="shop-list">
                      <li class="shop-item">
                          <div  class="shop-info">
                              <div  class="image">
                                 <img  referrerpolicy="no-referrer" data-src="https://img.alicdn.com/imgextra/i2/O1CN01q5TyUW1pTqStINPIq_!!6000000005362-0-tps-196-196.jpg" src="https://img.alicdn.com/imgextra/i2/O1CN01q5TyUW1pTqStINPIq_!!6000000005362-0-tps-196-196.jpg" lazy="loaded">
                              </div> 
                              <div  class="desc">
                                  <p  class="shop-name">
                                      <span  class="store-source">
                                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjE5MUU3MzQ3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjE5MUU3MzU3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMTkxRTczMjdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMTkxRTczMzdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhO6DAkAAAETSURBVHjaYvz//z8DGLSFQxlEgqqVjCCKEWwASHPVSohElgEDw7QLCDYO8ObxAwaRzR8YWcCan9wECoEcADSUhRWhCsSedBpV57aZDAw75jCIyCowvPEV+M8Cl8gzg9AgDSBFXukQdp4pqgEwA7dMh9D/W8P+/8/U/w8HuSYQjA0giwP1vPbh/8+E1YPoNoPY6C6BAoQBoAADOR3mRGS/g9joYQELJqyiMNtgmnDYjuoCEAAFHEgxNttwuABhACzucYUJDsCCofnPb9SwQE5QMHUgeaxhAFMIimNYPKNbANKMJAdOyqAUBUpZpABYUmaEZSaQISAaZhBIAT4A0gxJiUADUDA0hWGI48BMeE0nAgAEGABBLcYP0sUUiwAAAABJRU5ErkJggg==" alt="">
                                          <a  href="/shopdetail?id=b2b-220898147635461687&amp;t=alibaba&amp;o=m.1688.com" target="_blank" title="中山市松菱光电科技有限公司" class="name ellipsis">
                                              中山市松菱光电科技有限公司
                                          </a>
                                       </span>
                                      <span  class="remove">
                                          <el-button icon="el-icon-delete" ></el-button>
                                      </span>
                                  </p>
                                  <p  class="seller">
                                          <span  class="label">卖家：</span> 
                                          <span  class="value">中山市松菱光电科技有限公司</span>
                                  </p> 
                                  <p class="same-shop">
                                      <el-input
                                          size="mini"
                                          prefix-icon="el-icon-plus"
                                          v-model="input2">
                                      </el-input>
                                  </p>     
                              </div>
                          </div>
                      </li>
                       <li class="shop-item">
                          <div  class="shop-info">
                              <div  class="image">
                                 <img  referrerpolicy="no-referrer" data-src="https://img.alicdn.com/imgextra/i2/O1CN01q5TyUW1pTqStINPIq_!!6000000005362-0-tps-196-196.jpg" src="https://img.alicdn.com/imgextra/i2/O1CN01q5TyUW1pTqStINPIq_!!6000000005362-0-tps-196-196.jpg" lazy="loaded">
                              </div> 
                              <div  class="desc">
                                  <p  class="shop-name">
                                      <span  class="store-source">
                                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjE5MUU3MzQ3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjE5MUU3MzU3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMTkxRTczMjdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMTkxRTczMzdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhO6DAkAAAETSURBVHjaYvz//z8DGLSFQxlEgqqVjCCKEWwASHPVSohElgEDw7QLCDYO8ObxAwaRzR8YWcCan9wECoEcADSUhRWhCsSedBpV57aZDAw75jCIyCowvPEV+M8Cl8gzg9AgDSBFXukQdp4pqgEwA7dMh9D/W8P+/8/U/w8HuSYQjA0giwP1vPbh/8+E1YPoNoPY6C6BAoQBoAADOR3mRGS/g9joYQELJqyiMNtgmnDYjuoCEAAFHEgxNttwuABhACzucYUJDsCCofnPb9SwQE5QMHUgeaxhAFMIimNYPKNbANKMJAdOyqAUBUpZpABYUmaEZSaQISAaZhBIAT4A0gxJiUADUDA0hWGI48BMeE0nAgAEGABBLcYP0sUUiwAAAABJRU5ErkJggg==" alt="">
                                          <a  href="/shopdetail?id=b2b-220898147635461687&amp;t=alibaba&amp;o=m.1688.com" target="_blank" title="中山市松菱光电科技有限公司" class="name ellipsis">
                                              中山市松菱光电科技有限公司
                                          </a>
                                       </span>
                                      <span  class="remove">
                                          <el-button icon="el-icon-delete" ></el-button>
                                      </span>
                                  </p>
                                  <p  class="seller">
                                          <span  class="label">卖家：</span> 
                                          <span  class="value">中山市松菱光电科技有限公司</span>
                                  </p> 
                                  <p class="same-shop">
                                      <el-input
                                          size="mini"
                                          prefix-icon="el-icon-plus"
                                          v-model="input2">
                                      </el-input>
                                  </p>     
                              </div>
                          </div>
                      </li>
                  </ul>
              </div>
          </el-tab-pane>
          <el-tab-pane label="商品(2)" name="second">
              <div class="search_con">
                  <div>
                      <el-select v-model="value" placeholder="请选择商品">
                          <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                      </el-select>
                  </div>
                  <div>
                      <el-input
                          placeholder="请输入收藏商品关键词"
                          prefix-icon="el-icon-search"
                          style="width:310px"
                          v-model="search">
                      </el-input>
                  </div>
              </div>
              <div class="table">
                  <el-row class="table-content">
                      <el-col :span="6">
                          <el-card  class="el_card_body">
                              <a href="">
                                  <img class="goods_img" src="https://img.alicdn.com/bao/uploaded/i2/876583493/O1CN013bTouI1bfqO0ZvB1t_!!876583493.jpg" >
                                  <p class="title">
                                      <span class="store_source">
                                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjE5MUU3MzQ3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjE5MUU3MzU3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMTkxRTczMjdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMTkxRTczMzdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhO6DAkAAAETSURBVHjaYvz//z8DGLSFQxlEgqqVjCCKEWwASHPVSohElgEDw7QLCDYO8ObxAwaRzR8YWcCan9wECoEcADSUhRWhCsSedBpV57aZDAw75jCIyCowvPEV+M8Cl8gzg9AgDSBFXukQdp4pqgEwA7dMh9D/W8P+/8/U/w8HuSYQjA0giwP1vPbh/8+E1YPoNoPY6C6BAoQBoAADOR3mRGS/g9joYQELJqyiMNtgmnDYjuoCEAAFHEgxNttwuABhACzucYUJDsCCofnPb9SwQE5QMHUgeaxhAFMIimNYPKNbANKMJAdOyqAUBUpZpABYUmaEZSaQISAaZhBIAT4A0gxJiUADUDA0hWGI48BMeE0nAgAEGABBLcYP0sUUiwAAAABJRU5ErkJggg==" alt="">
                                      </span>
                                      适用于JB PULSE5 音乐脉动5全屏灯效无线蓝牙音箱重低音插卡便携
                                  </p>
                                  <div class="bottom clearfix">
                                      <p class="price">￥ 323.23</p>
                                      <el-button icon="el-icon-delete" ></el-button>
                                  </div>
                              </a>
                          </el-card>
                      </el-col>
                      <el-col :span="6">
                          <el-card  class="el_card_body">
                              <a href="">
                                  <img class="goods_img" src="https://img.alicdn.com/bao/uploaded/i2/876583493/O1CN013bTouI1bfqO0ZvB1t_!!876583493.jpg" >
                                  <p class="title">
                                      <span class="store_source">
                                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjE5MUU3MzQ3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjE5MUU3MzU3RjBGMTFFNkJDRjU4NEM0MzJERkIwODgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMTkxRTczMjdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMTkxRTczMzdGMEYxMUU2QkNGNTg0QzQzMkRGQjA4OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhO6DAkAAAETSURBVHjaYvz//z8DGLSFQxlEgqqVjCCKEWwASHPVSohElgEDw7QLCDYO8ObxAwaRzR8YWcCan9wECoEcADSUhRWhCsSedBpV57aZDAw75jCIyCowvPEV+M8Cl8gzg9AgDSBFXukQdp4pqgEwA7dMh9D/W8P+/8/U/w8HuSYQjA0giwP1vPbh/8+E1YPoNoPY6C6BAoQBoAADOR3mRGS/g9joYQELJqyiMNtgmnDYjuoCEAAFHEgxNttwuABhACzucYUJDsCCofnPb9SwQE5QMHUgeaxhAFMIimNYPKNbANKMJAdOyqAUBUpZpABYUmaEZSaQISAaZhBIAT4A0gxJiUADUDA0hWGI48BMeE0nAgAEGABBLcYP0sUUiwAAAABJRU5ErkJggg==" alt="">
                                      </span>
                                      适用于JB PULSE5 音乐脉动5全屏灯效无线蓝牙音箱重低音插卡便携
                                  </p>
                                  <div class="bottom clearfix">
                                      <p class="price">￥ 323.23</p>
                                      <el-button icon="el-icon-delete" ></el-button>
                                  </div>
                              </a>
                          </el-card>
                      </el-col>
                  </el-row>
              </div>
          </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
export default {
  name:'my-collection',
   data() {
    return {
      activeName: 'first',
      options: [{
        value: 'taobao',
        label: '淘宝'
      }, {
        value: 'tianmao',
        label: '天猫'
      }, {
        value: 'microshop',
        label: '微店'
      }, {
        value: '1688',
        label: '1688'
      }],
      value: '',
      search:'',
      input2:''
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped lang="less">
  .search_con{
      display: flex;
     
  }
  .search_con div{
      margin-right: 10px;
  }
  .el-tabs__nav-scroll{
      justify-content: flex-start;
  }
  .shop-list{
      display: grid;
      grid-template-columns: 48% 48%;
      justify-content: space-between;
      margin-top: 20px;
      min-height: 400px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      .shop-item{
          display: inline-block;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin-right: 0;
          margin-bottom: 20px;
          padding: 20px;
          width: 476px;
          height: 152px;
          background: #fff;
          -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
          box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
          border-radius: 4px;
          .shop-info{
              vertical-align: middle;
              .image{
                  width: 80px;
                  margin-right: 20px;
                  display: inline-block;
                  img{
                      width: 100%;
                      height: 80px;
                  }
              }
              .desc{
                  width: 330px;
                  display: inline-block;
                  vertical-align: top;
                  .shop-name{
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-pack: justify;
                      -ms-flex-pack: justify;
                      justify-content: space-between;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      .store-source{
                         display: flex;
                         align-items: center;
                          img{
                              width:16px;
                              height: 16px;
                              margin-right: 4px;
                          }
                      }
                      .name{
                          width: 260px;
                          display: inline-block;
                          font-size: 14px;
                          font-family: PingFangSC-Medium, PingFang SC;
                          font-weight: 500;
                          color: #333;
                          line-height: 20px;
                          vertical-align: middle;
                      }
                  }
                  .seller{
                      height: 18px;
                      margin-top: 4px;
                      font-size: 12px;
                      font-family: PingFang-SC-Regular, PingFang-SC;
                      font-weight: 400;
                      color: #666;
                  }
                  .same-shop{
                      margin-top: 30px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-pack: justify;
                      -ms-flex-pack: justify;
                      justify-content: space-between;
                      -webkit-box-align: center;
                      -ms-flex-align: center;
                      align-items: center;
                      width: 150px;
                    
                  }
              }
          }
      }
  }
  .table{
      padding: 15px 0;
      font-size: 12px;
  }
  .table-content{
      min-height: 500px;
  }
  .el_card_body{
      height: 300px;
      width: 210px;
      border-radius: 0;
      border: none;
      margin-bottom: 20px;
      position: relative;
  }
  .el_card_body:hover{
      box-shadow:1px 1px 10px #aaa;
  }

  .table-content .el-card__body .goods_img{
      width: 100%;
      height: 210px;
  }
  .table-content .el-card__body .title{
      margin: 6px 0;
      font-size: 10px;
      color: #9c9ea6;
      padding: 0 6px;
      max-height: 40px;
      overflow: hidden;
      cursor: pointer;
  }
  .table .bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .table .price{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333;
      padding: 0 6px;
  }
  .collection_page .el-button:focus, .bottom .el-button:hover{
      color: #333 !important;
      background-color:transparent !important;
  }
  .collection_page  .el-button{
      border: none !important;
      color: #999 !important;
  }
  .table .store_source{
      display: inline-block;
      margin-right: 4px;
  }
  .table .store_source img{
      width: 16px;
      height: 16px;
      vertical-align: middle;
  }
</style>