<template>
  <div class="mainShow">
    <HeaderTop />
    <Middle />
    <Carousel />
    <Describe />
    <goodsRecommend />
    <Bottom />
  </div>
</template>

<script>
import HeaderTop from "../mainPage/HeaderTop.vue";
import Middle from "../mainPage/Middle.vue";
import Carousel from "../mainPage/Carousel.vue";
import Describe from "../mainPage/Describe.vue";
import goodsRecommend from "../mainPage/goodsRecommend.vue";
import Bottom from "../mainPage/Bottom.vue";

export default {
  name: "MainPage",
  components: { HeaderTop, Middle, Carousel, Describe, goodsRecommend, Bottom },
};
</script>

<style lang="less" scoped>
// .mainShow {
//  // height: 2000px;
//   //background-color: #f5f5f5;
//   /* border: 1px solid red; */
// }
</style>
