<template>
  <div class="account_page">
    <div class="header">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('account.personal_data')" name="first">
          <div class="my_info">
            <!-- 个人信息 -->
            <user-info @getUserinfo="getUserinfo"></user-info>
            <div class="usersize_con">
              <div class="usersize_edit">
                <!-- <span class="title">我的尺码信息</span> -->
                <!-- <el-button type="text">编辑</el-button> -->
              </div>
              <div class="size-box" style="display: none">
                <div class="size-list"></div>
                <div class="size-info"></div>
              </div>
            </div>
            <div class="vip_info">
              <div class="level_con">
                <div class="top el-row">
                  <div class="el-col el-col-12">
                    <span>{{ $t("account.my_privilege") }}</span>
                  </div>
                  <div class="align-right el-col el-col-12"></div>
                </div>
                <div class="level-content">
                  <div class="vip_content">
                    <div class="item first">
                      <div>
                        <div class="td font-title">
                          {{ $t("account.Level_authority") }}
                        </div>
                        <div class="td font-content">
                          {{ $t("account.International_Shipping_Discount") }}
                        </div>
                        <div class="td font-content">
                          {{ $t("account.Insurance_Discount") }}
                        </div>
                        <div class="td font-content">
                          {{ $t("account.Value_added_service_discounts") }}
                        </div>
                        <div class="td font-content">
                          {{ $t("account.Free_Aftersale_Operation_Fee") }}
                          <div class="font-tips">
                            {{ $t("account.Used_Available_Month") }}
                          </div>
                        </div>
                        <!-- <div class="td font-content">
                          定制拍照
                          <div class="font-tips">已用/可用(月)</div>
                        </div> -->
                      </div>
                    </div>
                    <div
                      class="item tr-col"
                      v-for="(item, index) in levels.vip"
                      :key="index"
                    >
                      <div
                        class="td-col"
                        :class="{ active: levels.level == item.vip }"
                      >
                        <!-- active -->
                        <div class="td font-title">
                          VIP{{ item.vip }}
                          <div>
                            {{ levelInfos[item.vip] }}
                          </div>
                        </div>
                        <div class="td font-content">
                          {{ item.send_order_discount * 100 }} %
                        </div>
                        <div class="td font-content">
                          <span>{{ item.insure_discount * 100 }} %</span>
                        </div>
                        <div class="td font-content">
                          <span>{{ item.add_server_discount * 100 }} %</span>
                        </div>
                        <div class="td font-content">
                          <span
                            >{{
                              levels.level == item.vip ? levels.return : 0
                            }}/{{ item.return_exchange_count }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="clearfix"></div>
                    <div class="el-loading-mask" style="display: none">
                      <div class="el-loading-spinner">
                        <svg viewBox="25 25 50 50" class="circular">
                          <circle
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            class="path"
                          ></circle>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('account.Shipping_address')" name="second">
          <div class="address_con">
            <div class="content">
              <div class="nav">
                <el-button
                  type="success"
                  @click="showForm = true"
                  size="small"
                  icon="el-icon-plus"
                  >{{ $t("account.Add_shipping_address") }}</el-button
                >
                <span
                  >{{ $t("account.you_have_created") }}{{ address_list.length
                  }}{{ $t("account.Shipping_address") }}</span
                >
              </div>
              <section class="table" v-if="address_list.length !== 0">
                <ul class="table-top">
                  <li class="title name">{{ $t("account.Consignee") }}</li>
                  <li class="title address">
                    {{ $t("account.Details_address") }}
                  </li>
                  <li class="title action">{{ $t("account.Operate") }}</li>
                </ul>
                <div class="content">
                  <ul
                    class="item-row"
                    v-for="(item, index) in address_list"
                    :key="index"
                  >
                    <li class="info name ellipsis bold">
                      {{ item.consignee }}
                    </li>
                    <li class="info address">
                      <p>
                        <span>{{ item.zip }}</span>
                        <span>{{ item.tel }}</span>
                      </p>
                      <p class="info info-address multi-ellipsis">
                        {{ item.address }}
                      </p>
                      <!-- <el-tag type="success" size="mini">{{
                        $t("account.Home")
                      }}</el-tag> -->
                    </li>
                    <li class="info">
                      <div class="tools">
                        <span class="text-btn" @click="editAddress(item)">
                          {{ $t("account.Edit") }}
                        </span>
                        <span class="text-btn" @click="deleteAddress(item.id)">
                          {{ $t("account.Delete") }}
                        </span>
                        <span
                          v-if="item.is_default === 0"
                          class="text-btn"
                          @click="setAddressDefault(item.id)"
                        >
                          {{ $t("account.Set_as_default") }}
                        </span>
                        <el-tag v-else type="danger" size="mini">{{
                          $t("account.Default_address")
                        }}</el-tag>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
              <div v-else class="no-data" style="min-height: 100px">
                <img src="" class="no-data-img" style="margin-top: 50px" />
                <div class="default" style="margin-top: 30px">
                  <p class="no-data-msg">
                    {{
                      $t(
                        "account.You_have_not_added_a_delivery_address_please_add_a_delivery_address"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mask_con" v-if="showForm">
              <div class="center">
                <div class="header">
                  <span class="title">{{
                    $t("account.New_shipping_address")
                  }}</span>
                  <div @click="showForm = false">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
                <div class="form">
                  <el-form
                    ref="form"
                    :rules="rules"
                    :model="form"
                    label-width="150px"
                  >
                    <el-form-item
                      :label="$t('account.Consignee')"
                      prop="consignee"
                    >
                      <div class="col_1">
                        <el-input
                          v-model="form.consignee"
                          :placeholder="$t('account.name')"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <div class="col_3 account_col_3">
                      <el-form-item
                        :label="$t('account.Region')"
                        prop="countryId"
                      >
                        <el-select
                          v-model="form.countryId"
                          :placeholder="$t('account.country')"
                        >
                          <el-option
                            v-for="item in country_list"
                            :label="item.label"
                            :value="item.value"
                            :key="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="state">
                        <el-input
                          :placeholder="$t('account.State_Province')"
                          v-model="form.state"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="city">
                        <el-input
                          :placeholder="$t('account.City')"
                          v-model="form.city"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <el-form-item
                      :label="$t('account.Details_address')"
                      prop="address"
                    >
                      <div class="col_1">
                        <el-input
                          type="textarea"
                          v-model="form.address"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item :label="$t('account.Zip_code')" prop="zip">
                      <div class="col_1">
                        <el-input v-model="form.zip"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item
                      :label="$t('account.Phone_number')"
                      prop="tel"
                    >
                      <div class="col_1">
                        <el-input v-model="form.tel"></el-input>
                      </div>
                    </el-form-item>
                    <!-- <el-form-item label="地址标签">
                      <div class="col_1">
                        <el-input v-model="form.name"></el-input>
                      </div>
                    </el-form-item> -->
                    <!-- <el-form-item :label="$t('account.Label')">
                      <el-radio-group v-model="form.label">
                        <el-radio :label="$t('account.Home')"></el-radio>
                        <el-radio :label="$t('account.Work')"></el-radio>
                      </el-radio-group>
                    </el-form-item> -->
                    <el-form-item :label="$t('account.Default_URL')">
                      <el-switch v-model="form.is_default"></el-switch>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="footer">
                  <el-button @click="showForm = false" round
                    >{{ $t("account.Cancel") }}
                  </el-button>
                  <el-button type="primary" @click="submitForm" round>{{
                    $t("account.Sure")
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('account.Account_safety')" name="third">
          <security
            v-if="Object.keys(userinfo).length !== 0"
            :userInfo="userinfo"
          ></security>
        </el-tab-pane>
        <!-- <el-tab-pane :label="$t('account.Credit_card_billing_address')" name="fourth">{{ $t('account.Credit_card_billing_address') }}</el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import userInfo from "./userInfo.vue";
import security from "./security.vue";
import {
  getUserAddressList,
  addAddress,
  deleteUserAddress,
  setUserAddressDefault,
} from "@/Api/user";
import { getCountryList, myAuth } from "@/Api/index";
export default {
  name:"account-vue",
  components: { userInfo, security },
  data() {
    return {
      activeName: "first",
      form: {
        consignee: "",
        countryId: "",
        state: "",
        city: "",
        zip: "",
        address: "",
        tel: "",
        is_default: 0,
        label: "",
      },
      showForm: false,
      rules: {
        consignee: [
          {
            required: true,
            message: this.$t("account.name_cannot_be_blank"),
            trigger: "blur",
          },
        ],
        countryId: [
          {
            required: true,
            message: this.$t("account.Country_cannot_be_null"),
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: this.$t("account.State_Province_cannot_be_blank"),
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("account.City_cannot_be_empty"),
            trigger: "change",
          },
        ],
        label: [
          {
            type: "array",
            required: true,
            message: this.$t(
              "account.Please_select_at_least_one_activity_type"
            ),
            trigger: "change",
          },
        ],
        zip: [
          {
            required: true,
            message: this.$t("account.Zip_code_cannot_be_empty"),
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("account.Address_cannot_be_empty"),
            trigger: "change",
          },
        ],
        tel: [
          {
            required: true,
            message: this.$t("account.Phone_number_cannot_be_empty"),
            trigger: "blur",
          },
        ],
      },
      country_list: [],
      address_list: [],
      userinfo: {},
      levels: [],
      levelInfos: {
        1: this.$t("account.Public_Member"),
        2: this.$t("account.Silver_Member"),
        3: this.$t("account.Gold_Member"),
        4: this.$t("account.Platinum_Member"),
        5: this.$t("account.Diamond_Member"),
        6: this.$t("account.Black_Gold_Member"),
        7: this.$t("account.Black_Gold_PLUS"),
      },
    };
  },
  created() {
    this.getMyAuth();
    this.isAddress()
  },
  watch: {
    showForm(newVal) {
      if (!newVal) {
        this.form = {
          consignee: "",
          countryId: "",
          state: "",
          city: "",
          zip: "",
          address: "",
          tel: "",
          is_default: "",
          label: "",
        };
      }
    },
  },
  methods: {
    isAddress(){
      var addrsss = this.$route.query.address
      if(addrsss!=null){
        this.activeName="second"
        this.initList()
        getCountryList().then((res) => {
            if (res.code === 1) {
              this.country_list = res.data;
            }
          });
      }
 
    },
    t(key) {
      return this.$t(key);
    },
    getUserinfo(data) {
      this.userinfo = data;
    },
    initList() {
      //地址列表
      getUserAddressList().then((res) => {
        if (res.code === 1) {
          this.address_list = res.data;
        }
      });
    },
    handleClick(tab, event) {
      //  收获地址
      if (event.target.id.includes("second")) {
        // 国家列表
        this.country_list.length === 0 &&
          getCountryList().then((res) => {
            if (res.code === 1) {
              this.country_list = res.data;
            }
          });
        this.initList();
      }
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          addAddress(this.form).then((res) => {
            this.showForm = false;
            if (res.code === 1) {
              this.$message({
                message: this.$t("account.Added_address_successfully"),
                type: "success",
              });
              this.initList();
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    editAddress(item) {
      this.form = { ...item };
      if (item.is_default==1){
        this.form.is_default=true
      }else {
        this.form.is_default=false
      }
      this.showForm = true;
    },
    deleteAddress(aid) {
      deleteUserAddress({ aid }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: this.$t("account.Delete_successful"),
            type: "success",
          });
          this.initList();
        } else {
          this.$message({
            message: res.msg,
          });
        }
      });
    },
    setAddressDefault(aid) {
      setUserAddressDefault({ aid, type: "1" }).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: this.$t("account.Setting_successful"),
            type: "success",
          });
          this.initList();
        }
      });
    },
    getMyAuth() {
      myAuth().then((res) => {
        this.levels = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.usersize_con {
  margin-bottom: 20px;
}
.usersize_edit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 25px;
  }
}
.vip_info {
  .right {
    text-align: right;
  }
  .level_con {
    background: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .top {
      font-weight: 700;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 14px;
    }

    .level-content {
      .vip_content {
        margin-top: 20px;

        .item {
          float: left;
          vertical-align: middle;
          min-width: 112px;

          .font-title {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
          }
          .font-content {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333;
          }
          .td {
            font-size: 12px;
            height: 60px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            border-bottom: 1px solid #ccc;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            .font-tip {
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666;
            }
          }
          .active {
            border: 2px solid #ef0c25;
            border-right-width: 3px;
            -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
            .td {
              // width: 98px;
              color: #ef0c25;
              border-color: #ef0c25;
              height: 60px;
            }
            .font-title {
              background-color: #ef0c25;
              color: #fff;
            }
          }
        }
        .first {
          min-width: 150px;
          max-width: 180px;
          .td {
            font-size: 14px;
            padding-left: 20px;
            text-align: left;
          }
        }
      }
    }
  }
}

.address_con {
  min-height: 400px;
  background-color: #fff;
  padding: 10px 20px 20px;
  .content {
    .nav {
      display: flex;
      align-items: center;
      button {
        background: linear-gradient(to right, #fd5532, #ef0c25);
        border: none;
      }
      span {
        margin-left: 10px;
        color: #999;
        font-size: 12px;
      }
    }
    .table {
      margin-top: 30px;
      .address {
        display: inline-block;
        width: 470px;
      }
      .name {
        display: inline-block;
        width: 140px;
      }
      .action {
        display: inline-block;
        width: 330px;
        text-align: center;
      }
      .table-top {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px 0;
        font-size: 14px;
        border-bottom: 1px solid #ddd;
        .title {
          color: #333;
          font-weight: 700;
        }
      }
      .content {
        font-size: 12px;
        .item-row {
          padding: 20px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          color: #333;
          border-bottom: 1px solid #f2f2f2;
          .info-address {
            width: 440px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .tools {
            width: 340px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            .text-btn {
              cursor: pointer;
              padding: 0;
            }
            .text-btn:hover {
              color: #ef0c25;
            }
          }
        }
      }
    }
    .no-data {
      width: 100%;
      text-align: center;
      color: #ccc;
      .no-data-img {
        width: 300px;
        margin-right: 14px;
      }
      .default {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333;
      }
    }
  }
  .mask_con {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 90;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: auto;
    .center {
      border-radius: 10px;
      width: 800px;
      position: relative;
      min-height: 332px;
      background: #fff;
      -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      overflow: hidden;
      margin: 100px auto;
      .header {
        padding: 0 30px;
        height: 50px;
        line-height: 50px;
        background-color: #f2f2f2;
        color: #fff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        display: flex;
        justify-content: space-between;
        .title {
          vertical-align: middle;
          color: #333;
          font-weight: 400;
        }
      }
      .form {
        form {
          position: relative;
          padding: 20px;
          font-size: 12px;
          .col_2 {
            display: grid;
            grid-template-columns: 49% 49%;
            justify-content: space-between;
            padding-right: 30px;
          }
          .col_3 {
            display: flex;
            justify-content: flex-start;
            padding-right: 30px;
          }
          .col_1 {
            padding-right: 30px;
          }
        }
      }
      .footer {
        padding: 30px;
        text-align: center;
        button {
          font-size: 12px;
        }
        button:last-child {
          background: linear-gradient(to right, #fd5532, #ef0c25);
          border: 1px solid #ef0c25;
        }
        button:last-child:hover {
          background: linear-gradient(to right, #e34d2d, #d60b20);
        }
      }
    }
  }
}

</style>
