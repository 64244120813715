<template>
  <div class="container">
    <HeaderTop />
    <div class="w">
      <div class="tips">
        <span
          >{{ $t("goodslist.We_have_searched_for") }}"
          <span style="color: #333">{{ this.$route.query.searchValue }}</span>
          "{{ $t("goodslist.related_content_for_you_come_and_buy_it") }}~</span
        >
        <span class="agree"
          >{{ $t("goodslist.Agreed") }}
          <a href="/question?requestId=20" class="search">{{
            $t("goodslist.Search_Service_Terms")
          }}</a></span
        >
      </div>
      <!-- tab 标签栏 -->
      <el-tabs v-model="activeName" class="tabContainer" @tab-click="switchTab">
        <!--1688相关-->
        <el-tab-pane label="Agent Shopping from - 1688" name="1688">
          <div class="goods">
            <ul>
              <li v-for="item in goodsList3" :key="item.id">
                <a href="">
                  <router-link
                    :to="{
                      name: 'GoodsDetail',
                      query: { itemId: item.itemId, type: activeName },
                    }"
                    target="_blank"
                  >
                    <img :title="item.title" v-lazy="item.image" />
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.title"
                      placement="bottom"
                    >
                      <span class="title">
                        <a>{{ item.title }}</a>
                      </span>
                    </el-tooltip>
                    <span class="d_bottom">
                      <b class="d_price">{{
                        currency + " " + moneyRates(item.price)
                      }}</b>
                      <b class="sold">{{
                        $t("goodslist.Sold") + "：" + item.sold
                      }}</b>
                    </span>
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <!--淘宝相关-->
        <el-tab-pane
          v-if="tab != 'imageSearch'"
          label="Agent Shopping from - Taobao"
          name="taobao"
        >
          <div class="goods">
            <ul>
              <li v-for="item in goodsList2" :key="item.id">
                <a href="">
                  <router-link
                    :to="{
                      name: 'GoodsDetail',
                      query: { itemId: item.itemId, type: activeName },
                    }"
                    target="_blank"
                  >
                    <img :title="item.title" v-lazy="item.image" />
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.title"
                      placement="bottom"
                    >
                      <span class="title">
                        <a>{{ item.title }}</a>
                      </span>
                    </el-tooltip>
                    <span class="d_bottom">
                      <b class="d_price">{{
                        currency + " " + moneyRates(item.price)
                      }}</b>
                      <b class="sold">{{
                        $t("goodslist.Sold") + "：" + item.sold
                      }}</b>
                    </span>
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <!--微店相关-->
        <el-tab-pane
          v-if="tab != 'imageSearch'"
          label="Agent Shopping from - Micro"
          name="micro"
        >
          <div class="goods">
            <ul>
              <li v-for="item in goodsList1" :key="item.id">
                <a href="">
                  <router-link
                    :to="{
                      name: 'GoodsDetail',
                      query: { itemId: item.itemId, type: activeName },
                    }"
                    target="_blank"
                  >
                    <img :title="item.title" v-lazy="item.image" />
                    <!-- <img :src="item.image" alt="" loading="lazy" /> -->

                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.title"
                      placement="bottom"
                    >
                      <span class="title">
                        <a>{{ item.title }}</a>
                      </span>
                    </el-tooltip>

                    <span class="d_bottom">
                      <b class="d_price">{{
                        currency + " " + moneyRates(item.price)
                      }}</b>
                      <b class="sold">{{
                        $t("goodslist.Sold") + "：" + item.sold
                      }}</b>
                    </span>
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
        </el-tab-pane>

        <!-- 加载中动画 -->
        <van-loading
          v-if="isLoading"
          size="24px"
          type="spinner"
          style="text-align: center; height: 80px; line-height: 80px"
        >
          {{ $t("goodslist.loading") }}
        </van-loading>
      </el-tabs>
    </div>
    <Bottom />
  </div>
</template>

<script>
import { common } from "@/Api/common";
import { photoSearch, photoUpload } from "@/Api/index";
// import ImageCompressor from "image-compressorjs";
export default {
  name: "GoodsList",
  components: {
    // 需要将import引入改为components内部引入，解决递归式调用组件 导致<HeaderTop/>不显示问题
    HeaderTop: () => import("@/components/mainPage/HeaderTop.vue"),
    Bottom: () => import("@/components/mainPage/Bottom.vue"),
  },
  data() {
    return {
      activeName: "1688", // 默认type类型
      goodsList1: [], // 必须写
      goodsList2: [],
      goodsList3: [],
      pageNum: 1,
      searchValue: this.$route.query.inputValue,
      isover: false, // 是否到底 (默认没到底)
      isLoading: false, // 是否正在加载
      currency: "",
      tab: "",
      imgSearchUrl: "",
    };
  },
  // 监视url路由中的参数是否发生变化  只要路由发生了变化，就重新请求接口
  watch: {
    $route: {
      // immediate:true,
      handler(newValue) {
        this.pageNum = 1;
        this.activeName = "1688";
        this.goodsList1 = [];
        this.goodsList2 = [];
        this.goodsList3 = [];
        localStorage.setItem(
          "searchValue" + this.activeName,
          newValue.query.inputValue
        );
        // 重新请求接口
        this.getGoods(newValue.query.inputValue, "1688", this.pageNum);
      },
    },
  },
  methods: {
    // 转换money
    moneyRates(money) {
      return this.moneyRate(money);
    },
    // 同一个搜索词切换tab之后，不重复请求服务器
    switchTab(tab, event) {
      this.isLoading = true;
      var type = tab.name;
      var searchValue = localStorage.getItem("searchValue" + type);

      //切换时 判断这次搜索和上次搜索关键词是否一样
      if (searchValue === this.$route.query.inputValue) {
        // 关键词一样 判断下面条件 为了减少服务器请求 只要有数据 就不刷新
        if (type === "micro" && this.goodsList1.length > 0) {
          return;
        }
        if (type === "taobao" && this.goodsList2.length > 0) {
          return;
        }
        if (type === "1688" && this.goodsList3.length > 0) {
          return;
        }
      }
      localStorage.setItem("searchValue" + type, this.$route.query.inputValue);
      this.pageNum = 1;
      this.getGoods(this.$route.query.inputValue, type, this.pageNum);
    },
    // 监听滚动事件的方法
    handleScroll() {
      // 计算：页面滚动的高度scrollTop、窗口可视区域高度clientHeight、页面总高度scrollHeight
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - scrollTop - clientHeight < 10 && !this.isover) {
        // 当距离页面底部不足50px且非正在加载状态时，触发加载数据的方法
        this.pageNum++; // 增加页数
        if (this.activeName != "micro") {
          this.getGoods(
            this.$route.query.inputValue,
            this.activeName,
            this.pageNum
          );
        }
      }
    },
    // 上传照片
    uploadImage(file) {
      var base64 = file.content.split(";base64,");
      var data = {};
      this.imgSearchUrl = file.content;
      // 保存当前搜索的图片到本地
      let imgSearch = localStorage.getItem("imgSearch");
      if (imgSearch) {
        localStorage.removeItem("imgSearch");
      }
      localStorage.setItem("imgSearch", file.content);
      data["base64"] = base64[1];
      data["type"] = "1688"; //目前只有1688
      this.$toast.loading({
        message: "loading",
        duration: 0,
      });
      photoUpload(data).then((res) => {
        if (res.code == 1) {
          var data = {};
          // 这个地方可以优化  拿到 res.data 之后 以跳转的方式到另一个页面 然后走photoSearch方法
          data["imageId"] = res.data;
          data["type"] = "1688"; //目前只有1688
          this.$toast.clear();
          photoSearch(data).then((res) => {
            if (res.code === 1) {
              this.goodsList3 = res.data;
              // this.$toast("搜索成功");
            }
          });
        }
      });
    },
    compressPic(file, options) {
      let reads = new FileReader();
      reads.readAsDataURL(file);
      reads.onload = ({ target: { result: src } }) => {
        // 这里quality的范围是（0-1）
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var img = new Image();
        img.src = src;
        img.onload = function () {
          let width = options.width;
          canvas.width = width;
          canvas.height = width * (options.height / options.width);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          // 转换成base64格式 quality为图片压缩质量 0-1之间  值越小压缩的越大 图片质量越差
          let data = canvas.toDataURL(file.type, options.quality);
          let blob = new Blob([data], { type: data.type });
          // 创建一个新的 FileReader 对象
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          // 加载完成后
          reader.onloadend = function () {
            // 将读取的数据转换为 base64 编码的字符串
            const base64String = reader.result.split(",")[1];
            console.log(base64String);
            // 解析为 Promise 对象，并返回 base64 编码的字符串
            return base64String;
          };
        };
      };
    },
  },
  created() {
    this.currency = this.getCurrency;
    // 把主页输入值作为参数 请求商品列表
    localStorage.setItem(
      "searchValue" + this.activeName,
      this.$route.query.inputValue
    );
    this.getGoods(this.$route.query.inputValue, this.activeName, this.pageNum);
    // 监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // 组件销毁时移除事件监听 ，防止内存泄漏
    window.removeEventListener("scroll", this.handleScroll);
  },

  mixins: [common], // 使用common中的getGoods数组
};
</script>

<style lang="less" scoped>
.container {
  background-color: #f9f9f9;
  padding-bottom: 10px;
}

.tips {
  height: 60px;
  line-height: 60px;
}
.tips span {
  font-size: 16px;
  font-weight: 400;
  color: #79797e;
}
.agree {
  float: right;
}
.search {
  color: #2e5aef;
}
/* a {
  color: #2e5aef;
} */
/* tab 标签栏 */
.tabContainer {
  background-color: #fff;
  border-radius: 10px;
  min-height: 50vh;
  border: 1px #fff solid;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
.goods {
  ul {
    /* background-color: pink; */
    display: flex;
    /* 控制子元素是否换行 */
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;
  }
}

.goods li {
  margin-bottom: 15px;
  padding: 10px;
  margin-left: 13px;
  width: 224px;
  border: 1px solid transparent;
  /* height: 294px; */
  /* border: 1px solid green; */
}
/* 过渡效果 transition：参与过渡的属性 过渡的持续时间*/
.goods li:hover {
  /* scale: 1.1; */
  /* transform: scale(1.1); */
  transition: transform 0.5s;
  // box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.1);
  // border: 1px solid #e4e4e4;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.goods li span {
  display: block;
}
.goods img {
  width: 200px;
  height: 200px;
}
/* 标题显示 */
.title {
  cursor: pointer;
  color: #333;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.d_bottom {
  height: 24px;
  line-height: 24px;
  margin-top: 6px;
  .d_price {
    color: #333;
    font-size: 20px;
    font-weight: 700;
  }
  .sold {
    float: right;
    font-size: 12px;
    font-weight: normal;
    color: #999;
  }
}
.searchImg_con {
  margin: 20px 0;
  display: flex;
  align-items: center;
  .searchImg {
    margin-right: 20px;
    img {
      width: 100px;
      height: auto;
    }
  }
}
</style>