<template>
  <div class="couponlist_page">
    <div class="active_con">
      <h4>{{ $t('coupon.Coupon_activation') }}</h4>
      <div class="content">
        <el-input
          v-model="coupon_code"
          :placeholder="$t('coupon.Enter_your_coupon_activation_code')"
        ></el-input>
        <el-button type="primary" @click="activeCoupon">{{ $t('coupon.activation') }}</el-button>
      </div>
    </div>
    <el-row class="nav">
      <ul class="btn-list">
        <li
          :class="{ 'btn-item': true, active: btnActive === 0 }"
          @click="
            btnActive = 0;
            type = 0;
          "
        >
          <span>{{ $t("orderlist.Shopping") }}</span>
        </li>
        <li
          :class="{ 'btn-item': true, active: btnActive === 1 }"
          @click="
            btnActive = 1;
            type = 1;
          "
        >
          <span>{{ $t("orderlist.MyCoupon") }}</span>
        </li>
      </ul>
      <div class="mycoupon_nav" v-show="type === 1">
        <div :class="{active:tabActive==1}" @click="tabActive=1,myCouponListMethod(1)" >{{ $t('coupon.all') }}</div>
        <div :class="{active:tabActive==2}" @click="tabActive=2,myCouponListMethod(2)">{{ $t('coupon.Used') }}</div>
        <div :class="{active:tabActive==3}" @click="tabActive=3,myCouponListMethod(3)">{{ $t('coupon.expired') }}</div>
      </div>
    </el-row>

    <!-- 商城 -->
    <div class="coupon-list" v-if="type === 0">
      <div
        :class="'coupon-item'+ (item.remain === 0 ? ' disabled' : '')"
        v-for="(item, index) in couponListArr"
        :key="index"
      >
        <div class="coupon-top">
          <div class="coupon-left">
            <h4 v-if="item.type == 1">
              {{ $t('coupon.Order_full') }}{{ item.required }} {{ $t('coupon.reduce') }} {{ item.money }}
            </h4>
            <h4 v-if="item.type == 2">
              {{ $t('coupon.Waybill_full') }}{{ item.required }} {{ $t('coupon.minus') }} {{ item.money }}
            </h4>
            <h4 v-if="item.type == 3">{{ $t('coupon.Waybill') }}{{ item.money * 10 }}{{ $t('coupon.Discount') }}</h4>
            <p style="margin: 10px 0 3px 0">{{ $t('coupon.Get_coupon_number') }}:{{ item.code }}</p>
            <span
              >{{ $t('coupon.Validity') }}:{{ item.start_time_text }} —
              {{ item.end_time_text }}</span
            >
            <br />
            <span>{{ $t('coupon.Remaining') }}:{{ item.remain }} {{ $t('coupon.cards') }} </span>
            <br />
            <span>{{ $t('coupon.Require') }}:{{ item.point == null ? 0 : item.point }} {{ $t('coupon.Points') }}</span>
          </div>
          <div class="coupon-right">
            <div class="big-price" v-if="item.type != 3">
              <font>CNY</font>
              <span>{{ item.money }}</span>
            </div>

            <div class="big-price" v-if="item.type == 3">
              <!-- <font>CNY</font> -->
              <span>{{ item.money * 10 }} {{ $t('coupon.discount') }}</span>
            </div>
            <span class="small-price">{{ $t('coupon.Full') }}{{ item.required }}{{ $t('coupon.Available') }}</span>
          </div>
        </div>
        <div class="coupon-bottom">
          <div class="coupno-left">
            <div>
              <p style="margin-bottom: 3px">
                {{ $t('coupon.Coupon_type') }}:{{ item.type == "1" ? $t('coupon.Order_Coupon') : $t('coupon.Waybill_Coupon') }}
              </p>
              <p style="margin-bottom: 3px">
                {{ $t('coupon.The_validity_period_after_activation_is') }}:{{ item.valid_day }} {{ $t('coupon.sky') }}
              </p>
            </div>
          </div>
          <div class="coupon-right">
            <el-button
              type="primary"
              plain
              round
              @click="exchangeCouponButton(item)"
              >{{ $t('coupon.Redeem_now') }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 我的优惠券列表 --目前测试用的假数据，需要更换为 myCouponlist -->
    <div class="coupon-list" v-else>
      <div
      v-for="(item, index) in myCouponArr"
        :class="'coupon-item ' +(item.valid_use === 0 ? ' disabled' : '')"
       
        :key="index"
      >
        <div class="coupon-top">
          <div class="coupon-left">
   
            <h4 v-if="item.rule.type === 1">
              {{ $t('coupon.Order_full') }} {{ item.rule.required }} {{ $t('coupon.reduce') }} {{ item.money }}
            </h4>
            <h4 v-if="item.rule.type === 2">
              {{ $t('coupon.Waybill_full') }}{{ item.rule.required }} {{ $t('coupon.minus') }} {{ item.money }}
            </h4>
            <h4 v-if="item.rule.type === 3">{{ $t('coupon.Waybill') }}{{ item.money * 10 }}{{ $t('coupon.Discount') }}</h4>
            <p style="margin: 10px 0 3px 0">{{ $t('coupon.Coupon_No') }}:{{ item.code }}</p>
            <span v-if="valid_use != 0" >{{ $t('coupon.Validity') }}{{ item.add_time_text }} —
              {{ item.deal_time_text }}</span
            >
            <span v-else>{{ $t('coupon.usage_time') }}:{{ item.start_time_text }}</span>
          </div>
          <div class="coupon-right">
            <div class="big-price" v-if="item.rule.type != 3">
              <font>CNY</font>
              <span>{{ item.money }}</span>
            </div>

            <div class="big-price" v-if="item.rule.type == 3">
              <span>{{ item.money * 10 }} {{ $t('coupon.discount') }}</span>
            </div>
            <span class="small-price" v-if="item.rule.type === 1">{{ $t('coupon.Order_full') }} {{ item.rule.required }} {{ $t('coupon.CNY_available') }}</span>
            <span class="small-price" v-else>{{ $t('coupon.Waybill_full')}} {{ item.rule.required }} {{ $t('coupon.CNY_available') }}</span>
          </div>
        </div>
        <div class="coupon-bottom">
          <div class="coupno-left">
            <div>
              <p style="margin-bottom: 3px">
                {{ $t('coupon.Coupon_type') }} : {{ item.rule.type === 1 ? $t('coupon.Order_Coupon') : $t('coupon.Waybill_Coupon') }}
              </p>
              <p style="margin-bottom: 3px">{{ $t('coupon.Obtain_method') }} {{item.return_type===""?"reward":item.return_type }}</p>
              <!-- <p style="margin-bottom: 3px">使用范围：全部路线可用</p> -->
            </div>
          </div>
          <div class="coupon-right paid">
            <el-button
              v-show="item.valid_use > 0"
              type="primary"
              plain
              round
              @click="usecoupon(item)"
              >{{ $t('coupon.Use_now') }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exchangeCouponList,
  exchangeCoupon,
  myCouponList,
  getUserCouponList,
  activeCoupon,
} from "@/Api/coupon";

export default {
  name:"coupon-vue",
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      currency: "CNY", //显示货币
      btnActive: 0,
      couponListArr: [],
      type: 0,
      coupon_code: "",
      myCouponArr:[],
      tabActive:1
    };
  },
  methods: {
    usecoupon(item){
      //订单满减
      if(item.category==1){
          this.$router.push(
            {
              path:"/",
            }
          )
      }else{
        this.$router.push(
            {
              path:"/person/wareHouse",
            }
          )
      }
    },
    //转换金额
    moneyRates(money) {
      return this.moneyRate(money);
    },
    open() {
      this.$prompt(
        this.$t("coupon.Please_enter_the_activation_code"),
        this.$t("coupon.Tips"),
        {
          confirmButtonText: this.$t("coupon.Sure"),
          cancelButtonText: this.$t("coupon.Cancel"),
          inputType: "text",
        }
      )
        .then(({ value }) => {
          if (value == "") {
            this.$message.info(this.$t("coupon.Coupon_code_cannot_be_empty"));
            return;
          }
          var data = {};
          data["code"] = value;
          activeCoupon(data).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
            this.userCouponList();
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });
        });
    },
    activeCoupon() {
      if (this.coupon_code == "") {
        this.$message.info(this.$t("coupon.Coupon_code_cannot_be_empty"));
        return;
      }
      var data = {};
      data["code"] = this.coupon_code;
      activeCoupon(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.userCouponList();
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.userCouponList();
    },
    handleCurrentChange(val) {
      console.log("页数改变" + val);
    },
    userCouponList() {
      var data = {};
      data["page"] = this.currentPage;
      data["pageSize"] = this.pageSize;
      getUserCouponList(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    couponList() {
      exchangeCouponList().then((res) => {
        this.couponListArr = res.data;
      });
    },
    exchangeCouponButton(cid) {
      if(cid.remain==0){
        return
      }
      var data = {};
      data["cid"] = cid.id;
      exchangeCoupon(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          location.reload()
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    myCouponListMethod(state=0) {
      var data = {};
      data["page"] = 1;
      if(state>0){
        data["state"] = state;
      }
      myCouponList(data).then((res) => {
        if (res.code === 1) {
          this.myCouponArr = res.data.data;
        }
      });
    },
  },
  created() {
    this.currency = this.getCurrency;
    this.userCouponList();
    this.couponList();
    this.myCouponListMethod()
  },
};
</script>

<style scoped lang="less">
.header {
  display: flex;
  justify-content: space-between;
  .el-button.el-button--success {
    border-color: #ef0c25;
    background: linear-gradient(to right, #fd5532, #ef0c25);
  }
  .el-button.el-button--success:hover {
    background: linear-gradient(to right, #e34d2d, #d60b20);
  }
}
.desc {
  margin-top: 20px;
  margin-bottom: 20px;
}
.pageNo {
  margin-top: 20px;
  text-align: right;
  margin-top: 20px;
}

.couponlist_page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  padding: 20px;
}
.active_con {
  margin-bottom: 20px;
  background: #fff;
  padding: 20px 0 20px 20px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button.el-button--primary {
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }

    .el-button.el-button--primary:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
    .el-input {
      width: 400px;
    }
    button {
      margin-left: 20px;
      background: #ef0c25;
      border: none;
      width: 120px;
    }
  }
}
.mycoupon_nav {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding: 10px;
  font-size: 13px;
  color: #333;
  position: absolute;
  right: 0;
  top: 5px;
  div {
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
  }
}
.btn-list {
  display: flex;
  background: #fff;
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 88px;
    text-align: center;
    cursor: pointer;
    height: 40px;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 14px;
    color: #666;
    border: 1px solid #fff;
    // border-radius: 4px;
  }
  .active {
    color: #ef0c25;
    border-bottom: 2px solid #ef0c25;
    // background-color: #f7e5e6;
  }
}

.coupon-list {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
  .coupon-item {
    margin: 10px 0;
    padding: 5px 0;
    // border: 1px dashed #ccc;
    cursor: pointer;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
    .coupon-top,
    .coupon-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .coupon-top {
      border-bottom: 1px solid #eee;
      h4 {
        font-size: 14px;
        color: #333;
      }
      .big-price {
        font-size: 24px;
        font-weight: 600;
        color: #ef0c25;
        font {
          font-size: 14px;
        }
      }
      .small-price {
        color: #ef0c25;
      }
    }
    .coupon-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .el-button--primary.is-plain {
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    .el-button--primary.is-plain:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
    .el-button--primary.is-disabled {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }
  .coupon-item.disabled {
    opacity: 0.8;
    .coupon-bottom {
      padding: 5px 10px;
    }
    .coupon-bottom .coupon-right.paid {
      width: 61px;
      height: 61px;
      background: url("../../../public/splite.png") no-repeat;
      background-position: 0 0;
    }
    .coupon-bottom .coupon-right.expired {
      width: 61px;
      height: 61px;
      background: url("../../../public/splite.png") no-repeat;
      background-position: -345px 0;
    }
    .big-price,
    .small-price,
    h4 {
      color: #888 !important;
    }
  }
}
</style>
