<template>
    <div class="bottomPart">
      <div class="b_top">
        <ul class="w">
          <li>
            <img src="../../assets/bottom/b_goods.png" alt="" />
            <div class="t_right">
              <span>{{ $t('bottom.Commodity_purchase') }}</span>
              <p>{{ $t('bottom.Source_of_this_product') }}</p>
            </div>
          </li>
          <li>
            <img src="../../assets/bottom/b_money.png" alt="" />
            <div class="t_right">
              <span>{{ $t('bottom.Cost_savings') }}</span>
              <p>{{ $t('bottom.If_you_purchase_goods_from_different_merchants_we_will_help_you_consolidate_them_into_one_package_and_send_them_to_your_country') }}</p>
            </div>
          </li>
          <li>
            <img src="../../assets/bottom/b_user.png" alt="" />
            <div class="t_right">
              <span>{{ $t('bottom.service_quality') }}</span>
              <p>{{ $t('bottom.Provide_efficient_mature_and_stable_service_support') }}</p>
            </div>
          </li>
          <li>
            <img src="../../assets/bottom/b_message.png" alt="" />
            <div class="t_right">
              <span>{{ $t('bottom.Quick_response') }}</span>
              <p>{{ $t('bottom.Help_you_solve_your_difficulties_in_the_shortest_time') }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="b_main">
        <div class="w">
          <ul>
            <!-- <li>
              <h4>{{ $t('bottom.customer_service') }}</h4>
              <p><a href="/question?requestId=#">{{ $t('bottom.Help_Center') }}</a></p>
              <p><a href="/question?requestId=#">{{ $t('bottom.contact_us') }}</a></p>
            </li> -->
            <li>
              <h4>{{ $t('bottom.customer_service') }}</h4>
              <p><a href="/question?requestId=6">{{ $t('bottom.Purchasing_demo') }}</a></p>
              <p><a href="/question?requestId=11">{{ $t('bottom.Services_and_fees') }}</a></p>
              <p><a href="/question?requestId=5">{{ $t('bottom.Return_and_exchange_service') }}</a></p>
            </li>
            <li>
              <h4>{{ $t('bottom.payment_methods') }}</h4>
              <p><a href="/question?requestId=12">{{ $t('bottom.Recharge_method') }}</a></p>
              <p><a href="/question?requestId=13">{{ $t('bottom.Paypal_payment') }}</a></p>
              <!-- <p><a href="">{{ $t('bottom.International_Credit_Card_Payment') }}</a></p> -->
            </li>
            <li>
              <h4>{{ $t('bottom.Delivery_related') }}</h4>
              <p><a href="/estimation">{{ $t('bottom.Logistics_shipping_fee') }}</a></p>
              <p><a href="/question?requestId=14">{{ $t('bottom.Centralized_packaging') }}</a></p>
              <p><a href="/question?requestId=15">{{ $t('bottom.Customs_and_taxes') }}</a></p>
              <p><a href="/question?requestId=16">{{ $t('bottom.Precautions_for_receiving_goods') }}</a></p>
            </li>
            <li>
              <h4>{{ $t('bottom.After_sales_service') }}</h4>
              <p><a href="/question?requestId=17">{{ $t('bottom.Product_storage_period') }}</a></p>
              <p><a href="/question?requestId=18">{{ $t('bottom.Inspection_rules') }}</a></p>
              <p><a href="/question?requestId=19">{{ $t('bottom.Insurance_and_indemnity') }}</a></p>
            </li>
            <!-- <li>
              <h4>关注我们</h4>
              <p><a href="">帮助中心</a></p>
              <p><a href="">联系我们</a></p>
            </li> -->
          </ul>
          <div class="brand">
            <div class="b_first">
              <p>
                Copyright© 2024 seacloud.com All Rights Reserved
                <!-- <a href="">{{ $t('bottom.YuICP_No_2024000320') }}</a> -->
              </p>
              <span>
                <a href="/question?requestId=21">{{ $t('bottom.About_Seacloud') }} </a>
                <a href="/question?requestId=2">{{ $t('bottom.Terms_of_Service_and_User_Management') }} </a>
                <a href="/question?requestId=7">{{ $t('bottom.Privacy_Notice') }}</a>
                <a href="/question?requestId=9">{{ $t('bottom.contact_us') }}</a>
              </span>
            </div>
  
            <div class="b_desc">
              <p>
                <!-- {{ $t('bottom.Company_name_SEACLOUD_CN_TECHNOLOGY_CO_LIMITED_Company_registration_number_24052668') }} -->
              </p>
              <p>
                {{ $t('bottom.Henan_Cross_Sea_Yungou_Technology_Co_Ltd') }}
                
              </p>
            </div>
            <!-- <div class="b_icons"></div> -->
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  
  <style lang="less" scoped>
  .bottomPart {
     //border: 1px solid red;
     min-width: fit-content;
    .b_top {
      height: 120px;
      background-color: #f6f6f6;
      ul {
        display: flex;
        li {
          width: 300px;
          height: 90px;
          padding-top: 30px;
          // border: 1px solid red;
          img {
            float: left;
            margin-right: 20px;
            width: 40px;
            height: 40px;
          }
          .t_right {
            float: left;
            width: 220px;
          }
          span {
            display: block;
            font-size: 18px;
            color: #333;
          }
          p {
            margin-top: 4px;
            font-size: 12px;
            color: #666;
          }
        }
      }
    }
    .b_main {
      height: 384px;
      background-color: #282828;
      ul {
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        // padding: 40px 40px 0;
        padding-top: 40px;
        border-bottom: 1px solid #9c9ea6;
        color: #999;
        li {
          width: 196px;
          height: 150px;
          h4 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 18px;
          }
          p {
            cursor: pointer;
            font-size: 12px;
            margin-top: 6px;
            a {
              color: #999;
            }
          }
        }
      }
      .brand {
        .b_first {
          display: block;
          font-size: 14px;
          padding-top: 20px;
          color: #9c9ea6;
          p {
            float: left;
          }
          span {
            float: right;
            a {
              margin-left: 10px;
            }
          }
          a {
            color: #9c9ea6;
          }
        }
        .b_desc {
          font-size: 14px;
          padding-top: 20px; //
          color: #999;
        }
        .b_icons {
          height: 80px;
          margin-top: 30px;
          // margin-left: 40px;
          background: url(../../assets/bottom/footer-pay.png) no-repeat;
          background-size: auto 33px;
        }
      }
    }
  }
  </style>
  