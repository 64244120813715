<template>
  <div>
    <HeaderTop />
    <div class="person_page w">
      <div class="person_nav">
        <!-- 主导航 -->
        <ul class="person_panel">
          <li
            v-for="(item, index) in menu"
            :key="index"
            :class="{ active: item.id === curid }"
            @click="
              () => {
                curid = item.id;
				setArticle(item.id)
              }
            "
          >
            <div :title="item.title" class="text-overflow">{{ item.title }}</div>
          </li>
        </ul>
      </div>
      <div class="person_content">
		  <div v-if="isShow" v-html="content"></div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "@/components/mainPage/HeaderTop";
import Bottom from "@/components/mainPage/Bottom.vue";
import {getArticleList} from "@/Api/index"
import { getQuestionInfo } from "@/Api/other";
export default {
  name: "more",
  components: {
    HeaderTop,
    Bottom,
  },
  data() {
    return {
      curid: "",
	  isShow:false,
	  content:"",
      menu: []
    }
  },
  methods:{
	setArticle(id){
		this.isShow = true
    var data = {}
    data["question"]=id
    getQuestionInfo(data).then(res=>{
        this.content=res.data.content
    })
	}  
  },
  mounted() {
  	getArticleList().then((res)=>{
  		this.menu=res.data
  	})
  }

};
</script>

<style lang="less" scoped>
.person_page {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 15px;
  min-height: 500px;
  margin: 30px auto;
}
.person_nav {
  background: #fff;
  min-height: 500px;
  min-width: 170px;
  border-radius: 15px 0 0 15px;
}
.person_nav .person_panel {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fff;
}
.person_nav .person_panel li {
  min-width: 169px;
  max-width: 300px;

  margin: 10px 0;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  /* border: 1px red solid; */
}
.person_panel{
  li{
    padding: 0 15px;
  }
}
.person_nav .person_panel li.active {
  background: #f7f7f7;
  position: relative;
}
.person_nav .person_panel li.active::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: #ef0c25;
}
.person_nav .person_panel li a {
  color: #1a1311;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  display: block;
  width: 100%;
  white-space: nowrap;
}
.person_content {
  padding: 0 20px;
  width: 1030px;
  min-height: 500px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0 15px 15px 0;
  /* border: 1px solid red; */
}

.text-overflow {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出的内容 */
  text-overflow: ellipsis; /* 用省略号表示被隐藏的部分 */
  max-width: 200px; /* 设置最大宽度以限制文本的显示长度 */
}
</style>
