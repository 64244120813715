export default {
  // index: {
  //   "advertisement": "Cross Ocean Cloud Shopping德语"
  // },
  middle: {
    Shop_China_with_Seacloudbuy: "Chinesische Produkte mit Seacloudbuy kaufen",
    Please_enter_keywords_or_product_links: "geben Sie Schlüsselwörter oder Produktlinks ein",
    goods: "Ware",
    Choose: "wählen",
    Shop: "Geschäft",
    Buyer_purchase_order: "Gibt eine Bestellung auf",
    Merchants_receive_orders: "Nimmt Bestellungen entgegen",
    Verify_parcel: "Paket überprüfen",
    Transaction_complete: "Transaktion abgeschlossen",
    Search: "Suchen",
  },
  describe: {
    One_stop_service: "Service aus einer Hand",
    One_click_order: "Bequeme Bestellung per Mausklick",
    Ship_to_warehouse: "Handelsschiffe zum Lagerhaus",
    Quality_inspection: "Abnahmequalitätsprüfung und Verwiegung",
    Global_Freight: "Internationale Transportlogistik",
  },
  goodsdetail: {
    Starting_purchase_of:"Beginn des Kaufs von ",
    items:"Artikel",
    Service_Terms:"Servicebedingungen",
    Find_similar: "Finden Sie Ähnlichkeiten",
    Promote_and_share: "Bewerben und teilen",
    Favorite_Product: "Lieblingsprodukt",
    Sales_volume: "Verkaufsvolumen",
    Enter_the_store: "einen Laden betreten",
    Force_refresh: "Aktualisierung erzwingen",
    modification: "Überarbeiten",
    "After_payment_the_buyer_will_provide_purchasing_services_for_you_from_09:00-18:00_(UTC+8)": "09:00-18:00(+8) Nach der Zahlung erbringt der Käufer von 09:00-18:00(UTC+8) die Einkaufsdienstleistungen für Sie.",
    Source_of_this_product: "Quelle dieses Produkts",
    SeaCloud_can_entrust_buyers_to_purchase_for_you: "Sie können Käufer damit beauftragen, für Sie einzukaufen",
    Merchant: "Händler",
    SeaCloud_Certified_warehouse_shipping_costs: "SeaCloud Zertifiziertes Lager, Fracht",
    SeaCloud_certified_warehouse: " SeaCloud Zertifiziertes Lager",
    your_address: "deine Adresse",
    Estimate_international_shipping_costs: "Versandkosten schätzen",
    Please_select_properties: "Bitte wählen Sie ein Attribut aus",
    leave_a_message: "Nachricht",
    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them: "Notieren Sie sich die eingekauften Waren. Wenn keine besonderen Einkaufswünsche bestehen, ist das Aufschreiben nicht nötig.",
    The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default: " Die angezeigten Produkte stammen alle von Plattformen Dritter, die lediglich Informationssuche, Kauf und Versanddienste anbieten.  Eine Feststellung der Qualität und Echtheit der Ware ist nicht möglich. Wenn Sie den Einkaufsservice in Anspruch nehmen, akzeptieren Sie grundsätzlich die oben genannten Risiken.",
    agreed: "Ich stimme zu",
    Buy_now: "Kaufe es jetzt",
    Add_to_Cart: "In den Warenkorb legen",
    embargo: "Embargo",
    Tobacco_alcohol_and_contraband_are_not_accepted: "·Tabak, Alkohol und Schmuggelware werden nicht transportiert",
    Prohibited_items_include_but_are_not_limited_to_the_following_items_Swiss_Army_knives_a_full_set_of_kitchen_knives_kitchen_knives_paper_cutters_letter_openers_gardening_knives_scissors_saws_seam_ripper_seam_ripper_pure_fruit_and_vegetable_juices_inflatable_lighters_wine_putty_knife_cheese_Western_cheese_hairy_crab_bamboo_sword_wooden_knife_military_knife_card_universal_knife_card_skate_shoes_other_outdoor_knives_magnetic_components_Swiss_army_knife_end_mill_carving_knife_cigarette_label_cigarette_Card_vajra_bell_vajra_pestle_yue_knife_seeds_etc: "Zu den verbotenen Gegenständen gehören unter anderem die folgenden Gegenstände: Schweizer Taschenmesser, komplette Küchenmessersets, Küchenmesser, Papierschneider/Brieföffner, Gartenschere/-schere, Nahttrenner/Nahttrenner, reine Obst- und Gemüsesäfte, aufblasbar Feuerzeuge, Wein, Spachtelmesser, Käse / Käse im westlichen Stil, haarige Krabbe, Bambusschwert / Holzmesser, Säbelkarte / Universalmesser, Karte, Skate-Skates-Schuhe, andere Outdoor-Messer, magnetische Komponenten, Schweizer Taschenmesser, Schaftfräser, Tranchiermesser, Zigarettenetikett / Zigarettenkarte, Diamantglocke / Vajra-Stößel / Yue-Messer, Samen usw.",
    Purchase_limit: "Kauflimit",
    Medicines_liquids_paste_goods_and_cosmetic_powders_are_restricted_export_goods_by_China_Customs_Please_ask_before_ordering: "Medikamente, Flüssigkeiten, Pastenwaren und Kosmetikpulver sind vom chinesischen Zoll eingeschränkte Exportgüter.  Bitte erkundigen Sie sich vor der Bestellung",
    customer_service: "【Kundendienst】",
    Kind_tips: "Freundliche Tipps",
    About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes_restricted_items_logistics_route_restrictions_and_volume_and_weight_restrictions: "[Über die Zolllogistik] Aufgrund der Unterschiede in der Zollpolitik zwischen den Ländern reagieren einige Länder oder Regionen empfindlicher auf spezielle Waren, sodass es zu Embargos, eingeschränkten Artikeln, Beschränkungen der Logistikroute sowie Volumen- und Gewichtsbeschränkungen kommen kann.",
    The_goods_you_purchase_will_be_sent_to_the_SeaCloud_warehouse_After_arriving_at_the_warehouse_we_will_inspect_the_goods_for_you_and_take_photos_of_the_goods: "Die von Ihnen gekauften Waren werden an das Lager geschickt. Nach der Ankunft im Lager prüfen wir die Waren für Sie und machen Fotos von der Ware.",
    You_can_view_and_manage_products_in_My_Warehouse: "Sie können Produkte in [Mein Lager] anzeigen und verwalten.",
    If_you_are_not_satisfied_with_the_goods_arriving_at_the_SeaCloud_warehouse_you_can_apply_for_a_return_or_exchange_within_5_days_of_arrival_at_the_warehouse_The_purchaser_will_negotiate_with_the_merchant_on_your_behalf_to_handle_the_after_sales_service: "Wenn Sie mit der Ankunft der Ware im Lager nicht zufrieden sind, können Sie innerhalb von 5 Tagen nach Ankunft im Lager eine Rückgabe oder einen Umtausch beantragen. Der Käufer verhandelt in Ihrem Namen mit dem Händler über die Abwicklung des Kundendienstes.",
    No_store_information_found: "Keine Shop-Informationen gefunden",
    Please_read_and_agree_before_proceeding: "Bitte lesen und stimmen Sie zu, bevor Sie fortfahren.",
    Please_select_specification_properties: "Bitte wählen Sie Spezifikationsattribute aus",
    Share_invite_code: "Teilen Sie den Einladungscode",
    copy_Link: "Link kopieren",
    qty: "Menge",
    stock: "auf Lager",
    Description_text: "Beschreibungstext",
    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them_down: "Notieren Sie sich die eingekauften Waren. Wenn keine besonderen Einkaufswünsche bestehen, ist das Aufschreiben nicht nötig.",
    Purchasing_instructions: "Hinweise für Einkäufer",
    product_details: "Produktdetails",
    After_sales_service: "Kundendienst",
    loading: "Wird geladen...",
    Copy_successful:"Erfolgreich kopiert",
    Tips: "Hinweis",
    Sure: "Sicher",
    Cancel: "Stornieren",
  },
  bottom: {
    Massive_merchandise: "Massive Produkte",
    Commodity_purchase: "Rohstoffkauf",
    Cost_savings: "Frachtkonsolidierung",
    If_you_purchase_goods_from_different_merchants_we_will_help_you_consolidate_them_into_one_package_and_send_them_to_your_country: "Wenn Sie Waren von verschiedenen Händlern kaufen, helfen wir Ihnen, diese in einem Paket zusammenzufassen und in Ihr Land zu versenden.",
    service_quality: "Qualitätskontrolle",
    Provide_efficient_mature_and_stable_service_support: "Bieten Sie effizienten, ausgereiften und stabilen Service-Support",
    Quick_response: " Servicequalität",
    Help_you_solve_your_difficulties_in_the_shortest_time: " Helfen Sie, Ihre Schwierigkeiten in kürzester Zeit zu lösen",
    customer_service: "Kundendienst",
    Help_Center: "Hilfezentrum",
    contact_us: "kontaktiere uns",
    Purchasing_demo: "Einkaufsdemonstration",
    Services_and_fees: "Leistungen und Gebühren",
    Source_of_this_product: "Wir helfen Ihnen beim Kauf von Produkten aus allen Online- und Offline -Kanälen in China.",
    Return_and_exchange_service: "Rückgabe- und Umtauschservice",
    payment_methods: "Bezahlverfahren",
    Recharge_method: "Auflademethode",
    Paypal_payment: "Paypal zahlen",
    International_Credit_Card_Payment: "Kreditkartenzahlung",
    Delivery_related: "Lieferbezogen",
    Logistics_shipping_fee: "Logistikfracht",
    Centralized_packaging: "Zentralisierte Verpackung",
    Customs_and_taxes: "Zölle und Steuern",
    Precautions_for_receiving_goods: "Vorsichtsmaßnahmen für den Wareneingang",
    After_sales_service: "Kundendienst",
    Product_storage_period: "Lagerdauer des Produkts",
    Inspection_rules: "Inspektionsregeln",
    Insurance_and_indemnity: "Versicherung und Entschädigung",
    YuICP_No_2024000320: "2024000320 Vorbereitung Nr. 2024000320",
    About_Seacloud: "um Seacloud",
    Terms_of_Service_and_User_Management: "Nutzungsbedingungen und Benutzerverwaltung",
    Privacy_Notice: "Datenschutzerklärung",
    // contact_us: "kontaktiere uns",
    Company_name_SEACLOUD_CN_TECHNOLOGY_CO_LIMITED_Company_registration_number_24052668: "Firmenname: (SEACLOUD). Firmenregistrierungsnummer: 24052668",
    Henan_Cross_Sea_Yungou_Technology_Co_Ltd:"Henan Cross Sea Yungou Technology Co., Ltd",
  },
  index: {
    Front_page: "Titelseite",
    Shopping_cart: "Einkaufswagen",
    My_Order: "Meine Bestellung",
    My_warehouse: "mein Lager",
    Preview_package: "Vorschaupaket",
    My_package: "mein Paket",
    Transaction_Record: "Transaktionen",
    My_Points: "Meine Noten",
    Coupons: "Coupon",
    Account_setup: "Account Einstellungen",
    Trading_log: "Transaktionen",
    Promoter_Programm:"Promoter-Programm"
  },
  account: {
    personal_data: "Persönliche Angaben",
    my_privilege: "Mein Privileg",
    Level_authority: "Klassenprivilegien",
    International_Shipping_Discount: "Internationale Versandrabatte",
    Insurance_Discount: "Betrag des Versicherungsrabatts",
    Value_added_service_discounts: "Rabatte auf Mehrwertdienste",
    Free_Aftersale_Operation_Fee: "Keine After-Sales-Gebühren",
    Used_Available_Month: "Genutzt/verfügbar (Monate)",
    my_level: "mein Level",
    growth_value: "Wachstumswert",
    Shipping_address: "Lieferanschrift",
    Add_shipping_address: "Lieferadresse hinzufügen",
    you_have_created: "Du hast es geschaffen",
    Work: "Arbeiten",
    Address: "Adresse",
    Operate: "arbeiten",
    Edit: "bearbeiten",
    Delete: "löschen",
    Set_as_default: "als Standardadresse festgelegt",
    Default_address: "Standardadresse",
    You_have_not_added_a_delivery_address_please_add_a_delivery_address: " Sie haben keine Lieferadresse hinzugefügt. Bitte geben Sie eine Lieferadresse ein",
    New_shipping_address: "Neue Lieferadresse",
    Consignee: "Empfänger",
    name: "Name",
    Region: "Bereich",
    country: "Nation",
    State_Province: "Staat/Provinz",
    City: "Stadt",
    Details_address: "Adresse",
    Zip_code: "Postleitzahl",
    Phone_number: "Telefonnummer",
    Label: "Etikett",
    Home: "Heim",
    // Work: "Arbeiten",
    Default_URL: "Standardadresse",
    Cancel: "Stornieren",
    Sure: "Sicher",
    Account_safety: "Konto Sicherheit",
    Credit_card_billing_address: "Kreditkartenrechnungsadresse",
    name_cannot_be_blank: "Der Name darf nicht leer sein",
    Country_cannot_be_null: "Das Land darf nicht leer sein",
    State_Province_cannot_be_blank: "Die Provinz darf nicht leer sein",
    City_cannot_be_empty: "Die Stadt darf nicht leer sein",
    Please_select_at_least_one_activity_type: "Bitte wählen Sie mindestens eine Aktivitätsart aus",
    Zip_code_cannot_be_empty: "Die Postleitzahl darf nicht leer sein",
    Address_cannot_be_empty: "Die Adresse darf nicht leer sein",
    Phone_number_cannot_be_empty: "Die Mobiltelefonnummer darf nicht leer sein",
    Added_address_successfully: "Adresse erfolgreich hinzugefügt",
    Delete_successful: "Erfolgreich gelöscht",
    Setting_successful: "Installation erfolgreich",
    Public_Member: "öffentliches ",
    Silver_Member: "Silbernes ",
    Gold_Member: "Gold ",
    Platinum_Member: "Platin",
    Diamond_Member: "Diamond",
    Black_Gold_Member: "Schwarzem Gold",
    Black_Gold_PLUS: "Schwarzgold PLUS",
  },
  headertop: {
    Please_enter_keywords_or_product_links: "Bitte geben Sie Schlüsselwörter oder Produktlinks ein",
    Please_select: "bitte auswählen",
    goods: "Ware",
    Shop: "Geschäft",
    More_features: "Mehr Features",
    Freight_estimate: "Versandschätzung",
    Language_Currency: "Sprache/Währung",
    language: "Sprache",
    Currency: "Währung",
    Saving: "Speichern",
    Log_in: "Anmeldung",
    register: "Registrieren",
    Personal_Center: "Persönliches Zentrum",
    Shopping_cart: " Einkaufswagen",
    My_warehouse: "Mein Lager",
    My_Order: "Meine Bestellung",
    My_package: "Mein Paket",
    Trading_log: "Transaktionen Aufzeichnung",
    Sign_out: "Abmelden",
    Categories: "Kategorien",
    Commodity: "Ware",
    Ladies: "Frauenkleidung",
    bag: "Tasche",
    Men: "Herren ",
    Jewelry: "Schmuckzubehör",
    shoe: "Schuh",
    Sankeng_Clothing: "Sankeng-Kleidung",
    work_out: "trainieren",
    trousers: "Hose",
    skirt_suit: "Rock Anzug",
    Jacket: "Jacke",
    coat: "Mantel",
    travel_backpack: "Reiserucksack",
    fashion_handbags: "Modehandtaschen",
    Travel_series: "Reiseserie",
    small_bag: "kleine Tasche",
    Bags_accessories: "Taschen, Accessoires",
    // coat: "Mantel",
    // Jacket: "Jacke ",
    // trousers: "Hose",
    // work_out: "trainieren ",
    // shoe: "Schuh",
    jewelry: "Schmuck",
    belt: "Gürtel",
    Glasses: "Gläser ",
    hat: "Hut",
    scarf: "Schal ",
    Accessories: "Zubehör ",
    hair_accessories: "Haarschmuck",
  },
  goodslist: {
    We_have_searched_for: "Nach Ihnen gesucht",
    related_content_for_you_come_and_buy_it: " Verwandte Inhalte, kommen Sie und shoppen Sie nach Herzenslust",
    Agreed: "Genehmigt",
    Search_Service_Terms: "《Nutzungsbedingungen für die Suche》",
    micro: "Weidian",
    Taobao: "Taobao",
    loading: "Wird geladen...  ",
    Sold: "verkauft",
  },
  shopgoods: {
    loading: "Wird geladen... ",
  },
  goodsrecommend: {
    Everyone_is_looking: "Umsatzstärkstes Produkt",
  },
  center: {
    My_Balance: " Mein Gleichgewicht",
    Recharge: "Nachfüllen",
    Withdraw: "Zurückziehen",
    Points: "Integral",
    Service_Fee:"Service Fee",
    Rebate_ratio:"Rebate ratio",
    service_fee_desc:"Arbeitskosten für den Einkauf von Fremdwaren und die Nachverfolgung von Bestellungen",
    My_Coupons: "mein Rabattgutschein",
    My_growth_value: "Mein Wachstumswert",
    Trade_name: "Produktname",
    Submit_time: "Abgabetermin",
    Order_Status: "Bestellstatus",
    operate: "arbeiten",
    pay: "Bezahlen",
    order_details: "Bestelldetails",
    Confirm_Order: "Bestellung bestätigen",
    View_Logistics: "Überprüfen Sie die Logistik",
    Logistics_information: "Logistikinformationen",
    Submit_package: "Paket senden",
    message: "Nachricht",
    Please_enter_the_withdrawal_amount: "Bitte geben Sie den Auszahlungsbetrag ein (CNY)",
    Tips: "Hinweis",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Amount_cannot_be: "Der Betrag darf nicht 0 sein!",
    Amount_must_be_greater_than: "Der Betrag muss größer als 0 sein",
    The_withdrawal_amount_cannot_be_greater_than_the_wallet_limit: "Der Auszahlungsbetrag darf das Wallet-Limit nicht überschreiten",
    Please_enter_amount: "Bitte geben Sie den Betrag ein",
    The_amount_you_input_is: "Der von Ihnen eingegebene Betrag beträgt:",
    invite:"Einladung",
    Copy_successful:"Erfolgreich kopiert",
    Invite_friends_to_register:"Lade Freunde zur Registrierung ein",
    copy_Link: "Link kopieren",
  },
  cart: {
    Commodity_information: "Produktinformation",
    Note: "Anmerkung",
    Price: "Einzelpreis",
    qty: "Menge",
    Amount: "Menge",
    Operate: "arbeiten",
    Product_remarks: "Informationen zu Produktbemerkungen",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "Wenn Sie eine Nachricht zum Kauf hinterlassen, müssen Sie diese nicht schreiben, wenn keine besonderen Kaufanforderungen bestehen.",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Delete: "löschen",
    Delete_selected_items: "Ausgewählte Produkte löschen",
    Choose_all: "Wählen Sie Alle",
    selected: "ausgewählt",
    pieces_excluding_freight: "Stück (ohne Fracht)",
    Estimated_freight: "Geschätzte internationale Versandkosten",
    pay: "Siedlung",
    Your_shopping_cart_is_empty_go_treat_yourself: "hr Warenkorb ist leer, gönnen Sie sich etwas!  !",
    Are_you_sure_to_delete_the_item: "Wollen Sie das Produkt wirklich löschen?",
    Tips: "Hinweis",
    // Sure: "Sicher",
    // Cancel: "Stornieren",
    Are_you_sure_you_want_to_delete_the_items_selected_in_your_shopping_cart: "Sind Sie sicher, dass Sie die in Ihrem Warenkorb ausgewählten Artikel löschen möchten??",
    Delete_successful: "Erfolgreich gelöscht!!",
    Please_select_an_item: "Bitte wählen Sie ein Produkt aus!",

  },
  cartlnfo: {
    Confirm_Order: "Bestellung bestätigen",
    Merchant: "Händler",
    Select_shipping_country: "Wählen Sie das Versandland aus",
    Seacloud_warehouse: "Seacloud Lagerhaus",
    Please_fill_in_the_country_to_be_shipped_to: "Bitte geben Sie das Land ein, in das versendet werden soll",
    Seacloud_buyers_will_determine_whether_your_product_can_be_sent_based_on_the_country_you_fill_in: "Seacloud Der Käufer entscheidet anhand des von Ihnen eingegebenen Landes, ob Ihr Produkt versendet werden kann.",
    Confirm_product_info: "Produktinformationen bestätigen",
    product_detail: "Produktdetails",
    Note: "Anmerkung",
    Price: "Einzelpreis",
    qty: "Menge",
    additional_services: "Zusatzleitungen",
    Subtotal: "Zwischensumme",
    Note_information: "Bemerkungen",
    Product_remarks: "Informationen zu Produktbemerkungen",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "Wenn Sie eine Nachricht zum Kauf hinterlassen, müssen Sie diese nicht schreiben, wenn keine besonderen Kaufanforderungen bestehen.",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Additional_services: "Zusatzleistungen",
    Total_price_of_goods: "Gesamtpreis des Produkts",
    Shipping_fee_to_warehouse: "Versandkosten ans Lager",
    Total_stores: "Gesamtzahl der Geschäfte",
    Total_price_to_be_paid_international_shipping_charges_are_extra: "Zu zahlender Gesamtpreis (internationale Versandkosten fallen zusätzlich an)",
    Prohibited_Goods_Statement: "《Erklärung zu verbotenen Produkten》",
    Terms_of_Service_and_User_Management: "《Nutzungsbedingungen und Benutzerverwaltung》",
    Return_and_Exchange_Service: "《Rückgabe- und Umtauschservice》",
    Disclaimer: "《Haftungsausschluss》",
    I_have_read_and_agree: "Ich habe gelesen und stimme zu",
    Submit_Order: "Bestellung abschicken",
    Kind_tips_After_the_order_is_submitted_and_the_payment_is_successful_please_wait_patiently_for_the_goods_to_be_put_into_storage_The_goods_can_be_submitted_for_shipping_after_being_put_into_storage: "Herzliche Erinnerung: Nachdem die Bestellung aufgegeben wurde und die Zahlung erfolgreich war, warten Sie bitte geduldig, bis die Ware eingelagert wird.  Nach der Einlagerung kann die Ware zum Versand übergeben werden.",
    Please_agree_to_the_terms: "Bitte stimmen Sie den Bedingungen zu!",
    Please_choose_a_country: "Bitte wähle ein Land!",
  },
  payorder: {
    Checkout_counter: "Checkout Schalter",
    Orders_submitted_successfully: "Bestellungen erfolgreich übermittelt!",
    Balance: "Gleichgewicht",
    Your_balance_is_insufficient_please_top_up_before_making_payment: "Ihr Guthaben reicht nicht aus. Bitte laden Sie es vor der Zahlung auf",
    Recharge: "nachfüllen",
    Total_price: "Gesamtpreis:",
    Payables: "Abbrechnungsverbindlichkeiten",
    Handling_fee: "Bearbeitungsgebühr:",
    Confirm_payment: "bestätige Zahlung",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "Bei Zahlungen in Nicht-USD-Währungen fallen Währungsumrechnungs- und Bearbeitungsgebühren an. ",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: " China Renminbi Debit- und Kreditkarten von lokalen Partnerbanken",
    Please_enter_amount: "Bitte geben Sie den Betrag ein",
    Tips: "Hinweis",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Amount_cannot_be: "Der Betrag darf nicht 0 sein!",
    Amount_must_be_greater_than: "Der Betrag muss größer als 0 sein",
    The_amount_you_input_is: "Der von Ihnen eingegebene Betrag beträgt:",
    Please_select_mode_of_payment: "Bitte Zahlungsart auswählen!",
  },
  orderlist: {
    Shopping: "Einkaufszentrum",
    MyCoupon: "Meine Gutscheine",
    Paid: "Bezahlt",
    Ordering: "Bestellung",
    Return_Exchange: "Rückgabe/Umtausch",
    Start_date: "Startdatum",
    End_date: "Endtermin",
    Search: "Suchen",
    product_detail: "Produktdetails",
    Price: "Einzelpreis",
    qty: "Menge",
    Order_Status: "Bestellstatus",
    Value_added_services: "Wertdienste",
    Order_total: "Gesamtwert",
    Other_operations: "Andere Operationen",
    Order_number: "Bestellnummer:",
    Creation_time: "Gegründet:",
    Warehouse_Seacloud_Warehouse: "Lagerhaus:Seacloud Lagerhaus",
    Add_notes: "Notizen hinzufügen",
    Product_remarks: "Informationen zu Produktbemerkungen",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "Wenn Sie eine Nachricht zum Kauf hinterlassen, müssen Sie diese nicht schreiben, wenn keine besonderen Kaufanforderungen bestehen.",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Product_shipping_cost: "Produktversandgebühr:",
    Value_added_service_fee: ",Mehrwertdienstgebühr:",
    Pay_order: "Bestellung bezahlen",
    order_details: "Bestelldetails",
    message: "Nachricht",
    Cancel_order: "Bestellung stornieren",
    logistics: "Logistik",
    Logistics_information: "Logistikinformationen",
    Apply_for_after_sales_service: "After-Sales-Service",
    Schedule: "Zeitplan",
    Are_you_sure_you_want_to_continue_deleting:"Möchten Sie Ihre Bestellung wirklich stornieren?",
    Tips: "Hinweis",
    
  },
  deliverydetail: {
    First_weight: "höchste Priorität",
    First_weight_price: "Erster Preis",
    Additional_weight: "Gewicht erneuern",
    Additional_weight_price: "Preis für Erneuerungsgewicht",
    Size_limit: "Größenbeschränkung",
    Sensitive_items_that_can_be_mailed: "Sensible Artikel können per Post verschickt werden",
    Fuel_fee: "Benzinpreis",
    Operation_fee: "Betriebsgebühr",
    Service_fee: "Servicegebühr",
  },
  orderdetail: {
    Title: "Titel:",
    Product_Notes: "Produktbemerkungen:",
    Order_Number: "Bestellnummer:",
    Specifications: "Spezifikationsattribute:",
    Seller_Name: "Name des Verkäufers:",
    Price: " Preis:CNY",
    qty: "Menge:",
    Order_Time: "Bestellzeitpunkt:",
    Payment_Time: "Zahlungszeitpunkt:",
    Weight: "Gewicht",
                Measure_size:"Größe messen",            
                Remove_packaging:"Verpackung entfernen",               
                Take_photos:"Fotografieren",
                Priority_purchase:"Vorrangiger Kauf",           
                Add_air_column_pack:"Luftsäulenpaket hinzufügen",       
                Add_corner_protection:"Fügen Sie einen Kasteneckenschutz hinzu",             
                Shoe_support:"Schuhstütze",   
  },
  servereturn: {
    Application_Type: "Anwendungstyp",
    Please_select_application_type: "Bitte wählen Sie den Anwendungstyp aus",
    Request_return: "Antrag auf Rückgabe",
    Request_exchange: "Umtausch beantragen",
    Request_reason: "Grund für die Antragstellung",
    Submit: "einreichen",
    Cancel: "Stornieren",
  },
  trackInfo: {
    Logistics_Tracking: "Logistikverfolgung",
  },
  userrecharge: {
    Checkout_counter: "Checkout Schalter",
    Total_price: "Gesamtpreis:",
    Payables: "Abbrechnungsverbindlichkeiten",
    Handling_fee: "Bearbeitungsgebühr:",
    Confirm_payment: "bestätige Zahlung",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "Bei Zahlungen in Nicht-USD-Währungen fallen Währungsumrechnungs- und Bearbeitungsgebühren an.",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: "China Renminbi Debit- und Kreditkarten von lokalen Partnerbanken",
    Please_select_mode_of_payment: "Bitte Zahlungsart auswählen!",
    Please_keep_the_actual_shipping_address_of_the_product_consistent_with_the_PayPal_delivery_address_otherwise_it_will_cause_customs_clearance_failure_and_affect_the_timeliness:"Bitte achten Sie darauf, dass die tatsächliche Lieferadresse des Produkts mit der PayPal-Lieferadresse übereinstimmt, da sonst die Zollabfertigung fehlschlägt und die Pünktlichkeit beeinträchtigt wird.",
    Cooperation_with_the_People_s_Bank_of_China:"Zusammenarbeit mit der People's Bank of China"
  },

  security: {
    Username: "Nutzername:",
    Email: "E-Mail:",
    Security_level: "Sicherheitsstufe:",
    After_encryption_your_security_level_is: "Nach der Verschlüsselung beträgt Ihre Sicherheitsstufe",
    Medium: "Mitte",
    Last_login: "Kürzlich eingeloggt:",
    Logout: "Ausloggen",
    Change_login_password: "Login-Passwort ändern",
    A_highly_secure_password_can_make_your_account_more_secure_It_is_recommended_that_you_change_your_password_regularly_and_set_a_password_that_contains_numbers_and_letters_and_is_more_than_6_characters_long: "Ein Hochsicherheitspasswort kann Ihr Konto sicherer machen.  Es wird empfohlen, dass Sie Ihr Passwort regelmäßig ändern und ein Passwort festlegen, das Zahlen und Buchstaben enthält und länger als 6 Zeichen ist.",
    Change_password: "Kennwort ändern",
    Security_privacy_issues: "Sicherheits-/Datenschutzprobleme",
    Poor_customer_service: "Schrecklicher Kundenservice",
    Too_high_a_fee: "Die Kosten sind zu hoch",
    Complicated_shopping_process: "Komplexer Einkaufsprozess",
    Other_reasons: "Andere Gründe",
    Please_enter_content: "Bitte geben Sie Inhalte ein",
    Cancel: "Stornieren",
    Confirm: "Sicher",
    Change_email: "E-Mail ändern",
    For_the_security_of_your_account_you_need_to_first_pass_the_email_verification_code_login_password_security_verification: " Um die Sicherheit Ihres Kontos zu gewährleisten, müssen Sie zunächst eine Sicherheitsüberprüfung mithilfe des E-Mail-Bestätigungscodes und des Anmeldekennworts durchführen.",
    Email_verification_code: "E-Mail-Bestätigungscode",
    Click_to_get_the_verification_code: "Klicken Sie hier, um den Authentifizierungscode anzuzeigen",
    Login_password: "Passwort",
    Next_step: "Nächster Schritt",
    New_password: "Neues Kennwort",
    Repeat_password: "Passwort wiederholen",
    Verification_code_has_been_sent: "Bestätigungscode gesendet",
    Payment_password: "Zahlungspasswort",
    // Repeat_password: "Passwort wiederholen",
    Submit: "Einreichen",
    Please_enter_your_password_again: "Bitte geben Sie das Passwort erneut ein",
    The_two_passwords_are_inconsistent: "Das zweimal eingegebene Passwort ist inkonsistent",
    Please_enter_the_verification_code: "Bitte geben Sie den Bestätigungscode ein",
    // Change_password: "Kennwort ändern",
    Please_enter_your_login_password: "Bitte geben Sie Ihr Passwort ein",
    Please_enter_a_new_login_password: "Bitte geben Sie ein neues Login-Passwort ein",
    Please_enter_a_new_payment_password: "Bitte geben Sie ein neues Zahlungspasswort ein",
    Please_enter_your_payment_password_again: "Bitte geben Sie das Zahlungspasswort erneut ein",
    Please_enter_the_verification_code_of_your_email: "Bitte geben Sie den Bestätigungscode Ihrer 2813910143@. E-Mail-Adresse ein",
    Please_enter_your_password: "Bitte Passwort eingeben",
    Successfully_changed_your_login_password: "Login-Passwort erfolgreich geändert",
    Form_verification_failed: "Die Formularvalidierung ist fehlgeschlagen",
    Please_enter_your_new_password: "Bitte geben Sie ein neues Passwort ein",
    _6_to_15_characters_long: "Länge zwischen 6 und 15 Zeichen",
  },
  userInfo: {
    Edit: "Bearbeiten",
    Username: "Nutzername:",
    Balance: "Gleichgewicht:",
    Phone: " Email:",
    Gender: "Geschlecht:",
    None: "keiner",
    Male: "männlich",
    Female: "weiblich",
    Language: "Sprache:",
    Registration_time: " Anmeldezeit:",
    Growth_Value: "Wachstumswert",
    View_Growth_Value_Record: "Wachstumswertaufzeichnungen anzeigen",
    score:"Punktzahl",
    Only_3000_growth_points_are_needed_to_reach_V1: "Um V1 zu erreichen, sind nur 3000 Wachstumspunkte erforderlich",
    Distance: " Distanz",
    Only_requires: "muss nur",
    Sure: "Sicher",
    Cancel: "Stornieren",
                Upload_avatar:"Avatar hochladen",

  },
  warehouse: {
    Order_ID_Product_Name: "Bestellnummer/Produktname",
    Start_Date: "Startdatum",
    End_Date: "Endtermin",
    Search: "Suchen",
    Image: "Bild",
    Product_Details: "Produktdetails",
    Price: "Preis",
    Shipping: "Fracht",
    Weight: "Gewicht",
    Order_Status: "Bestellstatus",
    Days: "Tage",
    Action: "arbeiten",
    Details: "Einzelheiten",
    Leave_us_a_message: "Hinterlassen Sie uns eine Nachricht",
    Select_All: "Wählen Sie Alle",
    Shipping_Country: "Lieferungsland",
    Actual_Weight: "Nettogewicht",
    Selected_Products: "Ausgewähltes Produkt",
    Pieces: "Stücke",
    Preview_Packaging: "Verpackung vor der Show",
    Submit_Shipping: "Zum Versand einreichen",
    Order_Details: "Bestelldetails",
    Not_an_Array: "kein Array",
    Please_select_a_country: "Bitte wähle ein Land!",
    Please_select_an_order_to_submit: "Bitte wählen Sie die Bestellung zum Absenden aus!",
    Seacloud_warehouse_To: "Seacloud Lagerhaus To",
    Available: "verfügbar",
  },
  invoice: {
    Tip_Depending_on_the_customs_policy_of_the_destination_country_and_your_experience_filling_in_a_customs_invoice_can_reduce_or_avoid_customs_fees: " Tipp: Abhängig von der Zollpolitik des Ziellandes und Ihren Erfahrungen können durch das Ausfüllen einer Zollrechnung Zollgebühren reduziert oder vermieden werden.",
    Add: "Hinzufügen zu",
    Import_invoice: "Rechnungen importieren",
    Warehouse_ID: " Lagerhaus ID",
    Product_name: "Produktname",
    Please_enter_a_title: "Bitte geben Sie einen Titel ein",
    qty: "Menge",
    Please_enter_the_qty: "Bitte Menge eingeben",
    Price: "Preis(USD)",
    Please_enter_the_value: "Bitte Wert eingeben",
    Operation: "arbeiten",
    Delete: "löschen",
    Shipping_address: "Lieferanschrift /",
    Please_select_a_shipping_address: "Bitte wählen Sie die Lieferadresse aus",
    Logistics_route: "Logistikroute  /",
    Please_select_a_logistics_route: "Bitte wählen Sie eine Logistikroute aus",
    Waybill_value_added_service_: "Mehrwertdienste Frachtbrief /",
    Waybill_value_added_service: "Mehrwertdienste für Frachtbriefe",
    Coupon: "Gutschein  /",
    Whether_to_purchase_insurance: "Ob eine Versicherung abgeschlossen werden soll / ",
    insure: "insure",
    The_insurance_fee_is_3_of_the_total_price_of_the_goods: "Die Versicherungsgebühr beträgt 3 % vom Gesamtpreis des Produkts",
    Rehearsal_fee: "Probengebühr",
    rehearsal: "rehearsal",
    You_can_pay_the_rehearsal_fee_in_advance_to_ensure_that_the_package_is_packed_in_time_thereby_avoiding_additional_payments_and_making_the_delivery_process_faster_and_more_efficient_allowing_you_to_receive_the_package_faster: "Eine Vorab-Gebühr kann im Voraus bezahlt werden, um sicherzustellen, dass Ihr Paket umgehend verpackt wird. Dadurch werden zusätzliche Zahlungen vermieden und der Lieferprozess wird schneller und effizienter, sodass Sie Ihr Paket früher erhalten",
    Submit_package: "Paket abschicken",
    Estimated_total_cost: "Geschätzte Gesamtkosten/ ",
    Total: "Total",
    pay: "Bezahlen",
    Please_fill_in_the_invoice: "Bitte füllen Sie die Rechnung aus",
    // Please_select_a_logistics_route: "Bitte wählen Sie eine Logistikroute aus",
    Please_choose_whether_to_purchase_insurance: "  Bitte wählen Sie, ob Sie eine Versicherung abschließen möchten!",
    Estimated_cost: ",Geschätzte Kosten",
    First_weight: ",höchste Priorität",
    Fee: "g,kosten",
    Additional_weight: ",Gewicht erneuern",
    Please_return_to_the_previous_step_to_select_the_warehouse_order_information: "Bitte kehren Sie zum vorherigen Schritt zurück, um die Informationen für die Lagerbestellung auszuwählen!",
    Please_return_to_the_previous_step_to_select_the_country_information: "Bitte kehren Sie zum vorherigen Schritt zurück, um die Länderinformationen auszuwählen!!",
    Waybill_full: "Frachtbrief voll",
    discount: "reduzieren:",
    Yuan: "Yuan",
    Waybill_discount: "Frachtbriefrabatt",
    Discount: "falten",
    You_have_available_coupons:"Es stehen Ihnen Gutscheine zur Verfügung",
     No_coupons_available_yet:"Derzeit gibt es keine Gutscheine",
     Create_address:"Adresse erstellen",
  },
  paysend: {
    Checkout_counter: "Checkout Schalter",
    Orders_submitted_successfully: "Bestellungen erfolgreich übermittelt!",
    Balance: "Gleichgewicht",
    Your_balance_is_insufficient_please_top_up_before_making_payment: "Ihr Guthaben reicht nicht aus. Bitte laden Sie es vor der Zahlung auf",
    Recharge: "nachfüllen",
    Total_price: "Gesamtpreis:",
    Payables: "Abbrechnungsverbindlichkeiten",
    Handling_fee: "Bearbeitungsgebühr:",
    Confirm_payment: "bestätige Zahlung",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: " Bei Zahlungen in Nicht-USD-Währungen fallen Währungsumrechnungs- und Bearbeitungsgebühren an. ",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: " China Renminbi Debit- und Kreditkarten von lokalen Partnerbanken",
    Please_enter_amount: "Bitte geben Sie den Betrag ein",
    Tips: "Hinweis",
    Sure: "Sicher",
    Cancel: "Stornieren",
    Amount_cannot_be: "Der Betrag darf nicht 0 sein!",
    Amount_must_be_greater_than: "Der Betrag muss größer als 0 sein",
    The_amount_you_input_is: "Der von Ihnen eingegebene Betrag beträgt:",
    Please_select_mode_of_payment: "Bitte Zahlungsart auswählen!",

  },
  estimation: {
    Freight_Estimate: "Die geschätzten Versandkosten",
    Cooperate_with_many_international_express_companies_to_provide_super_low_freight_discounts: " Arbeiten Sie mit vielen internationalen Expressunternehmen zusammen, um extrem niedrige Rabatte auf die Versandkosten zu erhalten",
    Seacloud_warehouse_to: "Seacloud-Lager versendet nach (Land)",
    Please_enter_weight: "Bitte Gewicht (g) eingeben",
    Please_select: "bitte auswählen",
    Length: "Länge",
    Width: "Breite",
    Height: "Höhe",
    International_freight_description: "Alle Logistikdienstleistungen für nicht von Seacloudbuy selbst betriebene Routen werden von externen Logistikdienstleistern bereitgestellt und diese passen die Frachtpreise entsprechend den Marktbedingungen an. Bei Preisänderungen werden Sie von Seacloudbuy im Voraus benachrichtigt. Um das ultimative Serviceerlebnis zu bieten, kann Seacloudbuy bei Problemen mit Logistikdienstleistungen in Ihrem Namen mit externen Logistikanbietern verhandeln und kommunizieren.",
    Logistics_Service_Description: "Beschreibung der Logistikdienstleistung",
    Transportation_Method: "Transportmethode",
    ico:"Symbol",
    Time: "Zeit(Tage)",
    Freight: "Fracht",
    Features: "Besonderheit",
    Operation: "arbeiten",
    View: "Überprüfen",
    Details: "Einzelheiten",
  },
  userlogin: {
    Welcome_to_log_in: "Willkommen zum Einloggen",
    Please_enter_your_email_address: "Bitte geben Sie Ihre E-Mail-Adresse ein",
    Please_enter_your_password: "Bitte Passwort eingeben",
    Please_enter_your_verification_code: "Bitte geben Sie den Bestätigungscode ein",
    Login: "Anmeldung",
    Forgot_your_password: " Passwort vergessen?",
    Register: "Registrieren",

  },
  userforget: {
    Login: "Anmeldung",
    Email: "E-Mail",
    Account_safety: "Konto Sicherheit",
    Submit: "Einreichen",
    Already_have_an_account: "Sie haben bereits ein Konto",
    Return_to_SeaCloud_homepage: "Zurück zur SeaCloud-Homepage",
    Please_enter_the_email_address_associated_with_your_account: " Bitte geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein",
    Captcha: "Bestätigungscode",
    Please_enter_the_correct_email_address: "Bitte geben Sie Ihre gültige E-Mail-Adresse ein",
    The_verification_code_cannot_be_empty: "Der Verifizierungscode muss ausgefüllt werden",
    New_Password:"Neues Kennwort",
    Send: "Schicken",
    Please_enter_your_password: "Bitte Passwort eingeben",
    Please_enter_the_verification_code: "Bitte geben Sie den Bestätigungscode ein",


  },
  UserRegister: {
    Please_enter_your_password: "Bitte Passwort eingeben",
    Please_enter_the_verification_code: "Bitte geben Sie den Bestätigungscode ein",
    Welcome_to_register: "Willkommen zur Registrierung",
    Please_select_your_country: "Bitte wählen Sie Ihr Land",
    Please_fill_in_a_real_and_available_email_address: "Bitte geben Sie eine echte und verfügbare E-Mail-Adresse ein",
    Please_confirm_your_password: "Bitte bestätigen Sie Ihr Passwort",
    Please_enter_the_invitation_code: "Bitte geben Sie den Einladungscode ein(Optional)",
    Terms_of_Service_and_User_Management: "《Nutzungsbedingungen und Benutzerverwaltung》",
    Privacy_Notice: "《Datenschutzerklärung》",
    register: "Registrieren",
    Already_have_an_account: "Sie haben bereits ein Konto",
    Log_in: "Anmeldung",
    Please_read_the_terms_and_check_I_agree:"Bitte lesen Sie die Bedingungen und überprüfen Sie, ob ich damit einverstanden bin",
    The_two_passwords_do_not_match:"Die passwörter stimmen nicht überein!",
    Please_enter_your_email_address:"Geben Sie bitte Ihre Email-Adresse ein!",
    I_have_read_and_agree: "Ich habe gelesen und stimme zu",

  },

  UserSendMessage: {
    Please_enter_your_question: "Bitte geben Sie Ihre Frage ein",
    Send: "Schicken",
  },
 
  parcel: {
    Pending_payment: "Ausstehende Zahlung",
    Weight: "Gewicht",
    Total_price: "Gesamtpreis:",
    Operate: "arbeiten",
    Order_Details: "Bestelldetails",
    View_Logistics: "Überprüfen Sie die Logistik",
    Logistics_information: "Logistikinformationen",
    All_packages: "Alle Pakete",
    Waiting_for_packaging: "Zum Einpacken",
    Pay_the_difference: "Machen Sie den Unterschied aus",
    Sent: "Bereits gesendet",
    Confirm_receipt: "Den Wareneingang bestätigen",
    Canceled: "Abgesagt",
    After_sales_waybill: "Frachtbrief nach dem Verkauf",
    Enter_shipping_method_or_consignee: "Geben Sie die Versandart oder den Empfänger ein",
    Package_status: "Paket Status",
    Shipping_method: "Versandart",
    Pay_waybill: "Frachtbrief bezahlen",
    View_details: "Überprüfen Sie die Details",
    Cancel_waybill: "  Frachtbrief stornieren",
    Consult_customer_service: "Beratungsservice",
    Waybill_details: "Details zum Frachtbrief",
    Consignee: "Empfänger",
  },
  preview: {
    Waiting_for_shipment: "Geliefert werden",
    Cancel_the_preview_shipping_order: "Vorschau des Frachtbriefs abbrechen",
    Pending_payment: "Ausstehende Zahlung",
    Weight: "Gewicht",
    Total_price: "Gesamtpreis:",
    Operate: "arbeiten",
    Order_Details: "Bestelldetails",
    View_Logistics: "Überprüfen Sie die Logistik",
    Logistics_information: "Logistikinformationen",
    All_packages: "Alle Pakete",
    Waiting_for_packaging: "Zum Einpacken",
    Pay_the_difference: "Machen Sie den Unterschied aus",
    Sent: "Bereits gesendet",
    Confirm_receipt: "Den Wareneingang bestätigen",
    Canceled: "Abgesagt",
    After_sales_waybill: "Frachtbrief nach dem Verkauf",
    Enter_shipping_method_or_consignee: "Geben Sie die Versandart oder den Empfänger ein",
    Package_status: "Paket Status",
    Shipping_method: "Versandart",
    Pay_waybill: "Frachtbrief bezahlen",
    View_details: "Überprüfen Sie die Details",
    Cancel_waybill: "  Frachtbrief stornieren",
    Consult_customer_service: "Beratungsservice",
    Waybill_details: "Details zum Frachtbrief",
    Consignee: "Empfänger",
  },
  wallet: {
    Start_date: "Startdatum",
    End_date: "Endtermin",
    Please_select: "bitte auswählen",
    Consumption_records: "Spesenaufzeichnung",
    Query_by_date_period: "Abfrage nach Datumszeitraum",
    Query_by_type: "Abfrage nach Typ",
    Query: "Anfragen",
    Date: "Datum",
    Behavior: "Verhalten",
    Consumption_amount: "Verbrauchsmenge",
    Last_week: "letzte Woche",
    Last_month: "Im vergangenen Monat",
    Last_three_months: "letzten drei Monaten",
    Balance: "Gleichgewicht",
  },
  senddetail:{
    Waybill_number:"Frachtbriefnummer",
    Shipping_name:"Versandname",
    Destination_country:"Zielland",
    Telephone:"Telefon",
    Chargeable_Weight:"Aufladbares Gewicht ",
    Volume:"Volumen",
    Total_cost:"Gesamtpreis",
    insurance:"Versicherung",
    Discounted_price:"Verbilligter Preis",
    Waybill_status:"Frachtbriefstatus",
    Note:"Anmerkung",
    Consignee: "Empfänger",
    Shipping_address: "Lieferanschrift",
    Submit_time: "Abgabetermin",
    Shipping: "Fracht",
    Value_added_services: "Wertdienste",
  },
  points:{
    My_Points:"Meine Noten",
    Date:"Datum",
    Redeem_points_this_month:"Lösen Sie diesen Monat Punkte ein",
    My_VIP:"Mein VIP",
    Required_to_reach_next_level:"Erforderlich, um das nächste Level zu erreichen: ",
    points:"Punkte",
    Go_to_upgrade:"Gehe zum Upgrade",
    View_coupon:"Coupon anzeigen",
    reason:"Grund",
    Points_before_change:"Punkte vor Wechsel",
    Points_after_change:"Punkte nach Wechsel",
    change_value:"Wert ändern",
  },
  coupon:{
    Coupon_activation:"Coupon-Aktivierung",
    Enter_your_coupon_activation_code:"Geben Sie Ihren Coupon-Aktivierungscode ein",
    activation:"Aktivierung",
    all:"alle",
    Used:"Gebraucht",
    expired:"Abgelaufen",
    Order_full:"Vollständig bestellen",
    reduce:"reduzieren",
    Waybill:"Frachtbrief",
    Discount:"Rabatt",
    Get_coupon_number:"Gutscheinnummer erhalten",
    Validity:"Gültigkeitsdauer",
    Remaining:"Übrig",
    cards:"Card",
    Require:"Bedarf",
    Points:"Integral",
    discount:"falten",
    Full:"Voll",
    Available:"Verfügbar",
    Coupon_type:"Coupontyp",
    The_validity_period_after_activation_is:"Die Gültigkeitsdauer nach der Aktivierung beträgt",
    sky:"Himmel",
    Redeem_now:"Jetzt einlösen",
    Waybill_full:"Frachtbrief voll",
    minus:"reduzieren",
    Coupon_No:"Coupon-Nummer",
    // Validity:"Gültigkeitsdauer",
    usage_time:"nutzung zeit",
    CNY_available:"CNY verfügbar",
    Obtain_method:"Methode zum Erhalten",
    Use_now:"sofort nutzen",
    Order_Coupon:"Gutschein bestellen",
                Waybill_Coupon:"Frachtbrief-Gutschein",
                Activate_Coupon: "Gutschein aktivieren",
    Coupons_can_be_used_as_service_fees_Reward_coupons_must_be_activated_before_use_Please_activate_within_the_validity_period: "    Gutscheine können als Servicegebühr verwendet werden.  Prämiengutscheine müssen vor der Nutzung aktiviert werden, bitte innerhalb der Gültigkeitsdauer aktivieren.",
    Coupon_Number: "Gutscheinnummer",
    Type: "Typ",
    Name: "Name",
    Expiration_Date: "Frist",
    Amount: "Menge",
    Method: "Weg",
    Status: "Zustand",
    Please_enter_the_activation_code: "Bitte geben Sie den Aktivierungscode ein",
    Coupon_code_cannot_be_empty: "Der Gutscheincode darf nicht leer sein!",
    Tips: "Hinweis",
    Sure: "Sicher",
    Cancel: "Stornieren",
    // Obtain_method:"Methode zum Erhalten",
  },
  suspendball:{
    Por_favor_insira_sua_pergunta:"Bitte geben Sie Ihre Frage ein",
    Click_to_enter_customer_service:"Klicken Sie auf Kundenservice",
  },
  promotion:{
    My_promotional_commission:"Meine Werbeprovision",
    total_users:"Gesamtzahl der Benutzer",
    New_users_within_7_days:"Neu hinzugefügt am 7",
    active_user:"aktiver Nutzer",
    Payment_settings:"Zahlungseinstellungen",
    My_alliance_balance:"Mein Allianzgleichgewicht",
    Amount_withdrawn:"Betrag abgezogen",
    withdraw:"zurückziehen",
    my_rights:"meine Rechte",
    Commission_rate:"Provisionssatz",
    Points_record:"Punkterekord",
    Points:"Integral",
    What_is_an_affiliate_program:"Was ist ein Partnerprogramm",
    What_is_an_affiliate_program_desc:"Seacloudbuy ist ein von allen Benutzern initiiertes Sharing- und Profitprojekt. Ganz gleich, ob Sie eine Einzelperson oder ein Unternehmen sind, treten Sie Seacloudbuy als Promoter bei und laden Sie neue Leute ein, sich bei Seacloudbuy zu registrieren und erfolgreich Pakete zu kaufen und zu unterschreiben. Er/sie wird Ihr exklusiver Benutzer, und jeder internationale Logistikkonsum, den er/sie tätigt, bringt Sie profitieren von Cashback, keine Obergrenze.",
    How_my_winnings_are_calculated:"So werden meine Gewinne berechnet",
    How_my_winnings_are_calculated_desc:" Sobald Sie Mitglied werden, erhält Ihr Konto sofort einen Rabatt von 3 %. Je mehr internationale Logistiksendungen Sie registrierte Benutzer einladen, desto höher ist Ihr Bonus und auch die Rabattquote steigt. Wenn der Bonus einen bestimmten Betrag erreicht, können Sie eine Auszahlung beantragen oder damit etwaige Servicegebühren auf der Seacloudbuy-Plattform abziehen.",
    How_to_promote_for_free:"So fördern Sie",
    How_to_promote_for_free_desc:" Kopieren Sie Ihren Werbelink und senden Sie ihn an Ihre Freundin, wenn diese nach der Registrierung Ihr exklusiver Benutzer wird.",
    Commission_rate_desc:"Was ist Provisionssatz? Nachdem Ihr Werbenutzer das Paket erfolgreich erhalten hat, belohnt Seacloudbuy Sie mit den gesamten Versandkosten des Pakets multipliziert mit dem Provisionssatz.",
    Click_here_to_invite:"Zum Einladen hier klicken"
              
  }
  
  

}