import request from "@/utils/request";

// 添加商品
export function addcart(data) {
    var url = "/cart/addCart"
    return request.post(url,data)
}

//获取商品详情 id是
export function userCartInfo(ids){
    var url = "/cart/userCartInfo"
    return request.post(url,ids)
}

/**
 * 我的购物车列表
 * @returns 
 */
export function cartList(){
    var url = "/cart/cartList"
    return request.post(url)
}

/**
 * 修改购物车备注
 * @returns 
 */
export function cartRemark(data){
    var url = "/cart/cartGoodsRemark"
    return request.post(url,data)
}

/**
 * 删除
 * @returns 
 */
export function cartdelete(data){
    var url = "/cart/deleteCartGoods"
    return request.post(url,data)
}

// 添加商品数量
export function addGoodsNum(data){
    var url = "/cart/addGoodsNum"
    return request.post(url,data)
}