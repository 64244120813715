<template>
  <div class="w" style="margin-top: 100px">
    <div class="f_header">
      <div class="f_left">
        <img src="../../assets/header-logo.png" alt="" />
      </div>
      <div class="f_right">
        <span>
          {{ $t("userforget.Already_have_an_account") }},&nbsp;&nbsp;
          <router-link to="/login">{{ $t("userforget.Login") }}</router-link>
          &nbsp;&nbsp;
          <router-link to="/">{{
            $t("userforget.Return_to_SeaCloud_homepage")
          }}</router-link>
        </span>
      </div>
    </div>
    <div class="forgetPSW">
      <div class="f_title">
        <h1>{{ $t("userforget.Account_safety") }}</h1>
      </div>
      <div class="f_main">
        <h4>
          {{
            $t(
              "userforget.Please_enter_the_email_address_associated_with_your_account"
            )
          }}
        </h4>
        <div class="f_ipt">
          <el-form label-width="150px" style="float: left">
            <el-form-item :label="$t('userforget.Email')" prop="email">
              <el-input v-model="email" prefix-icon="el-icon-message">
              </el-input>
            </el-form-item>

            <el-form-item
            :label="$t('userforget.Captcha')"
              style="position: relative"
            >
              <el-input
                v-model="verificationCode"
                prefix-icon="el-icon-edit-outline"
              ></el-input>              
            </el-form-item>
            <el-form-item
            :label="$t('userforget.New_Password')"
              style="position: relative"
            >
              <el-input
                v-model="newpassword"
                type="password"
                prefix-icon="el-icon-edit-outline"
              ></el-input>
            </el-form-item>
            <button @click.prevent="submitForget">
              {{ $t("userforget.Submit") }}
            </button>
          </el-form>
          <div v-if="waitSecond == 0" class="s_btn" @click="send">{{ $t('userforget.Send') }}</div>
          <div v-if="waitSecond > 0" class="s_btn" style="cursor: pointer">
            {{ waitSecond }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forgetPasswordEmail,changePasswordEmail } from "@/Api/user";
export default {
  data() {
    return {
      email: "",
      verificationCode: "",
      waitSecond: 0,
      newpassword:""
    };
  },
  methods: {
    wait(){
      setInterval(() => {
        if(this.waitSecond>0){
          this.waitSecond=this.waitSecond-1
          // console.log(this.waitSecond)
          return this.waitSecond
        }
        
      }, 1000);
    },
    send() {
      if (this.isEmail(this.email) == false) {
        this.$message.info(
          this.$t("userforget.Please_enter_the_correct_email_address")
        );
        return;
      }
      /**
       * 忘记密码发邮件
       */
      var data = {};
      data["email"] = this.email;
      forgetPasswordEmail(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.waitSecond = 60;
          this.wait()
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    isEmail(email) {
      var reg = /^([a-zA-Z0-9_-])+@[a-zA-Z0-9_-]+(\.com+)+$/;
      return reg.test(email);
    },
    submitForget() {
      if (this.isEmail(this.email) == false) {
        this.$message.info(
          this.$t("userforget.Please_enter_the_correct_email_address")
        );
        return;
      }else if(this.verificationCode==""){
        this.$message.info(
          this.$t('userforget.Please_enter_the_verification_code')
        );
        return;
      }else if(this.newpassword==""){
        this.$message.info(
          this.$t('userforget.Please_enter_your_password')
        );
        return;
      }
      var data = {}
      data["email"]=this.email
      data["code"]=this.verificationCode
      data["password"]=this.newpassword
      changePasswordEmail(data).then((res)=>{
        if(res.code==1){
            this.$router.push({
              path:"/login"
            })
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.f_header {
  height: 80px;
  line-height: 80px;
  .f_left {
    height: 100%;
    float: left;
    margin-left: -150px;

    img {
      margin-top: 8px;
      scale: 0.7;
    }
  }
  .f_right {
    float: right;
    span {
      a {
        color: #ef0c25;
      }
    }
  }
}
.forgetPSW {
  box-shadow: -13px 8px 15px -8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .f_title {
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #fff;
    background: linear-gradient(to right, #fd5532, #ef0c25);
    h1 {
      font-weight: 500;
    }
  }
  .f_main {
    height: 370px;
    text-align: center;
    h4 {
      font-size: 16px;
      color: #4f4f4f;
      font-weight: 700;
      line-height: 100px;
      margin-top: 18px;
    }
    .f_ipt {
      width: 500px;
      margin-left: 350px;
      button {
        float: left;
        width: 280px;
        height: 40px;
        border: 0;
        color: #fff;
        margin-top: 10px;
        margin-left: 86px;
        border-radius: 4px;
        background: linear-gradient(to right, #fd5532, #ef0c25);
      }
      .s_btn {
        float: left;
        width: 100px;
        height: 40px;
        padding: 10px;
        border: #ef0c25;
        margin-left: 20px;
        color: #fff;
        cursor: pointer;
        border-radius: 4px;
        background: linear-gradient(to right, #fd5532, #ef0c25);
      }
      .s_btn:hover {
        background: linear-gradient(to right, #e34d2d, #d60b20);
      }
    }
  }
}
</style>
