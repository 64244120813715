<template>
  <div class="couponlist_page">
    <div class="active_con">
      <h4>优惠券激活</h4>
      <div class="content">
        <el-input placeholder="输入您的优惠券激活码"></el-input>
        <el-button type="primary">激 活</el-button>
      </div>
    </div>
    <el-row class="nav">
      <ul class="btn-list">
        <li
          :class="{ 'btn-item': true, active: btnActive === 0 }"
          @click="
            btnActive = 0;
            type = 0;
          "
        >
          <span>{{ $t("orderlist.Shopping") }}</span>
        </li>
        <li
          :class="{ 'btn-item': true, active: btnActive === 1 }"
          @click="
            btnActive = 1;
            type = 1;
          "
        >
          <span>{{ $t("orderlist.MyCoupon") }}(2)</span>
        </li>
      </ul>
      <div class="mycoupon_nav" v-show="type === 1">
        <div class="active">全部</div>
        <div>已使用</div>
        <div>已过期</div>
      </div>
    </el-row>

    <div class="coupon-list" v-if="type === 0">
      <div
        class="coupon-item"
        v-for="(item, index) in couponListArr"
        :key="index"
      >
        <div class="coupon-top">
          <div class="coupon-left">
            <h4 v-if="item.type == 1">
              订单满{{ item.required }} 减 {{ item.money }}
            </h4>
            <h4 v-if="item.type == 2">
              运单满{{ item.required }} 减 {{ item.money }}
            </h4>
            <h4 v-if="item.type == 3">运单打{{ item.money * 10 }}折</h4>
            <p style="margin: 10px 0 3px 0">领取券号：{{ item.code }}</p>
            <span
              >有效期：{{ item.start_time_text }} —
              {{ item.end_time_text }}</span
            >
            <br />
            <span>剩余：{{ item.remain_count }} 张 </span>
            <br />
            <span>要求：{{ item.point == null ? 0 : item.point }} 积分</span>
          </div>
          <div class="coupon-right">
            <div class="big-price" v-if="item.type != 3">
              <font>CNY</font>
              <span>{{ item.money }}</span>
            </div>

            <div class="big-price" v-if="item.type == 3">
              <!-- <font>CNY</font> -->
              <span>{{ item.money * 10 }} 折</span>
            </div>
            <span class="small-price">满{{ item.required }}可用</span>
          </div>
        </div>
        <div class="coupon-bottom">
          <div class="coupno-left">
            <div>
              <p style="margin-bottom: 3px">
                优惠券类型：{{ item.type == "1" ? "订单优惠券" : "运单优惠券" }}
              </p>
              <p style="margin-bottom: 3px">
                激活后有效期为:{{ item.valid_day }} 天
              </p>
            </div>
          </div>
          <div class="coupon-right">
            <el-button
              type="primary"
              plain
              round
              @click="exchangeCouponButton(item.id)"
              >立即兑换</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="coupon-list" v-else>
      <div
        :class="'coupon-item ' + (index === 1 ? ' disabled' : '')"
        v-for="(item, index) in couponListArr"
        :key="index"
      >
        <div class="coupon-top">
          <div class="coupon-left">
            <h4 v-if="item.type == 1">
              订单满{{ item.required }} 减 {{ item.money }}
            </h4>
            <h4 v-if="item.type == 2">
              运单满{{ item.required }} 减 {{ item.money }}
            </h4>
            <h4 v-if="item.type == 3">运单打{{ item.money * 10 }}折</h4>
            <p style="margin: 10px 0 3px 0">优惠券编号：{{ item.code }}</p>
            <span v-if="index !== 1"
              >有效期：{{ item.start_time_text }} —
              {{ item.end_time_text }}</span
            >
            <span v-else>使用时间：{{ item.start_time_text }}</span>
          </div>
          <div class="coupon-right">
            <div class="big-price" v-if="item.type != 3">
              <font>CNY</font>
              <span>{{ item.money }}</span>
            </div>

            <div class="big-price" v-if="item.type == 3">
              <span>{{ item.money * 10 }} 折</span>
            </div>
            <span class="small-price">满{{ item.required }}可用</span>
          </div>
        </div>
        <div class="coupon-bottom">
          <div class="coupno-left">
            <div>
              <p style="margin-bottom: 3px">
                优惠券类型：{{ item.type == "1" ? "订单优惠券" : "运单优惠券" }}
              </p>
              <p style="margin-bottom: 3px">获取方式：平台兑换</p>
              <p style="margin-bottom: 3px">使用范围：全部路线可用</p>
            </div>
          </div>
          <div class="coupon-right paid">
            <el-button
              v-show="index !== 1"
              type="primary"
              plain
              round
              @click="exchangeCouponButton(item.id)"
              >立即使用</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exchangeCouponList, exchangeCoupon, myCouponList } from "@/Api/coupon";
export default {
  name: "coupon-list",
  data() {
    return {
      btnActive: 0,
      couponListArr: [],
      type: 0,
    };
  },
  methods: {
    couponList() {
      exchangeCouponList().then((res) => {
        this.couponListArr = res.data;
      });
    },
    exchangeCouponButton(cid) {
      var data = {};
      data["cid"] = cid;
      exchangeCoupon(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    myCouponList() {
      var data = {};
      data["page"] = 1;
      myCouponList().then((res) => {
        console.log("已经领取的优惠券列表"+res);
      });
    },
  },
  created() {
    this.couponList();
  },
};
</script>

<style lang="less" scoped>
.couponlist_page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  padding: 20px;
}
.active_con {
  margin-bottom: 20px;
  background: #fff;
  padding: 20px 0 20px 20px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-input {
      width: 400px;
    }
    button {
      margin-left: 20px;
      width: 120px;
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    button:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
  }
}
.mycoupon_nav {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding: 10px;
  font-size: 13px;
  color: #333;
  position: absolute;
  right: 0;
  top: 5px;
  div {
    margin-right: 10px;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
  }
}
.btn-list {
  display: flex;
  background: #fff;
  .btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 88px;
    text-align: center;
    cursor: pointer;
    height: 40px;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 14px;
    color: #666;
    border: 1px solid #fff;
    // border-radius: 4px;
  }
  .active {
    color: #ef0c25;
    border-bottom: 2px solid #ef0c25;
    // background-color: #f7e5e6;
  }
}

.coupon-list {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  font-size: 12px;
  color: #888;
  .coupon-item {
    margin: 10px 0;
    padding: 5px 0;
    // border: 1px dashed #ccc;
    cursor: pointer;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
    .coupon-top,
    .coupon-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .coupon-top {
      border-bottom: 1px solid #eee;
      h4 {
        font-size: 14px;
        color: #333;
      }
      .big-price {
        font-size: 24px;
        font-weight: 600;
        color: #ef0c25;
        font {
          font-size: 14px;
        }
      }
      .small-price {
        color: #ef0c25;
      }
    }
    .coupon-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .el-button--primary.is-plain {
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    .el-button--primary.is-plain:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
    .el-button--primary.is-disabled {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }
  .coupon-item.disabled {
    opacity: 0.8;
    .coupon-bottom {
      padding: 5px 10px;
    }
    .coupon-bottom .coupon-right.paid {
      width: 61px;
      height: 61px;
      background: url("../../../public/splite.png") no-repeat;
      background-position: 0 0;
    }
    .coupon-bottom .coupon-right.expired {
      width: 61px;
      height: 61px;
      background: url("../../../public/splite.png") no-repeat;
      background-position: -345px 0;
    }
    .big-price,
    .small-price,
    h4 {
      color: #888 !important;
    }
  }
}
</style>
