<template>
  <div class="orderDetail">
    <div>
      <div class="title">
        {{ $t("orderdetail.Title")
        }}<a
          :href="orderInfo.goods_url"
          target="_blank"
          :title="orderInfo.goods_url"
          >{{ orderInfo.title }}</a
        >
      </div>
      <div>
        {{ $t("orderdetail.Product_Notes") }}{{ orderInfo.goods_remark }}
      </div>
      <div>{{ $t("orderdetail.Order_Number") }}{{ orderInfo.order_code }}</div>
      <div style="display: flex">
        {{ $t("orderdetail.Specifications") }}
        <p v-html="orderInfo.option"></p>
      </div>
      <div>{{ $t("orderdetail.Seller_Name") }} {{ orderInfo.shop_name }}</div>
      <div>{{ $t("orderdetail.Price") }} {{ orderInfo.goods_price }}</div>
      <div>{{ $t("orderdetail.qty") }}{{ orderInfo.goods_num }}</div>
      <div v-if="orderInfo.order_weight > 0">
        {{ $t('orderInfo.Weight') }}:{{ orderInfo.order_weight }}g
      </div>
      <div>{{ $t("orderdetail.Order_Time") }}{{ orderInfo.add_time_text }}</div>
      <div>
        {{ $t("orderdetail.Payment_Time") }}{{ orderInfo.paytime_text }}
      </div>

      <div>
        {{ $t('orderdetail.Measure_size') }}:<em>{{
          ischeckAddServe("measure size", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Remove_packaging') }}:<em>{{
          ischeckAddServe("Remove product", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Take_photos') }}:<em>{{
          ischeckAddServe("Photograph", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Priority_purchase') }}:<em>{{
          ischeckAddServe("Prioritize processing", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Add_air_column_pack') }}:<em>{{
          ischeckAddServe("Add air column package", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Add_corner_protection') }}:<em>{{
          ischeckAddServe("Add protective box", orderInfo.add_server)
        }}</em>
      </div>
      <div>
        {{ $t('orderdetail.Shoe_support') }}:<em>{{
          ischeckAddServe("shoe support", orderInfo.add_server)
        }}</em>
      </div>
    </div>
    <div class="orderImg">
      <img style="width: 200px;"  :src="orderInfo.order_img" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderInfo"],
  methods: {
    ischeckAddServe(addName, addServe) {
      if (addServe == "") {
        return "NO";
      }
      var addServeJson = JSON.parse(addServe);

      for (let key in addServeJson) {
        if (addName == key) {
          return "YES";
        }
      }
      return "NO";
    },
  },
};
</script>

<style lang="less" scoped>
.orderDetail {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  text-align: left;
  div {
    margin-bottom: 10px;
    color: #666;
  }
  em {
    font-weight: bold;
  }
}
.title {
  color: black;
}
.orderImg {
  right: 0;
  bottom: 0;
  width: 300px;
}
</style>