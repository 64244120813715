<template>
  <div class="cart_page">
    <!-- <div class="cart_nav">
      <div class="item">
        <el-select v-model="color" placeholder="标识颜色">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-select v-model="label" placeholder="商品标签">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">搜索</el-button>
    </div> -->
    <div class="cart_content" v-if="cartlist.length !== 0">
      <div class="table_nav">
        <el-row>
          <el-col :span="8">{{ $t("cart.Commodity_information") }}</el-col>
          <el-col :span="2">{{ $t("cart.Note") }}</el-col>
          <el-col :span="4">{{ $t("cart.Price") }}</el-col>
          <el-col :span="3">{{ $t("cart.qty") }}</el-col>
          <el-col :span="4">{{ $t("cart.Amount") }}</el-col>
          <el-col :span="3">{{ $t("cart.Operate") }}</el-col>
        </el-row>
      </div>
      <div class="cart_list">
        <div class="cart_item" v-for="(item, index) in cartlist" :key="item.id">
          <div class="item_title">
            <span>
              <el-checkbox v-model="item.status" @change="choseGoods(item)">{{
                item.shop_name
              }}</el-checkbox>
            </span>
            <div class="shop_logo"></div>
          </div>
          <div class="item_box">
            <el-row class="item_child">
              <el-col :span="8" class="goodInfo">
                <!-- <div class="checkBox">
                  <el-checkbox
                    v-model="item.status"
                    @change="choseGoods(item)"
                  ></el-checkbox>
                </div> -->
                <div class="img_box">
                  <img :src="item.goods_img" alt="" />
                </div>
                <div class="information">
                  <p class="title">
                    <a :href="item.goods_url" target="_blank">{{
                      item.title
                    }}</a>
                  </p>
                  <div class="option" v-html="item.option"></div>
                </div>
              </el-col>
              <el-col :span="2">
                <div>{{ item.remark }}</div>
                <el-button
                  type="text"
                  icon="el-icon-edit-outline"
                  @click="editRemark(item, index)"
                ></el-button>
                <!-- 备注 -->
                <el-dialog
                  :title="$t('cart.Product_remarks')"
                  :visible.sync="dialogVisible[index]"
                  width="40%"
                >
                  <el-input
                    type="textarea"
                    v-model="item.remark"
                    :placeholder="
                      $t(
                        'cart.Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it'
                      )
                    "
                  ></el-input>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="cancelRemark(item, index)">{{
                      $t("cart.Cancel")
                    }}</el-button>
                    <el-button
                      type="primary"
                      @click="saveRemark(item, index)"
                      >{{ $t("cart.Sure") }}</el-button
                    >
                  </div>
                </el-dialog>
              </el-col>
              <el-col :span="4">
                <p>
                  <span>{{ currency }} {{ moneyRates(item.goods_price) }}</span>
                </p>
              </el-col>
              <el-col :span="3">
                <div>
                  <el-input-number
                    v-model="item.goods_num"
                    size="mini"
                    :min="1"
                    :max="10"
                    @change="handleChange(item)"
                  ></el-input-number>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="text-align: center">
                  {{ currency }} {{ moneyRates(item.sum_money) }}
                </div>
              </el-col>
              <el-col :span="3" class="l_btn">
                <div style="text-align: center">
                  <el-button type="text" @click="deleteGoods(item.id)">{{
                    $t("cart.Delete")
                  }}</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 结算区域 -->
      <div class="payment_panel">
        <div class="tools">
          <div @click.prevent="allChecked">
            <el-checkbox v-model="all_checked_value">{{
              $t("cart.Choose_all")
            }}</el-checkbox>
          </div>
          <div>
            <el-button type="text" @click="deleteAllGoods">{{
              $t("cart.Delete_selected_items")
            }}</el-button>
          </div>
          <!-- <div><el-button type="text">编辑标签</el-button></div>
          <div><el-button type="text">查看仓库照片</el-button></div> -->
        </div>
        <div class="payment_btns">
          <div class="detail">
            <h4>{{ this.currency }}&nbsp;{{ moneyRates(total_price) }}</h4>
            <span
              >{{ $t("cart.selected") }}{{ total_nums
              }}{{ $t("cart.pieces_excluding_freight") }}</span
            >
          </div>
          <el-button class="p_fee"
            ><router-link to="/estimation">{{
              $t("cart.Estimated_freight")
            }}</router-link></el-button
          >
          <el-button type="primary" @click="toPay">{{
            $t("cart.pay")
          }}</el-button>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; margin-top: 50px">
      {{ $t("cart.Your_shopping_cart_is_empty_go_treat_yourself") }}
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import { cartList, cartdelete, cartRemark, addGoodsNum } from "@/Api/cart";
export default {
  name: "my-cart",
  data() {
    return {
      options: [],
      color: "",
      label: "",
      cartlist: [],
      checked: false,
      multipleSelection: [],
      all_checked_value: false,
      dialogVisible: [],
      recordRemark: "",
      selectedCartids: [],
      currency: "",
    };
  },

  watch: {},
  computed: {
    total_price() {
      return store.getters.computedPrice;
    },
    total_nums() {
      return store.getters.computedNums;
    },
  },
  mounted() {
    this.currency = this.getCurrency;
    this.setCartList();
  },
  methods: {
    moneyRates(money) {
      return this.moneyRate(money);
    },
    handleChange(value) {
      var data = {};
      data["cartId"] = value.id;
      data["goodsNum"] = value.goods_num;
      addGoodsNum(data).then((res) => {
        if (res.code == 1) {
          this.setCartList();
        }
      });
    },
    setCartList() {
      cartList().then((res) => {
        this.cartlist = res.data;
      });
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    // 选择
    choseGoods(item) {
      if (this.selectedCartids.indexOf(item.id) < 0) {
        this.selectedCartids.push(item.id);
      } else {
        const index = this.selectedCartids.indexOf(item.id);
        if (index !== -1) {
          this.selectedCartids.splice(index, 1);
        }
      }
      // 遍历查询，是否全选
      this.all_checked_value = this.cartlist.every((item) => item.status);
      // 更新vuex数据
      store.dispatch("updateCart", this.cartlist);
    },
    //全选
    allChecked() {
      this.all_checked_value = !this.all_checked_value;
      if (this.all_checked_value == true) {
        this.cartlist.forEach((item) => {
          if (this.selectedCartids.indexOf(item.id) < 0) {
            this.selectedCartids.push(item.id);
          }
        });
      } else {
        this.selectedCartids = [];
      }
      this.cartlist.forEach((item) => (item.status = this.all_checked_value));
      // 更新vuex数据
      store.dispatch("updateCart", this.cartlist);
    },
    // 删除单个商品
    deleteGoods(item) {
      this.$confirm(
        this.$t("cart.Are_you_sure_to_delete_the_item"),
        this.$t("cart.Tips"),
        {
          confirmButtonText: this.$t("cart.Sure"),
          cancelButtonText: this.$t("cart.Cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 删除数据
          var data = {};
          data["cartIds"] = item;
          cartdelete(data).then((res) => {
            if (res.code == 0) {
              this.$message.error(res.msg);
            } else {
              this.$message.success(res.msg);
              this.setCartList();
            }
          });
        })
        .catch(() => {});
    },
    //删除全部商品
    deleteAllGoods() {
      if (this.selectedCartids.length == 0) {
        return;
      }
      this.$confirm(
        this.$t(
          "cart.Are_you_sure_you_want_to_delete_the_items_selected_in_your_shopping_cart"
        ),
        this.$t("cart.Tips"),
        {
          confirmButtonText: this.$t("cart.Sure"),
          cancelButtonText: this.$t("cart.Cancel"),
          type: "warning",
        }
      )
        .then(() => {
          var ids = this.selectedCartids.join(",");
          var data = {};
          data["cartIds"] = ids;
          cartdelete(data).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.setCartList();
            }
          });
          // 删除全部数据
          // store.dispatch("deleteAllGoods");
          // this.cartlist = store.state.cartlist;
          // this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
        })
        .catch(() => {});
    },
    //编辑 备注
    editRemark(item, index) {
      this.$set(this.dialogVisible, index, true);
      // 保存原来的备注，以便取消的时候恢复
      this.recordRemark = item.remark;
    },
    //保存备注
    saveRemark(item, index) {
      console.log(item);
      console.log(index);
      var data = {};
      data["id"] = item.id;
      data["remark"] = item.remark;
      cartRemark(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.setCartList();
        }
      });
      this.$set(this.dialogVisible, index, false);
      // 更新vuex数据
      // store.dispatch("updateCart", this.cartlist);
    },
    //取消备注
    cancelRemark(item, index) {
      this.$set(this.dialogVisible, index, false);
      this.cartlist[index].remark = this.recordRemark;
    },

    toPay() {
      if (this.selectedCartids.length == 0) {
        this.$message.info(this.$t("cart.Please_select_an_item"));
        return;
      }
      this.$router.push({
        path: "/cartInfo",
        query: {
          orderId: this.selectedCartids.join(","),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cart_nav {
  display: flex;
  margin-bottom: 20px;
}
.cart_nav .item {
  margin-right: 15px;
}
.cart_nav .el-button--primary {
  background: #333333;
}
.table {
  min-height: 400px;
}
.table_nav {
  height: 36px;
  // line-height: 36px;
  text-align: center;
  /* display: grid; */
  /* grid-template-columns: 310px 165px 123px 123px 123px 123px; */
}
.cart_content {
  position: relative;
}
.table_nav div {
  text-align: center;
}

.el-input-number--mini {
  width: 100%;
}

.item_box {
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;
  text-align: center;
}
.item_child {
  padding: 12px 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;

  .l_btn {
    button.el-button.el-button--text {
      width: 90px;
      height: 30px;
      line-height: 6px;
      color: #fff;
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25) !important;
    }
    button.el-button.el-button--text:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20) !important;
    }
  }
}
.cart_item .goodInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart_item .checkBox {
  padding: 10px;
}
.cart_item .item_box .img_box img {
  width: 80px;
  height: 80px;
  // vertical-align: middle;
  margin-right: 10px;
}
.cart_item .information {
  width: 164px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cart_item .information .title {
  cursor: pointer;
  height: 34px;
  overflow: hidden;
  color: #333;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
}
.information .title a {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.information .option {
  text-align: left;
  width: 100%;
  color: #999;
  max-height: 48px;
  margin-top: 4px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-table-column--selection .cell {
  padding: 0;
}

.el-input-number--mini {
  width: 100%;
}
.el-table-column_goods {
  display: flex;
}
.el-table-column_goods img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.el-table-column_goods .info .tit {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.el-table-column_goods .info .desc {
  width: 100%;
  color: #999;
  max-height: 48px;
  margin-top: 4px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-table-column--selection .cell {
  padding: 0;
}

.payment_panel {
  // position: relative;
  width: 990px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #ddd;
  margin-top: 10px;
  z-index: 2000;
  background: #fff;
  margin-bottom: 1px;
}
.payment_panel .tools {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.payment_panel .tools > div {
  margin-right: 20px;
}
.payment_panel .tools .el-button--text {
  color: #ef0c25;
}
.payment_panel .payment_btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.payment_btns .detail {
  font-size: 14px;
  color: 333;
  margin-right: 10px;
  text-align: right;
  h4 {
    font-size: 24px;
    color: #ef0c25;
  }
}

.payment_btns .el-button {
  height: 60px;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid #ef0c25;
  a {
    color: #ef0c25;
  }
}
.p_fee:hover {
  background-color: #f7e5e6;
}
.payment_btns .el-button--primary {
  width: 140px;
  height: 60px;
  background: linear-gradient(to right, #fd5532, #ef0c25);
  color: #fff;
  // font-size: 18px;
}
.payment_btns .el-button--primary:hover {
  background: linear-gradient(to right, #e34d2d, #d60b20);
}
</style>
