import request from "@/utils/request";

//获取优惠券领取记录
export function getUserCouponList(data){
          var url = "/coupon/couponList";
          return request.post(url,data)
}
// 激活优惠券
export function activeCoupon(data){
          var url = "/coupon/activeCoupon";
          return request.post(url,data)
}
// 可兑换的优惠券列表
export function exchangeCouponList(){
          var url = "/coupon/couponExchangeList";
          return request.post(url)    
}

// 兑换优惠券
export function exchangeCoupon(data){
          var url = "/coupon/exchangeCoupon";
          return request.post(url,data)   
}

/**
 * 已经领取的优惠券列表
 */
export function myCouponList(data){
          var url = "/coupon/myCouponList";
          return request.post(url,data)   
}