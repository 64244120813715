<template>
  <div class="estimation" style="height: 1000px">
    <header-top></header-top>
    <!-- 运费估算 -->
    <div class="contain">
      <div class="body w">
        <h1 style="font-size: 18px;">{{ $t('estimation.Freight_Estimate') }}</h1>
        <br />
        <div class="input">
          <el-select
            class="inputT"
            prefix-icon="el-icon-message"
            v-model="value"
            filterable
            :placeholder="$t('estimation.Seacloud_warehouse_to')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            class="inputT"
            v-model="weight"
            :placeholder="$t('estimation.Please_enter_weight')"
          ></el-input>
          <el-select
            class="inputT"
            prefix-icon="el-icon-message"
            v-model="goodsvalue"
            filterable
            :placeholder="$t('estimation.Please_select')"
          >
            <el-option
              v-for="item in goodsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="inputT person_shape">
             <div class="tr">
                <span>{{$t('estimation.Length')}}</span>
                <el-input
                  class="children"
                  v-model="long"
                  placeholder="cm"
                ></el-input>
             </div>
             <div class="tr">
               <span>{{$t('estimation.Width')}}</span>
               <el-input
                  class="children"
                  v-model="width"
                  placeholder="cm"
                ></el-input>
             </div>
             <div class="tr">
               <span>{{$t('estimation.Height')}}</span>
               <el-input
                  class="children"
                  v-model="height"
                  placeholder="cm"
                ></el-input>
             </div>
          </div>
        </div>
        <div>
          <el-row>
            <el-button
              class="estimateFee"
              @click="computerData"
              type="success"
              round
              >{{ $t('estimation.Freight_Estimate') }}</el-button
            >
          </el-row>
        </div>
      </div>
    </div>
    <!-- 快递详情 -->
    <div class="deliver w">
      <p>
        {{ $t('estimation.International_freight_description') }}
        <a href="/question?requestId=14">{{ $t('estimation.Logistics_Service_Description') }}</a>
      </p>
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="handleRowChange"
      >
      <el-table-column
      prop="deliver.delivery_name"
      :label="$t('estimation.ico')"
      width="100"
    >
    <template slot-scope="scope">
      <img style="width:80px " :src="'https://seacloudbuy.com/'+scope.row.deliver.icon_image">
    </template>
    </el-table-column>
        <el-table-column
          prop="deliver.delivery_name"
          :label="$t('estimation.Transportation_Method')"
          width="260"
        >
        </el-table-column>
        <el-table-column prop="deliver.delivery_day" :label="$t('estimation.Time')" width="100">
        </el-table-column>
        <el-table-column prop="sumFee" :label="$t('estimation.Freight')" width="100">
          <template slot-scope="scope">
            {{  currency }} {{  moneyRates( scope.row.sumFee) }}
          </template>
        </el-table-column>
        <el-table-column prop="deliver.description" :label="$t('estimation.Features')">
        </el-table-column>

       
        <el-table-column fixed="right" :label="$t('estimation.Operation')" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleRowChange(scope.row)"
              type="text"
              size="small"
              >{{ $t('estimation.View') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="$t('estimation.Details')" :visible.sync="deliveryVisable" >
      <delivery-detail
        v-if="deliveryVisable"
        :currentItem="currentItem"
      ></delivery-detail>
    </el-dialog>

    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "./HeaderTop.vue";
import { estimation, getCountryList, getGoodsType } from "@/Api/index";
import DeliveryDetail from "../others/deliveryDetail.vue";
import Bottom from "./Bottom.vue";
export default {
  name:"estimation-vue",
  components: { HeaderTop, DeliveryDetail, Bottom },
  data() {
    return {
      options: [],
      goodsType: [],
      value: 1,
      goodsvalue: 3,
      weight: "",
      type: "",
      long: '',
      width: '',
      height: '',
      tableData: [],
      deliveryVisable: false,
      currentItem: null,
      currency:"CNY"
    };
  },
  methods: {
    moneyRates(money){
     return this.moneyRate(money)
    },
    computerData() {
      var data = {};
      data["countryId"] = this.value;
      data["weight"] = this.weight;
      data["long"] = this.long;
      data["width"] = this.width;
      data["height"] = this.height;
      data["goodstype"] = this.goodsvalue;
      estimation(data).then((res) => {
        if (res.code == 0) {
          this.tableData = [];
          this.$message.error(res.msg);
        } else {
          this.tableData = res.data;
        }
      });
    },
    handleRowChange(val) {
      this.deliveryVisable = true;
      this.currentItem = val;
    },
    getInitData(){
      getCountryList().then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.options = res.data;
      }
    });

    getGoodsType().then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.goodsType = res.data;
      }
    });
    }
  },
  created() {
      this.getInitData()
      this.currency = this.getCurrency
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  min-width: fit-content;
  height: 440px;
  background: url("/src/assets/home_bg.png");
  .body {
    // border: 1px solid red;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    padding-top: 80px;
    .input {
      /* width: 80%; */
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: space-around;
      font-size: 14px;
      .inputT {
        width: 408px;
         margin-top: 20px;
        display: flex;
        .tr{
          display: flex;
          align-items: center;
        }
        .children {
          width: 135px;
          text-align: left;
        }
      }
      .person_shape{
        background: #fff;
        height: 40px;
        color: #000;
        border-radius: 5px;
        padding-right: 2px;
        .tr{
          padding-left: 15px;
        }
        .children{
          width: auto;
        }
        span{
          color: #777;
        }
      }
    }

    .estimateFee {
      margin-top: 50px;
      width: 400px;
      height: 50px;
      border: 1px solid #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    .estimateFee:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
  }
}

.deliver {
  // border: 1px solid red;
  min-height: 300px;
  margin: 0 auto;
  p {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
  a {
    color: #2e5aef;
  }
}
</style>
