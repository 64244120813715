<template>
  <div class="base-info">
    <div class="box-content">
      <!-- 编辑 -->
      <div class="edit">
        <el-button
          type="text"
          icon="el-icon-edit-outline"
          @click="openUserInfo"
          >{{ $t("userInfo.Edit") }}</el-button
        >
      </div>
      <!-- 弹出框 -->
      <el-dialog
        title="个人信息"
        :visible.sync="dialogFormVisible"
        class="d_box"
      >
        <el-form :model="form">
          <el-form-item
            :label="$t('userInfo.Upload_avatar')"
            :label-width="formLabelWidth"
          >
            <van-uploader
              accept="image/*"
              :after-read="afterRead"
              :max-count="1"
            >
              <img :src="userInfoS.avatar" alt="please choose photo" />
            </van-uploader>
          </el-form-item>
          <el-form-item
            :label="$t('userInfo.Username')"
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('userInfo.Gender')"
            :label-width="formLabelWidth"
          >
            <el-radio v-model="form.sex" label="0">{{
              $t("userInfo.Female")
            }}</el-radio>
            <el-radio v-model="form.sex" label="1">{{
              $t("userInfo.Male")
            }}</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onDelete">{{ $t("userInfo.Cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            $t("userInfo.Sure")
          }}</el-button>
        </div>
      </el-dialog>
      <div class="userinfo_con">
        <div class="avatar">
          <div class="photo">
            <img :src="userInfoS.avatar" style="cursor: pointer;" @click="openUserInfo" />
            <div class="tag">V{{ userInfoS.level }}</div>
          </div>
          <span class="userinfo_id">ID:{{ userInfoS.usercode }}</span>
        </div>
        <div class="info">
          <div class="detail_info">
            <el-row>
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Username") }}</div>
                <div class="value">{{ userInfoS.username }}</div>
              </el-col>
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Balance") }}</div>
                <div class="value">{{ userInfoS.money }} CNY</div>
              </el-col>
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Phone") }}</div>
                <div class="value">
                  {{ userInfoS.email || this.$t("userInfo.None") }}
                </div>
              </el-col>
            </el-row>
            <el-row class="row_2">
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Gender") }}</div>
                <div class="value">
                  {{
                    userInfoS.gender === 0
                      ? this.$t("userInfo.Female")
                      : this.$t("userInfo.Male")
                  }}
                </div>
              </el-col>
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Language") }}</div>
                <div class="value">{{ userInfoS.language }}</div>
              </el-col>
              <el-col class="flex" :span="8">
                <div class="label">{{ $t("userInfo.Registration_time") }}</div>
                <div class="value">
                  {{ userInfoS.jointime_text || this.$t("userInfo.None") }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="vip_growth">
            <div class="info">
              <div class="label">
                {{ $t("userInfo.score") }} &nbsp;
                {{ userInfoS.score }}
              </div>
              <!-- <a href="" class="label record">{{
                $t("userInfo.View_Growth_Value_Record")
              }}</a> -->
            </div>
            <div class="progress">
              <el-progress
                :text-inside="true"
                :stroke-width="10"
                :percentage="
                  computerPerce(userInfoS.score, userInfoS.grow_value_required)
                "
                status="exception"
              ></el-progress>
            </div>
            <p class="label" v-if="userInfoS.grow_value_required > 0">
              {{ $t("userInfo.Distance") }}V{{ userInfoS.level + 1
              }}{{ $t("userInfo.Only_requires")
              }}{{ userInfoS.grow_value_required }}{{ $t("userInfo.score") }}
            </p>
            <!-- {{ $t('userInfo.Only_3000_growth_points_are_needed_to_reach_V1') }} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, editUser } from "@/Api/user";
import { compressImage } from "@/Api/index";
export default {
  data() {
    return {
      userInfoS: {},
      dialogFormVisible: false,
      form: {
        imageUrl: "",
        username: "",
        sex: "1",
      },
      isUpload: false,
      formLabelWidth: "120px",
    };
  },
  watch: {
    dialogFormVisible: {
      handler(value) {
        if (value == false && this.form.imageUrl != "") {
          this.userInfoS.avatar = this.imageUrl;
        }
      },
    },
  },
  methods: {
    computerPerce(score, sumcore) {
      if (score > 0) {
        return score / sumcore;
      }
      return 0;
    },
    openUserInfo() {
      this.dialogFormVisible = true;
      this.form.username = this.userInfoS.username;
      this.form.sex = this.userInfoS.gender + "";
    },
    afterRead(file) {
      this.form.imageUrl = this.userInfoS.avatar;
      this.userInfoS.avatar = file.content;
      this.isUpload = true;
    },
    onDelete() {
      this.dialogFormVisible = false;
      this.getUserInfo();
    },
    // 点击确定获取用户修改的信息
    submitForm() {
      this.dialogFormVisible = false;
      if (this.userInfoS.avatar.indexOf("http") < 0) {
        this.form.imageUrl = this.userInfoS.avatar;
      }
      if (this.isUpload == false) {
        this.form.imageUrl = "";
      }
      if (this.form.imageUrl != "") {
        compressImage(this.form.imageUrl, 300, 400, 0.5).then((base64) => {
          this.form.imageUrl = base64;
          editUser(this.form).then((res) => {
            if (res.code == 1) {
              this.getUserInfo();
            }
          });
        });
      } else {
        editUser(this.form).then((res) => {
          if (res.code == 1) {
            this.getUserInfo();
          }
        });
      }
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfoS = res.data;
        this.$emit("getUserinfo", this.userInfoS);
      });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}
.base-info {
  padding-bottom: 50px;
  .box-content {
    min-height: 200px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#3a3a3a),
      to(#515151)
    );
    background: linear-gradient(180deg, #3a3a3a, #515151);
    border-radius: 4px 4px 0 0;
    .edit {
      padding: 0 10px;
      text-align: right;
      button {
        color: #fff;
        font-size: 12px;
      }
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .userinfo_con {
      color: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .el-con {
        display: flex;
      }
      .label {
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 300;
        color: #fff;
      }
      .avatar {
        width: 205px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        letter-spacing: 1px;
        .photo {
          position: relative;
          img {
            -o-object-fit: cover;
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #f6ce52;
            color: #fff;
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            border-radius: 12px;
          }
        }
        .userinfo_id {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fff;
        }
      }
      .info {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        padding-right: 65px;
        .detail_info {
          .row_2 {
            margin: 15px 0;
          }
          .value {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fff;
          }
        }
        .vip_growth {
          .info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
          .progress {
            // border: 1px red solid;
            margin: 5px 0;
            // width: 250px;
            .el-progress.el-progress--line {
              width: 98%;
            }
          }
        }
      }
    }
  }
}
</style>
