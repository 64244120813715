import request from "@/utils/request";
// 获取用户详情
export function sendAddServe() {
          var url = "/send_order/sendAddServe"
          return request.post(url)
}

// 运单优惠券sendCouponList
export function couponList(data){
          var url = "/send_order/sendCouponList"
          return request.post(url,data)
}


// 运单列表
export function sendorderListg(data){
          var url = "/send_order/sendList"
          return request.post(url,data)  
}

// 查看运单详情
export function sendorderDetail(data){
          var url = "/send_order/sendOrderDetail"
          return request.post(url,data)  
}
// 提交运单submitOrderSend
export function createSendorder(data){
          var url = "/order/submitOrderSend"
          return request.post(url,data)  
}

// 提交运单预演submitOrderSend
export function createRehearsalSendorder(data){
    var url = "/order/submitRehearsal"
    return request.post(url,data)  
}

//根据运单编码 来获取总价格
export function getSendMoney(data){
          var url = "/send_order/getSendMoney"
          return request.post(url,data)   
}

// getSendMessage 运单留言信息
export function getSendMessage(data){
          var url = "/send_order/getSendMessage"
          return request.post(url,data)  
}

// orderMessage 运单留言
export function sendMessage(data){
          var url = "/send_order/orderMessage";
          return request.post(url,data) 
      }

    //   paySend 支付

    export function paySend(data){
        var url = "/send_order/paySend";
        return request.post(url,data) 
    }

    // ConfirmOrder 确认运单
    export function ConfirmOrder(data){
        var url = "/send_order/ConfirmOrder";
        return request.post(url,data) 
    }

    // cancelRehearsal 取消预演运单
    export function cancelRehearsal(data){
        var url = "/send_order/cancelRehearsal";
        return request.post(url,data) 
    }

    // sendTrack 物流路线
    export function sendTrack(data){
        var url = "/send_order/sendTrack";
        return request.post(url,data) 
    }

    // 取消运单 cancelSend
    export function cancelSend(data) {
        var url = "/send_order/cancelSend";
        return request.post(url,data) 
    }

    // 获取运单详情的订单图片信息
    export function orderImg(data){
        var url = "/send_order/getOrderImgInfo";
        return request.post(url,data) 
    }
     