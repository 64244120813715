import request from "@/utils/request";

/**
 * 登录
 * @param {*} email 
 * @param {*} password 
 * @param {*} captcha 
 * @param {*} t 
 * @returns 
 */
export function login(email,password,captcha,t) {
          var url = "/user/login"
          var  param = {
                    "account":email,
                    "password":password,
                    "code":captcha,
                    "t":t
          }
  return request.post(url,param)
}
/** 
 * 获取验证码  有问题
*/
export function getCaptcha(t) {
  return "https://www.seacloudbuy.com/api/index/captcha?id="+t
}

export function register(data){
  var url = "/user/register"
  return request.post(url,data)
}
// 获取用户语言和货币
export function getUserLanguageCurrency(userId){
  var url = "/user/getUserLanguageCurrency?userId="+userId
  return request.post(url)
}


// 获取推广商品的邀请码
export function getInviterUrl(type,itemId){
  var url = "/index/getInviteUrl?type="+type+"&itemId="+itemId
  return request.post(url)
}


// 获取用户详情
export function getUserInfo(){
  var url = "/user/getUserInfo"
  return request.post(url)
}

// 修改用户登录密码
export function editPassword(data){
  var url = "/user/editPassword"
  return request.post(url,data)
}

// 获取用户地址列表
export function getUserAddressList(){
  var url = "/user/getUserAddressList"
  return request.get(url)
}

// 添加地址
export function addAddress(data){
  var url = "/user/addAddress"
  return request.post(url,data)
}

// 删除地址
export function deleteUserAddress(data){
  var url = "/user/deleteUserAddress"
  return request.post(url,data)
}

// 添加地址
export function setUserAddressDefault(data){
  var url = "/user/setUserAddressDefault"
  return request.post(url,data)
}


// withDraw 提现接口
export function userWithDraw(data){
  var url = "/user/withDraw"
  return request.post(url,data)
}

// logout退出登录
export function logout(){
  var url = "/user/logout"
  return request.post(url)
}

export function forgetPasswordEmail(data){
  var url = "/user/forgetPasswordEmail"
  return request.post(url,data)
}

export function editUser(data){
  var url = "/user/editUser"
  return request.post(url,data)
}

export function changePasswordEmail(data){
  var url = "/user/changePasswordEmail"
  return request.post(url,data)
}

//isSetDefaultAddress
export function isSetDefaultAddress(){
  var url = "/user/isSetDefaultAddress"
  return request.post(url)
}

export function promotionPlan(){
  var url =  "/union/promotionPlan"
  return request.post(url)
}