import { render, staticRenderFns } from "./orderDetail.vue?vue&type=template&id=2c033af4&scoped=true"
import script from "./orderDetail.vue?vue&type=script&lang=js"
export * from "./orderDetail.vue?vue&type=script&lang=js"
import style0 from "./orderDetail.vue?vue&type=style&index=0&id=2c033af4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c033af4",
  null
  
)

export default component.exports