<template>
  <div class="desc w">
    <span class="serve">{{ $t('describe.One_stop_service') }}</span>
    <el-row :gutter="12">
      <el-col :span="6" class="box" v-for="item in boxArr" :key="item.id">
        <el-card shadow="hover">
          <img :src="item.imgUrl" alt="" />
          <span>{{ item.title }}</span>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Describe-vue",
  data() {
    return {
      boxArr: [
        {
          id: 1,
          imgUrl: require("@/assets/upload/icon1.png"),
          title: this.$t("describe.One_click_order"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/upload/icon2.png"),
          title: this.$t("describe.Ship_to_warehouse"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/upload/icon3.png"),
          title: this.$t("describe.Quality_inspection"),
        },
        {
          id: 4,
          imgUrl: require("@/assets/upload/icon4.png"),
          title: this.$t("describe.Global_Freight"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.serve {
  display: block;
  text-align: center;
  padding: 60px 0;
  color: #333;
  font-size: 40px;
  font-weight: 400;
  /* font-family: PingFang-SC-Regular,PingFang-SC; */
}

.box img {
  width: 55px;
  height: 55px;
  /* scale: 0.5; */
  display: block;
  margin: 0 auto;
}
.box span {
  display: block;
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: 400;
}
</style>
