<template>
  <div class="container">
    <HeaderTop />
    <div class="w">
      <!-- <div class="tips" v-if="tab != 'imageSearch'">
        <span class="agree"
          >{{ $t("goodslist.Agreed") }}
          <a href="" class="search">{{
            $t("goodslist.Search_Service_Terms")
          }}</a></span
        >
      </div> -->
      <div  class="searchImg_con">
        <div class="searchImg">
          <img  :src="imgSearchUrl"  />
        </div>
        <span class="agree"
          >{{ $t("goodslist.Agreed") }}
          <a href="" class="search">{{
            $t("goodslist.Search_Service_Terms")
          }}</a></span
        >
      </div>
      <!-- tab 标签栏 -->
      <el-tabs
        v-model="activeName"
        class="imgTabContainer"
        @tab-click="switchTab"
      >
        <!--1688相关-->
        <el-tab-pane label="1688" name="1688">
          <div class="goods">
            <ul>
              <li v-for="item in goodsList3" :key="item.id">
                <a href="">
                  <router-link
                    :to="{
                      name: 'GoodsDetail',
                      query: { itemId: item.itemId, type: activeName },
                    }"
                    target="_blank"
                  >
                    <img :title="item.title" v-lazy="item.image" />
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.title"
                      placement="bottom"
                    >
                      <span class="title">
                        <a>{{ item.title }}</a>
                      </span>
                    </el-tooltip>
                    <span class="d_bottom">
                      <b class="d_price">{{
                        currency + " " + moneyRates(item.price)
                      }}</b>
                      <b class="sold">{{
                        $t("goodslist.Sold") + "：" + item.sold
                      }}</b>
                    </span>
                  </router-link>
                </a>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <!-- 加载中动画 -->
        <van-loading
          v-if="isLoading"
          size="24px"
          type="spinner"
          style="text-align: center; height: 80px; line-height: 80px"
        >
          {{ $t("goodslist.loading") }}
        </van-loading>
      </el-tabs>
    </div>
    <Bottom />
  </div>
</template>

<script>
import { common } from "@/Api/common";
import { photoSearch } from "@/Api/index";
export default {
  components: {
    // 需要将import引入改为components内部引入，解决递归式调用组件 导致<HeaderTop/>不显示问题
    HeaderTop: () => import("@/components/mainPage/HeaderTop.vue"),
    Bottom: () => import("@/components/mainPage/Bottom.vue"),
  },
  data() {
    return {
      activeName: "1688", // 默认type类型
      goodsList1: [], // 必须写
      goodsList2: [],
      goodsList3: [],
      pageNum: 1,
      isover: false, // 是否到底 (默认没到底)
      isLoading: true, // 是否正在加载
      currency: "",
      tab: "",
      imgSearchUrl: "",
    };
  },
  // 监视url路由中的参数是否发生变化  只要路由发生了变化，就重新请求接口
  // watch: {
  //   $route: {
  //     // immediate:true,
  //     handler(newValue) {
  //       localStorage.setItem("searchValuemicro", newValue.query.inputValue);
  //       this.pageNum = 1;
  //       this.activeName = "micro";
  //       this.goodsList1 = [];
  //       this.goodsList2 = [];
  //       this.goodsList3 = [];
  //       // 重新请求接口
  //       this.getGoods(newValue.query.inputValue, "micro", this.pageNum);
  //     },
  //   },
  // },
  methods: {
    getImgSearch(pageNum = 1) {
      var data ={}
      data["imageId"]=this.$route.query.imageId
      data["type"]=1688
      data["pageNum"]=pageNum
      photoSearch(data).then((res) => {
        this.isLoading=false
        if (pageNum == 1) {
          this.goodsList3 = res.data;
        } else {
          res.data.forEach((item) => {
            this.goodsList3.push(item);
          });
        }
        // this.isLoading = false;
      });
    },
    // 转换money
    moneyRates(money) {
      return this.moneyRate(money);
    },
    // 同一个搜索词切换tab之后，不重复请求服务器
    switchTab(tab, event) {
      // this.isLoading = true;
      // console.log(tab);
      // console.log(event);
      // console.log(this.activeName);
      // var type = tab.name;
      // var searchValue = localStorage.getItem("searchValue" + type);

      // //切换时 判断这次搜索和上次搜索关键词是否一样
      // if (searchValue === this.$route.query.inputValue) {
      //   // 关键词一样 判断下面条件 为了减少服务器请求 只要有数据 就不刷新
      //   if (type === "micro" && this.goodsList1.length > 0) {
      //     return;
      //   }
      //   if (type === "taobao" && this.goodsList2.length > 0) {
      //     return;
      //   }
      //   if (type === "1688" && this.goodsList3.length > 0) {
      //     return;
      //   }
      // }
      // localStorage.setItem("searchValue" + type, this.$route.query.inputValue);
      // this.pageNum = 1;
      // this.getGoods(this.$route.query.inputValue, type, this.pageNum);
    },
    // 监听滚动事件的方法
    handleScroll() {
      // 计算：页面滚动的高度scrollTop、窗口可视区域高度clientHeight、页面总高度scrollHeight
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - scrollTop - clientHeight < 10) {
        // 当距离页面底部不足10px且非正在加载状态时，触发加载数据的方法
        if (this.isLoading == true) {
          //如果发起了请求 并且还没有响应 说明在加载中
          return;
        }
        this.pageNum++; // 增加页数
        this.isLoading = true;
        this.getImgSearch(this.pageNum);
      }
    },
  },
  created() {
    this.getImgSearch()
    this.imgSearchUrl=localStorage.getItem('imgSearch')
    console.log(this.imgSearchUrl)
    // 监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // 组件销毁时移除事件监听 ，防止内存泄漏
    window.removeEventListener("scroll", this.handleScroll);
  },

  mixins: [common], // 使用common中的getGoods数组
};
</script>

<style lang="less" scoped>
.container {
  background-color: #f9f9f9;
  padding-bottom: 10px;
}

.tips {
  height: 60px;
  line-height: 60px;
}
.tips span {
  font-size: 16px;
  font-weight: 400;
  color: #79797e;
}
.agree {
  float: right;
}
.search {
  color: #2e5aef;
}
/* a {
  color: #2e5aef;
} */
/* tab 标签栏 */
.el-tabs__nav-scroll {
  float: left !important;
}
.imgTabContainer {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

  //   .el-tabs__nav {
  //     margin-left: 50px;
  //   }
}
.goods {
  ul {
    /* background-color: pink; */
    display: flex;
    /* 控制子元素是否换行 */
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;
  }
}

.goods li {
  margin-bottom: 15px;
  padding: 10px;
  margin-left: 13px;
  width: 224px;
  border: 1px solid transparent;
  /* height: 294px; */
  /* border: 1px solid green; */
}
/* 过渡效果 transition：参与过渡的属性 过渡的持续时间*/
.goods li:hover {
  /* scale: 1.1; */
  /* transform: scale(1.1); */
  transition: transform 0.5s;
  //   box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.1);
  //   border: 1px solid #e4e4e4;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.goods li span {
  display: block;
}
.goods img {
  width: 200px;
  height: 200px;
}
/* 标题显示 */
.title {
  cursor: pointer;
  color: #333;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.d_bottom {
  height: 24px;
  line-height: 24px;
  margin-top: 6px;
  .d_price {
    color: #333;
    font-size: 20px;
    font-weight: 700;
  }
  .sold {
    float: right;
    font-size: 12px;
    font-weight: normal;
    color: #999;
  }
}
.searchImg_con {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .searchImg {
    margin-right: 20px;
    img {
      width: 100px;
      height: auto;
    }
  }
}
</style>
