<template>
  <div class="order_page">
    <!-- <div class="add-transport">
      <el-button icon="el-icon-plus" style="border: none">
        转运订单自助服务</el-button
      >
    </div> -->
    <div class="order-filter">
      <el-row>
        <el-col :span="17">
          <ul class="btn-list">
            <li
              :class="{ 'btn-item': true, active: btnActive === 0 }"
              @click="(btnActive = 0), switchLi(-1)"
            >
              <span>{{ $t("orderlist.All") }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 1 }"
              @click="(btnActive = 1), switchLi(0)"
            >
              <span>{{ $t("orderlist.Pending_payment") }}</span>
            </li>
            <li
              :class="{ 'btn-item': true, active: btnActive === 2 }"
              @click="(btnActive = 2), switchLi(1)"
            >
              <span>{{ $t("orderlist.Paid") }}</span>
            </li>
            <!-- <li
              :class="{ 'btn-item': true, active: btnActive === 4 }"
              @click="(btnActive = 4), switchLi(2)"
            >
              <span>待确认</span>
            </li> -->
            <li
              :class="{ 'btn-item': true, active: btnActive === 3 }"
              @click="(btnActive = 3), switchLi(3)"
            >
              <span>{{ $t("orderlist.Ordering") }}</span>
              <!-- <el-badge is-dot class="item"></el-badge> -->
            </li>

            <li
              :class="{ 'btn-item': true, active: btnActive === 5 }"
              @click="(btnActive = 5), switchLi(9)"
            >
              <span>{{ $t("orderlist.Return_Exchange") }}</span>
              <!-- <el-badge is-dot class="item"></el-badge> -->
            </li>
          </ul>
        </el-col>
        <el-col :span="7" class="time-sort">
          <!-- <el-button type="text" icon="el-icon-sort">时间排序</el-button>
          <el-button type="text" icon="el-icon-delete">订单回收站</el-button> -->
        </el-col>
      </el-row>
    </div>
    <div class="order-search">
      <!-- <el-row style="margin-bottom: 20px">
      
      </el-row> -->
      <el-row>
        <el-col :span="7">
          <el-input
            :placeholder="$t('orderlist.Order_number')"
            v-model="searchValue"
            size="small"
            class="input-with-select"
          >
            <!-- <i class="el-icon-search" slot="append" @click="searchInfo()"></i> -->
          </el-input>
        </el-col>
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="small"
        >
          <!-- <el-form-item>
            <el-select
              v-model="formInline.region"
              placeholder="商品状态"
              style="width: 180px"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item>
            <el-select
              v-model="formInline.region"
              placeholder="标识颜色"
              style="width: 150px"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item>
            <el-select
              v-model="formInline.region"
              placeholder="商品标签"
              style="width: 130px"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-date-picker
              style="width: 300px"
              v-model="formInline.time"
              type="daterange"
              :start-placeholder="$t('orderlist.Start_date')"
              :end-placeholder="$t('orderlist.End_date')"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              style="background: #333; border: none"
              @click="searchInfo()"
              >{{ $t("orderlist.Search") }}</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <div class="order-box">
      <div class="order-nav">
        <el-row>
          <el-col :span="6">{{ $t("orderlist.product_detail") }}</el-col>
          <el-col :span="3">{{ $t("orderlist.Price") }}</el-col>
          <el-col :span="1">{{ $t("orderlist.qty") }}</el-col>
          <el-col :span="3">{{ $t("orderlist.Order_Status") }}</el-col>
          <!-- <el-col :span="2">售后</el-col> -->

          <el-col :span="2">{{ $t("orderlist.Value_added_services") }}</el-col>
          <el-col :span="4">{{ $t("orderlist.Order_total") }}</el-col>
          <el-col :span="5">{{ $t("orderlist.Other_operations") }}</el-col>
        </el-row>
      </div>
      <div class="order-list">
        <div class="cart_item" v-for="(item, index) in cartlist" :key="item.id">
          <div class="item_title">
            <span>
              <el-checkbox v-model="item.status">{{
                item.shop_name
              }}</el-checkbox>
            </span>
            <div class="shop_logo"></div>
            <span class="order_id"
              >{{ $t("orderlist.Order_number") }}{{ item.order_code }}</span
            >
            <span class="create_time">
              {{ $t("orderlist.Creation_time") }} {{ item.add_time_text }}
            </span>
            <span class="storage">{{
              $t("orderlist.Warehouse_Seacloud_Warehouse")
            }}</span>
          </div>
          <div class="item_box">
            <el-row class="item_child">
              <el-col :span="6" class="goodInfo">
                <!-- <div class="checkBox">
                  <el-checkbox v-model="item.status"></el-checkbox>
                </div> -->
                <div class="img_box">
                  <img :src="item.order_img" alt="" />
                </div>
                <div class="information">
                  <p class="title">
                    <a
                      :href="item.goods_url"
                      target="_blank"
                      :title="item.title"
                      >{{ item.title.slice(0, 20) + "..." }}</a
                    >
                  </p>
                  <div class="option" v-html="item.option"></div>
                  <!-- 备注 -->

                  <el-button
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="showDialog(index)"
                    >{{ $t("orderlist.Add_notes") }}</el-button
                  >
                  <el-dialog
                    :title="$t('orderlist.Product_remarks')"
                    :visible.sync="dialogVisible[index]"
                    width="40%"
                  >
                    <el-input
                      type="textarea"
                      v-model="item.goods_remark"
                      :placeholder="
                        $t(
                          'orderlist.Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it'
                        )
                      "
                    ></el-input>
                    <div slot="footer" class="dialog-footer">
                      <el-button @click="cancel(index)">{{
                        $t("orderlist.Cancel")
                      }}</el-button>
                      <el-button
                        type="primary"
                        @click="saveRemark(index, item)"
                        >{{ $t("orderlist.Sure") }}</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </el-col>
              <el-col :span="3">
                <div>{{ currency }}{{ moneyRate(item.goods_price) }}</div>
              </el-col>
              <el-col :span="1">
                <p>
                  <span>{{ item.goods_num }}</span>
                </p>
              </el-col>
              <el-col :span="3">
                <div>
                  {{ item.status }}
                </div>
              </el-col>
              <!-- 增值服务 -->
              <el-col :span="2" class="add-service">
                <div>
                  <label class="addServer" @click="showAddDialog(item)">{{
                    $t("orderlist.Value_added_services")
                  }}</label>
                </div>
                <el-dialog
                  :title="$t('orderlist.Value_added_services')"
                  :visible.sync="dialogVisibleAdd"
                  width="400px"
                >
                  <el-form
                    ref="form"
                    :model="form"
                    label-width="50px"
                    style="display: flex; text-align: left"
                  >
                    <el-form-item>
                      <el-checkbox-group v-model="form.type">
                        <el-checkbox
                          v-for="(item1, index) in addServeListInfo"
                          :key="index"
                          :label="item1.label"
                          name="type"
                        ></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-form>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelDialogVisibleAdd">{{
                      $t("orderlist.Cancel")
                    }}</el-button>
                    <el-button type="primary" @click="saveDialogVisibleAdd">{{
                      $t("orderlist.Sure")
                    }}</el-button>
                  </span>
                </el-dialog>
              </el-col>
              <el-col
                :span="4"
                style="width: auto; line-height: 107px; margin-left: 50px"
              >
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      $t('orderlist.Product_shipping_cost') +
                      moneyRate(item.post_fee) +
                      $t('orderlist.Value_added_service_fee') +
                      moneyRate(item.add_fee)
                    "
                    placement="bottom"
                  >
                    <div>
                      {{ currency }}
                      {{
                        computerPriceFee(
                          item.sum_money,
                          item.post_fee,
                          item.add_fee
                        )
                      }}
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </el-tooltip>
                </div>
              </el-col>
              <el-col :span="5" class="l_btn">
                <!-- 未支付 -->
                <div v-if="[0].indexOf(item.state) >= 0">
                  <el-button type="success" @click="toPay(item.id)">{{
                    $t("orderlist.Pay_order")
                  }}</el-button>
                  <br />
                </div>
                <div v-if="[1, 2, 3].indexOf(item.state) >= 0">
                  <el-button type="text" @click="orderDetailInfo(item)">{{
                    $t("orderlist.order_details")
                  }}</el-button>
                  <el-dialog
                    :title="$t('orderlist.order_details')"
                    width="800px"
                    :visible.sync="item.dialogTableVisibleOrderDetail"
                  >
                    <order-detail
                      v-if="item.dialogTableVisibleOrderDetail"
                      :orderInfo="item"
                    ></order-detail>
                  </el-dialog>
                  <br />
                </div>
                <div v-if="[1, 2, 3, 9].indexOf(item.state) >= 0">
                  <el-button type="text" @click="toMessage(item)">{{
                    $t("orderlist.message")
                  }}</el-button>
                  <br />
                  <el-dialog
                    title="message"
                    style="text-align: left"
                    width="960px"
                    :visible.sync="item.dialogTableVisibleUserMessage"
                  >
                    <user-message
                      v-if="item.dialogTableVisibleUserMessage"
                      :currentItemMessage="currentItemMessage"
                    ></user-message>
                  </el-dialog>
                </div>
                <div v-if="[0, 1, 2].indexOf(item.state) >= 0">
                  <el-button type="text" @click="cancelItem(item)">{{
                    $t("orderlist.Cancel_order")
                  }}</el-button>
                  <br />
                </div>
                <!-- 待确认 -->
                <!-- <div v-if="[2].indexOf(item.state) >= 0">
                  <el-button type="text">确认采购</el-button>
                </div> -->
                <!-- 在途中 -->
                <div v-if="[3].indexOf(item.state) >= 0">
                  <el-button type="text" @click="orderTrackInfo(item)">{{
                    $t("orderlist.logistics")
                  }}</el-button>
                  <el-dialog
                    :title="$t('orderlist.Logistics_information')"
                    style="text-align: left"
                    :visible.sync="dialogTableVisibleOrderTrack"
                  >
                    <track-info :orderTrackInfos="orderTrackInfos"></track-info>
                  </el-dialog>
                  <br />
                  <el-button type="text" @click="applyReturnGoods(item)">{{
                    $t("orderlist.Apply_for_after_sales_service")
                  }}</el-button>
                  <el-dialog
                    :title="$t('orderlist.Apply_for_after_sales_service')"
                    style="text-align: left"
                    width="550px"
                    :visible.sync="dialogTableVisibleApplyReturnGoods"
                  >
                    <serve-return
                      @child-event="closeApplyReturnGoods"
                      :item="item"
                    ></serve-return>
                  </el-dialog>
                </div>
                <!-- 退/换货 -->
                <div v-if="item.state == 9">
                  <el-button type="text" @click="process(item)">{{
                    $t("orderlist.Schedule")
                  }}</el-button>
                  <el-dialog
                    :title="$t('orderlist.Schedule')"
                    style="text-align: left"
                    width="400px"
                    :visible.sync="dialogTableVisibleServiceOrder"
                  >
                    <time-line
                      :ServiceOrderInfos="ServiceOrderInfos"
                    ></time-line>
                  </el-dialog>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="tools_panel">
        <div>
          <!-- <el-checkbox v-model="checked">全选</el-checkbox> -->
          <!-- <el-button type="text" style="margin-left: 10px">编辑标签</el-button> -->
        </div>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  myOrderList,
  cancalOrder,
  rechangeRemark,
  myOrderSearch,
  addServeList,
  saveAddItem,
} from "@/Api/order";
import { orderDetail, orderTrack, orderServeReturn } from "@/Api/order";
import trackInfo from "@/components/others/trackInfo.vue";
import TimeLine from "@/components/others/timeLine.vue";
import ServeReturn from "@/components/others/serveReturn.vue";
import UserMessage from "@/components/user/UserMessage.vue";
import OrderDetail from "@/components/others/orderDetail.vue";

export default {
  components: { trackInfo, TimeLine, ServeReturn, UserMessage, OrderDetail },
  name: "person-orderList",
  data() {
    return {
      btnActive: 0,
      formInline: {
        user: "",
        region: "",
        time: "",
      },
      checked: false,
      multipleSelection: [],
      all_checked_value: false,
      searchValue: "",
      dialogVisible: [],
      recordRemark: "",
      cartlist: [],
      currentPage: 1,
      total: 0,
      pageSize: 10,
      currentState: -1,
      dialogVisibleAdd: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      currentItem: [],
      addServeListInfo: [],
      dialogTableVisibleOrderDetail: false, //商品详情
      orderInfo: {},
      orderTrackInfos: {},
      dialogTableVisibleOrderTrack: false, //物流跟踪
      dialogTableVisibleServiceOrder: false, // 退换货
      ServiceOrderInfos: {},
      dialogTableVisibleApplyReturnGoods: false, //申请退换货窗口
      currentItemMessage: null,
      currency: "",
    };
  },
  methods: {
    // 留言
    toMessage(item) {
      item.dialogTableVisibleUserMessage = true;
      this.currentItemMessage = item;
    },
    searchInfo() {
      var data = {};
      if (this.searchValue != "") {
        data["orderCode"] = this.searchValue;
      }
      var starttime = Date.parse(this.formInline.time[0]);
      var stoptime = Date.parse(this.formInline.time[1]);
      if (starttime > 0) {
        data["startTime"] = starttime;
      }
      if (stoptime > 0) {
        data["stopTime"] = stoptime;
      }
      data["state"] = this.currentState;

      myOrderSearch(data).then((res) => {
        this.cartlist = res.data.list;
        this.total = res.data.total;
      });

      console.log(data);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderList(this.currentPage);
    },
    handleCurrentChange(val) {
      this.getOrderList(val);
    },
    showDialog(index) {
      this.$set(this.dialogVisible, index, true);
    },
    cancel(index) {
      this.$set(this.dialogVisible, index, false);
    },
    saveRemark(index, item) {
      var data = {};
      data["id"] = item.id;
      data["goodsRemark"] = item.goods_remark;
      rechangeRemark(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
        }
        this.$set(this.dialogVisible, index, false);
        this.getOrderList(this.currentPage, this.currentState);
      });
    },
    getOrderList(val = 1, state = null) {
      this.currentPage = val;
      var data = {};
      data["pageNum"] = this.currentPage;
      data["pageSize"] = this.pageSize;
      if (state != null) {
        data["state"] = state;
      }
      myOrderList(data).then((res) => {
        this.cartlist = res.data.list;
        this.total = res.data.total;
      });
    },
    switchLi(index) {
      this.currentState = index;
      this.getOrderList(1, index);
    },
    computerPriceFee(sumPrice, postfee, addFee) {
      var money = parseFloat(sumPrice) + parseFloat(addFee);
      return this.moneyRate(money);
    },
    showAddDialog(item) {
      console.log(item);
      var keys = [];
      if (item.add_server != "") {
        const json = JSON.parse(item.add_server);
        for (let key in json) {
          keys.push(key);
        }
      }
      var keysYuan = [];
      this.addServeListInfo.forEach((item) => {
        if (keys.indexOf(item.serve_key) >= 0) {
          keysYuan.push(item.label);
        }
      });
      this.form.type = keysYuan;
      this.dialogVisibleAdd = true;
      this.currentItem = item;
    },
    saveDialogVisibleAdd() {
      var item = this.currentItem;
      var selectType = this.form.type;
      var selectId = [];
      this.addServeListInfo.forEach((item) => {
        if (selectType.indexOf(item.label) >= 0) {
          selectId.push(item.id);
        }
      });
      this.form.type = selectId;
      var data = {};
      data["orderId"] = item.id;
      data["addServerIds"] = [this.form.type];
      //修改增值服务  需要退款或者扣钱
      saveAddItem(data).then((res) => {
        if (res.code == 1) {
          this.getOrderList(this.currentPage, this.currentState);
        } else {
          this.$message.error(res.msg);
        }
      });
      this.dialogVisibleAdd = false;
    },
    cancelDialogVisibleAdd() {
      this.form.type = [];
      this.dialogVisibleAdd = false;
    },
    toPay(id) {
      this.$router.push({
        path: "/payorder",
        query: {
          orderId: id,
        },
      });
    },
    // 取消订单
    cancelItem(item) {
      this.$confirm(this.$t('orderlist.Are_you_sure_you_want_to_continue_deleting'), this.$t('orderlist.Tips'), {
        confirmButtonText: this.$t('orderlist.Sure'),
        cancelButtonText: this.$t('orderlist.Cancel'),
        type: "warning",
      })
        .then(() => {
          var data = {};
          data["oid"] = item.id;
          cancalOrder(data).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getOrderList(this.currentPage, this.currentState);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 订单详情
    orderDetailInfo(item) {
      var data = {};
      data["orderId"] = item.id;
      orderDetail(data).then((res) => {
        this.orderInfo = res.data;
        item.dialogTableVisibleOrderDetail = true;
      });
    },
    // 物流信息
    orderTrackInfo(item) {
      var data = {};
      data["oid"] = item.id;
      orderTrack(data).then((res) => {
        this.dialogTableVisibleOrderTrack = true;
        this.orderTrackInfos = res.data;
        // console.log(res)
      });
    },
    // 进度
    process(item) {
      var data = {};
      data["oid"] = item.id;
      // console.log(data)
      orderServeReturn(data).then((res) => {
        this.dialogTableVisibleServiceOrder = true;
        this.ServiceOrderInfos = res.data;
      });
    },
    // 申请退货
    applyReturnGoods(item) {
      console.log(item)
      this.dialogTableVisibleApplyReturnGoods = true;
    },
    // 子组件关闭父组件的对话框 申请退货
    closeApplyReturnGoods() {
      this.dialogTableVisibleApplyReturnGoods = false;
    },
  },
  created() {
    this.currency = this.getCurrency;
    this.getOrderList(1, -1);
    addServeList().then((res) => {
      this.addServeListInfo = res.data;
    });
  },
};
</script>

<style lang="less" scoped>
.add-transport {
  cursor: pointer;
  line-height: 60px;
  width: 990px;
  height: 60px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px dashed #d5d5d5;
  text-align: center;
}

.order-filter {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    .btn-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      min-width: 88px;
      text-align: center;
      cursor: pointer;
      height: 40px;
      padding: 0 10px;
      margin-right: 10px;
      font-size: 14px;
      color: #666;
      border: 1px solid #fff;
      border-radius: 4px;
    }
    .active {
      color: #ef0c25;
      border: 2px solid #ef0c25;
      background-color: #f7e5e6;
    }
  }
  .time-sort {
    text-align: right;
    line-height: 40px;
  }
}
.order-search {
  margin-top: 20px;
  .el-input-group--append .el-input__inner,
  .el-input-group__prepend {
    background-color: #f8f8f8 !important;
    border: none;
  }
}
.order-box {
  .el-row {
    .el-col {
      text-align: center !important;
    }
  }
  font-size: 12px;
  .order-nav {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
    font-weight: 600;
  }
  .order-list {
    .cart_item {
      .item_title {
        display: flex;
        margin-top: 20px;
        padding: 8px 0;
        color: #333;
        .order_id {
          font-weight: 700;
          margin: 0 20px 0 5px;
        }
        .create_time {
          margin-right: 20px;
        }
        .storage {
          font-weight: 600;
        }
      }
      .item_box {
        padding: 10px 3px;
        border: 1px solid #ddd;
        .item_child {
          display: flex;
          justify-content: center;
          align-items: center;
          .add-service {
            button.el-button.el-button--default:hover {
              color: #ef0c25;
              border-color: #ef0c25;
              background: #f7e5e6;
            }
          }
          .col {
            text-align: center;
          }
          .l_btn {
            .el-button.el-button--default:hover {
              border-color: #ef0c25 !important;
              background-color: #f7e5e6 !important;
            }
            .el-button.el-button--success {
              width: 180px;
              height: 30px;
              line-height: 6px;
            }
            button.el-button.el-button--text {
              width: 180px;
              height: 30px;
              line-height: 6px;
              color: #fff;
              border-color: #ef0c25;
              margin-top: 5px;
              background: linear-gradient(to right, #fd5532, #ef0c25);
            }
            button.el-button.el-button--text:hover {
              background: linear-gradient(to right, #e34d2d, #d60b20);
            }
          }
        }

        .goodInfo {
          display: flex;
          .img_box {
            flex-shrink: 0;
          }
          img {
            width: 80px;
            height: 80px;
            border-radius: 5px;
          }
          .information {
            padding-left: 5px;
            text-align: left;
            font-size: 12px;
            .el-button.el-button--default:hover {
              color: #ef0c25;
              border: 1px solid #ef0c25;
              background-color: #f7e5e6;
            }
            .el-button.el-button--primary {
              border: 1px solid #ef0c25;
              background: linear-gradient(to right, #fd5532, #ef0c25);
            }
            .el-button.el-button--primary:hover {
              background: linear-gradient(to right, #e34d2d, #d60b20);
            }

            .title {
              color: #333;
              margin-bottom: 5px;
            }
            .option {
              display: block;
              color: #9c9ea6;
            }
          }
        }
      }
    }
  }
  .tools_panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    margin-top: 20px;
    border: 1px solid #ddd;
  }
}
label:hover {
  color: red;
}
</style>
