<template>
  <div>
    <!-- <div class="header">
      <div>
        我的等级VIP
        {{ userInfo.level }}
        <el-button type="danger" @click="upgrade()">升级</el-button>
      </div>
    </div> -->
    <ul class="header">
      <li class="top-item">
        <p class="label">{{ $t('points.My_Points') }}:</p>
        <p>
          <span class="points"> {{ userInfo.score }}</span>
        </p>
        <p class="quota">{{ $t('points.Redeem_points_this_month') }}:{{ userInfo.score }} / {{ userInfo.grow_value_required }}</p>
      </li>
      <!-- grow_value_required -->
      <li class="top-item">
        <p class="label">{{ $t('points.My_VIP') }}:</p>
        <p>
          <span class="points"> {{ userInfo.level }}</span>
        </p>
        <p class="quota">
         {{ $t('points.Required_to_reach_next_level') }}  {{ userInfo.grow_value_required-userInfo.score>0?userInfo.grow_value_required-userInfo.score:0 }} {{ $t('points.points') }}
        
<!--          <a-->
<!--            href="javascript:;"-->
<!--            style="color: #fff; font-weight: 600; border-bottom: 1px solid"-->
<!--            @click="upgrade()"-->
<!--            >{{ $t('points.Go_to_upgrade') }}</a-->
<!--          >-->
        </p>
      </li>
      <li class="top-item">
        <el-button type="" class="black" round @click="toCoupon"
          >{{ $t('points.View_coupon') }}</el-button
        >
      </li>
    </ul>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="create_time"
          :label="$t('points.Date')"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="memo" :label="$t('points.reason')" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="before"
          :label="$t('points.Points_before_change')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="after"
          :label="$t('points.Points_after_change')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="after"
          :label="$t('points.change_value')"
          align="center"
        >
        <template slot-scope="scope">
            {{ parseFloat(scope.row.after)-parseFloat(scope.row.before) }}
          </template>
      </el-table-column>
      </el-table>
      <div class="pageNo">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/Api/user";
import { scoreRecord, upgradeScore } from "@/Api/record";

export default {
  name: "points-vue",
  data() {
    return {
      userInfo: "",
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    getUser() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    handleSizeChange(val) {
      console.log(val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getScoreList();
    },
    getScoreList(data = {}) {
      data["page"] = this.currentPage;
      scoreRecord().then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    upgrade() {
      upgradeScore().then((res) => {
        if (res.code == 1) {
          location.reload();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toCoupon() {
      this.$router.push({
        path: "/person/couponlist",
      });
    },
  },
  created() {
    this.getUser();
    this.getScoreList();
  },
};
</script>

<style scoped lang="less">
.header {
  height: 140px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  background: linear-gradient(to right, #fd5532, #ef0c25);
  .label {
    font-size: 14px;
  }
  .quota {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f2f2f2;
    line-height: 17px;
  }
  .points {
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
  }
  .black {
    border-radius: 20px;
    padding: 12px 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 400;
    color: #000;
    font-size: 12px;
    border: none;
    min-width: 90px;
  }
  .black:hover {
    color: #fff;
    background: linear-gradient(to right, #e34d2d, #d60b20);
  }
}
.pageNo {
  text-align: right;
}
.table {
  margin-top: 20px;
}
</style>
