export default {
  middle: {
    Shop_China_with_Seacloudbuy: "Compre China via seacloudbuy",
    Please_enter_keywords_or_product_links: " Insira palavras-chave ou links de produtos",
    goods: "mercadoria",
    Choose: "Escolher",
    Shop: "Comprar",
    Buyer_purchase_order: "Comprador faz pedido",
    Merchants_receive_orders: "Comerciantes recebem pedidos",
    Verify_parcel: " Verifique o pacote",
    Transaction_complete: "Transação concluída",
    Search: "Procurar",
  },
  describe: {
    One_stop_service: "Serviço completo",
    One_click_order: "Pedido conveniente com um clique",
    Ship_to_warehouse: "Navios mercantes para armazém",
    Quality_inspection: "Inspeção de qualidade de aceitação e pesagem",
    Global_Freight: "Logística de transporte internacional",
  },
  goodsdetail: {
    Find_similar: "Encontre semelhanças",
    Promote_and_share: "Promova e compartilhe",
    Favorite_Product: "Produto favorito",
    Sales_volume: "Volume de vendas",
    Starting_purchase_of: "commodity ",
    items: "Compra à peça!",
    Service_Terms: "Termos do Serviço",
    Enter_the_store: "entrar em uma loja",
    Force_refresh: "Forçar atualização",
    modification: "Rever",
    "After_payment_the_buyer_will_provide_purchasing_services_for_you_from_09:00-18:00_(UTC+8)": "09:00-18:00(+8) Nach der Zahlung erbringt der Käufer von 09:00-18:00(UTC+8) die Einkaufsdienstleistungen für Sie.",
    Source_of_this_product: "Fonte deste produto",
    SeaCloud_can_entrust_buyers_to_purchase_for_you: "Você pode confiar que os compradores comprem para você",
    Merchant: "Comerciante",
    SeaCloud_Certified_warehouse_shipping_costs: "Armazém certificado, frete",
    SeaCloud_certified_warehouse: "Armazém certificado",
    your_address: "seu endereço",
    Estimate_international_shipping_costs: "Estime os custos de envio internacional",
    Please_select_properties: "Selecione um atributo",
    leave_a_message: "mensagem",
    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them: "Anote os produtos adquiridos. Se não houver requisitos especiais de compra, não será necessário anotá-los.",
    The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default: "Os produtos exibidos são todos de plataformas de terceiros, que fornecem apenas serviços de busca de informações, compra e envio.  É impossível identificar a qualidade e autenticidade da mercadoria. Se utilizar o serviço de compras, aceita por defeito os riscos acima referidos.",
    agreed: "Concordo",
    Buy_now: "Compre agora",
    Add_to_Cart: "adicionar ao carrinho",
    embargo: "embargo",
    Tobacco_alcohol_and_contraband_are_not_accepted: "·Tabaco, álcool e contrabando não serão transportados",
    Prohibited_items_include_but_are_not_limited_to_the_following_items_Swiss_Army_knives_a_full_set_of_kitchen_knives_kitchen_knives_paper_cutters_letter_openers_gardening_knives_scissors_saws_seam_ripper_seam_ripper_pure_fruit_and_vegetable_juices_inflatable_lighters_wine_putty_knife_cheese_Western_cheese_hairy_crab_bamboo_sword_wooden_knife_military_knife_card_universal_knife_card_skate_shoes_other_outdoor_knives_magnetic_components_Swiss_army_knife_end_mill_carving_knife_cigarette_label_cigarette_Card_vajra_bell_vajra_pestle_yue_knife_seeds_etc: "Os itens proibidos incluem, mas não estão limitados a, os seguintes itens: canivetes suíços, conjuntos completos de facas de cozinha, facas de cozinha, cortadores de papel/abridores de cartas, tesouras/tesouras de jardinagem, abridores de costura/abridores de costura, sucos puros de frutas e vegetais, infláveis isqueiros, facas de massa de vinho, queijo/queijo estilo ocidental caranguejo peludo espada de bambu/faca de madeira cartão de sabre/cartão de faca universal patins sapatos outras facas ao ar livre componentes magnéticos faca do exército suíço end mill faca de escultura etiqueta de cigarro/cartão de cigarro sino de diamante/pilão vajra/ faca yue, sementes, etc.",
    Purchase_limit: "Limite de compra",
    Medicines_liquids_paste_goods_and_cosmetic_powders_are_restricted_export_goods_by_China_Customs_Please_ask_before_ordering: "Medicamentos, líquidos, produtos pastosos e pós cosméticos são produtos de exportação restritos pela Alfândega da China.  Por favor, pergunte antes de fazer o pedido",
    customer_service: "【atendimento ao Cliente】",
    Kind_tips: "Dicas gentis",
    About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes_restricted_items_logistics_route_restrictions_and_volume_and_weight_restrictions: " [Sobre logística alfandegária] Devido às diferenças nas políticas alfandegárias entre os países, alguns países ou regiões são mais sensíveis a mercadorias especiais, podendo haver embargos, itens restritos, restrições de rotas logísticas e restrições de volume e peso.",
    The_goods_you_purchase_will_be_sent_to_the_SeaCloud_warehouse_After_arriving_at_the_warehouse_we_will_inspect_the_goods_for_you_and_take_photos_of_the_goods: " As mercadorias que você compra serão enviadas para o armazém. Depois de chegar ao armazém, iremos inspecionar as mercadorias para você e tirar fotos das mercadorias.",
    You_can_view_and_manage_products_in_My_Warehouse: "Você pode visualizar e gerenciar produtos em [Meu Armazém]",
    If_you_are_not_satisfied_with_the_goods_arriving_at_the_SeaCloud_warehouse_you_can_apply_for_a_return_or_exchange_within_5_days_of_arrival_at_the_warehouse_The_purchaser_will_negotiate_with_the_merchant_on_your_behalf_to_handle_the_after_sales_service: "Se você não estiver satisfeito com a chegada da mercadoria ao armazém, poderá solicitar a devolução ou troca no prazo de 5 dias após a chegada ao armazém. O comprador negociará com o comerciante em seu nome para cuidar do serviço pós-venda.",
    No_store_information_found: "Nenhuma informação da loja encontrada",
    Please_read_and_agree_before_proceeding: "Por favor, leia e concorde antes de prosseguir.",
    Please_select_specification_properties: "Selecione os atributos de especificação",
    Share_invite_code: "Compartilhar código de convite",
    copy_Link: "link de cópia",
    qty: "quantidade",
    stock: "em estoque",
    Description_text: "Texto de descrição",
    Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them_down: " Anote os produtos adquiridos. Se não houver requisitos especiais de compra, não será necessário anotá-los.",
    Purchasing_instructions: "Instruções para agentes de compras",
    product_details: "Detalhes do produto",
    After_sales_service: "Serviço pós-venda",
    loading: "carregando...",
    Copy_successful: "Erfolgreich kopiert",
    Tips: "Dica",
    Sure: "Claro",
    Cancel: "Cancelar",
  },
  bottom: {
    Massive_merchandise: "Produtos enormes",
    Commodity_purchase: "Compra de commodities",
    Cost_savings: "Consolidação de Frete",
    If_you_purchase_goods_from_different_merchants_we_will_help_you_consolidate_them_into_one_package_and_send_them_to_your_country: "Se você comprar produtos de diferentes comerciantes, nós o ajudaremos a consolidá-los em um pacote e enviá-los para o seu país.",
    service_quality: "Inspeção de qualidade",
    Provide_efficient_mature_and_stable_service_support: "Fornece suporte de serviço eficiente, maduro e estável",
    Quick_response: "Qualidade de serviço",
    Help_you_solve_your_difficulties_in_the_shortest_time: "  Ajudá-lo a resolver suas dificuldades no menor tempo possível",
    customer_service: "atendimento ao Cliente",
    Help_Center: "Centro de ajuda",
    contact_us: "Contate-nos",
    Purchasing_demo: "Demonstração de compra",
    Services_and_fees: "Serviços e taxas",
    Source_of_this_product: "Ajudaremos você a comprar produtos de todos os canais on -line e offline na China.",
    Return_and_exchange_service: "Serviço de devolução e troca",
    payment_methods: "Forma de pagamento",
    Recharge_method: "Método de recarga",
    Paypal_payment: "Paypalpagar",
    International_Credit_Card_Payment: "Pagamento com cartão de crédito internacional",
    Delivery_related: "Relacionado à entrega",
    Logistics_shipping_fee: "Frete Logístico",
    Centralized_packaging: "Embalagem centralizada",
    Customs_and_taxes: "Alfândegas e impostos",
    Precautions_for_receiving_goods: "Precauções para receber mercadorias",
    After_sales_service: "Serviço pós-venda",
    Product_storage_period: "Período de armazenamento do produto",
    Inspection_rules: "Regras de inspeção",
    Insurance_and_indemnity: " Seguro e indenização",
    YuICP_No_2024000320: "2024000320 Preparação nº 2024000320",
    About_Seacloud: " sobre Seacloud",
    Terms_of_Service_and_User_Management: "Termos de serviço e gerenciamento de usuários",
    Privacy_Notice: "Declaração de privacidade",
    // contact_us:"Contate-nos",
    Company_name_SEACLOUD_CN_TECHNOLOGY_CO_LIMITED_Company_registration_number_24052668: " Nome da empresa: (SEACLOUD).Número de registro da empresa: 24052668",
    Office_address_Flat_108_26_Indescon_Square_CHINA_E16_8DG_: "Endereço do escritório:Flat 108 26 Indescon Square, London, CN, E16 8DG",

  },
  index: {
    Front_page: " primeira página",
    Shopping_cart: "carrinho de compras",
    My_Order: "Meu pedido",
    My_warehouse: "meu armazém",
    Preview_package: "pacote de visualização",
    My_package: "Meu pacote",
    Transaction_Record: "Registro de transação",
    My_Points: "Minhas pontuações",
    Coupons: "cupom",
    Account_setup: "Configurações de Conta",
    Trading_log: "Registro de transação",
    Promoter_Programm:"Programa Promotor"
  },
  account: {
    personal_data: "informações pessoais",
    my_privilege: "meu privilégio",
    Level_authority: "privilégios de classe",
    International_Shipping_Discount: "Descontos em frete internacional",
    Insurance_Discount: "Valor do desconto do seguro",
    Value_added_service_discounts: "Descontos em serviços de valor agregado",
    Free_Aftersale_Operation_Fee: "Sem taxas pós-venda",
    Used_Available_Month: "Uso/disponível (Meses)",
    my_level: "meu nível",
    growth_value: "valor de crescimento",
    Shipping_address: "Endereço para envio",
    Add_shipping_address: "Adicionar endereço de entrega",
    you_have_created: "você criou",
    Work: "Trabalhar ",
    Address: "Endereço",
    Operate: "operar",
    Edit: "editar",
    Delete: "excluir",
    Set_as_default: "definir como endereço padrão",
    Default_address: "endereço padrão",
    You_have_not_added_a_delivery_address_please_add_a_delivery_address: "Você não adicionou um endereço de entrega, adicione um endereço de entrega",
    New_shipping_address: "Novo endereço de entrega",
    Consignee: "destinatário",
    name: "nome",
    Region: "área",
    country: "nação",
    State_Province: "Estado/Província",
    City: "Cidade",
    Details_address: "Endereço",
    Zip_code: "código postal",
    Phone_number: "Número de telefone",
    Label: "Rótulo",
    Home: "Lar",
    // Work:"Trabalhar",
    Default_URL: "endereço padrão",
    Cancel: "Cancelar",
    Sure: "Claro",
    Account_safety: "Segurança da conta",
    Credit_card_billing_address: "Endereço de cobrança do cartão de crédito",
    name_cannot_be_blank: "o nome não pode ficar vazio",
    Country_cannot_be_null: "O país não pode ficar vazio",
    State_Province_cannot_be_blank: "A província não pode ficar vazia",
    City_cannot_be_empty: "A cidade não pode ficar vazia",
    Please_select_at_least_one_activity_type: "Selecione pelo menos um tipo de atividade",
    Zip_code_cannot_be_empty: "O CEP não pode ficar vazio",
    Address_cannot_be_empty: "O endereço não pode ficar vazio",
    Phone_number_cannot_be_empty: "O número do celular não pode ficar vazio",
    Added_address_successfully: "Endereço adicionado com sucesso",
    Delete_successful: "excluído com sucesso",
    Setting_successful: "Configuração bem sucedida",
    Public_Member: "Público",
    Silver_Member: " Prata",
    Gold_Member: "Ouro",
    Platinum_Member: "Platina",
    Diamond_Member: "Diamante",
    Black_Gold_Member: "Ouro Negro",
    Black_Gold_PLUS: "Ouro negro MAIS",
  },
  headertop: {
    Please_enter_keywords_or_product_links: "Insira palavras-chave ou links de produtos",
    Please_select: "por favor escolha",
    goods: "mercadoria",
    Shop: "comprar",
    More_features: "Mais recursos",
    Freight_estimate: "Estimativa de envio",
    Language_Currency: "Idioma/Moeda",
    language: "linguagem",
    Currency: "Moeda",
    Saving: "salvar",
    Log_in: "Conecte-se",
    register: "registro",
    Personal_Center: "Centro pessoal",
    Shopping_cart: "carrinho de compras",
    My_warehouse: "Meu armazém",
    My_Order: "Meu pedido",
    My_package: "Meu pacote",
    Trading_log: "Registro de transação",
    Sign_out: "sair",
    Categories: "Categorias",
    Commodity: "mercadoria",
    Ladies: "Roupas Femininas",
    bag: "Bolsa",
    Men: "Masculino",
    Jewelry: "acessórios de jóias",
    shoe: "sapato",
    Sankeng_Clothing: "Roupas Sankeng",
    work_out: "dar certo",
    trousers: "calça",
    skirt_suit: "terno de saia",
    Jacket: "Jaqueta",
    coat: "casaco",
    travel_backpack: "mochila de viagem",
    fashion_handbags: "bolsas de moda",
    Travel_series: "Série de viagens",
    small_bag: "bolsa pequena",
    Bags_accessories: "Bolsas, acessórios",
    // coat:"casaco",
    // Jacket:"Jaqueta", 
    // trousers:"calça", 
    // work_out:"dar certo ", 
    // shoe:"sapato",
    jewelry: "joia",
    belt: "cinto",
    Glasses: "Copos ",
    hat: "chapéu",
    scarf: "lenço ",
    Accessories: "Acessórios",
    hair_accessories: "acessórios de cabelo",
  },
  goodslist: {
    We_have_searched_for: "Procurei por você",
    related_content_for_you_come_and_buy_it: "Conteúdo relacionado, venha e compre o quanto quiser",
    Agreed: "aprovado",
    Search_Service_Terms: "《Pesquisar Termos de Serviço》",
    micro: "Weidian",
    Taobao: "Taobao",
    loading: "carregando... ",
    Sold: "vendido",
  },
  shopgoods: {
    loading: " carregando...  ",
  },
  goodsrecommend: {
    Everyone_is_looking: "Produto mais vendido",
  },
  center: {
    My_Balance: "meu saldo",
    Recharge: "completar",
    Withdraw: "retirar",
    Points: " integrante",
    Service_Fee:"Service Fee",
    Rebate_ratio:"Rebate ratio",
    service_fee_desc:"Custos trabalhistas para a compra de bens de terceiros e acompanhamento de pedidos",
    My_Coupons: "Meu cupom de desconto",
    My_growth_value: "Meu valor de crescimento",
    Trade_name: "Nome do Produto",
    Submit_time: "Hora de envio",
    Order_Status: "Status do pedido",
    operate: "operar",
    pay: "Pagar",
    order_details: "detalhes do pedido",
    Confirm_Order: "Confirmar pedido",
    View_Logistics: "verificar logística",
    Logistics_information: "Informações logísticas",
    Submit_package: "Enviar pacote",
    message: "mensagem",
    Please_enter_the_withdrawal_amount: "Por favor insira o valor do saque (CNY)",
    Tips: "Dica",
    Sure: "Claro",
    Cancel: "Cancelar",
    Amount_cannot_be: "O valor não pode ser 0!",
    Amount_must_be_greater_than: "O valor deve ser maior que 0",
    The_withdrawal_amount_cannot_be_greater_than_the_wallet_limit: "O valor do saque não pode ser maior que o limite da carteira",
    Please_enter_amount: "Por favor insira o valor",
    The_amount_you_input_is: " O valor que você digitou é:",
    invite: "convite",
    Copy_successful: "Erfolgreich kopiert",
    Invite_friends_to_register: "Convide amigos para se registrar",
    copy_Link: "link de cópia",
  },
  cart: {
    Commodity_information: "Informação do produto",
    Note: "Observação",
    Price: "preço unitário",
    qty: "quantidade",
    Amount: "Quantia",
    Operate: "operar",
    Product_remarks: " Informações sobre observações do produto",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: " Ao deixar uma mensagem de compra, você não precisa escrevê-la se não houver requisitos especiais de compra.",
    Sure: "Claro",
    Cancel: "Cancelar",
    Delete: "excluir",
    Delete_selected_items: "Excluir produtos selecionados",
    Choose_all: "selecionar tudo",
    selected: " selecionado",
    pieces_excluding_freight: "peças (excluindo frete)",
    Estimated_freight: "Custos estimados de envio internacional",
    pay: "Pagar",
    Your_shopping_cart_is_empty_go_treat_yourself: "Seu carrinho de compras está vazio, vá se deliciar!  !",
    Are_you_sure_to_delete_the_item: "Tem certeza de que deseja excluir o produto?",
    Tips: "Dica",
    // Sure:"Claro",
    // Cancel:"Cancelar",
    Are_you_sure_you_want_to_delete_the_items_selected_in_your_shopping_cart: "Tem certeza de que deseja excluir os itens selecionados no seu carrinho de compras?",
    Delete_successful: "Excluído com sucesso!",
    Please_select_an_item: "Selecione um produto!",

  },
  cartlnfo: {
    Confirm_Order: "Confirmar pedido",
    Merchant: "Comerciante",
    Select_shipping_country: "Selecione o país de envio",
    Seacloud_warehouse: "Seacloud armazém",
    Please_fill_in_the_country_to_be_shipped_to: "Por favor preencha o país para onde será enviado",
    Seacloud_buyers_will_determine_whether_your_product_can_be_sent_based_on_the_country_you_fill_in: "O comprador determinará se o seu produto pode ser enviado com base no país que você preencher.",
    Confirm_product_info: "Confirme as informações do produto",
    product_detail: "Detalhes do produto",
    Note: "Observação",
    Price: "preço unitário",
    qty: "quantidade",
    additional_services: "serviços adicionais",
    Subtotal: "Subtotal",
    Note_information: "Observações",
    Product_remarks: "Informações sobre observações do produto",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "Ao deixar uma mensagem de compra, você não precisa escrevê-la se não houver requisitos especiais de compra.",
    Sure: "Claro",
    Cancel: "Cancelar",
    Additional_services: "Serviços extras",
    Total_price_of_goods: "Preço total do produto",
    Shipping_fee_to_warehouse: "Taxa de envio para armazém",
    Total_stores: "Total de lojas",
    Total_price_to_be_paid_international_shipping_charges_are_extra: "Preço total a pagar (custos de envio internacional são extras)",
    Prohibited_Goods_Statement: "《Declaração de Produto Proibido》",
    Terms_of_Service_and_User_Management: "《Termos de Serviço e Gerenciamento de Usuários》",
    Return_and_Exchange_Service: "《Serviço de Devolução e Troca》",
    Disclaimer: "《Isenção de responsabilidade》",
    I_have_read_and_agree: "eu li e concordo",
    Submit_Order: "Enviar pedido",
    Kind_tips_After_the_order_is_submitted_and_the_payment_is_successful_please_wait_patiently_for_the_goods_to_be_put_into_storage_The_goods_can_be_submitted_for_shipping_after_being_put_into_storage: "Lembrete caloroso: Depois que o pedido for enviado e o pagamento for bem-sucedido, aguarde pacientemente que as mercadorias sejam armazenadas.  As mercadorias podem ser enviadas para envio após serem armazenadas.",
    Please_agree_to_the_terms: "Por favor, concorde com os termos!",
    Please_choose_a_country: "Por favor, selecione um País: Brasil!",
  },
  payorder: {
    Checkout_counter: " caixa de pagamento",
    Orders_submitted_successfully: "Pedidos enviados com sucesso!",
    Balance: "equilíbrio",
    Your_balance_is_insufficient_please_top_up_before_making_payment: " Seu saldo é insuficiente, recarregue antes de efetuar o pagamento",
    Recharge: "completar",
    Total_price: "Preço total:",
    Payables: "Contas a pagar",
    Handling_fee: "Taxa de manuseio:",
    Confirm_payment: "confirme o pagamento",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: " Serão cobradas taxas de conversão e manuseio de moeda ao pagar em moedas que não sejam dólares americanos. ",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: " Cartões de débito e crédito em renminbi da China de bancos parceiros locais",
    Please_enter_amount: "Por favor insira o valor",
    Tips: "Dica",
    Sure: "Claro",
    Cancel: "Cancelar",
    Amount_cannot_be: "O valor não pode ser 0!",
    Amount_must_be_greater_than: "O valor deve ser maior que 0",
    The_amount_you_input_is: "O valor que você digitou é:",
    Please_select_mode_of_payment: "Selecione a forma de pagamento!",
  },
  orderlist: {
    All: "Todos",
    Pending_payment: "Pagamento Pendente",
    Paid: "Pago",
    Ordering: "Encomenda",
    Return_Exchange: "Devolução/Troca",
    Start_date: "Data de início",
    End_date: "Data final",
    Search: "Procurar",
    product_detail: "Detalhes do produto",
    Price: "preço unitário",
    qty: "quantidade",
    Order_Status: "Status do pedido",
    Value_added_services: "Serviços de valor acrescentado",
    Order_total: "Total de pedidos",
    Other_operations: "Outras operações",
    Order_number: "Número do pedido:",
    Creation_time: "Tempo de criação:",
    Warehouse_Seacloud_Warehouse: "armazém:Seacloud armazém",
    Add_notes: "Adicionar notas",
    Product_remarks: "Informações sobre observações do produto",
    Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: " Ao deixar uma mensagem de compra, você não precisa escrevê-la se não houver requisitos especiais de compra.",
    Sure: "Claro",
    Cancel: "Cancelar",
    Product_shipping_cost: "Taxa de envio do produto:",
    Value_added_service_fee: ", taxa de serviço de valor agregado",
    Pay_order: "Ordem de pagamento",
    order_details: "Detalhes do pedido",
    message: "mensagem",
    Cancel_order: "cancelar pedido",
    logistics: "logística",
    Logistics_information: "Informações logísticas",
    Apply_for_after_sales_service: "Inscreva-se para serviço pós-venda",
    Schedule: "agendar",
    Are_you_sure_you_want_to_continue_deleting: "Você tem certeza que quer cancelar seu pedido?",
    Tips: "Dica",

  },
  deliverydetail: {
    First_weight: "alta prioridade",
    First_weight_price: "Primeiro preço",
    Additional_weight: "Renovar peso",
    Additional_weight_price: "Preço de peso de renovação",
    Size_limit: "limite de tamanho",
    Sensitive_items_that_can_be_mailed: "Itens sensíveis podem ser enviados pelo correio",
    Fuel_fee: "Custos de combustível",
    Operation_fee: "taxa operacional",
    Service_fee: "taxa de serviço",
  },
  orderdetail: {
    Title: "título:",
    Product_Notes: "Observações sobre o produto:",
    Order_Number: "Número do pedido:",
    Specifications: "Atributos de especificação:",
    Seller_Name: "Nome do Vendedor:",
    Price: "preço:CNY",
    qty: "quantidade:",
    Order_Time: "hora do pedido:",
    Payment_Time: "Prazo de pagamento:",
    Weight: "peso",
    Measure_size: "Meça o tamanho",
    Remove_packaging: "Remover embalagem",
    Take_photos: "Tirar fotos",
    Priority_purchase: "Compra prioritária",
    Add_air_column_pack: "Adicione pacote de coluna de ar",
    Add_corner_protection: "Adicionar proteção de canto de caixa",
    Shoe_support: "Suporte de sapato",
  },
  servereturn: {
    Application_Type: "tipo de aplicação",
    Please_select_application_type: "Selecione o tipo de aplicação",
    Request_return: "Pedido de devolução",
    Request_exchange: "Solicitar troca",
    Request_reason: "Motivo da aplicação",
    Submit: "Enviar",
    Cancel: "Cancelar",
  },
  trackInfo: {
    Logistics_Tracking: "Acompanhamento logístico",
  },
  userrecharge: {
    Checkout_counter: "caixa de pagamento",
    Total_price: "Preço total:",
    Payables: "Contas a pagar",
    Handling_fee: "Taxa de manuseio:",
    Confirm_payment: "confirme o pagamento",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: " Serão cobradas taxas de conversão e manuseio de moeda ao pagar em moedas que não sejam dólares americanos. ",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: "Cartões de débito e crédito em renminbi da China de bancos parceiros locais",
    Please_select_mode_of_payment: "Selecione a forma de pagamento!",
    Please_keep_the_actual_shipping_address_of_the_product_consistent_with_the_PayPal_delivery_address_otherwise_it_will_cause_customs_clearance_failure_and_affect_the_timeliness: "Por favor, mantenha o endereço de entrega real do produto consistente com o endereço de entrega do PayPal, caso contrário, isso causará falha no desembaraço aduaneiro e afetará a pontualidade.",
    Cooperation_with_the_People_s_Bank_of_China:"Cooperação com o Banco Popular da China"
  },

  security: {
    Username: "nome de usuário:",
    Email: "E-mail:",
    Security_level: "Nível de segurança:",
    After_encryption_your_security_level_is: "Após a criptografia, seu nível de segurança é",
    Medium: "meio",
    Last_login: "Login recente:",
    Logout: "Sair",
    Change_login_password: "Alterar senha de login",
    A_highly_secure_password_can_make_your_account_more_secure_It_is_recommended_that_you_change_your_password_regularly_and_set_a_password_that_contains_numbers_and_letters_and_is_more_than_6_characters_long: "Uma senha de alta segurança pode tornar sua conta mais segura.  É recomendável que você altere sua senha regularmente e defina uma senha que contenha números e letras e tenha mais de 6 caracteres.",
    Change_password: "alterar a senha",
    Security_privacy_issues: "Problemas de segurança/privacidade",
    Poor_customer_service: "Péssimo atendimento ao cliente",
    Too_high_a_fee: "O custo é muito alto",
    Complicated_shopping_process: "Processo de compra complexo",
    Other_reasons: "outras razões",
    Please_enter_content: "Por favor insira o conteúdo",
    Cancel: "Cancelar",
    Confirm: "Claro",
    Change_email: "Modificar e-mail",
    For_the_security_of_your_account_you_need_to_first_pass_the_email_verification_code_login_password_security_verification: " Para a segurança da sua conta, você precisa primeiro realizar a verificação de segurança por meio do código de verificação de e-mail + senha de login.",
    Email_verification_code: "Código de verificação de e-mail",
    Click_to_get_the_verification_code: "Clique para obter o código de autenticação",
    Login_password: "senha de login",
    Next_step: "Próxima Etapa",
    New_password: "Nova Senha",
    Repeat_password: "Repita a senha",
    Verification_code_has_been_sent: "Código de verificação enviado",
    Payment_password: "Senha de pagamento",
    Submit: "Enviar",
    Please_enter_your_password_again: "Por favor insira a senha novamente",
    The_two_passwords_are_inconsistent: "A senha digitada duas vezes é inconsistente",
    Please_enter_the_verification_code: "insira o código de verificação",
    Please_enter_your_login_password: "Por favor, insira sua senha",
    Please_enter_a_new_login_password: "Por favor insira a nova senha de login",
    Please_enter_a_new_payment_password: "Por favor insira a nova senha de pagamento",
    Please_enter_your_payment_password_again: "Por favor insira a senha de pagamento novamente",
    Please_enter_the_verification_code_of_your_email: "Por favor, insira o código de verificação do seu endereço de e-mail 2813910143@.",
    Please_enter_your_password: "Por favor insira a senha",
    Successfully_changed_your_login_password: "Senha de login alterada com sucesso",
    Form_verification_failed: "Falha na validação do formulário",
    Please_enter_your_new_password: "Por favor insira uma nova senha",
    _6_to_15_characters_long: "6 a 15 caracteres de comprimento",
  },
  userInfo: {
    Edit: "Editar",
    Username: "nome de usuário:",
    Balance: "equilíbrio:",
    Phone: "Email:",
    Gender: "gênero:",
    None: "nenhum",
    Male: " macho",
    Female: "fêmea",
    Language: "linguagem:",
    Registration_time: "Hora do registro:",
    Growth_Value: "valor de crescimento",
    View_Growth_Value_Record: "Ver registros de valor de crescimento",
    score: "pontuação",
    Only_3000_growth_points_are_needed_to_reach_V1: " Apenas 3.000 pontos de crescimento são necessários para alcançar V1",
    Distance: "distância",
    Only_requires: "só precisa de",
    Sure: "Claro",
    Cancel: "Cancelar",
    Upload_avatar: "Carregar avatar",

  },
  warehouse: {
    Order_ID_Product_Name: "Número do pedido/nome do produto",
    Start_Date: "data de início",
    End_Date: "data final",
    Search: "procurar",
    Image: "foto",
    Product_Details: "Detalhes do produto",
    Price: "preço",
    Shipping: "frete",
    Weight: "peso",
    Order_Status: " Status do pedido",
    Days: "dias",
    Action: "operar",
    Details: "Detalhes",
    Leave_us_a_message: "Deixe-nos uma mensagem",
    Select_All: "selecionar tudo",
    Shipping_Country: "País de envio",
    Actual_Weight: "peso líquido",
    Selected_Products: "Produto selecionado",
    Pieces: "peças",
    Preview_Packaging: "Embalagem pré-show",
    Submit_Shipping: "Enviar para envio",
    Order_Details: "detalhes do pedido",
    Not_an_Array: "não é uma matriz",
    Please_select_a_country: " Por favor, selecione um País: Brasil!",
    Please_select_an_order_to_submit: "Selecione o pedido a ser enviado!",
    Seacloud_warehouse_To: "Seacloud armazém To",
    Available: "disponível",
  },
  invoice: {
    Tip_Depending_on_the_customs_policy_of_the_destination_country_and_your_experience_filling_in_a_customs_invoice_can_reduce_or_avoid_customs_fees: " Dica: Dependendo da política alfandegária do país de destino e da sua experiência, o preenchimento de uma fatura alfandegária pode reduzir ou evitar taxas alfandegárias.",
    Add: "Adicionar à",
    Import_invoice: " Importar faturas",
    Warehouse_ID: "Armazém ID",
    Product_name: "Nome do Produto",
    Please_enter_a_title: "Por favor insira um título",
    qty: "quantidade",
    Please_enter_the_qty: "Por favor insira a quantidade",
    Price: "preço(USD)",
    Please_enter_the_value: "Por favor insira o valor",
    Operation: "Operar",
    Delete: "Excluir",
    Shipping_address: "Endereço para envio  /",
    Please_select_a_shipping_address: "Por favor selecione o endereço de entrega",
    Logistics_route: "Rota logística /",
    Please_select_a_logistics_route: "Selecione uma rota logística",
    Waybill_value_added_service_: "Serviços de valor agregado de conhecimento de embarque /",
    Waybill_value_added_service: "Serviços de valor agregado de conhecimento de embarque cupom",
    Coupon: "cupom  /",
    Whether_to_purchase_insurance: "Seja para comprar seguro  / ",
    insure: "insure",
    The_insurance_fee_is_3_of_the_total_price_of_the_goods: "A taxa de seguro é de 3% do preço total do produto",
    Rehearsal_fee: "Taxa de ensaio",
    rehearsal: "rehearsal",
    You_can_pay_the_rehearsal_fee_in_advance_to_ensure_that_the_package_is_packed_in_time_thereby_avoiding_additional_payments_and_making_the_delivery_process_faster_and_more_efficient_allowing_you_to_receive_the_package_faster: "Uma taxa pré-show pode ser paga antecipadamente para garantir que seu pacote seja embalado prontamente, evitando pagamentos adicionais e tornando o processo de entrega mais rápido e eficiente para que você receba seu pacote mais cedo",
    Submit_package: "Enviar pacote",
    Estimated_total_cost: "Custo total estimado/ ",
    Total: "Total",
    pay: "Pagar",
    Please_fill_in_the_invoice: " Por favor preencha a fatura",
    Please_choose_whether_to_purchase_insurance: "Por favor, escolha se deseja comprar um seguro!",
    Estimated_cost: ",Custo estimado",
    First_weight: ",alta prioridade",
    Fee: "g,custo",
    Additional_weight: ",Renovar peso",
    Please_return_to_the_previous_step_to_select_the_warehouse_order_information: "Volte à etapa anterior para selecionar as informações do pedido de armazém!",
    Please_return_to_the_previous_step_to_select_the_country_information: "Volte à etapa anterior para selecionar as informações do país!",
    Waybill_full: " Conhecimento de transporte completo",
    discount: "reduzir:",
    Yuan: "Yuan",
    Waybill_discount: "Desconto na carta de porte",
    Discount: "dobrar",
    You_have_available_coupons: "Você tem cupons disponíveis",
    No_coupons_available_yet: "No momento não há cupons",
    Create_address: "Criar endereço",
  },
  paysend: {
    Checkout_counter: " caixa de pagamento",
    Orders_submitted_successfully: "Pedidos enviados com sucesso!",
    Balance: "equilíbrio",
    Your_balance_is_insufficient_please_top_up_before_making_payment: " Seu saldo é insuficiente, recarregue antes de efetuar o pagamento",
    Recharge: "completar",
    Total_price: "Preço total:",
    Payables: "Contas a pagar",
    Handling_fee: "Taxa de manuseio:",
    Confirm_payment: "confirme o pagamento",
    Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "Serão cobradas taxas de conversão e manuseio de moeda ao pagar em moedas que não sejam dólares americanos. ",
    Alipay: "Alipay",
    Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: " Cartões de débito e crédito em renminbi da China de bancos parceiros locais",
    Please_enter_amount: "Por favor insira o valor",
    Tips: "Dica",
    Sure: "Claro",
    Cancel: "Cancelar",
    Amount_cannot_be: "O valor não pode ser 0!",
    Amount_must_be_greater_than: "O valor deve ser maior que 0",
    The_amount_you_input_is: "O valor que você digitou é:",
    Please_select_mode_of_payment: "Selecione a forma de pagamento!",
  },
  estimation: {
    Freight_Estimate: "Estimativa de envio",
    Cooperate_with_many_international_express_companies_to_provide_super_low_freight_discounts: "Coopere com muitas empresas expressas internacionais para obter descontos ultrabaixos nos custos de envio",
    Seacloud_warehouse_to: "O armazém Seacloud envia para (país)",
    Please_enter_weight: "Por favor insira o peso (g)",
    Please_select: "por favor escolha",
    Length: "comprimento",
    Width: "Largura",
    Height: "Altura",
    International_freight_description: "Todos os serviços de logística para rotas não operadas pela Seacloudbuy são fornecidos por fornecedores de logística terceirizados, e eles ajustarão os preços do frete de acordo com as condições de mercado.Se houver alguma alteração de preço, a Seacloudbuy irá notificá-lo com antecedência. A fim de fornecer a melhor experiência de serviço, se você encontrar problemas com serviços de logística, a Seacloudbuy pode negociar e se comunicar com fornecedores de logística terceirizados em seu nome.",
    Logistics_Service_Description: "Descrição do serviço logístico",
    Transportation_Method: "Método de transporte",
    ico: "ícone",
    Time: "tempo(dias)",
    Freight: "frete",
    Features: "recurso",
    Operation: "operar",
    View: "Verificar",
    Details: "detalhes",
  },
  userlogin: {
    Welcome_to_log_in: "Bem vindo ao login",
    Please_enter_your_email_address: "Por favor insira seu e-mail",
    Please_enter_your_password: " Por favor insira a senha",
    Please_enter_your_verification_code: "Insira o código de verificação",
    Login: "Conecte-se",
    Forgot_your_password: "Esqueceu a senha?",
    Register: "Registro",

  },
  userforget: {
    Login: "Conecte-se",
    Email: "E-mail",
    Account_safety: "Segurança da conta",
    Submit: "Enviar",
    Already_have_an_account: "Já tem uma conta",
    Return_to_SeaCloud_homepage: "Retornar à página inicial do SeaCloud",
    Please_enter_the_email_address_associated_with_your_account: "Por favor insira o e-mail associado à sua conta",
    Captcha: "Código de verificação",
    Please_enter_the_correct_email_address: "por favor insira seu e-mail válido",
    The_verification_code_cannot_be_empty: "o código de verificação deve ser preenchido",
    New_Password: "Nova Senha",
    Send: "Enviar",
    PPlease_enter_your_password: "Por favor insira a senha",
    Please_enter_the_verification_code: "insira o código de verificação",


  },
  UserRegister: {
    Please_enter_your_password: "Por favor insira a senha",
    Please_enter_the_verification_code: "insira o código de verificação",
    Welcome_to_register: "Bem vindo ao registro",
    Please_select_your_country: "Por favor selecione seu país",
    Please_fill_in_a_real_and_available_email_address: "Por favor, preencha um endereço de e-mail real e disponível",
    Please_confirm_your_password: "Por favor confirme sua senha",
    Please_enter_the_invitation_code: "Por favor insira o código do convite(Facultativo)",
    Terms_of_Service_and_User_Management: "《Termos de Serviço e Gerenciamento de Usuários》",
    Privacy_Notice: "《Declaração de privacidade》",
    register: "Registro",
    Already_have_an_account: "Já tem uma conta",
    Log_in: "Conecte-se",
    Please_read_the_terms_and_check_I_agree: "Por favor, leia os termos e marque Eu concordo",
    The_two_passwords_do_not_match: "As duas senhas não são iguais!",
    Please_enter_your_email_address: "Por favor insira seu endereço de e-mail!",
    I_have_read_and_agree: "eu li e concordo",
  },

  UserSendMessage: {
    Please_enter_your_question: "Por favor insira sua pergunta",
    Send: "Enviar",
  },

  parcel: {
    Pending_payment: "Pagamento Pendente",
    Weight: "peso",
    Total_price: "Preço total:",
    Operate: "operar",
    order_details: "detalhes do pedido",
    View_Logistics: "verificar logística",
    Logistics_information: "Informações logísticas",
    All_packages: "Todos os pacotes",
    Waiting_for_packaging: "Para ser embalado",
    Pay_the_difference: "Faça a diferença",
    Sent: "Já enviado",
    Confirm_receipt: "Confirmar o recebimento de mercadorias",
    Canceled: "Cancelado",
    After_sales_waybill: "Guia pós-venda",
    Enter_shipping_method_or_consignee: "Insira o método de envio ou destinatário",
    Package_status: "Status do pacote",
    Shipping_method: "Método de envio",
    Pay_waybill: "Pagar conhecimento de embarque",
    View_details: "verifique os detalhes",
    Cancel_waybill: "Cancelar conhecimento de embarque",
    Consult_customer_service: "Serviço de aconselhamento",
    Waybill_details: "Detalhes da carta de porte",
    Consignee: "Destinatário",
  },
  preview: {
    Waiting_for_shipment: "A ser entregue",
    Cancel_the_preview_shipping_order: "Cancelar visualização do conhecimento de embarque",
    Pending_payment: "Pagamento Pendente",
    Weight: "peso",
    Total_price: "Preço total:",
    Operate: "operar",
    order_details: "detalhes do pedido",
    View_Logistics: "verificar logística",
    Logistics_information: "Informações logísticas",
    All_packages: "Todos os pacotes",
    Waiting_for_packaging: "Para ser embalado",
    Pay_the_difference: "Faça a diferença",
    Sent: "Já enviado",
    Confirm_receipt: "Confirmar o recebimento de mercadorias",
    Canceled: "Cancelado",
    After_sales_waybill: "Guia pós-venda",
    Enter_shipping_method_or_consignee: "Insira o método de envio ou destinatário",
    Package_status: "Status do pacote",
    Shipping_method: "Método de envio",
    Pay_waybill: "Pagar conhecimento de embarque",
    View_details: "verifique os detalhes",
    Cancel_waybill: "Cancelar conhecimento de embarque",
    Consult_customer_service: "Serviço de aconselhamento",
    Waybill_details: "Detalhes da carta de porte",
    Consignee: "Destinatário",
  },
  wallet: {
    Start_date: "Data de início",
    End_date: "Data final",
    Please_select: "por favor escolha",
    Consumption_records: "Registro de despesas",
    Query_by_date_period: "Consulta por período de data",
    Query_by_type: "Consulta por tipo",
    Query: "Investigar",
    Date: "Data",
    Behavior: "Comportamento",
    Consumption_amount: "Quantidade de consumo",
    Last_week: "semana passada",
    Last_month: "mês passado",
    Last_three_months: "últimos três meses",
    Balance: "equilíbrio",
  },
  senddetail: {
    Waybill_number: "Número da carta de porte",
    Shipping_name: "Nome de envio",
    Destination_country: "País de destino",
    Telephone: "Phone",
    Chargeable_Weight: "Peso cobrável ",
    Volume: "Volume",
    Total_cost: "Preço total",
    insurance: "taxa de seguro",
    Discounted_price: "Preço com desconto",
    Waybill_status: "Estado da carta de porte",
    Note: "Observação",
    Consignee: "destinatário",
    Shipping_address: "Endereço para envio",
    Submit_time: "Hora de envio",
    Shipping: "frete",
    Value_added_services: "Serviços de valor acrescentado",
  },
  points: {
    My_Points: "Minhas pontuações",
    Date: "Datum",
    Redeem_points_this_month: "Resgatar pontos este mês",
    My_VIP: "Meu VIP",
    Required_to_reach_next_level: "Necessário para atingir o próximo nível:",
    points: "pontos",
    Go_to_upgrade: "Ir para atualizar",
    View_coupon: "Ver cupom",
    reason: "razão",
    Points_before_change: "Pontos antes da mudança",
    Points_after_change: "Pontos após mudança",
    change_value: "mudar valor",
  },
  coupon: {
    Coupon_activation: "Ativação de cupom",
    Enter_your_coupon_activation_code: "Digite seu código de ativação do cupom",
    activation: "ativação",
    all: "todos",
    Used: "Usado",
    expired: "expirado",
    Order_full: "Pedido completo",
    reduce: "reduzir",
    Waybill: "Guia",
    Discount: "Desconto",
    Get_coupon_number: "Obter número de cupom",
    Validity: "Período de validade",
    Remaining: "Restante",
    cards: "cartão",
    Require: "Exigir",
    Points: "integrante",
    discount: "Desconto",
    Full: "Cheio",
    Available: "Disponível",
    Coupon_type: "Tipo de cupom",
    The_validity_period_after_activation_is: "O prazo de validade após a ativação é",
    sky: "céu",
    Redeem_now: "Resgatar agora",
    Waybill_full: "Conhecimento de transporte completo",
    minus: "reduzir",
    Coupon_No: "Número do cupom",
    // Validity:"Período de validade",
    usage_time: "tempo de uso",
    CNY_available: "CNY disponível",
    Obtain_method: "método para obter",
    Use_now: "use imediatamente",
    Order_Coupon: "Cupom de pedido",
    Waybill_Coupon: "Cupom de frete",
    Activate_Coupon: "Gutschein ativo",
    Coupons_can_be_used_as_service_fees_Reward_coupons_must_be_activated_before_use_Please_activate_within_the_validity_period: " Antes da ativação da Nutzung, é necessário iniciar a ativação interna do Gültigkeitsdauer.",
    Coupon_Number: "Gutscheinnummer",
    Type: "Tipo",
    Name: "Nome",
    Expiration_Date: "Primeiro",
    Amount: "Menge",
    Method: "Nós g",
    Status: "Zustand",
    Please_enter_the_activation_code: "Verifique o código de atividade",
    Coupon_code_cannot_be_empty: "Der Gutscheincode darf nicht leer sein",
    Tips: "Dica",
    Sure: "Claro",
    Cancel: "Cancelar",
    // Obtain_method:"método para obter",
  },
  suspendball: {
    Por_favor_insira_sua_pergunta: "Por favor insira sua pergunta",
    Click_to_enter_customer_service: "Clique em atendimento ao cliente",
  },
  promotion:{
    My_promotional_commission:"Meine Werbeprovision",
    total_users:"Gesamtzahl der Benutzer",
    New_users_within_7_days:"Neu hinzugefügt am 7",
    active_user:"aktiver Nutzer",
    Payment_settings:"Zahlungseinstellungen",
    My_alliance_balance:"Mein Allianzgleichgewicht",
    Amount_withdrawn:"Betrag abgezogen",
    withdraw:"zurückziehen",
    my_rights:"meine Rechte",
    Commission_rate:"Provisionssatz",
    Points_record:"Punkterekord",
    Points:"Integral",
    What_is_an_affiliate_program:"Was ist ein Partnerprogramm",
    What_is_an_affiliate_program_desc:"Seacloudbuy ist ein von allen Benutzern initiiertes Sharing- und Profitprojekt. Ganz gleich, ob Sie eine Einzelperson oder ein Unternehmen sind, treten Sie Seacloudbuy als Promoter bei und laden Sie neue Leute ein, sich bei Seacloudbuy zu registrieren und erfolgreich Pakete zu kaufen und zu unterschreiben. Er/sie wird Ihr exklusiver Benutzer, und jeder internationale Logistikkonsum, den er/sie tätigt, bringt Sie profitieren von Cashback, keine Obergrenze.",
    How_my_winnings_are_calculated:"So werden meine Gewinne berechnet",
    How_my_winnings_are_calculated_desc:" Sobald Sie Mitglied werden, erhält Ihr Konto sofort einen Rabatt von 3 %. Je mehr internationale Logistiksendungen Sie registrierte Benutzer einladen, desto höher ist Ihr Bonus und auch die Rabattquote steigt. Wenn der Bonus einen bestimmten Betrag erreicht, können Sie eine Auszahlung beantragen oder damit etwaige Servicegebühren auf der Seacloudbuy-Plattform abziehen.",
    How_to_promote_for_free:"So fördern Sie",
    How_to_promote_for_free_desc:" Kopieren Sie Ihren Werbelink und senden Sie ihn an Ihre Freundin, wenn diese nach der Registrierung Ihr exklusiver Benutzer wird.",
    Commission_rate_desc:"Qual é a taxa de comissão? Depois que seu usuário promocional receber o pacote com sucesso, Seacloudbuy irá recompensá-lo com o custo total de envio do pacote multiplicado pela taxa de comissão.",
    Click_here_to_invite:"Click aqui para convidar"
              
  }


}