<template>
  <div class="wareHouse_page">
    <div class="warehouse_nav">
      <el-row>
        <el-col :span="7">
          <el-input
            :placeholder="$t('warehouse.Order_ID_Product_Name')"
            v-model="searchValue"
            size="small"
            class="input-with-select"
          >
          </el-input>
        </el-col>
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item>
            <el-date-picker
              style="width: 320px"
              v-model="formInline.time"
              type="daterange"
              :start-placeholder="$t('warehouse.Start_Date')"
              :end-placeholder="$t('warehouse.End_Date')"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              style="background: #333; border: none"
              @click="search"
              >{{ $t("warehouse.Search") }}</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <div class="warehouse_main">
      <div class="warehouse_list">
        <el-table
          ref="multipleTable"
          :data="tableData"
          class="table"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection"></el-table-column>

          <el-table-column label="ID" width="100">
            <template slot-scope="scope">{{ scope.row.order_code }}</template>
          </el-table-column>

          <el-table-column
            prop="name"
            :label="$t('warehouse.Image')"
            width="80"
          >
            <template slot-scope="scope">
              <img class="goods_img" :src="scope.row.order_img" alt="" />
            </template>
          </el-table-column>

          <el-table-column
            prop="address"
            :label="$t('warehouse.Product_Details')"
            width="200"
          >
            <template slot-scope="scope">
              <div class="info" style="flex-direction: column">
                <div>
                  <h4 class="title">
                    <a :href="scope.row.goods_url" target="blank">{{
                      scope.row.title
                    }}</a>
                  </h4>
                </div>
                <p v-html="scope.row.option"></p>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="name"
            :label="$t('warehouse.Price')"
            width="*0"
          >
            <template slot-scope="scope"
              >{{ currency }}
              {{ couputerPrice(scope.row.goods_price, scope.row.goods_num) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="address"
            :label="$t('warehouse.Shipping')"
            width="80"
          >
            <template slot-scope="scope"
              >{{ currency }}{{ moneyRates(scope.row.post_fee) }}</template
            >
          </el-table-column>

          <el-table-column
            prop="name"
            :label="$t('warehouse.Weight')"
            width="90"
          >
            <template slot-scope="scope"
              >{{
                scope.row.order_weight == "" ? 0 : scope.row.order_weight
              }}g</template
            >
          </el-table-column>

          <el-table-column
            prop="address"
            :label="$t('warehouse.Order_Status')"
            width="110"
          >
            <template slot-scope="scope">
              <el-link type="warning">{{
                scope.row.state == 4 ? "in warehouse" : ""
              }}</el-link>
            </template>
          </el-table-column>

          <el-table-column
            prop="name"
            :label="$t('warehouse.Days')"
            width="100"
          >
            <template slot-scope="scope"
              >{{ scope.row.inWareHouseDay }} day</template
            >
          </el-table-column>
          <el-table-column
            prop="address"
            :label="$t('warehouse.Action')"
            width="70"
          >
            <template slot-scope="scope">
              <el-dropdown trigger="click" slot="reference">
                <span class="el-dropdown-link">
                  <el-badge class="item badge" type="warning">
                    <el-button class="more el-icon-more"></el-button>
                  </el-badge>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="scope.row.state == 4"
                    @click.native="toOrderDetail(scope.row)"
                    >{{ $t("warehouse.Details") }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="scope.row.state == 4"
                    @click.native="tomessageUs(scope.row)"
                    >{{ $t("warehouse.Leave_us_a_message") }}
                  </el-dropdown-item>
                  <!-- <el-dropdown-item
                    v-if="scope.row.state == 4"
                    @click.native="toOrderDetail(scope.row)"
                    >物流              
                </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="warehouse_page">
        <div class="payment_panel">
          <div class="tools">
            <div @click.prevent="selectAll">
              <el-checkbox v-model="selectAlls">{{
                $t("warehouse.Select_All")
              }}</el-checkbox>
            </div>
            <div>
              {{ $t("warehouse.Seacloud_warehouse_To") }}
              <el-select
                v-model="value"
                :placeholder="$t('warehouse.Shipping_Country')"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <!-- <el-select
                v-model="this.deliveryInfo.count"
                placeholder="可用线路"
                style="width: 100px; margin-left: 10px"
              >
                <el-option
                  v-for="item in this.deliveryInfo.data"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
            </div>
          </div>
          <div class="payment_btns">
            <div class="detail">
              <div>
                <span>{{ $t("warehouse.Actual_Weight") }}</span>
                <h4>&nbsp;{{ goodsSumWeight }}&nbsp;g</h4>
              </div>
              <span
                >{{ $t("warehouse.Selected_Products") }}
                <span class="num">{{ countGoods }}</span>
                {{ $t("warehouse.Pieces") }}
              </span>
            </div>
            <el-button class="p_pack" @click="tosubmit(1)">{{
              $t("warehouse.Preview_Packaging")
            }}</el-button>
            <el-button type="primary" @click="tosubmit">{{
              $t("warehouse.Submit_Shipping")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 聊天信息对话框 -->
    <el-dialog
      title="message"
      style="text-align: left; border: 1px red"
      width="960px"
      :visible.sync="messageVisable"
    >
      <user-message
        v-if="messageVisable"
        :currentItemMessage="currentItemMessage"
      ></user-message>
    </el-dialog>
    <!-- 订单详情 对话框 -->
    <el-dialog
      :title="$t('warehouse.Order_Details')"
      width="800px"
      :visible.sync="orderDetailVisable"
    >
      <order-detail
        v-if="orderDetailVisable"
        :orderInfo="currentItem"
      ></order-detail>
    </el-dialog>
  </div>
</template>

<script>
import { wareHouseOrder } from "@/Api/order";
import { getCountryList, getCountryDelivery } from "@/Api/index";
import orderDetail from "@/components/others/orderDetail.vue";
import UserMessage from "@/components/user/UserMessage.vue";
export default {
  components: { orderDetail, UserMessage },
  data() {
    return {
      goods_remark: "",
      searchValue: "",
      formInline: {
        time: "",
      },

      tableData: [],
      multipleSelection: [],
      options: [],
      value: "",
      currentItem: null,
      currentItemMessage: null,
      orderInfo: "",
      selectAlls: false,
      countGoods: 0,
      goodsSumWeight: 0,
      deliveryInfo: [],
      messageVisable: false,
      orderDetailVisable: false,
      currency: "'",
    };
  },
  watch: {
    multipleSelection: {
      deep: true,
      handler(newValue) {
        this.goodsSumWeight = 0;
        this.countGoods = newValue.length;
        newValue.forEach((res) => {
          var weight = res.order_weight;
          if (isNaN(parseFloat(weight))) {
            console.log(this.$t("warehouse.Not_an_Array"));
          } else {
            this.goodsSumWeight += parseFloat(weight);
          }
        });
        // 计算重量
      },
    },
    // value: {
    //   handler(newValue) {
    //     this.countryDeliveryInfo(newValue);
    //   },
    // },
  },
  methods: {
    moneyRates(money) {
      return this.moneyRate(money);
    },
    //计算价格
    couputerPrice(goods_price, goods_num) {
      return this.moneyRate(goods_price) + "*" + goods_num;
    },
    // search 搜索按钮
    search() {
      var data = {};
      var starttime = Date.parse(this.formInline.time[0]);
      var stoptime = Date.parse(this.formInline.time[1]);
      if (starttime > 0) {
        data["startTime"] = starttime / 1000;
      }
      if (stoptime > 0) {
        data["stopTime"] = stoptime / 1000;
      }
      data["searchValue"] = this.searchValue;
      wareHouseOrder(data).then((res) => {
        this.tableData = res.data;
      });
    },
    // 提交按钮
    tosubmit(rehearsal = 0) {
      var countryId = this.value;
      if (countryId == "") {
        this.$message.info(this.$t("warehouse.Please_select_a_country"));
        return;
      }
      var ids = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length == 0) {
        this.$message.error(
          this.$t("warehouse.Please_select_an_order_to_submit")
        );
        return;
      }
      if (rehearsal == 1) {
        window.open(
          "/person/invoice?sendId=" +
            ids.join(",") +
            "&country=" +
            countryId +
            "&is_rehearsal=1",
          "_blank"
        );
      } else {
        window.open(
          "/person/invoice?sendId=" + ids.join(",") + "&country=" + countryId,
          "_blank"
        );
      }
    },
    //留言给我们
    tomessageUs(item) {
      this.currentItemMessage = item;
      this.messageVisable = true;
    },
    //全选
    selectAll() {
      this.selectAlls = !this.selectAlls;

      if (this.selectAlls) {
        this.$refs.multipleTable.selection = this.tableData;
        this.tableData.forEach((item) => {
          this.$refs.multipleTable.selection.push(item);
          this.multipleSelection = this.$refs.multipleTable.selection;
        });
      } else {
        this.tableData.forEach(() => {
          this.$refs.multipleTable.selection.pop();
        });
        this.multipleSelection = [];
      }
    },
    //国家列表
    countryList() {
      getCountryList().then((res) => {
        this.options = res.data;
      });
    },
    // 获取国家列表详情
    countryDeliveryInfo(countryId) {
      var data = {};
      data["countryId"] = countryId;
      getCountryDelivery(data).then((res) => {
        this.deliveryInfo = res.data;
        this.deliveryInfo.count =
          this.deliveryInfo.count + this.$t("warehouse.Available");
      });
    },
    // 商品详情
    toOrderDetail(item) {
      this.currentItem = item;
      this.orderDetailVisable = true;
    },
    // 获取在仓库列表
    getWareHouseOrder() {
      var data = {};
      wareHouseOrder(data).then((res) => {
        this.tableData = res.data;
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 勾选发生变化
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.$refs.multipleTable.selection.length == this.tableData.length) {
        this.selectAlls = true;
      } else {
        this.selectAlls = false;
      }
    },
    cancel() {},
    saveRemark() {},
  },
  created() {
    this.currency = this.getCurrency;
    this.getWareHouseOrder();
    this.countryList();
  },
};
</script>

<style lang="less" scoped>
.el-table td.el-table__cell div {
  display: flex;
}
.warehouse_nav {
  margin-top: 20px;
  .el-input-group--append .el-input__inner,
  .el-input-group__prepend {
    background-color: #f8f8f8 !important;
    border: none;
  }
}
.warehouse_list {
  .table {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 400px;
    .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      background: #f7f7f7;
    }
    .info {
      .title {
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        font-weight: 500;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .goods_img {
    width: 55px;
  }

  .badge {
    .more {
      background: transparent;
      border: none;
      padding: 0;
      width: 25px;
      height: 50px;
      transform: rotate(90deg);
    }
  }
}

.warehouse_page {
  position: sticky;
  bottom: 0;
}
.payment_panel {
  position: relative;
  width: 990px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #ddd;
  margin-top: 10px;
  z-index: 2000;
  background: #fff;
  margin-bottom: 1px;
}
.payment_panel {
  .tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    .el-button--text {
      color: #ef0c25;
    }
  }
}

.payment_btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .detail {
    font-size: 14px;
    color: #333;
    margin-right: 10px;
    text-align: right;
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      h4 {
        font-size: 24px;
        color: #ef0c25;
      }
    }
    .num {
      font-weight: 600;
      color: #ef0c25;
      font-size: 18px;
    }
  }
  .el-button {
    height: 50px;
    // width: 140px;
    font-weight: 700;
    font-size: 14px;
    border: 2px solid #ef0c25;
  }
  .p_pack {
    color: #ef0c25;
  }
  .p_pack:hover {
    background-color: #f7e5e6;
  }
  .p_pack:focus {
    background-color: #f7e5e6;
  }

  .el-button--primary {
    background: linear-gradient(to right, #fd5532, #ef0c25);
    color: #fff;
  }
  .el-button--primary:hover {
    background: linear-gradient(to right, #e34d2d, #d60b20);
  }
}
</style>
