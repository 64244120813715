<template>
  <div>
    <el-tooltip class="item" effect="dark" content="join us" placement="left">
      <div class="discord">
        <a href="https://discord.com/invite/QVRsmFQ6KR" target="_blank"></a>
      </div>
    </el-tooltip>
    <el-tooltip
      class="item"
      effect="dark"
      :content="$t('suspendball.Click_to_enter_customer_service')"
      placement="left"
    >
      <div
        v-if="showModal === false"
        class="suspend"
        @click="toggleModal()"
      ></div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" placement="left">
      <div
        v-if="showModal === true"
        class="suspend2"
        @click="toggleModal()"
      ></div>
    </el-tooltip>
    <!-- 弹窗 -->

    <div v-if="showModal" class="modal-content" @mouseleave="showModal = false">
      <!-- 头部 -->
      <div class="chatHeader">
        <h4>SeaCloudBuy</h4>
      </div>
      <!-- 内容 -->
      <div class="chatContent" id="chatContent">
        <!-- 内容区域 -->
        <div class="body" v-for="(item, index) in messages" :key="index">
          <div class="left" v-if="item.type == 2">
            <span class="left_avatar"></span>
            <p v-html="item.content"></p>
          </div>
          <div class="right" v-if="item.type == 1">
            <p v-html="item.content"></p>
            <!-- <span class="right_avatar">I</span> -->
            <img class="right_avatar"  :src="userInfo.avatar">
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="chatBottom">
        <input
          type="text"
          id="msgIpt"
          v-model="message"
          @keydown.enter="send"
          :placeholder="$t('suspendball.Por_favor_insira_sua_pergunta')"
        />

        <div class="tools">
          <!-- <img src="../../assets/mood.png" alt="" /> -->
          <img src="../../assets/photo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessage, sendMessage } from "@/Api/index";
import { getUserInfo } from "@/Api/user";
export default {
  name: "suspendBall",
  data() {
    return {
      showModal: false, // 控制客服弹窗 显示与隐藏
      messages: [], //消息记录
      message: "", //输入的消息
      userInfo:""
    };
  },
  created() {
    // 获取客服和用户的聊天记录 2秒刷新一次
    this.getMessages();
  },

  methods: {
      //获取用户
    getUserInfo(){
      getUserInfo().then((res)=>{
        this.userInfo =res.data
      })
    },
    // 发送信息
    send() {
      var data = {};
      data["content"] = this.message;
      sendMessage(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.message = "";
        }
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    getMessages() {
      setInterval(() => {
        // 只有等于True  窗口打开的时候请求
        if (this.showModal == true) {
          // console.log(this.showModal);
          getMessage().then((res) => {
            this.messages = res.data;
            this.reflush();
          });
          this.getFous();
        }
        if(this.userInfo=="" && this.showModal==true){
          this.getUserInfo()  
        }
      }, 2000);
    },
    reflush() {
      var container = document.getElementById("chatContent");
      // console.log(container);
      if (container != null) {
        container.scrollTop = container.scrollHeight;
      }
    },
    getFous() {
      var element = document.getElementById("msgIpt");
      element.focus();
    },
  },
};
</script>

<style lang="less" scoped>
/* 悬浮球Start */
.suspend {
  padding: 0;
  position: fixed;
  right: 20px;
  bottom: 85px;
  width: 48px;
  border-radius: 50%;
  height: 48px;
  background: url(../../assets/suspend-ball.png) 50% no-repeat;
  background-size: 48px 48px;
}
.discord {
  padding: 0;
  position: fixed;
  right: 20px;
  bottom: 145px;
  width: 48px;
  border-radius: 50%;
  height: 48px;
  background: url(../../assets/discord.jpeg) 50% no-repeat;
  background-size: 48px 48px;
}
.discord a {
  display: block;
  width: 100%;
  height: 100%;
}
.suspend2 {
  padding: 0;
  position: fixed;
  right: 20px;
  bottom: 85px;
  width: 48px;
  border-radius: 50%;
  height: 48px;
  background: url(../../assets/fold.png) 50% no-repeat;
  background-size: 48px 48px;
}

.modal-content {
  position: fixed;
  right: 20px;
  bottom: 145px;
  width: 400px;
  height: 550px;
  // height: auto;
  border-radius: 8px;
  background: #fff;
  box-shadow: -13px 8px 15px -8px rgba(0, 0, 0, 0.1);

  z-index: 99999999;
  .chatHeader {
    height: 80px;
    line-height: 80px;
    color: #fff;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(to right, #fd5532, #ef0c25);
  }
  .chatContent {
    height: 390px;
    overflow-y: scroll; // 垂直滚动条
    border-bottom: 2px solid #ececec;
    .body {
      height: 50px;
      line-height: 50px;
      margin: 10px;
      // border: 1px solid red;
      .left {
        float: left;
        display: flex;

        .left_avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
          background-size: cover;
          background-image: url("/src/assets/suspend-ball.png");
        }
      }
      .right {
        display: flex;
        float: right;
        .right_avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-left: 10px;
          background-size: cover;

        }
      }
    }
  }

  .chatBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 20px;
    border-bottom: #f2f2f2;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    input {
      float: left;
      border: 0;
      font-size: 14px;
      line-height: 42px;
      color: #757575;
      // caret-color: #3d3d3d;
    }
    .tools {
      float: right;
      img {
        width: 22px;
        height: 22px;
        margin: 8px 0 0 8px;
      }
    }
  }
}

/* 悬浮球End */
</style>
