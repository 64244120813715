<template>
  <div class="body">
    <el-table :data="[currentItem]" border style="width: 100%">
      <el-table-column
        :label="$t('deliverydetail.First_weight_price')"
        width="180"
      >
      <template>
        {{ moneyRate(currentItem.first_fee) }} {{ currency }} /{{ currentItem.first_weight }}g
      </template>
      </el-table-column>
      <el-table-column
        :label="$t('deliverydetail.Additional_weight_price')"
        width="180"
      >
      <template>
        {{ moneyRate(currentItem.conutine_fee) }} {{ currency }} /{{ currentItem.conutine_weight }}g
      </template>
      </el-table-column>
      <el-table-column  :label="$t('deliverydetail.Size_limit')" width="180">
        <template>
          {{ currentItem.side_limit }} cm
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('deliverydetail.Sensitive_items_that_can_be_mailed')"
      >
      <template>
        {{ currentItem.special }}
      </template>
      </el-table-column>
      <!-- <el-table-column
        :label="$t('deliverydetail.Operation_fee')"
        width="100"
      >
      <template>
        0
      </template>
      </el-table-column>
      <el-table-column prop="address" :label="$t('deliverydetail.Service_fee')" width="100">
        <template>
          0
        </template>
      </el-table-column> -->
    </el-table>

    <!-- <div>
      <span>{{ $t("deliverydetail.First_weight") }}</span
      ><span>{{ currentItem.first_weight }}g</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.First_weight_price") }}</span
      ><span>{{ moneyRate(currentItem.first_fee) }} {{ currency }}</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Additional_weight") }}</span
      ><span>{{ currentItem.conutine_weight }}g</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Additional_weight_price") }}</span
      ><span>{{ moneyRate(currentItem.conutine_fee) }} {{ currency }}</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Size_limit") }}</span
      ><span>{{ currentItem.side_limit }} cm</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Sensitive_items_that_can_be_mailed") }}</span
      ><span>{{ currentItem.special }}</span>
    </div> -->
    <!-- <div>
      <span>{{ $t("deliverydetail.Fuel_fee") }}</span
      ><span>{{ moneyRates(currentItem.oill_fee) }} {{ currency }}</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Operation_fee") }}</span
      ><span>0 {{ currency }}</span>
    </div>
    <div>
      <span>{{ $t("deliverydetail.Service_fee") }}</span
      ><span>0 {{ currency }}</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["currentItem"],
  data() {
    return {
      currency: "CNY",
    };
  },
  methods: {
    moneyRates(money) {
      return this.moneyRate(money);
    },
  },
  created() {
    this.currency = this.getCurrency;
  },
};
</script>

<style lang="less" scoped>
.body {
  font-size: 16px;
  div {
    padding: 5px;
  }
}
</style>