<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item :label="$t('servereturn.Application_Type')">
        <el-select v-model="form.region" :placeholder="$t('servereturn.Please_select_application_type')">
          <el-option :label="$t('servereturn.Request_return')" value="1"></el-option>
          <el-option :label="$t('servereturn.Request_exchange')" value="2" ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('servereturn.Request_reason')">
        <el-input type="textarea" style="width: 400px;" v-model="form.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t('servereturn.Submit') }}</el-button>
        <el-button @click="cancel">{{ $t('servereturn.Cancel') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { serveReturnGoods } from "@/Api/order"
export default {
          props:["item"],
  data() {
    return {
      form: {
        region: "2", //1 退货  2 换货
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
          var data = {}
          data["oid"]=this.item.id
          data["desc"]=this.form.desc
          data["type"]=this.form.region
          serveReturnGoods(data).then((res)=>{
                    if(res.code==1){
                              this.$message.success(res.msg)
                    }
          })
      this.$emit('child-event');
    },
    cancel(){
          this.$emit('child-event');
    }
  },
};
</script>

<style>
</style>