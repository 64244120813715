<template>
  <div>
    <HeaderTop />
    <div class="person_page w">
      <div class="person_nav">
        <!-- 主导航 -->
        <ul class="person_panel">
          <li
            v-for="(item, index) in menu"
            :key="index"
            :class="{ active: item.path === curPath }"
            @click="
              () => {
                curPath = item.path;
              }
            "
          >
            <router-link :to="item.path">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
      <div class="person_content">
        <router-view></router-view>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "@/components/mainPage/HeaderTop";
import router from "@/router/index";
import Bottom from "@/components/mainPage/Bottom.vue";

export default {
  name: "person-center",
  components: {
    HeaderTop,
    Bottom,
  },
  data() {
    return {
      curPath: "/person/center",
      menu: [
        {
          title: this.$t("index.Front_page"),
          path: "/person/center",
        },
        {
          title: this.$t("index.Shopping_cart"),
          path: "/person/cart",
        },
        // {
        //     title:'收藏夹',
        //     path:'/person/collection'
        // },
        {
          title: this.$t("index.My_Order"),
          path: "/person/orderlist",
        },
        {
          title: this.$t("index.My_warehouse"),
          path: "/person/wareHouse",
        },
        {
          title: this.$t("index.Preview_package"),
          path: "/person/preview",
        },
        {
          title: this.$t("index.My_package"),
          path: "/person/parcel",
        },
        {
          title: this.$t("index.Trading_log"),
          path: "/person/wallet",
        },
        {
          title: this.$t("index.My_Points"),
          path: "/person/points",
        },
        {
          title: this.$t("index.Coupons"),
          path: "/person/coupon",
        },
        {
          title:  this.$t("index.Account_setup"),
          path: "/person/account",
        },
        {
          title: this.$t("index.Promoter_Programm"),
          path: "/person/promotion",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      console.log(from)
      this.curPath = to.path;
    },
  },
  mounted() {
    this.curPath = router.history.current.fullPath;
  },
};
</script>

<style lang="less" scoped>
.person_page {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 15px;
  min-height: 500px;
  margin: 30px auto;
}
.person_nav {
  background: #fff;
  min-height: 500px;
  min-width: 170px;
  border-radius: 15px 0 0 15px;
}
.person_nav .person_panel {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fff;
}
.person_nav .person_panel li {
  min-width: 169px;

  margin: 10px 0;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  /* border: 1px red solid; */
}
.person_panel{
  li{
    padding: 0 15px;
  }
}
.person_nav .person_panel li.active {
  background: #f7f7f7;
  position: relative;
}
.person_nav .person_panel li.active::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background: #ef0c25;
}
.person_nav .person_panel li a {
  color: #1a1311;
  font-weight: 700;
  position: relative;
  font-size: 14px;
  display: block;
  width: 100%;
  white-space: nowrap;
}
.person_content {
  padding: 0 20px;
  width: 1030px;
  min-height: 500px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0 15px 15px 0;
  /* border: 1px solid red; */
}
</style>
