import request from "@/utils/request";

export function pay(data){
          var url= "/pay/createPay?type=paypal"
          return request.post(url,data) 
}


export function recharge(data) {
          var url= "/recharge/recharge"
          return request.post(url,data) 
}

export function rechargeCurrencyToMoney(data){
          var url= "/recharge/rechargeCurrencyToMoney"
          return request.post(url,data) 
}