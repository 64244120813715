<template>
  <div>
          我的留言
  </div>
</template>

<script>
export default {
          name:"message-vue"

}
</script>

<style>

</style>