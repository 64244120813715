<template>
  <div>
    <header-top></header-top>
    <div class="body">
      <h2 class="big_title">{{ $t('cartlnfo.Confirm_Order') }}</h2>
      <div class="countryDeliverAndOrder">
        <h4>{{ $t('cartlnfo.Select_shipping_country') }}</h4>
        <div class="deliver">
          <div>
            <p class="number">1</p>
            {{ $t('cartlnfo.Merchant') }} <span class="middle">></span> {{ $t('cartlnfo.Seacloud_warehouse') }} 
          </div>
          <div>
            <p class="number">2</p>
            {{ $t('cartlnfo.Seacloud_warehouse') }}  <span class="middle"> > </span>
            <el-select
              prefix-icon="el-icon-message"
              v-model="value"
              filterable
              :placeholder="$t('cartlnfo.Please_fill_in_the_country_to_be_shipped_to')"

              style="margin-right: 10px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                aria-placeholder=""
              >
              </el-option>
            </el-select>
            <span style="font-size: 12px"
              >{{ $t('cartlnfo.Seacloud_buyers_will_determine_whether_your_product_can_be_sent_based_on_the_country_you_fill_in') }} </span
            >
          </div>
        </div>
        <h4 class="goodsHead">{{ $t('cartlnfo.Confirm_product_info') }}</h4>
        <div class="goodsInfo">
          <el-row class="goods_nav">
            <el-col :span="8">{{ $t('cartlnfo.product_detail') }}</el-col>
            <el-col :span="4">{{ $t('cartlnfo.Note') }}</el-col>
            <el-col :span="3">{{ $t('cartlnfo.Price') }}</el-col>
            <el-col :span="3">{{ $t('cartlnfo.qty') }}</el-col>
            <el-col :span="3">{{ $t('cartlnfo.additional_services') }}</el-col>
            <el-col :span="3">{{ $t('cartlnfo.Subtotal') }}</el-col>
          </el-row>
          <div class="bodyinfo">
            <div v-for="(item, index) in cartsInfo" :key="index">
              <div class="shopInfo">
                <span class="title">
                  <img v-if="item.goods_url.indexOf('1688')>=0"
                    class="icoTitle"
                    src="../../assets/1688-logo.png"
                  />
                  <img v-if="item.goods_url.indexOf('weidian')>=0"
                    class="icoTitle"
                    src="../../assets/weidian-logo.png"
                  />
                  <img v-if="item.goods_url.indexOf('taobao')>=0 || item.goods_url.indexOf('tmall')>=0"
                    class="icoTitle"
                    src="../../assets/taobao-logo.png"
                  />
                  <a class="title">{{ item.shop_name }}</a>
                </span>

                <span class="store">{{ $t('cartlnfo.Seacloud_warehouse') }}</span>
              </div>
              <div class="goodsInfoBody">
                <el-row>
                  <el-col :span="8" style="display: flex">
                    <div class="item-img">
                      <img :src="item.goods_img" />
                    </div>
                    <div class="title-box">
                      <a href="" :title="item.title">
                        <p class="title">{{ item.title }}</p>
                      </a>
                      <p class="title_desc" v-html="item.option"></p>
                      <!-- <div class="no_return_or_change" style="margin: 4px 0px">
                        无退换货服务 &gt;
                      </div> -->
                    </div>
                  </el-col>

                  <el-col :span="4">
                    <div>{{ $t('cartlnfo.Note_information') }}</div>
                    <el-button
                      type="text"
                      icon="el-icon-edit-outline"
                      @click="editRemark(item, index)"
                    ></el-button>
                    <!-- 备注 -->
                    <el-dialog
                    :title="$t('cartlnfo.Product_remarks')"
                      :visible.sync="dialogVisible[index]"
                      width="40%"
                    >
                      <el-input
                        type="textarea"
                        v-model="item.remark"
                        :placeholder="$t('cartlnfo.Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it')"
                      ></el-input>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="cancelRemark(item, index)"
                          >{{ $t('cartlnfo.Cancel') }}</el-button
                        >
                        <el-button
                          type="primary"
                          @click="saveRemark(item, index)"
                          >{{ $t('cartlnfo.Sure') }}</el-button
                        >
                      </div>
                    </el-dialog>
                  </el-col>

                  <el-col :span="3">
                    <p>
                      <span>￥{{ item.goods_price / item.goods_num }}</span>
                    </p>
                  </el-col>

                  <el-col :span="3"> {{ item.goods_num }} </el-col>

                  <el-col :span="3">
                    <el-select
                      v-model="item.add_serveId"
                      multiple
                      :placeholder="$t('cartlnfo.Additional_services')"
                    >
                      <el-option
                        v-for="item in optionsAdd"
                        :key="item.id"
                        :label="
                          item.label 
                        "
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="3" class="price"
                    >{{ currency}} {{ moneyRates(item.goods_price) }}</el-col
                  >
                </el-row>
              </div>
              <div class="bottom">
                <el-row>
                  <el-col :span="21">
                    <p class="totle_title">{{ $t('cartlnfo.Total_price_of_goods') }}</p>
                    <p class="totle_title">{{ $t('cartlnfo.Shipping_fee_to_warehouse') }}</p>
                  </el-col>
                  <el-col :span="3">
                    <p class="totle_title">{{ currency }} {{ moneyRates(item.goods_price) }}</p>
                    <p class="totle_title">{{ currency }} {{moneyRates(item.post_fee) }}</p>
                  </el-col>
                </el-row>
                <p class="total">
                  <span class="label">{{ $t('cartlnfo.Total_stores') }}</span>
                  <em>{{ currency }} {{ moneyRates(item.goods_price) }}</em>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="fee">
          <span class="title">{{ $t('cartlnfo.Total_price_to_be_paid_international_shipping_charges_are_extra') }}</span>
          <em class="green">{{ currency }} {{  moneyRates(parseFloat(this.sum_fee)+parseFloat(this.addFee)) }}</em>
        </div>
      </div>

      <div class="submitbottom">
        <ul class="readme">
          <li><a href="/question?requestId=3" style="color: #466def;">{{ $t('cartlnfo.Prohibited_Goods_Statement') }}</a></li>
          <li><a href="/question?requestId=2" style="color: #466def;">{{ $t('cartlnfo.Terms_of_Service_and_User_Management') }}</a></li>
          <li><a href="/question?requestId=5" style="color: #466def;">{{ $t('cartlnfo.Return_and_Exchange_Service') }}</a></li>
          <li><a href="/question?requestId=4" style="color: #466def;">{{ $t('cartlnfo.Disclaimer') }}</a></li>
          <li style="margin-left: 20px; display: flex">
            <input type="checkbox" v-model="checkbox" />
            <label for="submit">{{ $t('cartlnfo.I_have_read_and_agree') }}</label>
          </li>
        </ul>
        <div class="submitButton">
          <div>
            <el-button type="success" @click="submitCart()">{{ $t('cartlnfo.Submit_Order') }}</el-button>
          </div>
        </div>
        <div class="tip">
          {{ $t('cartlnfo.Kind_tips_After_the_order_is_submitted_and_the_payment_is_successful_please_wait_patiently_for_the_goods_to_be_put_into_storage_The_goods_can_be_submitted_for_shipping_after_being_put_into_storage') }}
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import { getCountryList } from "@/Api/index";
import HeaderTop from "../mainPage/HeaderTop.vue";
import { userCartInfo, cartRemark } from "@/Api/cart";
import { createOrder, addServeList,couponList } from "@/Api/order";
import Bottom from "../mainPage/Bottom.vue";
export default {
  components: { HeaderTop, Bottom },
  name: "cart-info",
  data() {
    return {
      options: [],
      value: "",
      cartsInfo: [],
      sum_fee: 0.0,
      dialogVisible: [],
      optionsAdd: [],
      addFee: 0,
      checkbox:false,
      currency:"", //币种
      couponList:[]
    };
  },
  created() {
    this.orderCouponList()
    this.setCurrency()
    var carid = this.$route.query.orderId;
    // if (carid !== null) {
    // }
    userCartInfo({ ids: carid }).then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.sum_fee = res.data.sum_fee;
        this.cartsInfo = res.data.data;
      }
    });
    addServeList().then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.optionsAdd = res.data;
      }
    });
    getCountryList().then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.options = res.data;
      }
    });
  },
  methods: {
    // 设置货币
    setCurrency(){
      this.currency = this.getCurrency
    },
    // 货币转换
    moneyRates(money){
        return this.moneyRate(money)
    },
    selected(item) {
      console.log(item);
    },
    editCartRemark(item) {
      var data = {};
      (data["id"] = item.id), (data["remark"] = item.remark);
      cartRemark(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submitCart() {
      if(this.checkbox==false){
        this.$message.error(this.$t("cartlnfo.Please_agree_to_the_terms"));
        return;
      }
      var data = {};
      if (this.value == "") {
        this.$message.error(this.$t("cartlnfo.Please_choose_a_country"));
        return;
      }
      var addserveIds = [];
      var addServe = [];
      var carList = this.cartsInfo;
      carList.filter((item) => {
        if (item.add_serveId != "") {
          addserveIds.push(item.id);
          addServe.push(item.add_serveId);
        }
      });
      data["addServeIds"] = addserveIds;
      data["addServe"] = addServe;
      data["countryId"] = this.value;
      data["ids"] = this.$route.query.orderId;
      data["platform"] = "1";
      data["couponId"] = 0;
      createOrder(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.$router.push({
            path: "/payorder",
            query: {
              orderId: res.data.ids,
            },
          });
        }
      });
    },
    //编辑 备注
    editRemark(item, index) {
      this.$set(this.dialogVisible, index, true);
      // 保存原来的备注，以便取消的时候恢复
      //   this.recordRemark = item.remark;
    },
    //保存备注
    saveRemark(item, index) {
      this.editCartRemark(item);
      this.$set(this.dialogVisible, index, false);
      // 更新vuex数据
      // store.dispatch("updateCart", this.cartlist);
    },
    //取消备注
    cancelRemark(item, index) {
      this.$set(this.dialogVisible, index, false);
    },
    // couponList 订单优惠券
    orderCouponList(){
      couponList().then((res)=>{
        this.couponList=res.data
        console.log(res)
      })
    }
  },
  watch: {
    cartsInfo: {
      deep: true,
      handler(newValue) {
        this.addFee = 0;
        var count = newValue.length;
        for (var i = 0; i < count; i++) {
          var addServeId = newValue[i].add_serveId;
          var addServeFee = this.optionsAdd;
          for (var j = 0; j < addServeId.length; j++) {
            for (var k = 0; k < addServeFee.length; k++) {
              if (addServeId[j] == addServeFee[k]["id"]) {
                this.addFee += parseFloat(addServeFee[k]["serve_price"]);
              }
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  font-size: 14px;
  width: 1200px;
  padding-top: 20px;
  margin: 0 auto;
  .big_title {
    padding: 20px 10px;
  }
  .countryDeliverAndOrder {
    border: 1px rgb(222, 222, 222) solid;
    min-height: 400px;
    padding: 20px;
  }

  h4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .deliver {
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-height: 111px;
    background: #f5f5f5;
    border-radius: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333;
    line-height: 20px;
  }

  .number {
    width: 20px;
    height: 20px;
    background-color: black;
    color: white;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    margin: 10px;
    font-size: 14px;
  }

  .middle {
    padding-left: 10px;
    padding-right: 10px;
  }

  .goodsHead {
    // border-bottom: 1px solid #ddd;
    font-size: 14px;
  }

  .header {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  .goodsInfo {
    box-sizing: border-box;
    font-size: 12px;
    padding-bottom: 30px;
    .goods_nav {
      width: 100%;
      font-size: 14px;
      text-align: center;
      padding: 18px 0;
      color: #333;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
    }
    .goods_nav [class*="el-col-"] {
      text-align: center;
    }
  }

  .bodyinfo {
    width: 100%;
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid #f2f2f2;
    .shopInfo {
      display: flex;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 10px 10px 0;
      // height: 32px;
      .icoTitle {
        width: 16px;
        height: 16px;
        margin-left: 20px;
        margin-right: 5px;
        float: left;
      }
      .title {
        float: left;
        line-height: 18px;
        color: #ef0c25;
        margin-right: 10px;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .store {
        float: left;
        line-height: 1.5;
        color: #ef0c25;
        font-size: 12px;
        border-radius: 2px;
        background: #fff;
        padding: 0 9px;
        border-radius: 12.5px;
        border: 1px solid #ef0c25;
      }
    }

    .goodsInfoBody {
      .el-row {
        padding: 10px 0;
        margin-top: 10px;
        background-color: #f5f5f5;
        position: relative;
        text-align: center;
      }
      .item-img {
        flex-shrink: 0;
        height: 80px;
        width: 80px;
        display: inline-block;
        border: 1px solid #eee;
        overflow: hidden;
        background: none;
        border-radius: 0;
        margin-left: 20px;
        img {
          height: 80px;
          width: 80px;
        }
      }
      .title-box {
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
        height: auto;
        text-align: left;
        .title {
          color: #333;
          height: 35px;
          width: 100%;
          overflow: hidden;
          font-size: 12px;
        }
        .title_desc {
          color: #999;
          font-size: 12px;
          margin-bottom: 4px;
          margin-top: 8px;
          overflow: hidden;
        }
        .no_return_or_change {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          padding: 0 4px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff8000;
          line-height: 14px;
          border: 1px solid #ff8000;
          border-radius: 2px;
          cursor: pointer;
        }
      }
      .price {
        padding-right: 30px;
        text-align: right;
        color: #ef0c25;
      }
    }
    .bottom {
      // border-bottom: 1px dashed #ddd;
      text-align: right;
      padding: 10px;
      color: #606166;
      .totle_title {
        margin-right: 30px;
        padding: 3px 0;
        color: #333;
      }
      .total {
        border-top: 1px dashed #f2f2f2;
        font-size: 16px;
        padding-top: 10px;
        .label {
          margin-right: 50px;
        }
        em {
          margin-right: 30px;
          font-weight: 700;
          font-size: 20px;
          color: #ef0c25;
        }
      }
    }
  }

  .fee {
    font-weight: 700;
    font-size: 30px;
    padding: 20px 40px;
    text-align: right;
    .title {
      color: #9c9ea6;
      font-size: 14px;
      margin-right: 40px;
    }
    .green {
      color: #ef0c25;
    }
  }
}
.submitbottom {
  padding-bottom: 20px;
  text-align: right;
  background-color: #fbfbfb;
  padding-right: 30px;
  .readme {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    li {
      padding: 0 5px;
      a {
        color: #606266;
      }
      label {
        display: inline-block;
        padding-left: 10px;
        line-height: 19px;
        font-size: 14px;
        color: #606266;
      }
    }
  }
  .tip {
    padding: 10px;
    color: #e1742b;
  }
  .submitButton {
    display: flex;
    justify-content: flex-end;
    button {
      border-color: #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
    button:hover {
      background: linear-gradient(to right, #e34d2d, #d60b20);
    }
  }
}
</style>
