import request from "@/utils/request";

export function index(url) {
    return request.get(url)
}

// 商品关键词搜索
export function goodsSearch(url) {
    return request.get(url)
}
// 轮播图
export function CarouselList(url) {
    return request.get(url)
}
// 商品推荐
export function goodsRecommend(url) {
    return request.get(url)
}

//运费估算
export function estimation(data) {
    var url = "/deliver/estimate"
    return request.post(url, data)
}

// 国家列表
export function getCountryList() {
    var url = "/index/getCountryList"
    return request.post(url)
}

// 获取国家路线和详情
export function getCountryDelivery(data) {
    var url = "/index/getCountryDelivery"
    return request.post(url, data)
}

// 分类搜索
export function getcategory() {
    var url = "/index/category"
    return request.post(url)
}

/**
 * 语言和货币
 */
export function getLanguageCountry() {
    var url = "/index/getLanguage"
    return request.get(url)
}

/**
 * 保存用户选中的语言和货币
 */
export function setLanguageCountry(data) {
    var url = "/index/setLanguageCurrency"
    return request.post(url, data)
}

/**
 * 获取货物分类
 */
export function getGoodsType() {
    var url = "/index/getGoodType"
    return request.post(url)
}


// getShopGoods
export function getShopGoods(data) {
    var url = "/index/getShopGoods"
    return request.post(url, data)
}

// 获取用户的等级信息和vip等级信息
export function myAuth() {
    var url = "/user/myAuth"
    return request.post(url)
}

// 获取用户的聊天记录
export function getMessage() {
    var url = "/index/getMessage"
    return request.post(url)
}

export function sendMessage(data) {
    var url = "/index/sendMessage"
    return request.post(url, data)
}


export function photoUpload(data) {
    var url = "/index/photoUpload"
    return request.post(url, data)
}

export function photoSearch(data) {
    var url = "/index/photoSearch"
    return request.post(url, data)
}

// 把网络地址转换成base64
export function convertImageToBase64(url, callback) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.onload = e => callback(e.target.result);
            reader.readAsDataURL(blob);
        })
        .catch(error => console.error('Error converting image to Base64:', error));
}


// 压缩图片
export function compressImage(base64String, maxWidth, maxHeight, quality) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
            let width = img.width;
            let height = img.height;
            // 计算缩放比例
            let scaleFactor = 1;
            if (width > maxWidth) {
                scaleFactor = maxWidth / width;
            }
            if (height > maxHeight) {
                scaleFactor = Math.min(scaleFactor, maxHeight / height);
            }
            // 创建Canvas元素
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = width * scaleFactor;
            canvas.height = height * scaleFactor;

            // 将图像绘制到Canvas上并导出为base64格式
            ctx.drawImage(img, 0, 0, width * scaleFactor, height * scaleFactor);
            const compressedBase64 = canvas.toDataURL("image/jpeg", quality);

            resolve(compressedBase64);
        };
        img.onerror = (error) => reject(error);
    });
}


//   获取通知

export function question() {
    var data ={}
    data["question"]=21
    var url = "/index/getQuestion"
    return request.post(url,data)
}




export function getArticleList() {
    var data ={}
    var url = "/index/getArticleList"
    return request.get(url,data)
}
