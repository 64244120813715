<template>
  <div>
    <header-top></header-top>
    <div class="q_notice">
      <div class="body w">
        <div class="title">
          <h1 v-html="title"></h1>
          <p v-html="create_time"></p>
        </div>
        <div class="info" v-html="this.inner"></div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "../mainPage/HeaderTop.vue";
import { getQuestionInfo } from "@/Api/other";
import Bottom from "../mainPage/Bottom.vue";
export default {
  components: { HeaderTop, Bottom },
  data() {
    return {
      title: "",
      create_time: "",
      inner: "",
    };
  },
  methods: {
    questionInfo() {
      var data = {};
      data["question"] = this.$route.query.requestId;
      getQuestionInfo(data).then((res) => {
        this.title = res.data.title;
        this.create_time = res.data.create_time_text;
        this.inner = res.data.content;
        console.log(res);
      });
    },
  },
  created() {
    this.questionInfo();
  },
};
</script>

<style lang="less" scoped>
.q_notice {
  background-color: #f5f5f5;
  .body {
    color: #4f4f4f;
    padding: 20px 0 20px;
    .title {
      border-radius: 10px;
      background-color: #fff;
      margin-top: 20px;
      padding: 20px;
      h1 {
        font-size: 27px;
        color: #1e222e;
      }
    }
    .info {
      border-radius: 10px;
      margin-top: 20px;
      padding: 20px;
      font-size: 14px;
      // border: 1px red solid;
      min-height: 200px;
      background-color: #fff;
      min-height: 600px;
    }
  }
}
</style>
