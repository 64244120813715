<template>
  <div class="out">
    <div class="sendorder">
    <!-- {{ sendOrderDetail }} -->
    <div>
      {{ $t('senddetail.Waybill_number') }}:&nbsp;{{ sendOrderDetail.send_code }}
    </div>
    <div>
      {{ $t('senddetail.Note') }}:&nbsp;{{ sendOrderDetail.remark }}
    </div>
    <div>
      {{ $t('senddetail.Shipping_name') }}:&nbsp;{{ sendOrderDetail.delivery_name }}
    </div>
    <div>
      {{ $t('senddetail.Destination_country') }}:&nbsp;{{ sendOrderDetail.country_name }}
    </div>
    <div>
      {{ $t('senddetail.Consignee') }}:&nbsp;{{ sendOrderDetail.consignee }}
    </div>
    <div>
      {{ $t('senddetail.Shipping_address') }}:&nbsp;{{ sendOrderDetail.province + "" + sendOrderDetail.city +" "+sendOrderDetail.address }}
    </div>
    <div>
      {{ $t('senddetail.Telephone') }}:&nbsp;{{ sendOrderDetail.tel }}
    </div>
    <div>
      {{ $t('senddetail.Submit_time') }}:&nbsp;{{ sendOrderDetail.add_time_text }}
    </div>
    <div>
      {{ $t('senddetail.Chargeable_Weight') }}:&nbsp;{{ sendOrderDetail.actual_wight }}
    </div>
    <div>
      {{ $t('senddetail.Shipping') }}:&nbsp;{{ this.moneyRate(sendOrderDetail.actual_freigh==null || sendOrderDetail.actual_freigh==""?0:sendOrderDetail.actual_freigh) }} {{  this.getCurrency }}
    </div>
    <div>
      {{ $t('senddetail.Volume') }}:&nbsp;{{ sendOrderDetail.actual_volume }}
    </div>
    <div>
      {{ $t('senddetail.Total_cost') }}:&nbsp;{{ this.moneyRate(sendOrderDetail.total_fee) }}  {{  this.getCurrency }}
    </div>
    <div>
      {{ $t('senddetail.insurance') }}:&nbsp;{{ this.moneyRate(sendOrderDetail.insurance_fee) }}  {{  this.getCurrency }}
    </div>
    <div>
      {{ $t('senddetail.Discounted_price') }}:&nbsp;{{ sendOrderDetail.coupon_price }} CNY
    </div>
    <div>
      {{ $t('senddetail.Waybill_status') }}:&nbsp;{{ sendOrderDetail.state }}
    </div>
    <div>
      {{ $t('senddetail.Value_added_services') }}:&nbsp;<i v-for="(item,index) in addserveArr" :key="index">{{ item }}</i>
    
      
    </div>
    
    
    
    
    </div>
    <div class="orderInfo">
        <div v-for="(item,index) in orderImgs" :key="index">
          <a :title="item.title">
            <img  :src="item.order_img">
          </a>
        </div>
    </div>
  </div>

</template>

<script>
import {orderImg} from "@/Api/send"
export default {
          props:["sendOrderDetail"],
          data(){
              return {
                addserveArr:[],
                orderImgs:[]
              }
          },  
          methods:{
            addserverArr(addServer){
              var json = JSON.parse(addServer)
              for (let key in json) {
                this.addserveArr.push(key)
                }
            },
            getOrderImg(){
              var data = {}
              data['sid']= this.sendOrderDetail.id
              orderImg(data).then((res)=>{
                    this.orderImgs=res.data
              })
            }
          },
          created(){
            this.addserverArr(this.sendOrderDetail.add_serve)
            this.getOrderImg()
          }

}
</script>

<style lang="less" scoped>
.out{
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 10px;
  .sendorder{
    div{
      margin-bottom: 10px;
    }
  }
  .orderInfo{
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    div{
      width: 40%;
      height: 200px;
      margin-bottom: 10px;
      box-shadow: 0 0 10px rgba(67, 67, 67, 0.8);
      img{
        margin: 0 auto;
        width: 100%;
        height: 200px;
    
      }

    }
  }
}
</style>