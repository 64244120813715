<template>
  <div class="invoice_page">
    <div class="nav">
      <div class="tip">
        {{
          $t(
            "invoice.Tip_Depending_on_the_customs_policy_of_the_destination_country_and_your_experience_filling_in_a_customs_invoice_can_reduce_or_avoid_customs_fees"
          )
        }}
      </div>
      <div class="inviceTable">
        <div class="button">
          <!-- <el-button @click="orderInfo" class="el-icon-info" plain
            >订单信息</el-button
          > -->
          <el-button @click="addItem" class="el-icon-plus buttonColor"   plain>{{
            $t("invoice.Add")
          }}</el-button>
          <el-button @click="importOrder" class="el-icon-upload2 buttonColor" plain>{{
            $t("invoice.Import_invoice")
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="address_con"></div>
      <el-card>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            :label="$t('invoice.Warehouse_ID')"
            prop="date"
            width="180"
            align="center"
          >
            <template slot-scope="scope">goods {{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            prop="gatename"
            :label="$t('invoice.Product_name')"
            width="280"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                :placeholder="$t('invoice.Please_enter_a_title')"
                v-model="scope.row.gatename"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="gatenum"
            :label="$t('invoice.qty')"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                :placeholder="$t('invoice.Please_enter_the_qty')"
                v-model="scope.row.gatenum"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="gateprice"
            :label="$t('invoice.Price')"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                :placeholder="$t('invoice.Please_enter_the_value')"
                v-model="scope.row.gateprice"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="$t('invoice.Operation')"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="deleteItem(scope.$index)"
                >{{ $t("invoice.Delete") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="server_con">
        <el-row>
          <el-col :span="12">
            <div class="label">
              <span>{{ $t("invoice.Shipping_address") }}</span>
              <span style="font-size: 15px">Address</span>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select v-if="address_list.length>0"
              class="delivery"
              v-model="address"
              :placeholder="$t('invoice.Please_select_a_shipping_address')"
            >
             
              <el-option
                v-for="item in address_list"
                :key="item.id"
                :label="
                  item.country +
                  item.state +
                  item.city +
                  ' ' +
                  item.consignee +
                  ' ' +
                  item.tel
                "
                :value="item.id"
              >
                <span style="float: left">{{
                  item.country + item.state + item.city
                }}</span>
                <div>
                  <span> {{ item.address }} </span>
                  <h4>
                    &nbsp;&nbsp;{{ item.consignee }}&nbsp;&nbsp;{{ item.tel }}
                  </h4>
                </div>
              </el-option>
            </el-select>
            <el-button class="buttonColor" v-else type="success" @click="$router.push({path:'/person/account',query:{address:1}})">{{ $t('invoice.Create_address') }}</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="label">
              <span>{{ $t("invoice.Logistics_route") }} </span>
              <span style="font-size: 15px">Line</span>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select
              class="delivery"
              v-model="delivery"
              :placeholder="$t('invoice.Please_select_a_logistics_route')"
            >
              <el-option
                v-for="item in deliveryInfos"
                :key="item.id"
                :label="combination(item)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="label">
              <span>{{ $t("invoice.Waybill_value_added_service_") }} </span>
              <span style="font-size: 15px">Serve</span>
            </div>
          </el-col>
          <el-col :span="12">
            <!-- class="couponElSelect" -->
            <el-select
              
              v-model="form.type"
              :placeholder="$t('invoice.Waybill_value_added_service')"
              multiple
            >
              <el-option
                v-for="(item, index) in addServeSend"
                :key="index"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="label">
              <span>{{ $t("invoice.Coupon") }} </span>
              <span style="font-size: 15px">Coupon</span>
            </div>
          </el-col>
          <el-col :span="12">
            <el-select 
              class="couponElSelect"
              v-model="couponId"
              :placeholder="couponData.length>0?$t('invoice.You_have_available_coupons'):$t('invoice.No_coupons_available_yet')"
            >
              <el-option
                v-for="item in filterCouponData"
                :key="item.id"
                :label="rename(item)"
                :value="item.id"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="label">
              {{ $t("invoice.Whether_to_purchase_insurance") }}
              {{ $t("invoice.insure") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  $t(
                    'invoice.The_insurance_fee_is_3_of_the_total_price_of_the_goods'
                  )
                "
                placement="top-start"
              >
                <el-radio v-model="radioValue" label="1">YES</el-radio>
              </el-tooltip>
              <el-radio v-model="radioValue" label="2">NO</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="is_rehearsal == 1">
          <el-col :span="12">
            <div class="label">
              {{ $t("invoice.Rehearsal_fee") }} {{ $t("invoice.rehearsal") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pay_con">
              <h3 class="money">15 CNY</h3>
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  $t(
                    'invoice.You_can_pay_the_rehearsal_fee_in_advance_to_ensure_that_the_package_is_packed_in_time_thereby_avoiding_additional_payments_and_making_the_delivery_process_faster_and_more_efficient_allowing_you_to_receive_the_package_faster'
                  )
                "
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="is_rehearsal == 1">
          <div class="pay_con">
            <el-button type="success" class="buttonColor" @click="submitRehearsal">{{
              $t("invoice.Submit_package")
            }}</el-button>
          </div>
        </el-row>
        <el-row v-if="is_rehearsal != 1">
          <el-col :span="12">
            <div class="label">
              {{ $t("invoice.Estimated_total_cost") }}{{ $t("invoice.Total") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="pay_con">
              <h3 class="money">≈{{ this.moneyRate(this.sumMoney) }} {{ this.getCurrency }}</h3>
              <el-button type="success" class="buttonColor" @click="toPay">{{
                $t("invoice.pay")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { wareHouseOrderInvoice, DeliveryByOrder } from "@/Api/order";
import {
  sendAddServe,
  couponList,
  createSendorder,
  createRehearsalSendorder,
} from "@/Api/send";
import { getUserAddressList } from "@/Api/user";

export default {
  data() {
    return {
      sid: "",
      country: "",
      tableData: [],
      addServeSend: [],
      form: { type: [] },
      deliveryInfos: [],
      couponData: [],
      couponId: "",
      gateprice: "",
      delivery: "",
      address_list: [],
      address: "",
      orderInfos: [],
      sumMoney: 0,
      radioValue: 0,
      is_rehearsal: this.$route.query.is_rehearsal,
      filterCouponData:[]
    };
  },
  watch: {
    couponId: {
      handler() {
        this.computer();
      },
    },
    delivery: {
      handler() {
        this.computer();
      },
    },
    "form.type": {
      deep: true,
      handler() {
        this.computer();
      },
    },
  },
  methods: {
    // 提交包裹
    submitRehearsal() {
      if (this.tableData.length == 0) {
        this.$message.info(this.$t("invoice.Please_fill_in_the_invoice"));
        return;
      }
      if (this.delivery == 0 || this.delivery == "") {
        this.$message.info(this.$t("invoice.Please_select_a_logistics_route"));
        return;
      }
      if (this.radioValue == 0) {
        this.$message.info(
          this.$t("invoice.Please_choose_whether_to_purchase_insurance")
        );
        return;
      }
      this.computer();
      var gname = [];
      var gatePrice = [];
      var gateSumPrice = [];
      this.tableData.forEach((item) => {
        gname.push(item.gatename);
        if (item.gatenum == 1) {
          gatePrice.push(item.gateprice);
          gateSumPrice.push(item.gateprice);
        } else {
          gatePrice.push(parseFloat(item.gateprice) / parseFloat(item.gatenum));
          gateSumPrice.push(item.gateprice);
        }
      });
      var data = {};
      data["order"] = this.$route.query.sendId;
      data["countryId"] = this.$route.query.country;
      data["delivery_id"] = this.delivery;
      data["addServe"] = this.form.type;
      data["couponId"] = this.couponId;
      data["gate_name"] = gname.join("<;>");
      data["gate_price"] = gatePrice.join("<;>");
      data["gate_sum_price"] = gateSumPrice.join("<;>");
      data["address"] = this.address;
      data["insure"] = this.radioValue;
      data["rehearsal"] = 1;
      createRehearsalSendorder(data).then((res) => {
        if (res.code == 1) {
          this.$router.push({
            path: "/paysend",
            query: {
              sendcode: res.data,
              rehearsal:1
            },
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //跳转支付
    toPay() {
      if (this.tableData.length == 0) {
        this.$message.info(this.$t("invoice.Please_fill_in_the_invoice"));
        return;
      }
      if (this.delivery == 0 || this.delivery == "") {
        this.$message.info(this.$t("invoice.Please_select_a_logistics_route"));
        return;
      }
      if (this.radioValue == 0) {
        this.$message.info(
          this.$t("invoice.Please_choose_whether_to_purchase_insurance")
        );
        return;
      }
      this.computer();
      var gname = [];
      var gatePrice = [];
      var gateSumPrice = [];
      this.tableData.forEach((item) => {
        gname.push(item.gatename);
        if (item.gatenum == 1) {
          gatePrice.push(item.gateprice);
          gateSumPrice.push(item.gateprice);
        } else {
          gatePrice.push(parseFloat(item.gateprice) / parseFloat(item.gatenum));
          gateSumPrice.push(item.gateprice);
        }
      });
      var data = {};
      data["order"] = this.$route.query.sendId;
      data["countryId"] = this.$route.query.country;
      data["delivery_id"] = this.delivery
      data["addServe"] = this.form.type;
      data["couponId"] = this.couponId;
      data["gate_name"] = gname.join("<;>");
      data["gate_price"] = gatePrice.join("<;>");
      data["gate_sum_price"] = gateSumPrice.join("<;>");
      data["address"] = this.address;
      data["insure"] = this.radioValue;
      createSendorder(data).then((res) => {
        if (res.code == 1) {
          this.$router.push({
            path: "/paysend",
            query: {
              sendcode: res.data,
              rehearsal:0
            },
          });
          // this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //跳转支付
    computer() {
      // 计算物流
      this.sumMoney = 0;
      this.filterCouponData=[]
      this.deliveryInfos.forEach((item) => {
        if (item.id == this.delivery) {
          this.sumMoney = item.sumFee;
          this.couponData.forEach((item2)=>{
              if(parseFloat(item.sumFee)>=parseFloat(item2.rule.required)){
                this.filterCouponData.push(item2)
              }
          })
        }
      });
    
      //  计算优惠券
      this.couponData.forEach((item) => {
        if (item.id == this.couponId) {
          if (item.coupon_type == 2) {
            //运单满减
            if (this.sumMoney >= item.rule.required) {
              this.sumMoney = this.sumMoney - item.rule.money;
            }
          }
          //运单折扣
          if (item.coupon_type == 3) {
            this.sumMoney = this.sumMoney * parseFloat(item.money);
          }
        }
      });
      // 计算增值服务
      this.addServeSend.forEach((item) => {
        if (this.form.type.indexOf(item.id) >= 0) {
          this.sumMoney =
            parseFloat(this.sumMoney) + parseFloat(item.serve_price);
          console.log(item.id);
        }
      });
    },
    combination(delivery) {
      var info = "";
      info =
        delivery.deliver.delivery_name +
        ":" +
        delivery.area +
        this.$t("invoice.Estimated_cost") +" "+
        this.moneyRate(delivery.sumFee)+" "+this.getCurrency
        // this.$t("invoice.First_weight") +
        // delivery.first_weight +
        // this.$t("invoice.Fee") +
        // delivery.first_fee +
        // this.$t("invoice.Additional_weight") +
        // delivery.conutine_weight +
        // this.$t("invoice.Fee") +
        delivery.conutine_fee;
      return info;
    },
    // 导入订单
    importOrder() {
      var oid = this.$route.query.sendId;
      var data = {};
      data["oid"] = oid;
      wareHouseOrderInvoice(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
          return;
        }
        this.tableData = res.data.gateInfo;
        this.orderInfos = res.data.data;
      });
    },
    addItem() {
      this.tableData.push({
        gatename: "",
        gatenum: "",
        gateprice: "",
      });
    },
    deleteItem(index) {
      this.tableData.splice(index, 1);
    },
    checkPAudioParam() {
      var query = this.$route.query;
      this.sid = query["sendId"];
      this.country = query["country"];
      if (this.sid == "" || this.sid == null) {
        this.$message.info(
          "this.$t('invoice.Please_return_to_the_previous_step_to_select_the_warehouse_order_information')"
        );
        location.href = "/person/wareHouse";
        return;
      }
      if (this.country == "" || this.country == null) {
        this.$message.info(
          "this.$t('invoice.Please_return_to_the_previous_step_to_select_the_country_information')"
        );
        location.href = "/person/wareHouse";
        return;
      }
    },
    sendAddServeList() {
      sendAddServe().then((res) => {
        this.addServeSend = res.data;
      });
    },
    //     推荐物流
    getDeliveryByOrder() {
      var data = {};
      data["ids"] = this.$route.query.sendId;
      data["countryId"] = this.$route.query.country;
      DeliveryByOrder(data).then((res) => {
        this.deliveryInfos = res.data;
        console.log(this.deliveryInfos);
      });
    },
    // 优惠券
    getCouponList() {
      var data = {};
      data["oids"] = this.$route.query.sendId;
      couponList(data).then((res) => {
        this.couponData = res.data;
      });
    },
    //根据优惠券的类型和金额 返回字符串
    rename(item) {
      var str = "";
      if (item.coupon_type == 2) {
        str +=
          this.$t('invoice.Waybill_full') +
          item.rule.required +
          this.$t("invoice.discount") +
          item.money +
          this.$t("invoice.Yuan");
      } else if (item.coupon_type == 3) {
        str +=
          "this.$t('invoice.Waybill_discount')" +
          item.money * 10 +
          "this.$t('invoice.Discount')";
      }
      return str;
    },
    getUserAddressList() {
      //地址列表
      getUserAddressList().then((res) => {
        if (res.code === 1) {
          this.address_list = res.data;
        }
      });
    },
  },
  created() {
    this.checkPAudioParam();
    this.sendAddServeList();
    this.getDeliveryByOrder();
    this.getCouponList();
    this.getUserAddressList();
  },
};
</script>

<style lang="less" scoped>
.buttonColor{
  background: linear-gradient(to right, #fd5532, #ef0c25);
    color: #fff;
}
.buttonColor:hover {
    background: linear-gradient(to right, #e34d2d, #d60b20);
  }
.invoice_page {
  padding: 30px;
  .server_con {
    margin: 20px 0;
    padding: 0px 20px;
    .el-row {
      display: flex;
      align-items: center;
      margin: 20px 0;
      .label {
        font-weight: 600;
        font-size: 17px;
      }
      .pay_con {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .money {
          color: #ef0c25;
          font-weight: 600;
          margin-right: 20px;
        }
        button {
          height: 45px;
          border: none;
        }
      }
      .el-select {
        width: 100%;
        .el-input {
          .el-input--suffix .el-input__inner {
            border: none !important;
            font-size: 17px !important;
          }
          .el-input--suffix .el-input__inner::placeholder {
            font-size: 17px !important;
          }
        }
      }
    }
    .el-select-dropdown__item {
      height: auto !important;
    }
  }
}
.body {
  padding-top: 20px;
  padding-left: 20px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);
  min-height: 500px;
  border-radius: 10px;
  font-size: 14px;
}
.inviceTable {
  .button {
    margin-bottom: 10px;
    text-align: right;
  }
}
.bottom {
  margin-top: 20px;
  // display: flex;
  // justify-content: space-around;

  .sendServer,
  .deliver {
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);
    min-height: 300px;
    min-width: 48%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
  }
  // padding-bottom: 20px;
}
.coupon {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);
  // border: 1px red solid;
  .couponList {
    // border: 1px red solid;
    padding: 20px;
  }
  .couponElSelect {
    width: 400px;
  }
}
.sumMoney {
  padding: 20px;
  text-align: right;
}
</style>
