import axios from 'axios';
const baseURL = 'https://seacloudbuy.com/api/'; // 设置你的API基础URL
import {
    Loading
} from 'element-ui';


//判断现在的域名是否是seacloudbuy
function checkSea(){
    var path = location
    if (path.hostname==="seacloudbuy.com"){
        location.href="https://www.seacloudbuy.com"
    }
}

/**
 * 检查是否是移动设备
 */
function checkIsMobile(){
    var isTrue=  window.innerWidth < 1230;
    console.log(isTrue)
    console.log(window.innerWidth)
    if(isTrue){
        location.href="https://m.seacloudbuy.com"
    }
  
}
checkSea()
checkIsMobile()
const instance = axios.create({
    baseURL,
    timeout: 30000, // 设置请求超时时间
    headers: {
        'Content-Type': 'application/json',
        // 在这里可以设置其他默认的请求头
    },
});
let loadingInstance = null
var filter = ["/login", "/register", "/goodsDetail", "/forget"];
// 请求拦截器
instance.interceptors.request.use(
    config => {
        if (filter.indexOf(location.pathname) >= 0) {
            loadingInstance = Loading.service({
                "text": "Loading...",
                "background": "rgba(0,0,0,0.5)",
                "target": "body",
                spinner: 'el-icon-loading',
            })
        }

        var token = localStorage.getItem("token")
        if (token != null && token != "null") {
            config.headers.token = token
        }
        var to = localStorage.getItem("langIndex")
        if (to==null || to==="null" || to === "English") {
            to="en"
          } else if (to === "German") {
            to="de"
          } else if (to === "Portuguese") {
            to="pt"
          } else if (to === "中文") {
            to="zh-CHS"
          }
        var currency = localStorage.getItem("currIndex")
        if (currency == null || currency === "null") {
            currency = "USD"
        }
        config.headers.currency = currency
        config.headers.lang = to
		config.setTimeout=30000
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 响应拦截器
instance.interceptors.response.use(
    response => {
        if (location.pathname === "/goodsDetail") {
            setTimeout(() => {
                if (loadingInstance != null) {
                    loadingInstance.close();
                }
            }, 1000);
        } else {
            if (loadingInstance != null) {
                loadingInstance.close();
            }
        }

        return response.data;
    },
    error => {
        // 对响应错误做点什么
        try {
            var code = error.response.status
            if (code === 401) {
                localStorage.removeItem("token")
                localStorage.removeItem("userinfo")
                localStorage.removeItem("currIndex")
                localStorage.removeItem("rate")
                localStorage.removeItem("langIndex")
                localStorage.removeItem("imgSearch")
                location.href = "/login"
            }
        } catch (error) {
            // console.log(error)
            return
        }

        // return Promise.reject(error);
    }
);
export default instance;