import { render, staticRenderFns } from "./UserForget.vue?vue&type=template&id=72cc2404&scoped=true"
import script from "./UserForget.vue?vue&type=script&lang=js"
export * from "./UserForget.vue?vue&type=script&lang=js"
import style0 from "./UserForget.vue?vue&type=style&index=0&id=72cc2404&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72cc2404",
  null
  
)

export default component.exports