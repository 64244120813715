<template>
  <div>
    <header-top></header-top>
    <div class="login">
      <div class="content w">
        <div class="backimg"></div>
        <div class="loginForm">
          <div class="form">
            <h2 class="title">{{ $t("userlogin.Welcome_to_log_in") }}</h2>
            <div id="input">
              <el-input
                prefix-icon="el-icon-message"
                type="email"
                class="input"
                v-model="email"
                :placeholder="$t('userlogin.Please_enter_your_email_address')"
              ></el-input>
              <el-input
                prefix-icon="el-icon-lock"
                type="password"
                id="password"
                class="input"
                v-model="password"
                :placeholder="$t('userlogin.Please_enter_your_password')"
              >
                <i
                  slot="suffix"
                  @click="togglePasswordVisibility()"
                  class="el-input__icon el-icon-view"
                ></i>
              </el-input>
              <div style="display: flex; align-items: center">
                <el-input
                  class="input"
                  style="width: 50%; float: left; margin-left: 20px"
                  v-model="captcha"
                  :placeholder="
                    $t('userlogin.Please_enter_your_verification_code')
                  "
                ></el-input>
                <img
                  class="captcha"
                  @click="getCaptchaImg"
                  :src="code"
                  style="width: 33%; height: 40px"
                />
              </div>
            </div>
            <el-row style="text-align: center; margin-bottom: 10px">
              <el-button style="width: 90%;" @click="tologin" type="success">{{
                $t("userlogin.Login")
              }}</el-button>
            </el-row>
            <div class="l_bottom">
              <router-link to="/forget">{{
                $t("userlogin.Forgot_your_password")
              }}</router-link>
              <a style="cursor: pointer" @click="toregister">{{
                $t("userlogin.Register")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "../mainPage/HeaderTop.vue";
import { login, getCaptcha } from "@/Api/user";
import Bottom from "../mainPage/Bottom.vue";
export default {
  name: "user-login",
  data() {
    return {
      email: "",
      password: "",
      captcha: "",
      code: "",
      t: "",
    };
  },
  components: {
    HeaderTop,
    Bottom,
  },

  methods: {
    toregister() {
      var goodsUlr = localStorage.getItem("goodsUrl");
      if (goodsUlr == null || goodsUlr == "null") {
        this.$router.push({
          path: "/register",
        });
      }
      var params = goodsUlr.split("&");
      params.forEach((v) => {
        if (v.indexOf("inviter") >= 0) {
          var inviter = v.split("=");
          this.$router.push({
            path: "/register",
            query: {
              invite: inviter[1],
            },
          });
        }
      });
    },
    togglePasswordVisibility() {
      var passwordInput = document.getElementById("password");

      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    getCaptchaImg() {
      this.t = Date.now();
      this.code = getCaptcha(this.t);
    },
    tologin() {
      login(this.email, this.password, this.captcha, this.t).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          var userinfo = res.data.userinfo;
          localStorage.setItem("userinfo", JSON.stringify(userinfo));
          localStorage.setItem("token", userinfo.token);
          var goodsUlr = localStorage.getItem("goodsUrl");
          if (goodsUlr === null || goodsUlr === "null") {
            localStorage.removeItem("goodsUrl");
            this.$router.push({ path: "/" });
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "success",
            });
            localStorage.removeItem("goodsUrl");
            location.href = goodsUlr;
          }
        }
      });
    },
  },
  created() {
    this.getCaptchaImg();
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 1200px;
  height: 100%;
  background-image: url("/src/assets/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  justify-content: flex-end;
}

.login {
  width: 100%;
  height: 720px;
  background-color: black;
}

.loginForm {
  padding: 40px;
  // margin-top: 80px;
}

.form {
  width: 340px;
  margin-right: 30px; // 7.23
  // height: 580px;
  height: 620px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 20px;
  .l_bottom {
    display: flex;
    width: 90%;
    font-size: 12px;
    margin: 0 auto;
    justify-content: space-between;
    a {
      margin-top: 2px;
      cursor: pointer;
      color: #606266;
    }
    a:hover {
      color: #ef0c25;
    }
  }
}

.content {
  margin: 0 auto;
  display: flex;
}

.title {
  margin: 0 auto;
  width: 50%;
  padding: 20px;
  text-align: center;
}

.input {
  width: 90%;
  text-align: center;
  margin: 10px;
}

#input {
  text-align: center;
}
</style>
