import Vue from 'vue'
import router from 'vue-router'
Vue.use(router)

import userRecharge from '@/components/user/userRecharge.vue'
import cartInfo from '@/components/cart/cartInfo.vue'
import payorder from '@/components/cart/payorder.vue'
import paysend from '@/components/cart/paysend.vue'
import MainPage from '@/components/mainPage/MainPage.vue'
import GoodsList from '@/components/goods/GoodsList.vue'
import GoodsDetail from '@/components/goods/GoodsDetail.vue'
import GoodsImageSearch from '@/components/goods/GoodsImageSearch.vue'
import person from '@/pages/person/index.vue'
import center from '@/pages/person/center.vue'
import cart from '@/pages/person/cart.vue'
import collection from '@/pages/person/collection.vue'
import login from '@/components/user/Userlogin.vue'
import register from '@/components/user/UserRegister.vue'
import forget from '@/components/user/UserForget.vue'
import estimation from '@/components/mainPage/Estimation.vue'
import orderList from '@/pages/person/orderList.vue'
import wareHouse from '@/pages/person/wareHouse.vue'
import preview from '@/pages/person/preview'
import parcel from '@/pages/person/parcel'
import coupon from '@/pages/person/coupon'
import wallet from '@/pages/person/wallet'
import points from '@/pages/person/points'
import message from '@/pages/person/message'
import promotion from '@/pages/person/promotion'
import ShopGoods from '@/components/goods/ShopGoods.vue'
import userMessage from '@/components/user/UserMessage.vue'
import suspendBall from '@/components/user/suspendBall.vue'
import track from '@/components/others/trackInfo.vue'
import timeLine from '@/components/others/timeLine.vue'
import account from '@/pages/person/account.vue'
import invoice from '@/components/ship/invoice.vue'
import question from '@/components/others/question.vue'
import couponlist from '@/pages/person/couponlist.vue'
import more from '@/components/others/more.vue'

export default new router({
    mode: 'history',
    routes: [ //配置路由，这里是个数组
        {
            path: "/",
            name: 'MainPage',
            component: MainPage,
        },
        {
            path: "/goodsList",
            name: 'GoodsList',
            component: GoodsList,
        },
        {
            path: "/goodsDetail",
            name: 'GoodsDetail',
            component: GoodsDetail,
        },
        {
            path: "/goodsImageSearch",
            name: 'GoodsImageSearch',
            component: GoodsImageSearch,
        },
        {
            path: "/login",
            name: 'login',
            component: login,
        },

        {
            path: "/register",
            name: 'register',
            component: register,
        },
        {
            path: "/forget",
            name: 'forget',
            component: forget,
        },
        {
            path: "/estimation",
            name: 'estimation',
            component: estimation,
        },
        {
            path: "/cartInfo",
            name: 'cartInfo',
            component: cartInfo,
        },
        {
            path: "/payorder",
            name: 'payorder',
            component: payorder,
        },
        {
            path: "/paysend",
            name: 'paysend',
            component: paysend,
        },
        {
            "path": "/userRecharge",
            "name": "userRecharge",
            component: userRecharge,
        },
        {
            "path": "/ShopGoods",
            "name": "ShopGoods",
            component: ShopGoods,
        },
        {
            "path": "/userMessage",
            "name": "userMessage",
            component: userMessage,
        },
        {
            "path": "/suspendBall",
            "name": "suspendBall",
            component: suspendBall,
        },
        {
            "path": "/track",
            "name": "track",
            component: track,
        },
        {
            "path": "/timeLine",
            "name": "timeLine",
            component: timeLine,
        },
        {
            "path": "/question",
            "name": "question",
            component: question,
        },
		{
			path: "/more",
			name: 'more',
			component: more,
		},
        {
            path: "/person",
            name: 'person',
            component: person,
            redirect: '/person/center',
            children: [
				{
                    path: "center",
                    name: 'person-center',
                    component: center,
                },
                {
                    path: "cart",
                    name: 'person-cart',
                    component: cart,
                },
                {
                    path: "collection",
                    name: 'person-collection',
                    component: collection,
                },
                {
                    path: "orderList",
                    name: 'person-orderList',
                    component: orderList,
                },
                {
                    path: "wareHouse",
                    name: 'wareHouse',
                    component: wareHouse,
                },
                {
                    path: "preview",
                    name: 'preview',
                    component: preview,
                },
                {
                    path: "parcel",
                    name: 'parcel',
                    component: parcel,
                },
                {
                    path: "coupon",
                    name: 'coupon',
                    component: coupon,
                },
                {
                    path: "wallet",
                    name: 'wallet',
                    component: wallet,
                },
                {
                    path: "points",
                    name: 'points',
                    component: points,
                },
                {
                    path: "message",
                    name: 'message',
                    component: message,
                },
                {
                    path: "promotion",
                    name: 'promotion',
                    component: promotion,
                },
                {
                    path: "account",
                    name: 'account',
                    component: account,
                },
                {
                    path: "invoice",
                    name: 'invoice',
                    component: invoice,
                },
                {
                    path: "couponlist",
                    name: "couponlist",
                    component: couponlist,
                },

            ]
        },
    ]
})