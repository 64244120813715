<template>
  <div>
    <header-top></header-top>
    <div class="controller">
      <h1>{{ $t("userrecharge.Checkout_counter") }}</h1>
      <div class="rechargeList">
        <ul>
          <li
            v-for="(item, index) in chargeList"
            :key="index"
            @click="selectItem(item.title)"
            :class="{ p_height: item.title === 'PayPal' }"
          >
            <div class="radioImg">
              <span>
                <input type="radio" :checked="item.title == selectResult" />
              </span>
              <img :src="item.src" />
              <span>{{ item.title }} </span>
            </div>
            <div class="infoMessage">
              <span>{{ item.desc }}</span>
            </div>
            <div v-if="item.title === 'PayPal'">
              <div class="btns">
                <button
                  v-for="(item, index) in btnList"
                  :key="index"
                  @click="activateButton(index)"
                  :class="{ selected: selectedIndex === index }"
                >
                  {{ item }}
                </button>
              </div>
              <div class="notive_bar">
                <van-notice-bar
                  :scrollable="false"
                  :text="$t('userrecharge.Please_keep_the_actual_shipping_address_of_the_product_consistent_with_the_PayPal_delivery_address_otherwise_it_will_cause_customs_clearance_failure_and_affect_the_timeliness')"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="payBottom">
        <div class="pay_content">
          <div class="pay_box">
            <div>
              <span>{{ $t("userrecharge.Total_price") }}</span>
              <span class="price"
               v-if="isfirst"  >{{ currencyCode }} &nbsp;
                {{ this.moneyRate(moneys.sumMoney) }}</span
              >
              <span class="price"
              v-else  >{{ currentType }} &nbsp;
                {{ parseFloat(convertyMoney+moneys.serveFee).toFixed(3) }}
                </span
              >
            </div>
            <div class="bottom">
              <div class="pay_all">
                <span>{{ $t("userrecharge.Payables") }}</span>
                <span class="zjMoney" v-if="isfirst"
                  >{{ currencyCode }} &nbsp;
                  {{ this.moneyRate(moneys.sumMoney) }}&nbsp;</span
                >
                <span class="zjMoney"  v-else
                  >{{ currentType }} &nbsp;
                  {{ convertyMoney }}&nbsp;</span
                >
              </div>
              <div>
                <span>{{ $t("userrecharge.Handling_fee") }}</span>
                <span>{{ moneys.serveFee !=null?moneys.serveFee:0 }}</span>
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button type="success" @click="payConfirm">{{
              $t("userrecharge.Confirm_payment")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div id="payform"></div>
    <bottom></bottom>
  </div>
</template>

<script>
import HeaderTop from "../mainPage/HeaderTop.vue";
import { recharge,rechargeCurrencyToMoney } from "@/Api/pay";
import Bottom from "../mainPage/Bottom.vue";
import {isSetDefaultAddress} from "@/Api/user"
export default {
  components: { HeaderTop, Bottom },
  name: "user-recharge",
  data() {
    return {
      btnList: ["USD", "GBP", "EUR"],
      selectedIndex: null,
      radio: "",
      chargeList: [
        {
          src: "http://seacloudbuy.com/uploads/20240405/5a8eef7843c2f3eb13624e1f522f7c86.png",
          title: "PayPal",
          desc: this.$t(
            "userrecharge.Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks"
          ),
        },
        {
          src: "http://seacloudbuy.com/uploads/20240428/59ca328d7f4e2bf2dba83369af0530e6.png",
          title: "Alipay",
          desc: this.$t(
              "userrecharge.Cooperation_with_the_People_s_Bank_of_China"
          ),
        },
      ],
      currentType:"USD",
      selectResult: false,
      moneys: [],
      currencyCode: "CNY",
      isfirst:true,
      convertyMoney:0
    };
  },
  watch:{
    selectResult:{
      handler(value){
        if(value!="PayPal"){
          this.activateButton(-1)
        }else if(this.selectedIndex==null){
          this.activateButton(0)
        }
      }
    }
  },
  methods: {
    isSetDefaultAddress(){
      isSetDefaultAddress().then((res)=>{
        if (res.code===1){
          this.$message.error(res.msg)
          this.$router.push({path:'/person/account',query:{address:1}})
        }
      })
    },
    activateButton(index) {
      if(index>=0){
        this.currentType=this.btnList[index]
        this.selectedIndex = index;
        this.toRechargeCurrencyToMoney()
        this.isfirst=false
      }else{
        this.selectedIndex=null
        if (this.selectResult==="Alipay"){
          this.currentType="CNY"
          this.toRechargeCurrencyToMoney()
        }
      }
    },
    toReCharge() {
      this.$router.push({
        path: "/userRecharge",
      });
    },

    selectItem(title) {
      this.selectResult = title;
    },
    payConfirm() {
      var data = {};

      if (this.selectResult == false) {
        this.$message.error(
          this.$t("userrecharge.Please_select_mode_of_payment")
        );
        return;
      }
      if (this.selectResult === "PayPal") {
        data["platform"] = "PayPal";
        if(this.isfirst===false){
          data["money"]= this.convertyMoney
          data["rate"]=this.moneys.serveFee
        }else{
          data["money"]=this.moneyRate(this.$route.query.money)
        }
        data["type"]=this.currentType
        recharge(data).then((res) => {
          if (res.code === 1 && res.data == null) {
            this.$message.info(res.msg);
          }else if (res.code === -1) {
            this.$message.error(res.msg)
            this.$router.push({path:'/person/account',query:{address:1}})
          } else {
            document.getElementById("payform").innerHTML =
              '<form id="gopay" action="' +
              res.data +
              '" method="post" ></form>;';

            // target="_blank"
            document.getElementById("gopay").submit();
          }
        });
      }
      if (this.selectResult === "Alipay") {
        data["platform"] = "zfb";
        data["money"]= parseFloat(this.convertyMoney)
        data["rate"]=parseFloat(this.moneys.serveFee)
        recharge(data).then((res) => {
          if (res.code === 0) {
            this.$message.error(res.msg);
          } else {
            if (res.code === 0) {
              this.$message.error(res.msg);
            } else {
              // 由于支付宝返回的是个form表单  调用form表单的单击事件
              const div = document.createElement("div");
              div.innerHTML = res.data.data;
              document.body.appendChild(div);
              document.forms.alipaysubmit.setAttribute("target", "_blank");
              document.forms.alipaysubmit.submit();
            }
          }
        });
      }
      return;
    },
    toRechargeCurrencyToMoney(){
      var data ={}
      data["type"]=this.currentType
      data["money"]=this.$route.query.money
      rechargeCurrencyToMoney(data).then((res)=>{
        this.convertyMoney=res.data.money
        this.moneys = res.data
      })
    }
  },
  created() {
    this.currencyCode = this.getCurrency;
    this.toRechargeCurrencyToMoney()
    this.isfirst=false
    this.isSetDefaultAddress()
  },
};
</script>

<style lang="less" scoped>
.controller {
  padding-top: 20px;
  font-size: 14px;
  width: 1200px;
  margin: 0 auto;
  min-height: 400px;
  .orderStatus {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  span {
    h1 {
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.rechargeList {
  margin-top: 20px;
  border-top: 1px rgb(222, 222, 222) solid;
  border-left: 1px rgb(222, 222, 222) solid;
  border-right: 1px rgb(222, 222, 222) solid;
  ul li:hover {
    background-color: rgb(222, 222, 222);
  }
  .p_height {
    height: auto;
  }
  ul li {
    // display: flex;
    // justify-content: space-between;
    border-bottom: 1px rgb(222, 222, 222) solid;
    padding: 15px;
    align-items: center;
    height: 90px;
    line-height: 50px;

    .radioImg {
      float: left;

      display: flex;
      // justify-content: center;
      // align-content: center;

      vertical-align: middle;

      span {
        margin-left: 5px;
      }
      .money {
        width: 100px;
        text-align: center;
        border-radius: 10px;
        background: rgba(17, 186, 102, 0.1);
        border: 1px solid #ef0c25;
        color: #ef0c25;
      }
    }
    .infoMessage {
      float: right;
      font-size: 12px;
      // 右边的间距
      padding-right: 20px;
    }
    .btns {
      margin-top: 50px;
      .selected {
        color: #ef0c25;
        border-color: #ef0c25;
        background: #f7e5e6;
      }
      button {
        padding: 0 18px;
        min-width: 65px;
        height: 30px;
        background: #fff;
        border-radius: 20px;
        border: 1px solid #d8d8d8;
        cursor: pointer;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
    }
    .notive_bar {
      font-size: 12px;
      border-radius: 2px;
    }
    .bottom {
      display: flex;
      // 右边的间距
      padding-right: 20px;
      p {
        padding: 0 5px;
        color: #ef0c25;
        font-weight: 400;
        font-size: 12px;
        border-radius: 5px;
        // background-color: #fcf8d9;
      }
    }
    .doller {
      span {
        margin-left: 5px;
      }
    }
    img {
      width: 50px;
    }
  }
}
.payBottom {
  min-width: 1200px;
  padding: 20px 0;
  .pay_content {
    max-width: 1200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    .pay_box {
      text-align: right;
      height: 60px;
      margin-right: 10px;
      font-size: 12px;
      font-family: PingFang-SC;
      color: #666;
      position: relative;
      .price {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ef0c25;
      }
      .bottom {
        display: flex;
      }
    }
    .btn {
      button {
        width: 180px;
        height: 60px;
        font-size: 18px;
        border-color: #ef0c25;
        background: linear-gradient(to right, #fd5532, #ef0c25);
      }
      button:hover {
        background: linear-gradient(to right, #e34d2d, #d60b20);
      }
    }
  }
}
</style>
