export default {
        middle: {
                Shop_China_with_Seacloudbuy: "跨海云购        畅游中国购物桥",
                Please_enter_keywords_or_product_links: "请输入关键字或商品链接",
                goods: "商品",
                Choose: "选择",
                Shop: "店铺",
                Buyer_purchase_order: "买家下单",
                Merchants_receive_orders: "商家接单",
                Verify_parcel: "核验包裹",
                Transaction_complete: "交易完成",
                Search: "搜索",
        },
        // describe: {
        //         One_stop_service: "一站式服务",
        //         One_click_order: "便捷式一键下单",
        //         Ship_to_warehouse: "商家发货至仓库",
        //         Quality_inspection: "验收质检称重",
        //         Global_Freight: "国际运输物流",
        // },
 
        bottom: {
                Massive_merchandise: "海量商品",
                Commodity_purchase: "商品购买",
                Cost_savings: "货运集包",
                If_you_purchase_goods_from_different_merchants_we_will_help_you_consolidate_them_into_one_package_and_send_them_to_your_country: "您购买不同商家商品，我们帮您整合成一个包裹寄送到您国家",
                service_quality: "质量检验",
                Provide_efficient_mature_and_stable_service_support: "提供高效且成熟稳定的服务支持",
                Quick_response: "服务品质",
                Help_you_solve_your_difficulties_in_the_shortest_time: "最短时间内帮助您解决困难",
                customer_service: "客户服务",
                Help_Center: "帮助中心",
                contact_us: "联系我们",
                Purchasing_demo: "代购演示",
                Services_and_fees: "服务与费用",
                Source_of_this_product: "我们将帮助您购买中国所有线上、线下渠道的商品。",
                Return_and_exchange_service: "退换货服务",
                payment_methods: "支付方式",
                Recharge_method: "充值方式",
                Paypal_payment: "Paypal支付",
                International_Credit_Card_Payment: "国际信用卡支付",
                Delivery_related: "配送相关",
                Logistics_shipping_fee: "物流运费",
                Centralized_packaging: "集中打包",
                Customs_and_taxes: "海关及税项",
                Precautions_for_receiving_goods: "收货注意事项",
                After_sales_service: "售后服务",
                Product_storage_period: "商品保管期",
                Inspection_rules: "验货规则",
                Insurance_and_indemnity: "保险及赔偿",
                YuICP_No_2024000320: "豫ICP备2024000320号",
                About_Seacloud: "关于Seacloud",
                Terms_of_Service_and_User_Management: "服务条款和用户管理",
                Privacy_Notice: "隐私声明",
                // contact_us: "联系我们",
                Company_name_SEACLOUD_CN_TECHNOLOGY_CO_LIMITED_Company_registration_number_24052668: "公司名称：SEACLOUD (CN) TECHNOLOGY CO., LIMITED  公司注册编号：24052668",
                Henan_Cross_Sea_Yungou_Technology_Co_Ltd:"Henan Cross Sea Yungou Technology Co., Ltd",

        },
        index: {
                Front_page: "首页",
                Shopping_cart: "购物车",
                My_Order: "我的订单",
                My_warehouse: "我的仓库",
                Preview_package: "预演包裹",
                My_package: "我的包裹",
                Transaction_Record: "交易记录",
                My_Points: "我的积分",
                Coupons: "优惠券",
                Account_setup: "账号设置",
                Trading_log: "交易记录",
                Promoter_Programm:"推广者计划"
                


        },
        account: {
                personal_data: "个人信息",
                my_privilege: "我的特权",
                Level_authority: "等级特权",
                International_Shipping_Discount: "国际运输折扣",
                Insurance_Discount: "保险优惠金额",
                Value_added_service_discounts: "增值服务折扣",
                Free_Aftersale_Operation_Fee: "免退换货操作费",
                Used_Available_Month: "已用/可用(月)",
                my_level: "我的等级",
                growth_value: "成长值",
                Shipping_address: "收货地址",
                Add_shipping_address: "添加收货地址",
                you_have_created: "您已创建",
                Work: "工作",
                Address: "详细地址",
                Operate: "操作",
                Edit: "编辑",
                Delete: "删除",
                Set_as_default: "设为默认地址",
                Default_address: "默认地址",
                You_have_not_added_a_delivery_address_please_add_a_delivery_address: "您还没有添加收货地址，请添加收货地址",
                New_shipping_address: "新增收货地址",
                Consignee: "收件人",
                name: "名",
                Region: "所在地区",
                country: "国家",
                State_Province: "州/省份",
                City: "城市",
                Details_address: "详细地址",
                Zip_code: "邮编",
                Phone_number: "手机号",
                Label: "标签",
                Home: "家",
                // Work: "工作",
                Default_URL: "默认地址",
                Cancel: "取消",
                Sure: "确定",
                Account_safety: "账号安全",
                Credit_card_billing_address: "信用卡账单地址",
                name_cannot_be_blank: "名字不能为空",
                Country_cannot_be_null: "国家不能为空",
                State_Province_cannot_be_blank: "省份不能为空",
                City_cannot_be_empty: "城市不能为空",
                Please_select_at_least_one_activity_type: "请至少选择一个活动性质",
                Zip_code_cannot_be_empty: "邮编不能为空",
                Address_cannot_be_empty: "地址不能为空",
                Phone_number_cannot_be_empty: "手机号不能为空",
                Added_address_successfully: "添加地址成功",
                Delete_successful: "删除成功",
                Setting_successful: "设置成功",
                Public_Member: "大众会员",
                Silver_Member: "白银会员",
                Gold_Member: "黄金会员",
                Platinum_Member: "白金会员",
                Diamond_Member: "钻石会员",
                Black_Gold_Member: "黑金会员",
                Black_Gold_PLUS: "黑金PLUS",

        },
        headertop: {
                Please_enter_keywords_or_product_links: "请输入关键字或商品链接",
                Please_select: "请选择",
                goods: "商品",
                Shop: "店铺",
                More_features: "更多功能",
                Freight_estimate: "运费估算",
                Language_Currency: "语言/币种",
                language: "语言",
                Currency: "币种",
                Saving: "保存",
                Log_in: "登录",
                register: "注册",
                Personal_Center: "个人中心",
                Shopping_cart: "购物车",
                My_warehouse: "我的仓库",
                My_Order: "我的订单",
                My_package: "我的包裹",
                Trading_log: "交易记录",
                Sign_out: "退出登录",
                Categories: "商品分类",
                Commodity: "商品",
                Ladies: "女装",
                bag: "包",
                Men: "男装",
                Jewelry: "首饰饰品",
                shoe: "鞋子",
                Sankeng_Clothing: "三坑服饰",
                work_out: "运动健身",
                trousers: "裤装",
                skirt_suit: "裙装",
                Jacket: "上衣",
                coat: "外套",
                travel_backpack: "出行背包",
                fashion_handbags: "时尚手袋",
                Travel_series: "旅行系列",
                small_bag: "小型包袋",
                Bags_accessories: "箱包配件",
                // coat: "外套",
                // Jacket: "上衣",
                // trousers: "裤装",
                // work_out: "运动健身",
                // shoe: "鞋子",
                jewelry: "首饰",
                belt: "腰带",
                Glasses: "眼镜",
                hat: "帽子",
                scarf: "围巾",
                Accessories: "配件",
                hair_accessories: "发饰",
        },
        goodslist: {
                We_have_searched_for: "已为您搜索到",
                related_content_for_you_come_and_buy_it: "相关内容，快来尽情选购吧",
                Agreed: "已同意",
                Search_Service_Terms: "《搜索服务条款》",
                micro: "微店",
                Taobao: "淘宝",
                loading: "加载中... ",
                Sold: "已售",
        },
        shopgoods: {
                loading: "加载中... ",
        },
        goodsrecommend: {
                Everyone_is_looking: "大家都在关注",
        },
        center: {
                My_Balance: "我的余额",
                Recharge: "充值",
                Withdraw: "提现",
                Points: "积分",
                My_Coupons: "我的优惠券",
                My_growth_value: "我的成长值",
                Trade_name: "商品名称",
                Submit_time: "提交时间",
                Order_Status: "订单状态",
                operate: "操作",
                Service_Fee:"服务费",
                Rebate_ratio:"回扣比例",
                service_fee_desc:"支付第三方商品的采购、订单跟进的人工成本",
                pay: "去支付",
                order_details: "订单详情",
                Confirm_Order: "确认订单",
                View_Logistics: "查看物流",
                Logistics_information: "物流信息",
                Submit_package: "提交包裹",
                message: "留言",
                Please_enter_the_withdrawal_amount: "请输入提现金额(CNY)",
                Tips: "提示",
                Sure: "确定",
                Cancel: "取消",
                Amount_cannot_be: "金额不能是0！",
                Amount_must_be_greater_than: "金额必须大于0",
                The_withdrawal_amount_cannot_be_greater_than_the_wallet_limit: "提现金额不能大于钱包额度",
                Please_enter_amount: "请输入金额",
                The_amount_you_input_is: "你输入的金额是:",
                invite:"邀请",
                Copy_successful:"复制成功",
                Invite_friends_to_register:"邀请好友注册",
                copy_Link: "复制链接",
        },
        cart:{
                Commodity_information:"商品信息",
                Note:"备注",
                Price:"单价",
                qty:"数量",
                Amount:"金额",
                Operate:"操作",
                Product_remarks:"商品备注信息",
                Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it:"给采购的留言，没有特殊采购要求的可以不写",
                Sure: "确 定",
                Cancel: "取 消",
                Delete:"删除",
                Delete_selected_items:"删除选中的商品",
                selected:"已选中 ",
                Choose_all:"全选",
                pieces_excluding_freight:"件（不含运费）",
                Estimated_freight:"预估国际运费",
                pay:"结 算",
                Your_shopping_cart_is_empty_go_treat_yourself:"你的购物车是空的，快去犒劳一下自己吧！！",
                Are_you_sure_to_delete_the_item:"确定删除商品?",
                Tips:"提示",
                // Sure:"确定",
                // Cancel:"取消",
                Are_you_sure_you_want_to_delete_the_items_selected_in_your_shopping_cart:"确定删除购物车选中的商品?",
                Delete_successful:"删除成功!",
                Please_select_an_item:"请选择商品！",
        },
        describe: {
                One_stop_service: "一站式服务",
                One_click_order: "便捷式一键下单",
                Ship_to_warehouse: "商家发货至仓库",
                Quality_inspection: "验收质检称重",
                Global_Freight: "国际运输物流",
        },
        goodsdetail: {
                Find_similar: "找相似",
                Promote_and_share: "推广分享",
                Favorite_Product: "收藏商品",
                Sales_volume: "销量",
                Enter_the_store: "进入店铺",
                Force_refresh: "强制刷新",
                modification: "修改",
                Starting_purchase_of:"商品 ",
                items:"件起购买!",
                Service_Terms:"服务条款",
                "After_payment_the_buyer_will_provide_purchasing_services_for_you_from_09:00-18:00_(UTC+8)": "支付后，采购商会在09:00-18:00（UTC+8）为您进行代购服务",
                Source_of_this_product: "本商品来源",
                SeaCloud_can_entrust_buyers_to_purchase_for_you: "SeaCloud可委托采购商为您代购",
                Merchant: "商家",
                SeaCloud_Certified_warehouse_shipping_costs: "SeaCloud认证仓库，运费",
                SeaCloud_certified_warehouse: " SeaCloud认证仓库",
                your_address: "你的地址",
                Estimate_international_shipping_costs: "估算国际运费",
                Please_select_properties: "请选择属性",
                leave_a_message: "留言",
                Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them: "给采购的商品备注，无特殊采购要求可不写。",
                The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default: "展示商品均来自第三方平台，SeaCloud只提供信息搜索、代购、寄送服务。SeaCloud无法鉴定商品质量、真伪，使用SeaCloud代购服务即默认接受以上风险。",
                agreed: "我同意",
                Buy_now: "立即购买",
                Add_to_Cart: "加入购物车",
                embargo: "禁运",
                Tobacco_alcohol_and_contraband_are_not_accepted: "·烟、酒、违禁品不承运",
                Prohibited_items_include_but_are_not_limited_to_the_following_items_Swiss_Army_knives_a_full_set_of_kitchen_knives_kitchen_knives_paper_cutters_letter_openers_gardening_knives_scissors_saws_seam_ripper_seam_ripper_pure_fruit_and_vegetable_juices_inflatable_lighters_wine_putty_knife_cheese_Western_cheese_hairy_crab_bamboo_sword_wooden_knife_military_knife_card_universal_knife_card_skate_shoes_other_outdoor_knives_magnetic_components_Swiss_army_knife_end_mill_carving_knife_cigarette_label_cigarette_Card_vajra_bell_vajra_pestle_yue_knife_seeds_etc: "违禁品包括但不限于以下商品：瑞士军刀，全套厨房刀具，菜刀，裁纸/切纸/拆信刀,园艺/刀/剪/锯,拆线器/拆线刀,纯果蔬汁,充气打火机,葡萄酒,油灰刀,芝士/西式奶酪,大闸蟹,竹剑/木刀,军刀卡/万能刀卡,冰刀鞋,其他户外刀具,磁性元器件,瑞士军刀,立铣刀,雕刻刀,烟标/烟卡,金刚铃/金刚杵／钺刀，种子等。",
                Purchase_limit: "限购",
                Medicines_liquids_paste_goods_and_cosmetic_powders_are_restricted_export_goods_by_China_Customs_Please_ask_before_ordering: "药品、液体、膏状货物、化妆品粉末属于中国海关限制出口货物。订购前请咨询",
                customer_service: "【客服】",
                Kind_tips: "温馨提示",
                About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes_restricted_items_logistics_route_restrictions_and_volume_and_weight_restrictions: "【关于海关物流】由于各国依据各国海关政策差异，部分国家或地区对特殊商品较为敏感，所以会出现禁运、限购物品、物流线路限制及体积重量限制的情况。",
                The_goods_you_purchase_will_be_sent_to_the_SeaCloud_warehouse_After_arriving_at_the_warehouse_we_will_inspect_the_goods_for_you_and_take_photos_of_the_goods: "您购买的商品，会寄送到SeaCloud仓库，到达仓库后，我们会替您检查商品、并且拍摄商品照片。",
                You_can_view_and_manage_products_in_My_Warehouse: "您可以在[我的仓库]查看、管理商品",
                If_you_are_not_satisfied_with_the_goods_arriving_at_the_SeaCloud_warehouse_you_can_apply_for_a_return_or_exchange_within_5_days_of_arrival_at_the_warehouse_The_purchaser_will_negotiate_with_the_merchant_on_your_behalf_to_handle_the_after_sales_service: "如果您对到达SeaCloud仓库的商品不满意，可以在到达仓库5天内申请退换货，采购会代您跟商家协商处理售后",
                No_store_information_found: "没有找到店铺信息",
                Please_read_and_agree_before_proceeding: "请阅读同意后操作",
                Please_select_specification_properties: "请选择规格属性",
                Share_invite_code: "分享邀请码",
                copy_Link: "复制链接",
                qty: "数量",
                stock: "库存",
                Description_text: "描述文字",
                Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them_down: "给采购的商品备注，无特殊采购要求可不写。",
                Purchasing_instructions: "代购须知",
                product_details: "产品详情",
                After_sales_service: "售后服务",
                loading: "加载中...",
                Copy_successful:"复制成功",
                tip:"提示",
                Cancel: "取消",
                Sure: "确定",
               
        },
       
        cartlnfo: {
                Confirm_Order: "确认订单",
                Merchant: "商家",
                Select_shipping_country: "选择寄送国家",
                Seacloud_warehouse: "Seacloud 仓库",
                Please_fill_in_the_country_to_be_shipped_to: "请填写将要寄送的国家",
                Seacloud_buyers_will_determine_whether_your_product_can_be_sent_based_on_the_country_you_fill_in: "Seacloud采购员会根据填写国家，判断您的商品是否可以寄送。",
                Confirm_product_info: "确认商品信息",
                product_detail: "商品详情",
                Note: "备注",
                Price: "单价",
                qty: "数量",
                additional_services: "附加服务",
                Subtotal: "小计",
                Note_information: "备注信息",
                Product_remarks: "商品备注信息",
                Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "给采购的留言，没有特殊采购要求的可以不写",
                Sure: "确 定",
                Cancel: "取 消",
                Additional_services: "额外服务",
                Total_price_of_goods: "商品总价",
                Shipping_fee_to_warehouse: "到仓运费",
                Total_stores: "店铺合计",
                Total_price_to_be_paid_international_shipping_charges_are_extra: "待支付总价(国际运费需另计)",
                Prohibited_Goods_Statement: "《禁购商品声明》",
                Terms_of_Service_and_User_Management: "《服务条款和用户管理》",
                Return_and_Exchange_Service: "《退换货服务》",
                Disclaimer: "《免责声明》",
                I_have_read_and_agree: "我已阅读并同意",
                Submit_Order: "提交订单",
                Kind_tips_After_the_order_is_submitted_and_the_payment_is_successful_please_wait_patiently_for_the_goods_to_be_put_into_storage_The_goods_can_be_submitted_for_shipping_after_being_put_into_storage: "温馨提示：订单提交并且支付成功后，请耐心等待商品入库。入库后可提交寄送商品。",
                Please_agree_to_the_terms: "请同意条款！",
                Please_choose_a_country: "请选择国家！",
        },
        payorder: {
                Checkout_counter: "收银台",
                Orders_submitted_successfully: "订单提交成功！",
                Balance: "余额",
                Your_balance_is_insufficient_please_top_up_before_making_payment: "您的余额不足，请先充值后再进行支付",
                Recharge: "充值",
                Total_price: "总价：",
                Payables: "应付款",
                Handling_fee: "手续费：",
                Confirm_payment: "确认付款",
                Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "非美元币种支付时会产生货币兑换和手续费",
                Alipay: "支付宝",
                Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: "中国 人民币 当地合作银行的借记卡和贷记卡",
                Please_enter_amount: "请输入金额",
                Tips: "提示",
                Sure: "确定",
                Cancel: "取消",
                Amount_cannot_be: "金额不能是0！",
                Amount_must_be_greater_than: "金额必须大于0",
                The_amount_you_input_is: "你输入的金额是:",
                Please_select_mode_of_payment: "请选择支付方式！",
        },
        orderlist: {
                All:"全选",
                Pending_payment:"未支付",
                Shopping: "商城",
                MyCoupon: "我的优惠券",
                Paid: "已支付",
                Ordering: "订购中",
                Return_Exchange: "退/换货",
                Start_date: "开始日期",
                End_date: "结束日期",
                Search: "搜索",
                product_detail: "商品详情",
                Price: "单价",
                qty: "数量",
                Order_Status: "订单状态",
                Value_added_services: "增值服务",
                Order_total: "订单总额",
                Other_operations: "其他操作",
                Order_number: "订单编号：",
                Creation_time: "创建时间：",
                Warehouse_Seacloud_Warehouse: "仓库: Seacloud仓库",
                Add_notes: "添加备注信息",
                Product_remarks: "商品备注信息",
                Leave_a_message_for_purchasing_If_you_don_t_have_special_purchasing_requirements_you_don_t_need_to_write_it: "给采购的留言，没有特殊采购要求的可以不写",
                Sure: "确 定",
                Cancel: "取 消",
                Product_shipping_cost: "商品运费：",
                Value_added_service_fee: "，增值服务费:",
                Pay_order: "支付订单",
                order_details: "订单详情",
                message: "留言",
                Cancel_order: "取消订单",
                logistics: "物流",
                Logistics_information: "物流信息",
                Apply_for_after_sales_service: "申请售后",
                Schedule: "进度",
                Are_you_sure_you_want_to_continue_deleting:"确定要取消订单吗?",
                Tips: "提示",
                
        },
        deliverydetail: {
                First_weight: "首重",
                First_weight_price: "首重价格",
                Additional_weight: "续重",
                Additional_weight_price: "续重价格",
                Size_limit: "尺寸限制",
                Sensitive_items_that_can_be_mailed: "可邮寄敏感物",
                Fuel_fee: "燃油费",
                Operation_fee: "操作费",
                Service_fee: "服务费",
        },
        orderdetail: {
                Title: "标题：",
                Product_Notes: "商品备注：",
                Order_Number: "订单编号：",
                Specifications: "规格属性：",
                Seller_Name: "卖家名称:",
                Price: "价格：CNY",
                qty: "数量：",
                Order_Time: "下单时间：",
                Payment_Time: "支付时间：",
                Weight: "重量",
                Measure_size:"测量尺寸",            
                Remove_packaging:"拆掉产品包装盒",               
                Take_photos:"照片",
                Priority_purchase:"优先购买",           
                Add_air_column_pack:"添加气柱包",       
                Add_corner_protection:"添加箱角保护",             
                Shoe_support:"鞋子支撑",   
        },
        servereturn: {
                Application_Type: "申请类型",
                Please_select_application_type: "请选择申请类型",
                Request_return: "申请退货",
                Request_exchange: "申请换货",
                Request_reason: "申请原因",
                Submit: "提交",
                Cancel: "取消",
                 },
        trackInfo: {
                Logistics_Tracking: "物流追踪",
        },
        userrecharge: {
                Checkout_counter: "收银台",
                Total_price: "总价：",
                Payables: "应付款",
                Handling_fee: "手续费：",
                Confirm_payment: "确认付款",
                Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "非美元币种支付时会产生货币兑换和手续费",
                Alipay: "支付宝",
                Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: "中国 人民币 当地合作银行的借记卡和贷记卡",
                Please_select_mode_of_payment: "请选择支付方式！",
                Please_keep_the_actual_shipping_address_of_the_product_consistent_with_the_PayPal_delivery_address_otherwise_it_will_cause_customs_clearance_failure_and_affect_the_timeliness:"请保持商品实际寄送地址和paypal收货地址一致，否则将会导致清关失败，影响时效。",
                Cooperation_with_the_People_s_Bank_of_China:"与中国人民银行合作"

        },

        security: {
                Username: "用户名 :",
                Email: "邮箱 :",
                Security_level: "安全级别 :",
                After_encryption_your_security_level_is: "加密后,您的安全级别为",
                Medium: "“中”",
                Last_login: "最近登录:",
                Logout: "注销账号",
                Change_login_password: "修改登录密码",
                A_highly_secure_password_can_make_your_account_more_secure_It_is_recommended_that_you_change_your_password_regularly_and_set_a_password_that_contains_numbers_and_letters_and_is_more_than_6_characters_long: "安全性高的密码可以使账号更安全。建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码。",
                Change_password: "修改密码",
                Security_privacy_issues: "安全/隐私问题",
                Poor_customer_service: "糟糕的客户服务",
                Too_high_a_fee: "费用太高了",
                Complicated_shopping_process: "复杂的购物过程",
                Other_reasons: "其他原因",
                Please_enter_content: "请输入内容",
                Cancel: "取 消",
                Confirm: "确 定",
                Change_email: "修改邮箱",
                For_the_security_of_your_account_you_need_to_first_pass_the_email_verification_code_login_password_security_verification: "为了您的账户安全，需要先通过“邮箱验证码+登录密码”进行安全校验",
                Email_verification_code: "邮箱验证码",
                Click_to_get_the_verification_code: "点击获取验证码",
                Login_password: "登录密码",
                Next_step: "下一步",
                New_password: "新的密码",
                Repeat_password: "重复密码",
                Verification_code_has_been_sent: "已发送验证码",
                Payment_password: "支付密码",
                // Repeat_password: "重复密码",
                Submit: "提 交",
                Please_enter_your_password_again: "请再次输入密码",
                The_two_passwords_are_inconsistent: "两次输入密码不一致",
                Please_enter_the_verification_code: "请输入验证码",
                // Change_password: "修改密码",
                Please_enter_your_login_password: "请输入登录密码",
                Please_enter_a_new_login_password: "请输入新的登录密码",
                Please_enter_a_new_payment_password: "请输入新的支付密码",
                Please_enter_your_payment_password_again: "请再次输入支付密码",
                Please_enter_the_verification_code_of_your_email: "请输入你2813910143@qq.com邮箱的验证码",
                Please_enter_your_password: "请输入密码",
                Successfully_changed_your_login_password: "修改登录密码成功",
                Form_verification_failed: "表单验证失败",
                Please_enter_your_new_password: "请输入新密码",
                _6_to_15_characters_long: "长度在 6 到 15 个字符",
        },
        userInfo: {
                Edit: "编辑",
                Username: "用户名：",
                Balance: "余额：",
                Phone: "邮箱：",
                Gender: "性别：",
                None: "无",
                Male: "男",
                Female: "女",
                Language: "语言：",
                Registration_time: "注册时间：",
                Growth_Value: "成长值",
                View_Growth_Value_Record: "查看成长值记录",
                score:"积分",
                Only_3000_growth_points_are_needed_to_reach_V1: "距离V1只需要3000个成长值",
                Distance: "距离",
                Only_requires: "只需要",
                 Cancel: "取消",
                 Sure: "确定",
                Upload_avatar:"上传头像",
                        },
        warehouse: {
                Order_ID_Product_Name: "订单编号/商品名字",
                Start_Date: "开始日期",
                End_Date: "结束日期",
                Search: "搜索",
                Image: "图片",
                Product_Details: "商品详情",
                Price: "价格",
                Shipping: "运费",
                Weight: "重量",
                Order_Status: "订单状态",
                Days: "天数",
                Action: "操作",
                Details: "详情",
                Leave_us_a_message: "留言给我们",
                Select_All: "全选",
                Shipping_Country: "寄送国家",
                Actual_Weight: "实重",
                Selected_Products: "选中商品",
                Pieces: "件",
                Preview_Packaging: "预演包装",
                Submit_Shipping: "提交运送",
                Order_Details: "订单详情",
                Not_an_Array: "不是数组",
                Please_select_a_country: "请选择国家！",
                Please_select_an_order_to_submit: "请选择要提交的订单！",
                Seacloud_warehouse_To: "Seacloud 仓库 To",
                Available: "条可用",
        },
        invoice: {
                Tip_Depending_on_the_customs_policy_of_the_destination_country_and_your_experience_filling_in_a_customs_invoice_can_reduce_or_avoid_customs_fees: "提示：根据目的地国家的海关政策和您的经验，填写海关发票可以减少或避免海关费用。",
                Add: "添加",
                Import_invoice: "导入发票",
                Warehouse_ID: "仓库ID",
                Product_name: "商品名字",
                Please_enter_a_title: "请输入标题",
                qty: "数量",
                Please_enter_the_qty: "请输入数量",
                Price: "价格(USD)",
                Please_enter_the_value: "请输入价值",
                Operation: "操作",
                Delete: "删除",
                Shipping_address: "收货地址 /",
                Please_select_a_shipping_address: "请选择收货地址",
                Logistics_route: "物流路线 /",
                Please_select_a_logistics_route: "请选择物流路线",
                Waybill_value_added_service_: "运单增值服务 /",
                Waybill_value_added_service: "运单增值服务",
                Coupon: "优惠券 /",
                Whether_to_purchase_insurance: "是否购买保险 / ",
                insure: "insure",
                The_insurance_fee_is_3_of_the_total_price_of_the_goods: "保险费用为商品总价的3%",
                Rehearsal_fee: "预演费用",
                rehearsal: "rehearsal",
                You_can_pay_the_rehearsal_fee_in_advance_to_ensure_that_the_package_is_packed_in_time_thereby_avoiding_additional_payments_and_making_the_delivery_process_faster_and_more_efficient_allowing_you_to_receive_the_package_faster: "可以提前支付预演费以确保包裹被及时打包，从而避免产生额外的支付，并且使送达过程更加快速高效，让您更快地收到包裹",
                Submit_package: "提交包裹",
                Estimated_total_cost: "预估总费用/ ",
                Total: "Total",
                pay: "去支付",
                Please_fill_in_the_invoice: "请填写发票",
                // Please_select_a_logistics_route: "请选择物流线路",
                Please_choose_whether_to_purchase_insurance: "请选择是否购买保险！",
                Estimated_cost: ",预估费用",
                First_weight: ",首重",
                Fee: "g,费用",
                Additional_weight: ",续重",
                Please_return_to_the_previous_step_to_select_the_warehouse_order_information: "请请返回上一步选择到仓订单的信息！",
                Please_return_to_the_previous_step_to_select_the_country_information: "请返回上一步选择国家信息!",
                Waybill_full: "运单满",
                discount: "减:",
                Yuan: "元",
                Waybill_discount: "运单折扣",
                Discount: "折",
                You_have_available_coupons:"你有可用的优惠券",
                No_coupons_available_yet:"暂时没有优惠券",
                Create_address:"创建地址",
        },
        paysend: {
                Checkout_counter: "收银台",
                Orders_submitted_successfully: "订单提交成功！",
                Balance: "余额",
                Your_balance_is_insufficient_please_top_up_before_making_payment: "您的余额不足，请先充值后再进行支付",
                Recharge: "充值",
                Total_price: "总价：",
                Payables: "应付款",
                Handling_fee: "手续费：",
                Confirm_payment: "确认付款",
                Covers_202_countries_or_regions_Supports_25_currencies_Currency_conversion_and_handling_fees_will_be_incurred_when_paying_in_non_USD_currencies_Debit_and_credit_cards_from_local_cooperative_banks: "非美元币种支付时会产生货币兑换和手续费",
                Alipay: "支付宝",
                Chinese_Yuan_Debit_and_credit_cards_from_local_partner_banks: "中国 人民币 当地合作银行的借记卡和贷记卡",
                Please_enter_amount: "请输入金额",
                Tips: "提示",
                Sure: "确定",
                Cancel: "取消",
                Amount_cannot_be: "金额不能是0！",
                Amount_must_be_greater_than: "金额必须大于0",
                The_amount_you_input_is: "你输入的金额是:",
                Please_select_mode_of_payment: "请选择支付方式！",
        },
        estimation: {
                Freight_Estimate: "运费估算",
                Cooperate_with_many_international_express_companies_to_provide_super_low_freight_discounts: "携手国际众多快递公司，运费超低折扣",
                Seacloud_warehouse_to: "Seacloud 仓库寄往（国家）",
                Please_enter_weight: "请输入重量(g)",
                Please_select: "请选择",
                Length: "长",
                Width: "宽",
                Height: "高",
                International_freight_description:"所有非Seacloudbuy自营线路的物流服务均由第三方物流商提供，且他们会根据市场行情调整运费价格，如有价格变动Seacloudbuy会提前通知。为提供极致的服务体验，如您在物流服务上遇到问题，Seacloudbuy可代您与第三方物流商协商沟通。",
                Logistics_Service_Description: "物流服务说明",
                Transportation_Method: "运输方式",
                ico:"图标",
                Time: "时间(天)",
                Freight: "运费",
                Features: "特征",
                Operation: "操作",
                View: "查看",
                Details: "详细信息",
        },
        userlogin: {
                Welcome_to_log_in: "欢迎登陆",
                Please_enter_your_email_address: "请输入邮箱",
                Please_enter_your_password: "请输入密码",
                Please_enter_your_verification_code: "请输入验证码",
                Login: "登录",
                Forgot_your_password: "忘记密码?",
                Register: "注册",

        },
        userforget: {
                Login: "登录",
                Email: "邮箱",
                Account_safety: "账号安全",
                Submit: "提交",
                Already_have_an_account: "已有账号",
                Return_to_SeaCloud_homepage: "返回SeaCloud主页",
                Please_enter_the_email_address_associated_with_your_account: "请输入您账号的关联邮箱",
                Captcha: "验证码",
                Please_enter_the_correct_email_address: "请输入正确的邮箱",
                The_verification_code_cannot_be_empty: "验证码不能为空",
                New_Password:"新密码",
                Send: "发送",
                Please_enter_your_password: "请输入密码",
                Please_enter_the_verification_code: "请输入验证码",

        },
        UserRegister: {
                Please_enter_your_password: "请输入密码",
                Please_enter_the_verification_code: "请输入验证码",
                Welcome_to_register: "欢迎注册",
                Please_select_your_country: "请选择您的国家",
                Please_fill_in_a_real_and_available_email_address: "请填写真实可用的邮箱",
                Please_confirm_your_password: "请确认密码",
                Please_enter_the_invitation_code: "请输入邀请码(可选)",
                I_have_read_and_agree: "我已阅读并同意",
                Terms_of_Service_and_User_Management: "《Seacloud 用户注册协议》",
                Privacy_Notice: "《隐私声明》",
                register: "注册",
                Already_have_an_account: "已有账号",
                Log_in: "登录",
                Please_read_the_terms_and_check_I_agree:"请阅读条款并勾选我同意",
                The_two_passwords_do_not_match:"两个密码不匹配！",
                Please_enter_your_email_address:"请输入您的电子邮件地址！",
                  
                 },
        UserSendMessage: {
                Please_enter_your_question: "请输入您的问题",
                Send: "发送",
        },

        parcel: {
                Pending_payment: "待付款",
                Weight: "重量",
                Total_price: "总价：",
                Operate: "操作",
                order_details: "订单详情",
                View_Logistics: "查看物流",
                Logistics_information: "物流信息",
                All_packages: "全部包裹",
                Waiting_for_packaging: "待打包",
                Pay_the_difference: "补差价",
                Sent: "已发出",
                Confirm_receipt: "确认收货",
                Canceled: "已取消",
                After_sales_waybill: "售后运单",
                Enter_shipping_method_or_consignee: "输入运送方式或收货人",
                Package_status: "包裹状态",
                Shipping_method: "运送方式",
                Pay_waybill: "支付运单",
                View_details: "查看详情",
                Cancel_waybill: "取消运单",
                Consult_customer_service: "咨询客服",
                Waybill_details: "运单详情",
                Consignee: "收货人",

        },
        preview: {
                Waiting_for_shipment: "待发货",
                Cancel_the_preview_shipping_order: "取消预演运单",
                Pending_payment: "待付款",
                Weight: "重量",
                Total_price: "总价：",
                Operate: "操作",
                order_details: "订单详情",
                View_Logistics: "查看物流",
                Logistics_information: "物流信息",
                All_packages: "全部包裹",
                Waiting_for_packaging: "待打包",
                Pay_the_difference: "补差价",
                Sent: "已发出",
                Confirm_receipt: "确认收货",
                Canceled: "已取消",
                After_sales_waybill: "售后运单",
                Enter_shipping_method_or_consignee: "输入运送方式或收货人",
                Package_status: "包裹状态",
                Shipping_method: "运送方式",
                Pay_waybill: "支付运单",
                View_details: "查看详情",
                Cancel_waybill: "取消运单",
                Consult_customer_service: "咨询客服",
                Waybill_details: "运单详情",
                Consignee: "收货人",
        },
        wallet: {
                Start_date: "开始日期",
                End_date: "结束日期",
                Please_select: "请选择",
                Consumption_records: "消费记录",
                Query_by_date_period: "按日期期间查询",
                Query_by_type: "按类型查询",
                Query: "查询",
                Date: "日期",
                Behavior: "行为",
                Consumption_amount: "消费金额",
                Last_week: "最近一周",
                Last_month: "最近一个月",
                Last_three_months: "最近三个月",
                Balance:"余额",
        },
        senddetail:{
                Waybill_number:"运单编号",
                Shipping_name:"货运名称",
                Destination_country:"目的国家",
                Telephone:"电话",
                Chargeable_Weight:"计费重量 ",
                Volume:"体积",
                Total_cost:"总费用",
                insurance:"保险费",
                Discounted_price:"优惠金额",
                Waybill_status:"运单状态",
                Note:"备注",
                Consignee: "收货人",
                Shipping_address: "收货地址",
                Submit_time: "提交时间",
                Shipping: "运费",
                Value_added_services: "增值服务",
              },
              points:{
                My_Points:"我的积分",
                Date:"日期",
                Redeem_points_this_month:"本月积分兑换",
                My_VIP:"我的VIP",
                Required_to_reach_next_level:"距离下一级还需：",
                points:"积分",
                Go_to_upgrade:"去升级",
                View_coupon:"查看优惠券",
                reason:"原因",
                Points_before_change:"变更前积分",
                Points_after_change:"变更后积分",
                change_value:"变化值",
              },
              coupon:{
                Coupon_activation:"优惠券激活",
                Enter_your_coupon_activation_code:"输入您的优惠券激活码",
                Coupon_code_cannot_be_empty: "优惠券编码不能为空！",
                activation:"激 活",
                all:"全部",
                Used:"已使用",
                expired:"已过期",
                Order_full:"订单满",
                reduce:"减",
                Waybill:"运单打",
                Discount:"折",
                Get_coupon_number:"领取券号",
                Validity:"有效期",
                Remaining:"剩余",
                cards:"张",
                Require:"要求",
                Points:"积分",
                discount:"折",
                Full:"满",
                Available:"可用",
                Coupon_type:"优惠券类型",
                The_validity_period_after_activation_is:"激活后有效期为",
                sky:"天",
                Redeem_now:"立即兑换",
                Waybill_full:"运单满",
                minus:"减",
                Coupon_No:"优惠券编号",
                // Validity:"有效期",
                usage_time:"使用时间",
                CNY_available:"CNY可用",
                Obtain_method:"获取方式",
                Use_now:"立即使用",
                Order_Coupon:"订单优惠券",
                Waybill_Coupon:"运单优惠券",
                Activate_Coupon: "激活优惠券",
                Coupons_can_be_used_as_service_fees_Reward_coupons_must_be_activated_before_use_Please_activate_within_the_validity_period: "优惠券可以用作服务费。奖励优惠券必须在使用前激活，请在有效时间内激活。",
                Coupon_Number: "优惠券编号",
                Type: "类型",
                Name: "名称",
                Expiration_Date: "截至时间",
                Amount: "金额",
                Method: "方式",
                Status: "状态",
                Please_enter_the_activation_code: "请输入激活码",
                // Coupon_code_cannot_be_empty: "优惠券编码不能为空！",
                Tips: "提示",
                Cancel: "取消",
                Sure: "确定",
                // Obtain_method:"获取方式",
              },
              suspendball:{
                Por_favor_insira_sua_pergunta:"请输入您的问题",
                Click_to_enter_customer_service:"点击进入客服",
              },
              promotion:{
                My_promotional_commission:"我的推广佣金",
                total_users:"总用户",
                New_users_within_7_days:"7天内新增加用户",
                active_user:"活跃用户",
                Payment_settings:"收款设置",
                My_alliance_balance:"我的联盟余额",
                Amount_withdrawn:"已提取金额",
                withdraw:"提现",
                my_rights:"我的权益",
                Commission_rate:"佣金率",
                Points_record:"积分记录",
                Points:"积分",
                What_is_an_affiliate_program:"什么是会员计划",
                What_is_an_affiliate_program_desc:"Seacloudbuy是一个由所有用户发起的共享和盈利项目。无论你是个人还是商家，加入Seacloudbuy推广人，邀请新人在Seacloudbuy注册并成功购买签收包裹后，他/她都将成为你的独家用户，他/她的每一次国际物流消费都将为你带来现金返点，没有上限。",
                How_my_winnings_are_calculated:"我的奖金是如何计算的",
                How_my_winnings_are_calculated_desc:"  一旦您成为会员，您的帐户将立即有3%的返利率。你邀请注册用户的国际物流货运越多，你的奖金就越多，返利率也会提高。当奖金达到一定金额后，您可以申请提现，也可以用来在Seacloudbuy平台上扣除任何服务费。",
                How_to_promote_for_free:"如何推广",
                How_to_promote_for_free_desc:" 复制您的推广链接，发送给您的朋友，当她访问进来的时候，注册后即可成为您的独家用户。",
                Commission_rate_desc:"什么是佣金率？您的推广用户成功收到包裹后，Seacloudbuy会给您发放该包裹运费总额*佣金率的奖励。",
                Click_here_to_invite:"点击此处去邀请"
        }
              
                
}