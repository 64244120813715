<template>
  <div class="container">
          <h3>{{ $t('trackInfo.Logistics_Tracking') }}</h3>
          <br>
          <div>
                    <div v-for="(item,index) in orderTrackInfos" :key="index">
                              <h4>{{ item.time }}</h4>
                              <p>{{ item.desc }}</p>

                    </div>
          </div>
          <!-- {{  orderTrackInfos}} -->
  </div>
</template>

<script>
export default {
          props:["orderTrackInfos"]

}
</script>

<style scoped>
.container{
          /* border: 1px red solid; */
          width: 560px;
          margin: 0 auto;
          min-height: 300px;
          font-size: 16px;
}
</style>