<template>
  <div class="Container" @scroll="loadImg" ref="scrollContainer">
    <HeaderTop />
    <div class="w">
      <div class="detailTop">
        <!-- 左侧内容 -->
        <div class="detailLeft">
          <van-empty
            v-if="!imageLoaded"
            image="search"
            :description="$t('loading...')"
            class="bigPic"
          />
          <img
            v-if="imageLoaded"
            :src="this.mainPicture"
            class="bigPic"
            alt=""
          />

          <!-- 小图 -->
          <ul>
            <li>
              <img
                v-for="(item, index) in childrenPicture"
                :key="index"
                :src="item.url"
                alt=""
                @click="switchSmallPhoto(item.url)"
              />
            </li>
          </ul>
          <!-- 推广分享与收藏 -->
          <div class="share">
            <el-button type="success" class="btn" @click="findSimilar" round>{{
              $t("goodsdetail.Find_similar")
            }}</el-button>
            <div class="s_right">
              <span @click="promotion()">
                <i class="el-icon-share"></i
                >{{ $t("goodsdetail.Promote_and_share") }}
              </span>
              <!-- <span
                @click="collectGoods(), toggleFavorite()"
                :class="{ active: isFavorite }"
              >
                <i class="el-icon-star-on"></i
                >{{ $t("goodsdetail.Favorite_Product") }}
              </span> -->
            </div>
          </div>
          <div class="line"></div>
          <!-- 销量详情 -->
          <div class="sale_desc">
            <span
              >{{ $t("goodsdetail.Sales_volume") }}：{{
                this.detailList.sales
              }}+</span
            >
            <!-- <span>平均到货：4天 <a href="" class="check">查看详情</a></span> -->
            <!-- <span>实量(g)：103</span>
            <span>体积(cm)：26.0*25.0*1.0</span> -->
          </div>
        </div>
        <!-- 右侧内容 -->
        <div class="detailRight">
          <!-- 店铺名 -->
          <div class="shop">
            <img
              v-if="type == 'micro'"
              class="typeLogo"
              src="../../assets/weidian-logo.png"
              alt=""
            />
            <img
              v-if="type == 'taobao'"
              class="typeLogo"
              src="../../assets/taobao-logo.png"
              alt=""
            />
            <img
              v-if="type == '1688'"
              class="typeLogo"
              src="../../assets/1688-logo.png"
              alt=""
            />
            <span>{{
              detailList.nick === null
                ? detailList.seller_info.shop_name
                : detailList.nick
            }}</span>
            <button
              @click="toShopGoods(detailList)"
              style="width: auto; padding: 0 8px"
            >
              {{ $t("goodsdetail.Enter_the_store") }}
            </button>
          </div>
          <!-- 商品标题 -->
          <div class="title">
            <el-tooltip
              class="item"
              effect="light"
              :content="detailList.title"
              placement="bottom"
            >
              <span class="desc">
                <a
                  style="white-space: wrap;font-size: 16px"
                  v-if="detailList.title !== undefined"
                  >{{ detailList.title }}</a
                >
              </span>
            </el-tooltip>

            <div class="d_right">
              <i class="el-icon-refresh"></i>
              <span @click="reloadPage()">{{
                $t("goodsdetail.Force_refresh")
              }}</span>
            </div>
          </div>
          <!-- 价格 -->
          <div class="priceDesc">
            <span v-if="this.getCurrency!='CNY'">{{ this.getCurrency }} {{ this.moneyRate(price) }} ≈ CNY {{ price }}</span>
            <span v-else>{{ this.getCurrency }} {{ this.moneyRate(price) }}</span>
            <!-- <div class="p_right">
              <i class="el-icon-edit-outline"></i>
              <span>{{ $t("goodsdetail.modification") }}</span>
            </div> -->
          </div>
          <!-- 提示 -->
          <span class="tips">
            {{
              $t(
                "goodsdetail.After_payment_the_buyer_will_provide_purchasing_services_for_you_from_09:00-18:00_(UTC+8)"
              )
            }}
          </span>
          <!-- 步骤条 -->
          <div class="fee">
            <div class="origin">
              <span>{{ $t("goodsdetail.Source_of_this_product") }}：</span>
              <button>
                <a :href="detailList.detail_url" target="_blank">
                  <i class="el-icon-link"></i>{{ type }}
                </a>
              </button>
              {{
                $t(
                  "goodsdetail.SeaCloud_can_entrust_buyers_to_purchase_for_you"
                )
              }}
            </div>
            <div class="stepMain">
              <el-steps class="steps" direction="vertical" space="45px">
                <el-step></el-step>
                <el-step></el-step>
              </el-steps>
              <div class="s_desc">
                <span>
                  {{ $t("goodsdetail.Merchant") }}
                  <i class="el-icon-caret-right"></i>
                  {{
                    $t(
                      "goodsdetail.SeaCloud_Certified_warehouse_shipping_costs"
                    )
                  }}
                  <input
                    v-model="detailList.post_fee"
                    type="text"
                    autocomplete="off"
                    class="el-input__inner"
                  />CNY
                </span>

                <span class="step2">
                  {{ $t("goodsdetail.SeaCloud_certified_warehouse") }}
                  <i class="el-icon-caret-right"></i>
                  {{ $t("goodsdetail.your_address") }}
                  <router-link to="/estimation" class="estimateFee">
                    {{
                      $t("goodsdetail.Estimate_international_shipping_costs")
                    }}
                  </router-link>
                </span>
              </div>
            </div>
          </div>
          <!-- 商品尺码与颜色 -->
          <div v-for="(item, index) in this.detailList.attr" :key="index">
            <div class="goods_color">
              <span>{{ item.values }}</span>
              <span v-if="isSelectedSkuId" style="color: red"
                >{{ $t("goodsdetail.Please_select_properties") }}
              </span>
              <br />
              <ul>
                <li v-for="(children, k) in item.children" :key="k">
                  <img
                    v-if="children.image != null"
                    :src="children.image"
                    :class="{
                      imgSelected: children.selected,
                      disableSelect: children.stock,
                    }"
                    @click="
                      selectImg(item.pid, children.vid),
                        switchPhoto(
                          item.pid,
                          children.vid,
                          children.image,
                          children.stock
                        )
                    "
                    :alt="children.values"
                    :title="children.values"
                  />
                  <span
                    v-else-if="children.image == null"
                    :class="{
                      SizeSelected: children.selected,
                      disableSelect: children.stock,
                    }"
                    @click="
                      selectSize(item.pid, children.vid),
                        switchSkuid(item.pid, children.vid, children.stock)
                    "
                    >{{ children.values }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- 商品数量 -->
          <div class="goods_nums">
            <span>
              {{ $t("goodsdetail.qty") }}：
              <el-input-number
                v-model="num"
                @change="handleChange"
                :min="1"
                :max="10"
              ></el-input-number>
            </span>
 
            <span v-if="currentStorck > 0"
              > &nbsp; {{ $t("goodsdetail.stock") }}: {{ currentStorck }}
            </span>
            <br />
            <br />
            <p style="cursor: pointer;" v-if="detailList.min_num>0 && type==1688">  {{ $t("goodsdetail.Starting_purchase_of") }}  <font style="color: red;">{{detailList.min_num  }}</font>  {{ $t("goodsdetail.items") }}</p>
          </div>
          <!-- 商品留言 -->
          <div class="goods_msg">
            <span>{{ $t("goodsdetail.leave_a_message") }}</span>
            <textarea
              v-model="remark"
              autocomplete="on"
              :placeholder="
                $t(
                  'goodsdetail.Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them_down'
                )
              "
            ></textarea>
          </div>
          <!-- 平台通知 -->
          <div class="notice">
            <!-- <span
              >{{
                $t(
                  "goodsdetail.Make_a_note_for_the_purchased_goods_If_there_are_no_special_purchasing_requirements_you_don_t_need_to_write_them"
                )
              }}。</span
            > -->
            <div class="line"></div>
            <span>{{
              $t(
                "goodsdetail.The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default"
              )
            }}</span>
            <input type="checkbox" v-model="isagree" />
            <span class="agree">
              {{ $t("goodsdetail.agreed") }}
              <router-link to="/question?requestId=4" style="color: #ef0c25">
                《Seacloudbuy {{ $t("goodsdetail.Service_Terms") }} 》
              </router-link></span
            >
          </div>
          <!-- 购买/加购 -->
          <div class="goods_buy">
            <el-button type="success" class="buy" @click="buy">{{
              $t("goodsdetail.Buy_now")
            }}</el-button>
            <el-button type="success" class="buy" @click="add">{{
              $t("goodsdetail.Add_to_Cart")
            }}</el-button>
            <!-- <div class="line"></div> -->
          </div>
        </div>
      </div>
      <!-- 商品详情与评论 -->
      <div class="detailBottom">
        <!-- tab 标签栏 -->
        <el-tabs
          v-model="activeName"
          class="tabContainer"
          @tab-click="switchTab"
        >
          <!--商品详情-->
          <el-tab-pane
            :label="$t('goodsdetail.product_details')"
            name="details"
          >
            <div class="detailListdesc">
              <div class="desc" v-html="detailList.desc"></div>
            </div>
          </el-tab-pane>
          <!--评论相关-->
          <!-- <el-tab-pane label="评论(99)" name="comment"> 评论(99) </el-tab-pane> -->
          <!--规格参数-->
          <!-- <el-tab-pane label="规格参数" name="spec"> 规格参数 </el-tab-pane> -->
          <!--代购须知-->
          <el-tab-pane
            :label="$t('goodsdetail.Purchasing_instructions')"
            name="attention"
            class="buy_attention"
          >
            <h1>{{ $t("goodsdetail.embargo") }}</h1>
            <p>
              ·{{
                $t(
                  "goodsdetail.Tobacco_alcohol_and_contraband_are_not_accepted"
                )
              }}
            </p>
            <p class="ban_includes">
              {{
                $t(
                  "goodsdetail.Prohibited_items_include_but_are_not_limited_to_the_following_items_Swiss_Army_knives_a_full_set_of_kitchen_knives_kitchen_knives_paper_cutters_letter_openers_gardening_knives_scissors_saws_seam_ripper_seam_ripper_pure_fruit_and_vegetable_juices_inflatable_lighters_wine_putty_knife_cheese_Western_cheese_hairy_crab_bamboo_sword_wooden_knife_military_knife_card_universal_knife_card_skate_shoes_other_outdoor_knives_magnetic_components_Swiss_army_knife_end_mill_carving_knife_cigarette_label_cigarette_Card_vajra_bell_vajra_pestle_yue_knife_seeds_etc"
                )
              }}
            </p>
            <div>
              <img src="../../assets/ban/smok.png" alt="" />
              <img src="../../assets/ban/knif.png" alt="" />
            </div>
            <h1>{{ $t("goodsdetail.Purchase_limit") }}</h1>
            <p>
              {{
                $t(
                  "goodsdetail.Medicines_liquids_paste_goods_and_cosmetic_powders_are_restricted_export_goods_by_China_Customs_Please_ask_before_ordering"
                )
              }}
              <a href="">{{ $t("goodsdetail.customer_service") }}</a>
            </p>
            <div>
              <img src="../../assets/ban/medicine.png" alt="" />
              <img src="../../assets/ban/makeup.png" alt="" />
              <img src="../../assets/ban/lipstick.png" alt="" />
              <img src="../../assets/ban/paste.png" alt="" />
            </div>
            <h1>{{ $t("goodsdetail.Kind_tips") }}</h1>
            <p>
              {{
                $t(
                  "goodsdetail.About_customs_logistics_Due_to_differences_in_customs_policies_among_countries_some_countries_or_regions_are_more_sensitive_to_special_commodities_so_there_may_be_embargoes_restricted_items_logistics_route_restrictions_and_volume_and_weight_restrictions"
                )
              }}
            </p>
          </el-tab-pane>
          <!--售后服务-->
          <el-tab-pane
            :label="$t('goodsdetail.After_sales_service')"
            name="saleAfter"
            class="sale_after"
          >
            <p>
              {{
                $t(
                  "goodsdetail.The_goods_you_purchase_will_be_sent_to_the_SeaCloud_warehouse_After_arriving_at_the_warehouse_we_will_inspect_the_goods_for_you_and_take_photos_of_the_goods"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "goodsdetail.You_can_view_and_manage_products_in_My_Warehouse"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "goodsdetail.If_you_are_not_satisfied_with_the_goods_arriving_at_the_SeaCloud_warehouse_you_can_apply_for_a_return_or_exchange_within_5_days_of_arrival_at_the_warehouse_The_purchaser_will_negotiate_with_the_merchant_on_your_behalf_to_handle_the_after_sales_service"
                )
              }}
            </p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
        :title="$t('goodsdetail.Tips')"
        :visible.sync="centerDialogVisibleTip"
        width="30%"
        :before-close="handleClose">
      <span>{{
          $t(
              "goodsdetail.The_displayed_products_are_all_from_third_party_platforms_and_SeaCloud_only_provides_information_search_purchasing_and_shipping_services_SeaCloud_cannot_authenticate_the_quality_and_authenticity_of_goods_If_you_use_SeaCloud_purchasing_service_you_accept_the_above_risks_by_default"
          )
        }}</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="returnHistory">{{ $t("goodsdetail.Cancel") }}</el-button>
    <el-button type="primary" @click="okTip">{{ $t("goodsdetail.Sure") }}</el-button>
  </span>
    </el-dialog>
    <Bottom />
  </div>
</template>

<script>
import { goodsDetail, collectGoods } from "@/Api/goods";
import { addcart } from "@/Api/cart";
import { Dialog } from "vant";
import { Toast } from "vant";
import { photoUpload, convertImageToBase64, compressImage } from "@/Api/index";
export default {
  name: "GoodsDetail",
  components: {
    // 需要将import引入改为components内部引入，解决递归式调用组件 导致<HeaderTop/>不显示问题
    HeaderTop: () => import("@/components/mainPage/HeaderTop.vue"),
    Bottom: () => import("@/components/mainPage/Bottom.vue"),
  },
  data() {
    return {
      detailList: [],
      type: this.$route.query.type,
      itemId: this.$route.query.itemId,
      num: 1,
      activeName: "details",
      mainPicture: "",
      childrenPicture: [],
      currentSkuid: "",
      imgIndex: null,
      sizeIndex: null,
      currentStorck: "", //当前库存
      price: 0,
      remark: "",
      attrbuteSku: "",
      isSelectedSkuId: false,
      isagree: false,
      isFavorite: false,
      invite: "",
      imageLoaded: false, // 图片加载状态
      curreney: "",
      centerDialogVisibleTip:false
    };
  },
  mounted() {},
  created() {
    var result = this.checkLogin();
    if (result) {
      this.getGoodsDetail();
    }
  },
  methods: {
    okTip(){
      this.centerDialogVisibleTip=false
    },
    returnHistory(){
      this.$router.push(
          {
            path:"/"
          }
      )
    },
    handleClose(){

    },
    findSimilar() {
      convertImageToBase64(this.mainPicture, (base64) => {
        compressImage(base64, 400, 500, 0.4).then((compressBase64) => {
          let imgSearch = localStorage.getItem("imgSearch");
          if (imgSearch) {
            localStorage.removeItem("imgSearch");
          }
          localStorage.setItem("imgSearch", compressBase64);

          let data = {
            base64: compressBase64.split(";base64,")[1],
            type: "1688", // 目前只有1688
          };

          photoUpload(data)
            .then((res) => {
              if (res && res.code === 1 && res.data) {
                let searchData = {
                  imageId: res.data,
                  type: "1688", // 目前只有1688
                };
                this.$toast.clear();
                location.href = `/goodsImageSearch?tab=imageSearch&imageId=${searchData.imageId}&type=1688`;
              } else {
              }
            })
            .catch((error) => {
            });
        });
      });
    },
    // 商品详情
    getGoodsDetail() {
      goodsDetail(
        "/index/getItem?type=" + this.type + "&itemId=" + this.itemId
      ).then(
        (res) => {
          this.detailList = res.data;
          this.mainPicture = this.detailList.pic_url;
          this.imageLoaded = true; // 图片加载完成后设置加载状态为true
          this.childrenPicture = this.detailList.item_imgs;
          this.price = this.detailList.price;
          this.centerDialogVisibleTip=true
        }
      );
    },
    // 进入店铺接口
    toShopGoods(item) {
      if (item.seller_info.shop_id == null) {
        this.$message.info(this.$t("goodsdetail.No_store_information_found"));
        return;
      }
      window.open(
        "/ShopGoods?shopId=" +
          item.seller_info.shop_id +
          "&type=" +
          this.$route.query.type +
          "&logo=" +
          item.seller_info.shop_logo +
          "&name=" +
          item.seller_info.shop_name,
        "_blank"
      );
    },
    // 添加购物车方法
    buy() {
      if (this.isagree == false) {
        this.$message.error(
          this.$t("goodsdetail.Please_read_and_agree_before_proceeding")
        );
        return;
      }
      if(this.type==1688 && this.detailList.min_num>this.num){
        this.$message.info(  this.$t("goodsdetail.Starting_purchase_of")  +this.detailList.min_num + this.$t("goodsdetail.items"));
        return;
      }
      var data = {};
      data["title"] = this.detailList.title;
      data["goodsPrice"] = this.price;
      data["goodsNum"] = this.num;
      data["goodsImg"] = this.mainPicture;
      data["shopName"] = this.detailList.seller_info.shop_name;
      data["shopLink"] = this.detailList.seller_info.zhuy;
      data["discountInfo"] = "";
      data["shopId"] = this.detailList.seller_info.shop_id;
      data["goodsUrl"] = this.detailList.detail_url;
      data["postFee"] = this.detailList.post_fee;
      data["option"] = this.attrbuteSku;
      data["remarl"] = this.remark;
      data["promotionCode"] = this.$route.query.inviter;
      data["category"]=this.detailList.category
      this.findSku(this.currentSkuid);
      var currentSkuId = this.attrbuteSku.split(";");
      var sumLenght = this.detailList.attr.length;
      var sum = 0;
      for (var i = 0; i < currentSkuId.length; i++) {
        if (currentSkuId[i] != "") {
          sum++;
        }
      }
      if (sum < sumLenght) {
        this.isSelectedSkuId = true;
        this.$message.error(
          this.$t("goodsdetail.Please_select_specification_properties")
        );
        return;
      }
      this.isSelectedSkuId = false;
      addcart(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          // console.log(res.data.id)
          this.$router.push({
            path: "/cartInfo",
            query: {
              orderId: res.data.id,
            },
          });
        }
      });
    },
    add() {
      if (this.isagree == false) {
        this.$message.error(
          this.$t("goodsdetail.Please_read_and_agree_before_proceeding")
        );
        return;
      }
      if(this.type==1688 && this.detailList.min_num>this.num){
        this.$message.info(  this.$t("goodsdetail.Starting_purchase_of")  +this.detailList.min_num + this.$t("goodsdetail.items"));
        return;
      }
      var data = {};
      data["title"] = this.detailList.title;
      data["goodsPrice"] = this.price;
      data["goodsNum"] = this.num;
      data["goodsImg"] = this.mainPicture;
      data["shopName"] = this.detailList.seller_info.shop_name;
      data["shopLink"] = this.detailList.seller_info.zhuy;
      data["discountInfo"] = "";
      data["shopId"] = this.detailList.seller_info.shop_id;
      data["goodsUrl"] = this.detailList.detail_url;
      data["postFee"] = this.detailList.post_fee;
      data["option"] = this.attrbuteSku;
      data["remarl"] = this.remark;
      data["promotionCode"] = this.$route.query.inviter;
      data["category"]=this.detailList.category
      this.findSku(this.currentSkuid);
      var currentSkuId = this.attrbuteSku.split(";");
      var sumLenght = this.detailList.attr.length;
      var sum = 0;
      for (var i = 0; i < currentSkuId.length; i++) {
        if (currentSkuId[i] != "") {
          sum++;
        }
      }
      if (sum < sumLenght) {
        this.isSelectedSkuId = true;
        this.$message.error(
          this.$t("goodsdetail.Please_read_and_agree_before_proceeding")
        );
        return;
      }
      this.isSelectedSkuId = false;
      addcart(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
        }
      });
    },
    reloadPage() {
      location.reload();
    },
    handleChange(value) {
      // console.log("数量" + value);
    },
    promotion() {
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
      this.invite =
        location.host +
        "/goodsDetail?itemId="+this.$route.query.itemId+"&type="+this.$route.query.type+"&inviter=" +
        userinfo.usercode;
      const textField = document.createElement("textarea");
      textField.innerText = this.invite;
      document.body.appendChild(textField);

      textField.select();
      document.execCommand("copy");

      document.body.removeChild(textField);

      Dialog.alert({
        title: this.$t("goodsdetail.Share_invite_code"),
        message: this.invite,
        theme: "round-button",
        width: "420px",
        height: "182px",
        confirmButtonText: this.$t("goodsdetail.copy_Link"),
      }).then(() => {
        Toast({
          message: this.$t("goodsdetail.Copy_successful"),
          duration: 1000,
        });
      });
    },
    collectGoods() {
      var data = {};
      data["url"] = document.URL;
      data["image"] = this.detailList.pic_url;
      data["price"] = this.detailList.price;
      data["type"] = this.type;
      data["itemId"] = this.itemId;
      collectGoods(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
    },
    switchTab(tab, event) {
      console.log(tab, event);
    },
    selectImg(k, v) {
      var attr = this.detailList.attr;
      for (var i = 0; i < attr.length; i++) {
        if (k == attr[i].pid) {
          for (var j = 0; j < attr[i].children.length; j++) {
            if (v == attr[i].children[j].vid) {
              if (this.detailList.attr[i].children[j].selected == false) {
                this.detailList.attr[i].children[j].selected = true;
              } else {
                this.detailList.attr[i].children[j].selected = false;
              }
            } else {
              this.detailList.attr[i].children[j].selected = false;
            }
          }
        }
      }
    },
    selectSize(k, v) {
      var attr = this.detailList.attr;
      for (var i = 0; i < attr.length; i++) {
        if (k == attr[i].pid) {
          for (var j = 0; j < attr[i].children.length; j++) {
            if (v == attr[i].children[j].vid) {
              if (this.detailList.attr[i].children[j].selected == false) {
                this.detailList.attr[i].children[j].selected = true;
              } else {
                this.detailList.attr[i].children[j].selected = false;
              }
            } else {
              this.detailList.attr[i].children[j].selected = false;
            }
          }
        }
      }
    },
    //选中小图
    switchSmallPhoto(src) {
      this.mainPicture = src;
    },
    //选中图片
    switchPhoto(pid, vid, src, stock) {
      if (stock) {
        return;
      }
      var needle = pid + ":" + vid;
      this.checkStock(needle); //检查可能选中的  把库存等于0的 置不可选吧
      //判断当前的pid是否在this.currentSkuid ，在就覆盖
      var selected = this.currentSkuid.split(";");
      for (var i = 0; i < selected.length; i++) {
        if (selected[i] == pid + ":" + vid && this.detailList.attr.length > 1) {
          //同一个sku点击
          this.currentSkuid = this.currentSkuid.replace(selected[i] + ";", "");
          return;
        } else if (
          selected[i].indexOf(pid + ":") >= 0 &&
          this.detailList.attr.length > 1
        ) {
          // 不同的sku点击
          if (this.currentSkuid.indexOf(selected[i]) >= 0) {
            this.currentSkuid = this.currentSkuid.replace(
              selected[i] + ";",
              ""
            );
          }
        } else if (
          selected[i].indexOf(pid + ":" + vid) >= 0 &&
          this.detailList.attr.length == 1
        ) {
          this.currentSkuid = "";
          return;
        } else if (
          selected[i] != pid + ":" + vid &&
          this.detailList.attr.length == 1
        ) {
          this.currentSkuid = "";
        }
      }
      if (this.currentSkuid.indexOf(needle) < 0) {
        //不存在就 拼接字符串
        this.currentSkuid += needle + ";";
      } else {
        //存在就取消
        if (this.currentSkuid.indexOf(needle + ";") >= 0) {
          this.currentSkuid = this.currentSkuid.replace(needle + ";", "");
        } else {
          this.currentSkuid = this.currentSkuid.replace(needle, "");
        }
      }
      this.findSku(this.currentSkuid);
      this.mainPicture = src;
    },
    //选中非图片
    switchSkuid(pid, vid, stock) {
      if (stock) {
        return;
      }
      var needle = pid + ":" + vid;
      this.checkStock(needle); //检查可能选中的  把库存等于0的 置不可选吧
      var selected = this.currentSkuid.split(";");
      // console.log(this.currentSkuid)
      for (var i = 0; i < selected.length; i++) {
        if (selected[i] == pid + ":" + vid && this.detailList.attr.length > 1) {
          //同一个sku点击
          this.currentSkuid = this.currentSkuid.replace(selected[i] + ";", "");
          return;
        } else if (
          selected[i].indexOf(pid + ":") >= 0 &&
          this.detailList.attr.length > 1
        ) {
          // 不同的sku点击
          if (this.currentSkuid.indexOf(selected[i]) >= 0) {
            this.currentSkuid = this.currentSkuid.replace(
              selected[i] + ";",
              ""
            );
          }
        } else if (
          selected[i] == pid + ":" + vid &&
          this.detailList.attr.length == 1
        ) {
          this.currentSkuid = "";
          return;
        } else if (
          selected[i] != pid + ":" + vid &&
          this.detailList.attr.length == 1
        ) {
          this.currentSkuid = "";
        }
      }
      if (this.currentSkuid.indexOf(needle) < 0) {
        this.currentSkuid += needle + ";";
      } else {
        //存在就取消
        if (this.currentSkuid.indexOf(needle + ";") >= 0) {
          this.currentSkuid = this.currentSkuid.replace(needle + ";", "");
        } else {
          this.currentSkuid = this.currentSkuid.replace(needle, "");
        }
      }
      this.findSku(this.currentSkuid);
    },
    findSku(propSku) {
      if (propSku.substr(propSku.length - 1) == ";") {
        propSku = propSku.substr(0, propSku.length - 1);
      }
      var propSkus = propSku.split(";");
      var skus = this.detailList.skus.sku;
      var count = skus.length;
      this.attrbuteSku = ""; //增加初始化
      //匹配是否选中
      for (var i = 0; i < count; i++) {
        if (propSkus.length > 1) {
          if (
            skus[i]["properties"].split(";").includes(propSkus[0]) &&
            skus[i]["properties"].split(";").includes(propSkus[1])
          ) {
            // 在这里 需要 判断是否可以点击  只有点击 才能被选中
            this.currentStorck = skus[i]["quantity"];
            this.price = skus[i]["price"];
            this.attrbuteSku = skus[i]["properties_name"];
            // this.$message.success("选中" + skus[i]["properties_name"]);
          }
        } else if (this.detailList.attr.length == 1) {
          if (skus[i]["properties"].split(";").includes(propSkus[0])) {
            this.currentStorck = skus[i]["quantity"];
            this.price = skus[i]["price"];
            this.attrbuteSku = skus[i]["properties_name"];
            // this.$message.success("选中" + skus[i]["properties_name"]);
          }
        }
      }
    },
    checkStock(propSku) {
      var propSkus = propSku.split(";");
      var skus = this.detailList.skus.sku;
      var count = skus.length;
      // 在设置不可选择前  进行初始化 默认都可以选
      var att = this.detailList.attr;
      for (var k = 0; k < att.length; k++) {
        for (var m = 0; m < att[k].children.length; m++) {
          att[k].children[m].stock = false;
        }
      }
      //如果没有全选sku
      if (propSkus.length < att.length) {
        for (var i = 0; i < count; i++) {
          if (skus[i]["properties"].indexOf(propSku) >= 0) {
            if (skus[i]["quantity"] == 0) {
              //假设500 是没有库存
              var properties = skus[i]["properties"].split(";");
              for (var j = 0; j < properties.length; j++) {
                if (properties[j] != propSku) {
                  var selectAttr = properties[j].split(":");
                  // 假设没有库存成立 下面是筛选出没有库存 也就是不可选的
                  for (var k = 0; k < att.length; k++) {
                    for (var m = 0; m < att[k].children.length; m++) {
                      if (
                        att[k]["pid"] == selectAttr[0] &&
                        att[k].children[m]["vid"] == selectAttr[1]
                      ) {
                        att[k].children[m].stock = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    loadImg(e) {
      let imgs = document.querySelectorAll(".lazyload");
      var viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      var scrollDistance = e.target.scrollTop;
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].offsetTop < scrollDistance + viewportHeight) {
          imgs[i].setAttribute("src", imgs[i].getAttribute("lazyload"));
        }
      }
    },
    checkLogin() {
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
      if (userinfo === null || userinfo === "null") {
        var goodsUlr =
          location.protocol +
          "//" +
          location.host +
          location.pathname +
          location.search;
        localStorage.setItem("goodsUrl", goodsUlr);
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.Container {
  overflow: scroll;
  height: 100vh;
}
.detailTop {
  margin-top: 68px;
  margin-bottom: 35px;
  height: 950px;

  // border: 1px solid red;
  // 左侧部分内容
  .detailLeft {
    float: left;
    width: 420px;
    text-align: center;

    // border: 1px solid red;
    .bigPic {
      height: 420px;
      width: 420px;
      background-size: cover;
    }

    ul {
      width: 420px;
      margin-top: 8px;

      li {
        display: flex;
        justify-content: flex-start;
        // justify-content: space-between;
        display: flex;
        // flex-wrap: wrap;
        overflow: hidden;

        img {
          width: 81.6px;
          height: auto;
          margin-right: 3px;
          // border: 1px solid red;
        }
      }
    }

    .share {
      height: 40px;
      width: 420px;
      line-height: 40px;
      margin-top: 20px;

      // border: 1px solid red;
      .btn {
        border: 1px solid #ef0c25;
        background: linear-gradient(to right, #fd5532, #ef0c25);
        float: left;
        margin-top: 4px;
      }
      .btn:hover {
        background: linear-gradient(to right, #e34d2d, #d60b20);
      }

      .s_right {
        float: right;
        color: #adadad;
        // 收藏状态的样式
        .active {
          color: #ff4700;
        }

        span {
          margin-right: 10px;
        }
        span:hover {
          cursor: pointer;
        }
        .el-icon-share {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }

        .el-icon-star-on {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
    }

    .line {
      margin-top: 20px;
      border: 1px solid #f6f4f7;
    }

    .sale_desc {
      margin-top: 20px;

      span {
        display: block;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #999;
        margin-top: 3px;

        a {
          color: #2e5aef;
        }
      }
    }
  }

  // 右侧部分内容
  .detailRight {
    float: right;
    width: 62%;

    // border: 1px solid greenyellow;
    .shop {
      height: 34px;
      line-height: 34px;

      .typeLogo {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      button {
        width: 68px;
        height: 22px;
        line-height: 22px;
        margin-left: 30px;
        background-color: #333;
        color: #fff;
        font-size: 12px;
        border-radius: 14px;

        a {
          color: #fff;
        }
      }

      button:hover {
        color: #ef0c25;
      }
    }

    .title {
      // height: 45px;
      line-height: 25px;
      display: flex;
      // background-color: rgb(137, 212, 137);
      .desc {
        font-weight: 700;
        color: #333;
        margin-right: 20px;
        display: inline-block;
        max-width: 550px;
        // white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        a:hover {
          cursor: pointer;
        }
      }

      .d_right {
        display: inline;
        color: #2e7ef3;

        span {
          margin-left: 5px;
        }
      }
    }

    .priceDesc {
      span:nth-child(1) {
        font-weight: 700;
        font-size: 32px;
        color: #333;
        margin-right: 10px;
      }

      .p_right {
        display: inline;
        cursor: pointer;
        color: #ef0c25;
      }
    }

    .tips {
      color: #666;
      font-size: 12px;
      margin-bottom: 22px;
      // background-color: greenyellow;
    }

    .fee {
      height: 150px;
      margin-top: 20px;
      border-radius: 5px;
      background-color: #f5f5f5;

      .stepMain {
        padding: 20px;
      }

      .origin {
        // width: 400px;
        height: 26px;
        padding-top: 2px;
        font-size: 14px;
        color: #fff;
        // border-radius: 6px;
        padding-left: 8px;
        background: linear-gradient(to right, #fd5532, #ef0c25);

        button {
          width: 92px;
          height: 18px;
          border-radius: 15px;
          border: 0;
          background-color: #fff;

          a {
            font-size: 12px;
            padding: 3px;
          }
        }
      }

      .steps {
        float: left;
      }

      .s_desc {
        float: left;
        color: #333;

        .step2 {
          display: block;
          margin-top: 18px;

          .estimateFee {
            color: #ef0c25;
          }
        }

        .el-input__inner {
          width: 57px;
          height: 26px;
          margin-right: 8px;
          text-align: center;
          line-height: 26px;
        }

        .el-input__inner:focus {
          border: 1px solid #ef0c25;
        }
      }
    }

    // 颜色列表
    .goods_color {
      margin-top: 10px;

      span:hover {
        cursor: pointer;
      }

      .imgSelected {
        border: 2px solid #ef0c25;
      }

      .SizeSelected {
        border: 2px solid #ef0c25;
      }

      .disableSelect {
        cursor: not-allowed; //不能点击的效果
        color: #cfd0d3; //置灰的颜色
        background-color: #eee;
        border: 2px solid #eee;
      }

      ul li {
        display: inline;

        img {
          width: 42px;
          height: 42px;
          vertical-align: middle;
          margin: 5px 8px 0 0;
          border: 2px solid #eee;
        }

        span {
          vertical-align: middle;
          line-height: 38px;
          display: inline-block; // 文本换行时边框不会叠加在一起
          margin: 5px 8px 0 0;
          padding: 0 8px;
          border: 2px solid #eee;
        }
      }
    }

    .goods_nums {
      margin-top: 20px;

      span {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }

    .goods_msg {
      margin-top: 20px;
      span {
        font-size: 12px;
        font-weight: 600;
        color: #333;
      }

      textarea {
        margin-top: 8px;
        // resize: vertical;
        resize: none;
        height: 60px;
        padding: 5px 15px;
        width: 100%;
        font-size: 12px;
        // color: #c0c4cc;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }

      textarea::placeholder {
        color: #c0c4cc;
      }
    }

    .notice {
      span {
        display: block;
        color: #999;
        padding: 15px 0;
      }

      .line {
        border: 1px solid #f6f4f7;
      }

      .agree {
        display: inline;
      }
    }

    .goods_buy {
      margin-top: 20px;
      .buy,
      .cart {
        width: 180px;
        height: 60px;
        font-weight: 700;
      }

      .buy {
        border: 1px solid #ef0c25;
        background: linear-gradient(to right, #fd5532, #ef0c25);
      }

      .buy:hover {
        background: linear-gradient(to right, #e34d2d, #d60b20);
      }

      .cart {
        color: #ef0c25;
        background-color: #fff;
        border: 1px solid #ef0c25;
      }

      .cart:hover {
        background-color: #f7e5e6;
      }

      .line {
        margin-top: 35px;
        border: 1px solid #f6f4f7;
      }
    }
  }
}

.detailBottom {
  // float: left;
  width: 100%;
  position: relative;
  text-align: center;
  border: 1px solid #f2f2f2;
  border-top: 0;
  overflow: hidden;
  margin-bottom: 60px;
  padding-bottom: 30px;
  .buy_attention {
    text-align: left;
    img {
      margin: 20px 20px 20px 0;
    }

    h1 {
      font-weight: 200;
      color: #333;
      font-size: 24px;
    }

    p {
      margin-top: 6px;
      font-size: 12px;
      color: #666;

      a {
        color: #2e5aef;
      }
    }

    .ban_includes {
      font-size: 12px;
      color: #999;
    }
  }

  .sale_after {
    font-size: 14px;
    text-align: left;
    line-height: 28px;
    color: #666;
  }
}
.detailListdesc {
  min-height: 100vh;
  .desc {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    overflow: hidden; /* 防止图片溢出div */
    img {
      width: 100%;
    }
  }
}

.tabContainer {
  padding: 10px;
}
</style>
