import request from "@/utils/request";

// 创建未支付订单
export function createOrder(data){
          var url = "/order/createOrder";
          return request.post(url,data)
}

// 获取当前未支付订单的总价和余额
export function getNoPayOrder(data){
          var url = "/order/getPayOrderMoney";
          return request.post(url,data) 
}

/**
 * 余额 支付
 */
export function payConfirm(data){
          var url = "/order/payConfirm";
          return request.post(url,data) 
}

// 订单列表
export function myOrderList(data){
          var url = "/order/myOrderList";
          return request.post(url,data) 
}

// 修改订单备注
export function rechangeRemark(data){
          var url = "/order/rechangeRemark";
          return request.post(url,data) 
}


export function myOrderSearch(data){
          
          var url = "/order/myOrderSearch";
          return request.post(url,data) 
}


// 增值服务列表
export function addServeList(){
    var url = "/order/addServeList";
          return request.post(url) 
}

// 追加增值服务
export function saveAddItem(data){
    
    var url = "/order/saveAddItem";
          return request.post(url,data) 
}

// cancalOrder 取消订单
export function cancalOrder(data){
    
    var url = "/order/cancalOrder";
          return request.post(url,data) 
}

// myOrderUp 我的首页显示最新动态的十条数据
export function myOrderUp(){
    
    var url = "/order/myOrderUp";
          return request.post(url) 
}

// 订单详情
export function orderDetail(data){
    var url = "/order/orderDetail";
    return request.post(url,data) 
}

// orderTrack 订单物流
export function orderTrack(data){
    var url = "/order/orderTrack";
    return request.post(url,data) 
}

// orderServeReturn 订单售后
export function orderServeReturn(data){
    var url = "/order/orderServeReturn";
    return request.post(url,data) 
}


// serveReturnGoods 发起售后申请
export function serveReturnGoods(data){
    var url = "/order/serveReturnGoods";
    return request.post(url,data) 
}


// orderMessage 订单留言
export function orderMessage(data){
    var url = "/order/orderMessage";
    return request.post(url,data) 
}

// 获取订单留言
export function getOrderMessage(data){
    var url = "/order/getOrderMessage";
    return request.post(url,data) 
}

// orderOk 确认订单
export function orderOk(data){
    var url = "/order/orderOk";
    return request.post(url,data) 
}

// 到仓库订单
export function wareHouseOrder(data){
    var url = "/order/wareHouseOrder";
    return request.post(url,data) 
}

// getWareHouseOrderInvoice 获取发票信息
export function wareHouseOrderInvoice(data){
    var url = "/order/getWareHouseOrderInvoice";
    return request.post(url,data) 
}

// 在把订单提交转运时 推荐物流 getDeliveryByOrder
export function DeliveryByOrder(data){
    var url = "/order/getDeliveryByOrder";
    return request.post(url,data) 
}
// 订单优惠券
export function couponList(data){
    var url = "/order/sendCouponList"
    return request.post(url,data)
}