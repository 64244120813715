// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    // 多组件之间共享数据和方法
    state: {
        // 共享数据,
        cartlist:[
            {
                "id": 10,
                "uid": 4,
                "title": "行走的艺术品 空军一号af1空军龙年限定纯白空军纯黑空军小麦空军大勾黑白熊猫空军一号黑色ambush空军情人节限定",
                "goods_price": "335.00",
                "goods_num": 1,
                "sum_money": "335.00",
                "goods_img": "https://si.geilicdn.com/pcitem1763200120-794f0000018959cb84420a231418_999_996.jpg",
                "shop_name": "Denim",
                "shop_id": "1639127361",
                "shop_link": "https://weidian.com/?userid=1639127361",
                "discount_info": "",
                "goods_url": "https://weidian.com/item.html?itemID=6461287822?skuId=104748279707",
                "post_fee": "10.00",
                "option": "配色:小麦<br /> 码数:36",
                "remark": "i need",
                "add_time": 1709259424,
                "promotionCode": "",
                "add_time_text": "2024-03-01 10:17:04",
                status: false,
                },
                 {
                "id": 11,
                "uid": 4,
                "title": "行走的艺术品 空军一号af1空军龙年限定纯白空军纯黑空军小麦空军大勾黑白熊猫空军一号黑色ambush空军情人节限定",
                "goods_price": "135.12",
                "goods_num": 1,
                "sum_money": "135.12",
                "goods_img": "https://si.geilicdn.com/pcitem1763200120-794f0000018959cb84420a231418_999_996.jpg",
                "shop_name": "Denim",
                "shop_id": "1639127361",
                "shop_link": "https://weidian.com/?userid=1639127361",
                "discount_info": "",
                "goods_url": "https://weidian.com/item.html?itemID=6461287822?skuId=104748279707",
                "post_fee": "10.00",
                "option": "配色:小麦<br /> 码数:36",
                "remark": "i need",
                "add_time": 1709259424,
                "promotionCode": "",
                "add_time_text": "2024-03-01 10:17:04",
                status: false,
                }
        ]
    },
    mutations:{
        UPDATE_CART(state,data){
            state.cartlist = [...data]
        },
        DELETE_GOODS(state,goods){
            let targetIndex = state.cartlist.findIndex(item => item.id === goods.id)
            state.cartlist.splice(targetIndex,1)
        },
        DELETE_ALL_GOODS(state){
            state.cartlist = []
        }
    },
    actions: {
        updateCart(state,payload){
            state.commit('UPDATE_CART',payload)
        },
        deleteGoods(state,payload){
            state.commit('DELETE_GOODS',payload)
        },
        deleteAllGoods(state){
            state.commit('DELETE_ALL_GOODS')
        },
    },
    getters:{
        // 计算价格
        computedPrice(state){
            let checked_list = state.cartlist.filter(item => item.status)
            return checked_list.reduce((pre,cur) => {
                return pre += (Number(cur.goods_price) * Number(cur.goods_num))
            },0)
        },
        //计算数量
        computedNums(state){
            let checked_list = state.cartlist.filter(item => item.status)
            return checked_list.length
        }
    }
});