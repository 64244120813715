import request from "@/utils/request";

// 获取商品详情
export function goodsDetail(url) {
    return request.get(url)
}

// 收藏商品
export function collectGoods(data){
    var url = "/index/collectGoods";
    return request.post(url,data)
  }