<template>
  <div class="shop w">
    <span class="hot">{{ $t("goodsrecommend.Everyone_is_looking") }} ◕</span>
    <div class="goods">
      <ul>
        <li
          v-for="item in shopList"
          :key="item.id"
          @click="toGoodsDetail(item.type, item.itemid)"
        >
          <img alt="" v-lazy="item.image" />

          <el-tooltip
            class="item"
            effect="light"
            :content="item.title"
            placement="bottom"
          >
            <span class="goods_title">
              <!-- <a href="" :title="item.title">{{ item.title.slice(0, 15) }}...</a> -->
              <!-- <img  v-if="item.type=='taobao'" src="../../assets/taobao-logo.png">
              <img  v-if="item.type=='micro'" src="../../assets/weidian-logo.png">
              <img  v-if="item.type=='1688'" src="../../assets/1688-logo.png"> -->
              <a href="" id="goods_title">{{ item.title }}</a>
            </span>
          </el-tooltip>

          <span class="goods_price"
            >{{ currencyCode }} {{ moneyRates(item.price) }}</span
          >
          <!-- <span class="goods_user">{{ item.last_user }}</span> -->
          <!-- <span>{{ getFormattedTimeDifference(item.last_time) }}前浏览</span> -->
        </li>
      </ul>
    </div>
    <div class="loading">
      <van-loading
        v-if="isLoading"
        size="60px"
        style="text-align: center; height: 100px"
      />
    </div>
  </div>
</template>

<script>
import { goodsRecommend } from "@/Api/index";
export default {
  name: "ShopList",
  data() {
    return {
      shopList: [],
      loading: true,
      isLoading: false, // 是否正在加载
      pageNum: 1,
      over: false,
      globs: parseFloat(new Date().getTime() / 1000).toFixed(0), //全局秒
      currencyCode: "CNY",
    };
  },

  methods: {
    moneyRates(money) {
      return this.moneyRate(money);
    },
    toGoodsDetail(type, itemId) {
      window.open("/goodsDetail?itemId=" + itemId + "&type=" + type, "_blank");
    },
    getGoodsRecommend(pageNum = 1) {
      goodsRecommend("/index/recommend?pageNum=" + pageNum).then((res) => {
        if (pageNum == 1) {
          this.shopList = res.data;
        } else {
          res.data.forEach((item) => {
            this.shopList.push(item);
          });
        }
        this.isLoading = false;
      });
    },
    // 监听滚动事件的方法
    // handleScroll() {
    //   if (location.pathname != "/") {
    //     return;
    //   }
    //   // 计算：页面滚动的高度scrollTop、窗口可视区域高度clientHeight、页面总高度scrollHeight
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   let clientHeight =
    //     window.innerHeight ||
    //     document.documentElement.clientHeight ||
    //     document.body.clientHeight;
    //   let scrollHeight =
    //     document.documentElement.scrollHeight || document.body.scrollHeight;
    //   if (scrollHeight - scrollTop - clientHeight < 20) {
    //     // 当距离页面底部不足10px且非正在加载状态时，触发加载数据的方法
    //     if (this.isLoading == true) {
    //       //如果发起了请求 并且还没有响应 说明在加载中
    //       return;
    //     }
    //     this.pageNum++; // 增加页数
    //     this.isLoading = true;
    //     this.getGoodsRecommend(this.pageNum);
    //   }
    // },
    // 多久前浏览逻辑
    // updateTimeDifference() {
    //   this.data.forEach((item) => {
    //     item.formattedTimeDifference = this.getFormattedTimeDifference(
    //       item.last_time
    //     );
    //   });
    // },
    // getFormattedTimeDifference(lastTime) {
    //   const currentTime = Math.floor(Date.now() / 1000);
    //   const timeDifference = currentTime - lastTime;
    //   return this.formatTimeDifference(timeDifference);
    // },
    // formatTimeDifference(seconds) {
    //   const days = Math.floor(seconds / (24 * 3600));
    //   const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    //   const minutes = Math.floor((seconds % 3600) / 60);
    //   const remainingSeconds = seconds % 60;
    //   return `${days}天 ${hours}小时 ${minutes}分钟 ${remainingSeconds}秒`;
    // },
  },
  created() {
    this.currencyCode = this.getCurrency;
    this.isLoading = true;
    this.getGoodsRecommend();

    // 监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.shop {
  .hot {
    display: block;
    text-align: center;
    padding: 60px 0;
    color: #333;
    font-size: 40px;
    font-weight: 400;
  }
  /* 商品推荐列表 */
  .goods {
    ul {
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-start;
      // justify-content: space-around;
      justify-content: space-between;
      li {
        width: 220px;
        // margin-right: 20px;
        margin-bottom: 22px;
        border: 1px solid transparent;
        span {
          display: block;
          margin: 5px 0px 4px 4px;
          a {
            color: #333;
            font-size: 12px;
          }
          a:hover {
            text-decoration: underline;
            color: #ef0c25;
          }
        }
        img {
          // width: 220px;
          width: 100%;
          height: 200px;
        }
        // 文字溢出隐藏
        .goods_title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          img {
            width: 12px;
            height: 12px;
          }
        }
        .goods_price {
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
        .goods_user {
          color: #999;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }
    // ul:nth-child(5n) {
    //   margin-right: 0;
    //   // background-color: pink;
    // }

    /* 过渡效果 transition：参与过渡的属性 过渡的持续时间*/
    li:hover {
      /* scale: 1.1; */
      transform: scale(1.1);
      transition: transform 0.5s;
      // box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.1);
      // border: 1px solid #e4e4e4;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }
  // 最后一行商品不贴边显示？ 待验证
  .goods:not(:last-child) {
    justify-content: flex-start;
  }
}
</style>
