<template>
  <div id="app">
    <router-view></router-view>
    <suspendBall />
  </div>
</template>

<script>
import suspendBall from "/src/components/user/suspendBall.vue";
export default {
  name: "App",
  components: { suspendBall },
  created() {},
};
</script>

<style lang="less">
*{
  font-size: 13px;
}
i.el-icon-loading {
  color: #ef0c25 !important;
}
p.el-loading-text {
  color: #ef0c25 !important;
}
// @media screen and (max-width: 768px) {
//   .w {
//     width: 100vw !important;
//   }
//   .contain {
//     height: auto !important;
//   }
//   .headerTop {
//     flex-wrap: wrap !important;
//     height: auto !important;
//   }
//   .headerTop a {
//     height: 40px !important;
//   }
//   .headerTop > li {
//     display: flex;
//     align-items: center;
//   }
//   .headerTop > li:nth-child(2) {
//     width: 100% !important;
//     justify-content: center !important;
//     margin-bottom: 10px !important;
//   }
//   .headerTop > li:nth-child(2) > div {
//     width: 90% !important;
//   }
//   .headerTop > li:nth-child(3) {
//     display: none !important;
//   }
//   .headerTop > li:nth-child(4) {
//     margin-left: 20px !important;
//   }
//   .headerTop > li:last-child {
//     margin-right: 20px !important;
//   }
//   .langAndCurrency {
//     top: 0;
//     right: 0;
//   }
//   .discord,
//   .suspend {
//     z-index: 999;
//   }
//   .slogan {
//     padding-top: 40px !important;
//     font-size: 35px !important;
//   }
//   .van-step__title {
//     width: 50% !important;
//   }
//   .van-step:last-child .van-step__line {
//     width: 100% !important;
//   }
//   .van-steps--horizontal .van-steps__items {
//     display: grid;
//     grid-template-columns: repeat(4, 25%);
//   }
//   .middle {
//     height: auto !important;
//   }
//   .detailTop .detailRight {
//     width: 97% !important;
//   }
//   .detailTop .detailRight .title {
//     flex-direction: column;
//   }
//   .detailTop .detailRight .shop {
//     width: 100% !important;
//   }
//   .detailTop .detailRight .fee {
//     height: 100% !important;
//   }
//   .detailTop .detailRight .fee .origin {
//     height: 100% !important;
//   }
//   .detailTop .detailRight .fee .stepMain {
//     display: flex;
//   }
//   .swiper-container,
//   .middleInput,
//   .bottomPart .b_top {
//     display: none !important;
//   }
//   .box span {
//     font-size: 12px !important;
//   }
//   .el-card__body,
//   .el-main {
//     padding: 10px !important;
//   }
//   .serve,
//   .shop .hot {
//     padding: 30px !important;
//     font-size: 28px !important;
//   }
//   .shop .goods {
//     display: grid !important;
//     grid-template-columns: 48% 48% !important;
//   }
//   .shop .goods li {
//     width: 100% !important;
//   }

//   .bottomPart .b_main ul {
//     flex-wrap: wrap !important;
//   }
// }
.d_box {
  .el-input__inner {
    width: 45% !important;
  }
  button.el-button.el-button--default:hover {
    color: #ef0c25;
    border-color: #ef0c25;
    background-color: #f7e5e6;
  }
}
.cartinfo_page input {
  height: 26px;
  line-height: 26px;
  .el-input__icon {
    line-height: 26px;
  }
}
.l_btn .el-button.el-button--default:hover {
  color: #ef0c25;
  border-color: #ef0c25 !important;
  background-color: #f7e5e6 !important;
}
span.el-switch__core {
  border-color: #ef0c25 !important;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
textarea.el-textarea__inner:focus {
  border-color: #ef0c25 !important;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #ef0c25 !important;
}

li.el-dropdown-menu__item:hover {
  color: #ef0c25 !important;
  background-color: #f7e5e6 !important;
}

button.el-button.more.el-icon-more.el-button--default:hover {
  color: #ef0c25;
}
button.el-button.more.el-icon-more.el-button--default:focus {
  color: #ef0c25;
}
span.el-radio__inner:hover {
  border-color: #ef0c25;
}
span.el-radio__input.is-checked + .el-radio__label {
  color: #ef0c25;
}
span.el-radio__input.is-checked .el-radio__inner {
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
button.el-button.el-button.is-plain:focus {
  color: #ef0c25;
  border-color: #ef0c25;
}
button.el-button.el-icon-plus.el-button--default.is-plain:hover {
  background: #fff;
  border-color: #ef0c25;
  color: #ef0c25;
}
button.el-button.el-icon-upload2.el-button--default.is-plain:hover {
  background: #fff;
  border-color: #ef0c25;
  color: #ef0c25;
}

.el-message-box__headerbtn:hover .el-message-box__close {
  color: #ef0c25 !important;
}

.cart_content button.el-button.el-button--default:hover {
  color: red;
  border-color: #ef0c25;
  background-color: #f7e5e6;
}
button.el-button.el-button--primary {
  color: #fff;
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}

button.el-button.el-button--primary:hover {
  background: linear-gradient(to right, #e34d2d, #d60b20);
}
button.el-button.el-button--default.is-round:hover {
  color: red;
  border-color: #ef0c25;
  background-color: #f7e5e6;
}
button.el-button.el-button--default.is-round:focus {
  color: red;
  border-color: #ef0c25;
  background-color: #f7e5e6;
}

button.el-button.el-button--default.el-button--small:hover {
  color: red;
  border-color: #ef0c25;
  background-color: #f7e5e6;
}
button.el-button.el-button--default.el-button--small.el-button--primary {
  color: #fff;
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
button.el-button.el-button--default.el-button--small.el-button--primary:hover {
  background: linear-gradient(to right, #e34d2d, #d60b20);
}
.el-range-editor.is-active {
  border-color: #ef0c25 !important ;
}

.el-date-table td.available:hover {
  color: #ef0c25 !important;
}
.el-date-table td.today span {
  color: #ef0c25 !important;
}

.el-date-table td.today.end-date span,
.el-date-table td.today.start-date span {
  color: #fff !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background: linear-gradient(to right, #fd5532, #ef0c25);
}

button.btn-next:hover {
  color: #ef0c25 !important;
}
button.btn-prev:hover {
  color: #ef0c25 !important;
}
.el-pager li.active {
  color: #ef0c25 !important;
}
.el-pager li:hover {
  color: #ef0c25 !important;
}

.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: #ef0c25 !important;
}

.item_box .el-input__inner {
  border: 1px solid #dcdfe6 !important;
}
.goods_nums .el-input__inner {
  border: 1px solid #dcdfe6 !important;
}
.headerTop .el-input.el-input--suffix:focus {
  border: 1px solid #dcdfe6 !important;
}
.headerTop .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent !important;
}
.headerTop div.el-input.el-input--suffix .el-input__inner:focus {
  border-color: transparent;
}
.middleInput .el-input.el-input--suffix:focus {
  border: 1px solid #dcdfe6 !important;
}
.middleInput .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent !important;
}
.middleInput div.el-input.el-input--suffix .el-input__inner:focus {
  border: 1px solid transparent !important;
}

div.el-input.el-input--suffix:focus {
  border-color: #ef0c25 !important;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #ef0c25 !important;
}

.item_box button.el-button.el-button--success {
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25) !important;
}
button.el-button.el-button--success {
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25) !important;
}

button.el-button.el-button--text {
  color: #ef0c25;
}

div.swiper-button-prev {
  width: 30px;
  background-size: 44px 44px;
  background-image: url(./assets/arrow-left.png);
}
div.swiper-button-next {
  width: 70px;
  background-size: 44px 44px;
  background-image: url(./assets/arrow-right.png);
}
/* 积分模块 */
div.el-progress-bar__outer {
  width: 190px;
  /* height: 8px; */
}
/* 强行不显示百分比 */
div.el-progress-bar__innerText {
  display: none;
}
div.allyContainer .myAssest ul li .percent .el-progress[data-v-73799b26] {
  line-height: 0;
}
/* 推广 提示框 */
/* .el-tooltip__popper.is-dark {
  color: #fff;
  background-color: #ff4e4e !important;
}

.el-tooltip__popper[x-placement^="top"] .popper__arrow {
  z-index: 999999;
  border-top-color: #ff4e4e !important;
}
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  z-index: 999999;
  border-bottom-color: #ff4e4e !important;
} */

.el-dialog__body {
  padding: 20px 20px 10px;
}
.el-dialog__header {
  /* height: 45px;
  line-height: 45px;
  padding: 0 0 0 20px; */
  border-bottom: 1px solid #dddddd;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: linear-gradient(to right, #fd5532, #ef0c25) !important;
  border-color: #ef0c25 !important;
}
span .el-checkbox__inner:hover {
  border-color: #ef0c25 !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #ef0c25 !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ef0c25 !important;
}
.el-table td.el-table__cell div {
  /* text-align: center !important; */
}
.el-button--text {
  color: #ef0c25;
}
body {
  // font-family: PingFang SC, Hiragino Sans GB, Helvetica Neue, Helvetica,
  //   Microsoft YaHei, 微软雅黑, Arial, sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
em {
  font-style: normal;
}
.w {
  width: 1200px;
  margin: 0 auto;
  /* border: 1px solid red; */
  /* height: 2000px; */
  /* background-color: #fff; */
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
.header {
  z-index: 999;
}
/* el的样式在APP.vue中修改 */
.middle input.el-input__inner {
  height: 80px;
  font-size: 28px;
  font-weight: 300;
}
div.el-input-group__append {
  color: #fff;
  border-color: #ef0c25;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
i.el-input__icon.el-icon-picture-outline {
  /* display: block; */
  margin-right: 8px;
  scale: 2.5;
}

i.el-input__icon.el-icon-picture-outline {
  color: #606266 !important;
}
// i.el-input__icon.el-icon-picture-outline:hover {
//   color: #ef0c25 !important;
// }

input.el-input__inner:focus {
  border: 1px solid #ef0c25;
}
.login .el-input__inner {
  background: #f8f8f8;
  border: none !important;
}
/* start */
.deliver .el-select .el-input.is-focus .el-input__inner {
  border-color: #ef0c25;
}
.deliver li.el-select-dropdown__item.selected {
  color: #ef0c25;
}
.deliver .el-select .el-input.is-focus .el-input__inner:focus-within {
  border-color: #ef0c25;
}
.goodsInfoBody .el-select .el-input.is-focus .el-input__inner {
  border-color: #ef0c25;
}
//
div.el-input.el-input--suffix .el-input__inner:focus {
  border-color: #ef0c25;
}

li.el-select-dropdown__item.selected span {
  color: #ef0c25;
}
li.el-select-dropdown__item.selected::after {
  color: #ef0c25;
}
/* end */

/* vant样式也在APP.vue中修改 */
div.van-steps.van-steps--horizontal {
  background-color: transparent;
}
.van-step--horizontal .van-step__circle-container {
  background-color: transparent;
}
.van-step--horizontal .van-step__line {
  height: 1.5px;
  /* margin-top: 5px; */
  background: #fff;
}
i.van-step__circle {
  width: 15px;
  height: 15px;
  /* background: #fff; */
}
.van-step--finish .van-step__circle {
  /* background-color: #ef0c25; */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.van-step__icon--active,
.van-step__icon--finish {
  margin-top: 4px;
  /* background-color: #7f7067; */
  scale: 1.5;
}

.van-step--horizontal .van-step__title {
  font-size: 14px;
  margin-top: 50px;
}
div.van-step__title {
  color: #fff;
}
.uploadImg .van-uploader__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* tab 标签栏 */
div.el-tabs__nav-scroll {
  height: 60px;
  line-height: 60px;
}
.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
  // justify-content: space-between;
  // margin-left: 50px;
}
// tab标签
.imgTabContainer .el-tabs__nav-scroll {
  float: left;
  margin-left: 50px;
}
div.el-tabs__nav-wrap.is-top::after {
  height: 2px;
  background: #f2f2f2;
}

.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
}

.el-tabs--top .el-tabs__item.is-top {
  padding-right: 200px;
}
.el-tabs--top .el-tabs__item {
  font-size: 18px;
  font-weight: 500;
  color: #303133;
}
.el-tabs--top .el-tabs__item:hover {
  color: #ef0c25;
}
.el-tabs--top .el-tabs__item.is-active {
  color: #ef0c25;
}
.el-tabs__active-bar.is-top {
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
/* goodsDetail */
.detailBottom .el-tabs__nav-scroll {
  display: flex;
  justify-content: flex-start;
}
.detailBottom .el-tabs--top .el-tabs__item.is-top {
  padding-right: 10px;
}
.detailBottom .el-tabs--top .el-tabs__item:hover {
  /* color: #303133; */
}
/* 步骤条 */
div.el-step__title.is-process {
  font-weight: 300;
}

div.el-step__icon.is-text {
  border: 0;
  color: #fff;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}

/* 详情页 分享 */

button.el-button.is-round {
  padding: 8px 28px;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.wareHouse_page .el-badge__content.is-fixed {
  top: 10px;
}
.wareHouse_page .el-table th.el-table__cell.is-leaf {
  background: #f7f7f7;
}
.wareHouse_page table,
.parcel_page table {
  width: 100% !important;
}
.parcel_page table {
  padding: 0 20px;
}
.parcel_page .el-table .el-table__cell {
  padding: 0;
}
.parcel_page .is-leaf.el-table__cell {
  padding: 12px 0;
}
.wareHouse_page table .el-badge__content--warning {
  background: #f11526;
}
.account_page .el-tabs__nav-scroll {
  justify-content: initial;
}
.account_page div.el-tabs__nav-scroll {
  height: 45px;
  line-height: 45px;
}
.account_page .el-tabs--top .el-tabs__item.is-top {
  padding-right: 10px;
}
.account_page .el-tabs--top .el-tabs__item {
  font-size: 16px;
}
.base-info .el-progress-bar__outer {
  background: #353535 !important;
}
.base-info .el-progress-bar__inner {
  background: #ef0c25;
}
.account_col_3 .el-form-item .el-form-item__content {
  margin-left: 0 !important;
}
.account_col_3 .el-form-item:first-child .el-form-item__content {
  margin-left: 150px !important;
}
.invoice_page .delivery .el-select-dropdown__item {
  height: auto;
}
</style>
