<template>
  <!-- swiper轮播图 -->
  <div class="swiper-container w">
    <div class="swiper-wrapper">
      <div
      class="swiper-slide"
      v-for="(item, index) in carouselList"
        :key="index"
      >
        <img  
        
        @click="toRequest(item.url)" :src="path + item.image"   />
      </div>
    </div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import { CarouselList } from "@/Api/index";

export default {
  data() {
    return {
      carouselList: [],
      path: "https://www.seacloudbuy.com",
    };
  },

  mounted() {
    this.getSwiperPhoto()  
  },

  methods: {
    getSwiperPhoto(){
      CarouselList("/index/index").then(
      (res) => {
        this.carouselList = res.data;
        this.getSwiper()
      }
    );
    },
    getSwiper(){
      setTimeout(() => {
      new Swiper(".swiper-container", {
        // direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        slidesPerView: 4, // 同时展示几张图片
        autoplay: {
          //自动开始
          delay: 3000, //时间间隔
          disableOnInteraction: false, // 手动操作轮播图后不会暂停
        },
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 如果需要滚动条
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
    }, 100);
    },
    toRequest(url) {
      this.$router.push({
        path: "/question",
        query: {
          requestId: url,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
/* 轮播图 */
.swiper-container {
  padding: 30px 0 20px;
}
.swiper-slide {
  display: flex;
  justify-content: space-between;
}
</style>
