import request from "@/utils/request";

// 发送验证码
export function sendCode(data) {
  var url = "/ems/send";
  return request.post(url,data);
}


// 检查验证码
export function checkCode(data) {
    var url = "/ems/check";
    return request.post(url,data);
  }