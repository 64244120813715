

import request from "@/utils/request";

/**
 * 
 * 获取消费日志
 * @returns 
 */
export function getRecord(data){
          var url= "/record/getRecord"
          return request.post(url,data) 
}

// 消费类型
export function getRecordType(){
          var url= "/record/getRecordType"
          return request.post(url) 
}


// scoreRecord 积分变动记录

export function scoreRecord(){
          var url= "/record/scoreRecord"
          return request.post(url) 
}

// upgradeScore 用积分升级
export function upgradeScore(){
          var url= "/record/upgradeScore"
          return request.post(url)   
}