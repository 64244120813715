<template>
  <div class="chatBox w">
    <!-- 内容 -->
    <div class="chatContent" id="chatContent">
      <div v-for="item in message" :key="item.id">
        <!-- 左边用户 -->
        <div v-if="item.type == 2" class="left_user">
          <div class="userBox">
            <div class="avatar">S</div>
            <div class="sender-name">Seacloud</div>
          </div>
          <div class="text">
            {{ item.content }}
            <!-- <p class="date">{{ item.create_time_text }}</p> -->
          </div>
        </div>

        <!-- 右边用户 -->
        <div v-if="item.type == 1" class="right_user">
          <div class="text">
            {{ item.content }}
            <!-- <p class="date">{{ item.create_time_text }}</p> -->
          </div>

          <div class="userBox">
            <img class="avatar" :src="userInfo.avatar" />
            <div class="sender-name">{{ userInfo.username }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="chatBottom">
      <div class="tools">
        <img src="../../assets/photo.png" alt="" />
      </div>
      <textarea id="message"
        v-model="newMessage"
        :placeholder="$t('UserSendMessage.Change')"
        @keyup.enter="sendMessage">
      </textarea>
      <button @click="sendMessage">{{ $t('UserSendMessage.Send') }}</button>
    </div>
  </div>
</template>

<script>
import { sendMessage, getSendMessage } from "@/Api/send";
import { getUserInfo } from "@/Api/user";
export default {
  props: ["currentItemMessage"],
  data() {
    return {
      messages: [
        {
          senderName: "Friend",
          senderAvatar: "F",
          text: "Hello, how are you?",
        },
      ],
      myMessages: [],
      message: [],
      newMessage: "",
      userInfo: "",
    };
  },
  created() {
    this.getMessage();
    this.reflush();
    this.getFous()
  },
  methods: {
    getUser(){
      getUserInfo().then((res)=>{
        this.userInfo =res.data
      })
    },
    sendMessage() {
      var data = {};
      data["sid"] = this.currentItemMessage.id;
      data["uid"] = this.currentItemMessage.uid;
      data["content"] = this.newMessage;
      sendMessage(data).then((res) => {
        if (res.code == 1) {
          this.newMessage = "";
          this.getMessage();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getMessage() {
      var data = {};
      data["sid"] = this.currentItemMessage.id;
      data["uid"] = this.currentItemMessage.uid;
      getSendMessage(data).then((res) => {
        this.message = res.data;
      });
    },
    reflush() {
      setTimeout(() => {
        var container = document.getElementById("chatContent");
        container.scrollTop = container.scrollHeight;
      }, 400);
    },
    getFous(){
      // 延迟500毫秒 获取焦点
      setTimeout(() => {
      var element =  document.getElementById("message")
      element.focus()
      }, 500);
     
    }
  },
};
</script>

<style lang="less" scoped>
.chatBox {
  width: 900px;
  // height: 500px;
  background-color: #fff;
  .chatContent {
    height: 300px;
    overflow-y: scroll; // 垂直滚动条
    border-bottom: 2px solid #ececec;

    .left_user {
      display: flex;
      margin-top: 20px;
      .userBox {
        float: left;
        .avatar {
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #4ec764;
          border-radius: 50%;
        }

        .sender-name {
          text-align: center;
          margin-top: 5px;
          font-weight: bold;
        }
      }

      .text {
        align-self: flex-start;
        position: relative;
        text-align: left;
        font-size: 18px;
        border-radius: 4px;
        background-color: #f2f2f2;
        padding: 10px;
        margin-left: 10px;

        &:before {
          content: "";
          position: absolute;
          left: -19px;
          top: 10px;
          width: 0;
          height: 0;
          line-height: 0;
          font-size: 0;
          border: 10px solid transparent;
          border-right-color: #f2f2f2;
        }
      }
    }

    .right_user {
      display: flex;
      justify-content: flex-end;
      // text-align: center;
      margin: 20px 30px 0 0;
      .userBox {
        .avatar {
          width: 40px;
          height: 40px;
          margin-left: 10px;
          background-color: #4ec764;
          border-radius: 50%;
        }

        .sender-name {
          text-align: center;
          margin-top: 5px;
          font-weight: bold;
        }
      }

      .text {
        align-self: flex-start;
        position: relative;
        text-align: left;

        font-size: 18px;
        border-radius: 4px;
        background: linear-gradient(to right, #fd5532, #ef0c25);
        padding: 10px;
        color: #fff;
        margin-right: 10px;

        &:after {
          content: "";
          position: absolute;
          right: -19px;
          top: 10px;
          width: 0;
          height: 0;
          line-height: 0;
          font-size: 0;
          border: 10px solid transparent;
          border-left-color: #ef0c25;
        }
      }
    }

    // padding-bottom: 10px;
  }

  .chatBottom {
    .tools {
      img {
        width: 22px;
        height: 22px;
        margin-top: 8px;
      }
    }

    textarea {
      border: 0;
      width: 100%;
      height: 100px;
      caret-color: #3d3d3d;
    }

    button {
      position: absolute;
      bottom: 20px;
      right: 30px;
      width: 130px;
      height: 40px;
      border: 0;
      border-radius: 4px;
      color: #ef0c25;
      background-color: #e9e9e9;
    }

    button:hover {
      background-color: #d2d2d2;
    }
  }
}

.date {
  font-size: 10px;
  display: inline-block;
}
</style>
