<template>
  <div class="header">
    <!-- 新增模块1 -->
    <div class="topbar" :class="{ hidden: isHidden }">
      beijing time： {{ nowTime }}
    </div>
    <div class="head">
      <ul class="headerTop">
        <router-link to="/"
          ><li><img src="@/assets/header-logo.png" alt="" /></li
        ></router-link>
        <li v-show="activeName != 'login' && activeName != 'register'">
        </li>
        <li v-show="activeName != 'login' && activeName != 'register'">
          <i class="el-icon-setting"></i>
          <router-link to="/more">{{
            $t("headertop.More_features")
          }}</router-link>
        </li>
        <li v-show="activeName != 'login' && activeName != 'register'">
          <i class="el-icon-s-help"></i
          ><a href="/estimation">
            {{ $t("headertop.Freight_estimate") }}
          </a>
        </li>
        <li v-show="activeName != 'login' && activeName != 'register'">
          <span class="h_lang" @click="showLangCurrency">
            <i class="el-icon-orange"></i>
            <a>{{ $t("headertop.Language_Currency") }} </a>
          </span>

          <div
            class="langAndCurrency"
            v-if="isshowLangCurrency"
            @mouseleave="hideBox"
          >
            <div class="l_box">
              <span>{{ $t("headertop.language") }}</span>
              <ul>
                <li
                  v-for="(item, index) in langAndCurrency.language"
                  :class="{ langSelected: langIndex === item.name }"
                  @click="selectLang(item.name)"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
              <span>{{ $t("headertop.Currency") }}</span>
              <ul>
                <li
                  v-for="(item, index) in langAndCurrency.currency"
                  :class="{ currSelected: currIndex === item.letter }"
                  @click="selectCurr(item.letter, item.rate)"
                  :key="index"
                >
                  {{ item.letter }}
                </li>
              </ul>

              <!-- 保存按钮 -->
              <div
                style="width: 100%; text-align: center; padding-bottom: 10px"
              >
                <el-row>
                  <el-button
                    class="save"
                    style="width: 90%"
                    type="success"
                    @click="saveLanguageCountry"
                  >
                    <a style="color: #fff">{{ $t("headertop.Saving") }}</a>
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="
            token == null && activeName != 'login' && activeName != 'register'
          "
        >
          <button class="login">
            <router-link to="/login">{{ $t("headertop.Log_in") }}</router-link>
          </button>
          <button v-if="token == null" class="register">
            <router-link to="/register">{{
              $t("headertop.register")
            }}</router-link>
          </button>
        </li>
        <li v-else-if="token != null">
          <el-dropdown placement="top">
            <el-avatar icon="el-icon-user-solid"></el-avatar>

            <el-dropdown-menu slot="dropdown">
              <router-link to="/person/center">
                <el-dropdown-item>{{
                  $t("headertop.Personal_Center")
                }}</el-dropdown-item>
              </router-link>
              <router-link to="/person/cart">
                <el-dropdown-item>{{
                  $t("headertop.Shopping_cart")
                }}</el-dropdown-item>
              </router-link>
              <router-link to="/person/orderlist">
                <el-dropdown-item>{{
                  $t("headertop.My_Order")
                }}</el-dropdown-item>
              </router-link>
              <router-link to="/person/wareHouse">
                <el-dropdown-item>{{
                  $t("headertop.My_warehouse")
                }}</el-dropdown-item>
              </router-link>
              <router-link to="/person/parcel">
                <el-dropdown-item>{{
                  $t("headertop.My_package")
                }}</el-dropdown-item>
              </router-link>
              <router-link to="/person/wallet">
                <el-dropdown-item>{{
                  $t("headertop.Trading_log")
                }}</el-dropdown-item>
              </router-link>
              <el-dropdown-item @click.native="logoutButton()">{{
                $t("headertop.Sign_out")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { common } from "@/Api/common";
import {
  getcategory,
  getLanguageCountry,
  setLanguageCountry,
} from "@/Api/index";

import { getUserLanguageCurrency, logout } from "@/Api/user";
import { photoUpload, compressImage } from "@/Api/index";
export default {
  name: "HeaderTop",
  data() {
    return {
      isHidden: false, // 控制元素是否隐藏
      input: "",
      select: this.$t("headertop.goods"),
      activeName: this.$route.name,
      token: localStorage.getItem("token"),
      category: [],
      children: [],
      baseUrl: "https://www.seacloudbuy.com",
      isBodyVisible: false,
      langAndCurrency: [],
      isshowLangCurrency: false,
      langIndex: null,
      currIndex: null,
      rate: 1,
      currentCategory: "",
      selectedCategoryIndex: "",
      userInfoShow: false,
	  nowTime:"0000-00-00 00:00:00"
    };
  },
  methods: {
    handleScroll() {
      // 判断滚动位置来决定是否隐藏元素
      this.isHidden = window.scrollY > 0;
    },
    // 上传照片
    uploadImage(file) {
      compressImage(file.content, 400, 500, 0.4).then((compressedBase64) => {
        var data = {};
        let imgSearch = localStorage.getItem("imgSearch");
        if (imgSearch) {
          localStorage.removeItem("imgSearch");
        }
        localStorage.setItem("imgSearch", file.content);
        data["base64"] = compressedBase64.split(";base64,")[1];
        data["type"] = "1688"; //目前只有1688
        // console.log(data["base64"])

        this.$toast.loading({
          message: "loading",
          duration: 0,
        });
        photoUpload(data).then((res) => {
          if (res.code == 1) {
            var data = {};
            // 这个地方可以优化  拿到 res.data 之后 以跳转的方式到另一个页面 然后走photoSearch方法
            data["imageId"] = res.data;
            data["type"] = "1688"; //目前只有1688
            this.$toast.clear();
            location.href =
              "/goodsImageSearch?tab=imageSearch&imageId=" +
              data["imageId"] +
              "&type=1688";
          }
        });
      });
    },
    logoutButton() {
      logout().then((res) => {
        if (res.code == 1) {
          localStorage.removeItem("userinfo");
          localStorage.removeItem("token");
          localStorage.removeItem("currIndex");
          localStorage.removeItem("rate");
          localStorage.removeItem("langIndex");
          localStorage.removeItem("imgSearch");
        }
        this.$router.push({
          path: "/login",
        });
        console.log(res);
      });
    },
    // end
    selectLang(index) {
      this.langIndex = index;
    },
	getNowTime(){
		setInterval(()=>{
			const date = new Date();
		  const year = date.getFullYear();
		  const month = (date.getMonth() + 1).toString().padStart(2, '0');
		  const day = date.getDate().toString().padStart(2, '0');
		  const hours = date.getHours().toString().padStart(2, '0');
		  const minutes = date.getMinutes().toString().padStart(2, '0');
		  const seconds = date.getSeconds().toString().padStart(2, '0');
		  this.nowTime= `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},1000)
	
	},
    selectCurr(index, rate) {
      this.currIndex = index;
      this.rate = rate;
    },
    changeCagegoryChildren(selectedCategoryIndex, index, children) {
      if (selectedCategoryIndex === index) {
        this.children = children;
        return true;
      } else {
        return false;
      }
    },
    categoryInfo(children, index) {
      if (this.selectedCategoryIndex >= 0) {
        this.selectedCategoryIndex = "";
      }
      this.children = children;
      this.currentCategory = index;
    },
    showBody() {
      // this.isBodyVisible = true;
      this.isBodyVisible = !this.isBodyVisible;
    },
    hideBody() {
      this.isBodyVisible = false;
      this.currentCategory = false;
    },
    hideBox() {
      this.isshowLangCurrency = false;
    },
    showLangCurrency() {
      this.isshowLangCurrency = !this.isshowLangCurrency;
    },
    categoryType(categoryIndex) {
      this.selectedCategoryIndex = categoryIndex;
      this.isBodyVisible = true;
    },
    saveLanguageCountry() {
      var data = {};
      data["lang"] = this.langIndex;
      data["currency"] = this.currIndex;
      var user = JSON.parse(localStorage.getItem("userinfo"));
      if (user == null) {
        localStorage.setItem("rate", this.rate);
        localStorage.setItem("langIndex", this.langIndex);
        localStorage.setItem("currIndex", this.currIndex);
      }
      setLanguageCountry(data).then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        }
        localStorage.setItem("rate", this.rate);
        localStorage.setItem("langIndex", this.langIndex);
        localStorage.setItem("currIndex", this.currIndex);
      });
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
  },
  mounted() {
    // 组件挂载后添加滚动事件监听
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 组件销毁前移除滚动事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
	  this.getNowTime()
    var user = JSON.parse(localStorage.getItem("userinfo"));
    if (user != null) {
      getUserLanguageCurrency(user.id).then((res1) => {
        this.langIndex = res1.data.language;
        this.currIndex = res1.data.currency;
        localStorage.setItem("langIndex", this.langIndex);
        localStorage.setItem("currIndex", this.currIndex);
      });
    } else {
      this.langIndex = localStorage.getItem("langIndex");
      this.currIndex = localStorage.getItem("currIndex");

      if (this.langIndex == null || this.langIndex == "null") {
        localStorage.setItem("langIndex", "English");
      }
      if (this.currIndex == null || this.currIndex == "null") {
        localStorage.setItem("currIndex", "USD");
      }
    }
    if (this.activeName == "MainPage") {
      /**
       * 获取主页分类
       */
      getcategory().then((res) => {
        if (res.code == 0) {
          this.$message.error(res.msg);
        } else {
          this.category = res.data;
        }
      });
    }
    /**
     * 获取国家和语言
     */
    getLanguageCountry().then((res) => {
      if (res.code == 0) {
        this.$message.error(res.msg);
      } else {
        this.langAndCurrency = res.data;
        var currency = localStorage.getItem("currIndex");
        this.langAndCurrency.currency.forEach((item) => {
          if (currency == item.letter) {
            localStorage.setItem("rate", item.rate);
          }
        });
      }
    });
  },
  mixins: [common], // 使用common中的getResult（）方法
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 48px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #eee;
  box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  // border: 1px solid red;
  // background: url("/src/assets/home_top.png") no-repeat;
  // background-size: 100% 100%;
  // min-width: fit-content;
  /* border: 1px red solid; */
}
// 新增模块1
.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding-left: 50px;
  color: #fff;
  z-index: 1000;
  background-color: #262626;
}
/* 隐藏元素的样式 */
.hidden {
  display: none;
}
.head {
  padding: 0 50px;
}
.headerTop {
  height: 60px;
  padding: 10px 0;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  li {
    line-height: 40px;
    color: #262626;
    font-size: 16px;
    font-weight: 700;
  }
  li:hover {
    cursor: pointer;
  }
  li:hover > a {
    color: #ef0c25;
  }

  li:hover > i {
    color: #ef0c25;
  }

  .h_lang:hover > a {
    color: #ef0c25;
  }
  .h_lang:hover > i {
    color: #ef0c25;
  }

  img {
    width: 160px;
    height: 35px;
    margin-top: 3px;
  }
}

/* 商品分类*/
.headerBottom {
  display: flex;
  height: 44px;
  padding-top: 8px;
}

.headerBottom li {
  line-height: 28px;
  padding: 0 15px;
  border-radius: 15px;
}
.headerBottom span li:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
.shopClassify {
  height: 28px;
  line-height: 28px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: #000;
  border-radius: 15px;
}
.shopClassify:hover {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(to right, #fd5532, #ef0c25);
}
.login {
  margin-right: 10px;
}
.login,
.register {
  width: auto;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  border: 0;
  background: linear-gradient(to right, #fd5532, #ef0c25);
  border-radius: 15px;
  a {
    font-weight: normal;
    color: #fff;
  }
}

ul li a {
  padding-left: 5px;
}
/* input 输入框 */
.inputTop {
  width: 100px;
}
.langAndCurrency {
  position: absolute;
  width: 262px;
  height: 380px;
  color: #606266;
  margin-top: 10px;
  padding: 0 5px;
  border-radius: 10px;
  border-top: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.1);
  .currSelected,
  .langSelected {
    border: 1px solid #ef0c25;
  }
  .l_box {
    margin: 10px;
    .save {
      border: 1px solid #ef0c25;
      background: linear-gradient(to right, #fd5532, #ef0c25);
    }
  }
  span {
    font-size: 14px;
    margin-left: 10px;
  }
  ul {
    display: flex;
    justify-content: space-around;
    text-align: center;
    // margin-top: -12px;
    flex-wrap: wrap;
    li {
      width: 96px;
      height: 24px;
      margin-bottom: 10px;
      border-radius: 3px;
      line-height: 24px;
      font-size: 12px;
      border: 1px solid #dcdfe6;
    }
    li:hover {
      // border: 1px solid #b8ead1;
      background-color: #f7e5e6;
    }
  }
}
// 商品分类列表
.body {
  // display: none;
  height: 510px;
  position: absolute;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-shadow: -7px 6px 9px -2px rgba(0, 0, 0, 0.1);
  // border: 1px red solid;
  border-radius: 10px;
  margin-top: 20px;

  .b_left_li {
    // border: 1px red solid;
    height: 40px;
    line-height: 40px;
    padding: 0 8px;
    border-radius: 0;
    background-color: #f1f1f1;
    // border: 1px solid red;
    i {
      float: right;
      line-height: 40px;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .b_left {
    float: left;
    width: 250px;
    height: 100%;
    position: absolute;
    overflow-y: scroll; // 垂直滚动条
    // background-color: pink;
    ul {
      margin: 10px;
      font-size: 16px;
      li {
        color: #333;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        padding: 0 8px;
        // border: 1px solid red;
        i {
          float: right;
          line-height: 40px;
          font-size: 12px;
          font-weight: 700;
        }
      }
      li:hover {
        border-radius: 0;
        font-weight: 550;
        color: rgba(0, 0, 0, 0.8);
        background-color: #f1f1f1;
      }
    }
  }
  .b_right {
    float: right;
    height: 100%;
    width: 950px;
    overflow-y: scroll; // 垂直滚动条
    ul {
      position: absolute; // 让li的浮动占位置
      padding: 20px;
      // border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        border: 2px solid transparent;
        float: left;
        // border: 1px solid red;
        margin-right: 30px;
        margin-top: 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }
        p {
          text-align: center;
        }
      }
      li:hover {
        border: 2px solid #ef0c25;
        border-radius: 8px;
      }
      li:hover p {
        color: #ef0c25;
      }
    }
  }
}
.selectCategory {
  background-color: #f1f1f1;
}
.input-with-select {
  min-width: 300px;
  max-width: 450px;
}
</style>
